import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkActiveUser } from "../store/slices/authSlice";
import {
  fetchInstallmentsByPlotId,
  fetchPaidInstallmentsForUser,
} from "../store/slices/installmentSlice";
import { BookCopy, HandCoins, ReceiptText } from "lucide-react";

const PaidInstallments = () => {
  const dispatch = useDispatch();
  const { activeUser, isAuthenticated, error } = useSelector(
    (state) => state.auth
  );
  // console.log(activeUser);
  const { installments, error: installmentsError } = useSelector(
    (state) => state.installments
  );
  const [totals, setTotals] = useState({
    totalPaidAmount: 0,
    totalPendingInstallmentsAmount: 0,
    totalPlotAmount: 0,
  });
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(checkActiveUser());
    }
  }, [dispatch, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated && activeUser && activeUser.id) {
      const customerId = activeUser.id;
      // console.log("Fetching data for customerId:", customerId);

      // Add a null check for plots
      if (
        activeUser.plots &&
        Array.isArray(activeUser.plots) &&
        activeUser.plots.length > 0
      ) {
        activeUser.plots.forEach((plot) => {
          const plotId = plot._id;
          // console.log("Fetching data for plotId:", plotId);

          dispatch(fetchPaidInstallmentsForUser({ customerId, plotId }))
            .unwrap()
            .then((result) => {
              // console.log("Dispatch Result for plot:", plotId, result);
              setTotals((prevTotals) => ({
                ...prevTotals,
                [plotId]: {
                  totalPaidAmount: result.totalPaidAmount || 0,
                  totalPendingInstallmentsAmount:
                    result.totalPendingInstallmentsAmount || 0,
                  totalPlotAmount: result.totalPlotAmount || 0,
                },
              }));
            })
            .catch((error) => {
              console.error("Detailed Dispatch Error:", error);
              alert("There was an issue fetching your data.");
            });
        });
      } else {
        console.error("No plots available for this user.", activeUser);
      }
    } else {
      console.error(
        "Selected customer ID is invalid or user data is incomplete.",
        {
          isAuthenticated,
          activeUser,
          userId: activeUser?.id,
        }
      );
    }
  }, [dispatch, isAuthenticated, activeUser]);
  const handlePlotClick = (plotId) => {
    dispatch(fetchInstallmentsByPlotId(plotId));
    const plot = activeUser.plots.find((plot) => plot._id === plotId);
    setSelectedPlot(plot);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-400 bg-red-400/10 px-6 py-3 rounded-lg backdrop-blur-sm border border-red-400/20">
          Error: {error}
        </div>
      </div>
    );
  }

  if (!activeUser) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-teal-300/70 bg-slate-800/50 px-6 py-3 rounded-lg backdrop-blur-sm">
          No user data available.
        </div>
      </div>
    );
  }

  // console.log(installments);

  const userPlots = activeUser.plots || [];

  const filteredInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      ["Pending", "Partially Paid", "Overdue"].includes(installment.status)
  );

  const completeInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      installment.status === "Completed"
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "text-yellow-400 bg-yellow-400/10 border-yellow-400/20";
      case "Partially Paid":
        return "text-blue-400 bg-blue-400/10 border-blue-400/20";
      case "Overdue":
        return "text-red-400 bg-red-400/10 border-red-400/20";
      case "Completed":
        return "text-emerald-400 bg-emerald-400/10 border-emerald-400/20";
      default:
        return "text-gray-400 bg-gray-400/10 border-gray-400/20";
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-300 p-6 md:p-8 lg:p-12">
      {activeUser && activeUser.role === "Customer" && (
        <div className="space-y-8">
          <div className="flex items-center gap-4 mb-6">
            <div className="h-12 w-12 rounded-full bg-teal-500/20 flex items-center justify-center">
              <svg
                className="w-6 h-6 text-teal-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </div>
            <div>
              <h2 className="text-4xl font-bold text-white">
                Welcome, {activeUser?.additionalData?.name || "Guest"}
              </h2>
              <p className="text-gray-400">
                Managing {userPlots.length} plot
                {userPlots.length !== 1 && "s"}
              </p>
            </div>
          </div>

          {userPlots.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {userPlots.map((plot) => {
                // Match the society for this plot
                const society = activeUser.societies.find(
                  (society) => society._id === plot.society_id
                );
                const plotTotals = totals[plot._id] || {};
                return (
                  <div
                    key={plot._id}
                    className={`relative group cursor-pointer transition-all duration-500 ease-in-out ${
                      selectedPlot?._id === plot._id
                        ? "bg-gradient-to-r from-teal-600/30 to-gray-600/30 "
                        : "bg-gray-800/20 hover:bg-gray-800/30 "
                    }`}
                    onClick={() => handlePlotClick(plot._id)}
                    onMouseEnter={() => setHoveredCard(plot._id)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-teal-300/10 to-emerald-300/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    <div className="relative bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                      <div className="absolute inset-0 bg-gradient-to-br from-teal-300/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-xl" />
                      <h4 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300 mb-4">
                        Plot {plot.plot_number}
                      </h4>
                      <div className="space-y-2 text-teal-300/80">
                        <p className="flex items-center justify-between">
                          <span>Category</span>
                          <span className="font-semibold">{plot.category}</span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Price</span>
                          <span className="font-semibold">
                            PKR {plot.price.toLocaleString()}
                          </span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Status</span>
                          <span
                            className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                              plot.status
                            )}`}
                          >
                            {plot.status}
                          </span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Society</span>
                          <span className="font-semibold">
                            {society ? society.name : "Unknown"}
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <BookCopy
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Paid Amount:
                          </span>
                          <span className="text-white font-semibold">
                            {plotTotals.totalPaidAmount} PKR
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <HandCoins
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Pending Installments Amount:
                          </span>
                          <span className="text-white font-semibold">
                            {plotTotals.totalPendingInstallmentsAmount} PKR
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <ReceiptText
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Plot Amount:
                          </span>
                          <span className="text-white font-semibold">
                            {plotTotals.totalPlotAmount} PKR
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-12 bg-slate-800/50 backdrop-blur-sm rounded-xl border border-teal-300/10">
              <p className="text-teal-300/70">
                No plots available for this customer.
              </p>
            </div>
          )}

          {selectedPlot && (
            <div className="mt-12 space-y-8">
              <h3 className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-teal-300 to-emerald-300">
                Installment Details for Plot {selectedPlot.plot_number}
              </h3>

              {filteredInstallments.length > 0 && (
                <div className="bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                  <h4 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300 mb-6">
                    Current Installments
                  </h4>
                  <div className="space-y-4">
                    {filteredInstallments.map((installment) => (
                      <div
                        key={installment._id}
                        className="group bg-slate-700/50 hover:bg-slate-700/70 backdrop-blur-sm p-5 rounded-lg border border-teal-300/10 transition-all duration-300 hover:scale-[1.02]"
                      >
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                          <div>
                            <p className="text-teal-300/60 text-sm">Amount</p>
                            <p className="font-semibold">
                              PKR {installment.amount.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">Status</p>
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                                installment.status
                              )}`}
                            >
                              {installment.status}
                            </span>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">Due Date</p>
                            <p className="font-semibold">
                              {new Date(
                                installment.due_date
                              ).toLocaleDateString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">
                              Remaining Amount
                            </p>
                            <p className="font-semibold">
                              PKR{" "}
                              {installment.remaining_amount
                                ? installment.remaining_amount.toLocaleString()
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {completeInstallments.length > 0 && (
                <div className="bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                  <h4 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300 mb-6">
                    Completed Installments
                  </h4>
                  <div className="space-y-4">
                    {completeInstallments.map((installment) => (
                      <div
                        key={installment._id}
                        className="group bg-slate-700/50 hover:bg-slate-700/70 backdrop-blur-sm p-5 rounded-lg border border-teal-300/10 transition-all duration-300 hover:scale-[1.02]"
                      >
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                          <div>
                            <p className="text-teal-300/60 text-sm">Amount</p>
                            <p className="font-semibold">
                              PKR {installment.amount.toLocaleString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">Status</p>
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                                installment.status
                              )}`}
                            >
                              {installment.status}
                            </span>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">
                              Paid Date
                            </p>
                            <p className="font-semibold">
                              {new Date(
                                installment.due_date
                              ).toLocaleDateString()}
                            </p>
                          </div>
                          <div>
                            <p className="text-teal-300/60 text-sm">
                              Paid Amount
                            </p>
                            <p className="font-semibold">
                              PKR{" "}
                              {installment.paid_amount
                                ? installment.paid_amount.toLocaleString()
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {installmentsError && (
        <div className="mt-8 text-center">
          <div className="inline-block text-red-400 bg-red-400/10 px-6 py-3 rounded-lg backdrop-blur-sm border border-red-400/20">
            Error fetching installments: {installmentsError}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaidInstallments;
