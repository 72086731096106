import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { fetchAllUsers, registerUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import {
  User,
  Phone,
  Camera,
  Lock,
  BookUser,
  UserRoundPen,
  IdCard,
  ChevronUp,
  ChevronDown,
  Eye,
  EyeOff,
  MapPinHouse,
  CalendarClock,
  CreditCard,
  Shield,
} from "lucide-react";

const EmployeeCreation = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);
  const [profileImage, setProfileImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { users, status } = useSelector((state) => state.auth);

  // Designations from the previous component
  const designations = ["Manager", "Sales Officer", "Other"];

  useEffect(() => {
    dispatch(fetchSocieties());
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, status, users.length]);

  const handleProfileImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatCNIC = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.slice(0, 13);
    if (truncatedValue.length <= 5) {
      return truncatedValue;
    } else if (truncatedValue.length <= 12) {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`;
    } else {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
        5,
        12
      )}-${truncatedValue.slice(12)}`;
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    employeeData: Yup.object().shape({
      name: Yup.string().required("Employee name is required"),
      designation: Yup.string()
        .required("Designation is required")
        .oneOf(designations, "Invalid designation"),
      personalDetails: Yup.object().shape({
        phone: Yup.string()
          .required("Phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Phone number must be in format +92 3XX-XXXXXXX"
          ),
        address: Yup.string().required("Address is required"),
        dateOfJoining: Yup.date().required("Date of joining is required"),
        cnic: Yup.string()
          .required("CNIC is required")
          .matches(
            /^\d{5}-\d{7}-\d{1}$/,
            "CNIC must be in format XXXXX-XXXXXXX-X"
          ),
      }),
      salaryDetails: Yup.object().shape({
        salary: Yup.number()
          .required("Salary is required")
          .positive("Salary must be a positive number"),
      }),
    }),
    societies: Yup.array()
      .min(1, "At least one society must be selected")
      .required("Society selection is required"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      // Register user first
      const userResult = await dispatch(
        registerUser({
          ...values,
          role: "Employee",
          profile_picture: profileImage,
        })
      );
      if (userResult.payload && userResult.payload.user) {
        const newUser = userResult.payload.user;
        const employeeData = {
          ...values.employeeData,
          _id: newUser._id,
        };
        if (!newUser._id) {
          throw new Error("Failed to get user ID.");
        }

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Employee registered successfully",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#5EEAD4",
          confirmButtonColor: "#008000",
        });

        resetForm();
        setProfileImage(null);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Registration failed",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
        confirmButtonColor: "#f27474",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSocietyChange = (option, setFieldValue, values) => {
    const currentSocieties = values.societies || [];
    const updatedSocieties = currentSocieties.includes(option._id)
      ? currentSocieties.filter((id) => id !== option._id)
      : [...currentSocieties, option._id];

    setFieldValue("societies", updatedSocieties);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="min-h-screen py-12 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="relative container mx-auto px-4">
        <Formik
          initialValues={{
            username: "",
            password: "",
            role: "Employee",
            societies: [],
            profile_picture: "",
            employeeData: {
              name: "",
              designation: "",
              personalDetails: {
                phone: "",
                address: "",
                dateOfJoining: "",
                cnic: "",
              },
              salaryDetails: {
                salary: "",
                lastPaidDate: null,
                salarySlip: "",
              },
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className="relative max-w-4xl mx-auto backdrop-blur-xl bg-white/10 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-8 border border-white/20 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none"></div>

              <div className="relative">
                <h2 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-teal-300 via-teal-300 to-teal-400 text-transparent bg-clip-text">
                  Employee Registration
                </h2>
                <p className="text-teal-300/60 text-center mb-8">
                  Join our society today
                </p>
                <div className="mb-12 flex flex-col items-center">
                  <div className="relative group">
                    <div className="relative w-32 h-32">
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile Preview"
                          className="w-full h-full rounded-full object-cover ring-4 ring-teal-300/50"
                        />
                      ) : (
                        <div className="w-full h-full rounded-full bg-gradient-to-br from-slate-700/50 to-slate-800/50 flex items-center justify-center ring-4 ring-teal-300/50 backdrop-blur-sm">
                          <Camera size={40} className="text-teal-300" />
                        </div>
                      )}
                      <label className="absolute bottom-0 right-0 bg-gradient-to-r from-teal-400 to-cyan-400 p-2 rounded-full cursor-pointer hover:scale-110 transition-transform duration-300 shadow-lg">
                        <Camera size={20} className="text-white" />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleProfileImageChange(event, setFieldValue)
                          }
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* Form Content */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Left Column - Basic Information */}
                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-teal-300 mb-4">
                      Basic Information
                    </h3>

                    {/* Username Field */}
                    <div className="relative flex items-center">
                      <User
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="username"
                        placeholder="Username"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Password Field */}
                    <div className="relative flex items-center">
                      <Lock
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-3"
                      >
                        {showPassword ? (
                          <Eye size={20} className="text-teal-300" />
                        ) : (
                          <EyeOff size={20} className="text-teal-300" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Designation Dropdown */}
                    <div className="relative flex items-center">
                      <Shield
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        as="select"
                        name="employeeData.designation"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      >
                        <option value="">Select Designation</option>
                        {designations.map((designation) => (
                          <option key={designation} value={designation}>
                            {designation}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="employeeData.designation"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Society Selection */}
                    <h3 className="text-xl font-semibold text-teal-300 mb-4">
                      Society Information
                    </h3>
                    <div className="relative">
                      <div className="flex items-center border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50">
                        <MapPinHouse
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <input
                          type="text"
                          readOnly
                          placeholder="Societies"
                          value={values.societies
                            .map(
                              (id) =>
                                societies.find((society) => society._id === id)
                                  ?.name
                            )
                            .join(", ")}
                          onClick={toggleDropdown}
                          className="block w-full max-w-2xl pl-10 pr-4 py-3 border border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50"
                        />
                        <span
                          className="absolute right-2 cursor-pointer"
                          onClick={toggleDropdown}
                        >
                          {isOpen ? (
                            <ChevronUp size={20} className="text-teal-300" />
                          ) : (
                            <ChevronDown size={20} className="text-teal-300" />
                          )}
                        </span>
                      </div>
                      {isOpen && (
                        <ul className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 outline-none transition-all duration-300 backdrop-blur-md">
                          <li className="px-4 py-2 text-gray-500">
                            Select Society
                          </li>
                          {societies.map((option) => (
                            <li
                              key={option._id}
                              onClick={() =>
                                handleSocietyChange(
                                  option,
                                  setFieldValue,
                                  values
                                )
                              }
                              className="block w-full py-2 pl-10 pr-4 gap-2 border cursor-pointer border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50"
                            >
                              {option.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <ErrorMessage
                      name="societies"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-teal-300 mb-4">
                      Contact Information
                    </h3>

                    {/* Employee Name */}
                    <div className="relative flex items-center">
                      <UserRoundPen
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.name"
                        placeholder="Employee Name"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Phone Number */}
                    {/* Phone Number Field */}
                    <div className="relative flex items-center">
                      <Phone
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="tel"
                        name="employeeData.personalDetails.phone"
                        placeholder="+92 3XX-XXXXXXX"
                        autoComplete="off"
                        value={values.employeeData.personalDetails.phone}
                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/[^\d]/g, "");
                          if (!rawValue.startsWith("92")) {
                            rawValue = `92${rawValue}`;
                          }
                          const formattedValue = `+${rawValue.slice(
                            0,
                            2
                          )} ${rawValue.slice(2, 5)}-${rawValue.slice(5, 12)}`;
                          setFieldValue(
                            "employeeData.personalDetails.phone",
                            formattedValue
                          );
                        }}
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.phone"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* CNIC */}
                    <div className="relative flex items-center">
                      <IdCard
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.personalDetails.cnic"
                        placeholder="XXXXX-XXXXXXX-X"
                        autoComplete="off"
                        value={formatCNIC(
                          values.employeeData.personalDetails.cnic
                        )}
                        onChange={(e) => {
                          const formattedValue = formatCNIC(e.target.value);
                          setFieldValue(
                            "employeeData.personalDetails.cnic",
                            formattedValue
                          );
                        }}
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.cnic"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Address */}
                    <div className="relative flex items-center">
                      <BookUser
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.personalDetails.address"
                        placeholder="Employee Address"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.address"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Date of Joining */}
                    <div className="relative flex items-center">
                      <CalendarClock
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="date"
                        name="employeeData.personalDetails.dateOfJoining"
                        placeholder="Employee Joining Date"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.dateOfJoining"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Salary */}
                    <div className="relative flex items-center">
                      <CreditCard
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="number"
                        name="employeeData.salaryDetails.salary"
                        placeholder="Employee Salary"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.salaryDetails.salary"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="col-span-2 flex items-center justify-center mt-8">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="py-3 px-8 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold text-lg shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                    >
                      {isSubmitting ? "Registering..." : "Register Employee"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmployeeCreation;
