import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  WalletCards,
  Loader2,
  AlertCircle,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import { fetchTransactions } from "../store/slices/transactionSlice";

const PaymentsExpense = () => {
  const dispatch = useDispatch();
  const { transactions, loading, error } = useSelector(
    (state) => state.transactions
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  // Format PKR amount with commas
  const formatPKR = (amount) => {
    return new Intl.NumberFormat("en-PK", {
      style: "currency",
      currency: "PKR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const paymentTransactions = transactions.filter((t) =>
    ["Full Payment", "Partial Payment", "Installment Payment"].includes(
      t.transaction_type
    )
  );

  // Calculate total amount
  const totalAmount = paymentTransactions.reduce(
    (sum, transaction) => sum + transaction.amount,
    0
  );

  // Pagination Logic
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = paymentTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const totalPages = Math.ceil(paymentTransactions.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 flex items-center justify-center p-6">
        <div className="w-full max-w-md">
          <div className="bg-red-500/10 border border-red-500/20 rounded-2xl p-6 flex items-center space-x-4 shadow-2xl">
            <AlertCircle className="w-8 h-8 text-red-500 animate-pulse" />
            <div>
              <p className="text-red-400 font-medium text-lg">Error Occurred</p>
              <p className="text-red-300 text-sm mt-1">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-12 px-4 sm:px-6 lg:px-8 rounded-xl shadow-lg">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4">
            <div className="bg-teal-500/20 p-3.5 rounded-xl shadow-lg">
              <WalletCards className="w-7 h-7 text-teal-300" />
            </div>
            <h2 className="text-4xl font-extrabold text-white tracking-tight">
              Payment Transactions
            </h2>
          </div>
          {!loading && paymentTransactions.length > 0 && (
            <div className="text-right bg-gray-800/50 rounded-xl p-4 border border-teal-500/20 shadow-xl">
              <p className="text-sm text-teal-400 mb-1 uppercase tracking-wider">
                Total Amount
              </p>
              <p className="text-3xl font-bold text-teal-300 tracking-tighter">
                {formatPKR(totalAmount)}
              </p>
            </div>
          )}
        </div>

        {/* Transactions Container */}
        <div className="bg-gray-800/50 rounded-3xl border border-gray-700/50 shadow-2xl backdrop-blur-xl p-6 md:p-8">
          {loading ? (
            <div className="flex flex-col items-center justify-center py-16">
              <Loader2 className="w-12 h-12 text-teal-400 animate-spin mb-6" />
              <p className="text-teal-300 text-xl font-semibold tracking-wide">
                Loading Transactions...
              </p>
            </div>
          ) : paymentTransactions.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-16">
              <WalletCards className="w-16 h-16 text-gray-600 mb-6" />
              <p className="text-teal-300 text-2xl font-semibold tracking-wide">
                No Payment Transactions
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="overflow-x-auto rounded-2xl border border-gray-700/50">
                <table className="w-full">
                  <thead className="bg-gray-900/60">
                    <tr>
                      {[
                        "Name",
                        "Society Name",
                        "Transaction Type",
                        "Amount",
                        "Date",
                      ].map((header) => (
                        <th
                          key={header}
                          className="px-6 py-4 text-left text-xs font-bold text-teal-400 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700/50">
                    {currentTransactions.map((transaction) => (
                      <tr
                        key={transaction._id}
                        className="hover:bg-teal-500/10 transition-colors duration-300"
                      >
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.transaction_type === "Salary Payment" ? (
                            <>
                              Employee
                              <br />
                              <span className="text-md font-semibold">
                                {transaction.employee_id?.name || "No Employee"}
                              </span>
                            </>
                          ) : transaction.transaction_type ===
                            "Expense Payment" ? (
                            <>
                              Investor
                              <br />
                              <span className="text-md font-semibold">
                                {transaction.user_id?.investorData?.name ||
                                  "No Investor"}
                              </span>
                            </>
                          ) : (
                            <>
                              Customer
                              <br />
                              <span className="text-md font-semibold">
                                {transaction.customer_id?.name || "No Customer"}
                              </span>
                            </>
                          )}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.transaction_type === "Salary Payment"
                            ? transaction.employee_id?.society?.name ||
                              "No Society"
                            : transaction.transaction_type ===
                                "Expense Payment" &&
                              transaction.user_id?.investorData?.societies &&
                              transaction.user_id.investorData.societies
                                .length > 0
                            ? transaction.user_id.investorData.societies
                                .map((society) => society.name)
                                .join(", ")
                            : transaction.transaction_type === "Expense Payment"
                            ? "No Society"
                            : transaction.customer_id?.societies &&
                              transaction.customer_id.societies.length > 0
                            ? transaction.customer_id.societies
                                .map((society) => society.name)
                                .join(", ")
                            : "No Society"}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.transaction_type}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {formatPKR(transaction.amount)}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {new Date(
                            transaction.transaction_date
                          ).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              <div className="flex items-center justify-center space-x-6 mt-8">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="group flex items-center space-x-2 px-6 py-3 bg-teal-500/20 text-teal-300 rounded-xl 
                             hover:bg-teal-500/30 transition-all duration-300 
                             disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronsLeft className="w-5 h-5 group-hover:-translate-x-1 transition-transform" />
                  <span>Previous</span>
                </button>
                <span className="text-teal-300 font-medium tracking-wider">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="group flex items-center space-x-2 px-6 py-3 bg-teal-500/20 text-teal-300 rounded-xl 
                             hover:bg-teal-500/30 transition-all duration-300 
                             disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span>Next</span>
                  <ChevronsRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentsExpense;
