import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

// Async actions for fetching, creating, updating, and deleting resells
export const fetchResells = createAsyncThunk(
  "resell/fetchResells",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/resell/resells");
      return response.data;
    } catch (error) {
      console.error("Error fetching resells:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch resells");
    }
  }
);

export const fetchResellById = createAsyncThunk(
  "resell/fetchResellById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/resell/resells/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching resell by ID:", error);
      return rejectWithValue(error.response?.data || "Failed to fetch resell");
    }
  }
);

export const createResell = createAsyncThunk(
  "resell/createResell",
  async (resellData, { rejectWithValue }) => {
    try {
      console.log("Creating resell with data:", resellData); // Debugging log
      const response = await api.post("/resell/resells", resellData);
      return response.data;
    } catch (error) {
      console.error("Error creating resell:", error);
      return rejectWithValue(error.response?.data || "Failed to create resell");
    }
  }
);

export const updateResell = createAsyncThunk(
  "resell/updateResell",
  async ({ id, resellData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/resell/resells/${id}`, resellData);
      return response.data;
    } catch (error) {
      console.error("Error updating resell:", error);
      return rejectWithValue(error.response?.data || "Failed to update resell");
    }
  }
);

export const deleteResell = createAsyncThunk(
  "resell/deleteResell",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/resell/resells/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting resell:", error);
      return rejectWithValue(error.response?.data || "Failed to delete resell");
    }
  }
);

const resellSlice = createSlice({
  name: "resell",
  initialState: {
    resells: [],
    resell: null,
    status: "idle",
    error: null,
  },
  reducers: {
    clearResell: (state) => {
      state.resell = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResells.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchResells.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resells = action.payload;
      })
      .addCase(fetchResells.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchResellById.fulfilled, (state, action) => {
        state.resell = action.payload;
      })
      .addCase(createResell.fulfilled, (state, action) => {
        state.resells.push(action.payload.resell);
      })
      .addCase(updateResell.fulfilled, (state, action) => {
        const index = state.resells.findIndex(
          (resell) => resell._id === action.payload.resell._id
        );
        if (index !== -1) state.resells[index] = action.payload.resell;
      })
      .addCase(deleteResell.fulfilled, (state, action) => {
        state.resells = state.resells.filter(
          (resell) => resell._id !== action.meta.arg
        );
      });
  },
});

export const { clearResell } = resellSlice.actions;

export default resellSlice.reducer;
