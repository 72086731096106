import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addScholarship } from "../store/slices/scholarshipSlice";
import { useSnackbar } from "notistack";

const AddScholarship = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    cnic: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      let rawValue = value.replace(/[^\d]/g, "");
      if (!rawValue.startsWith("92")) {
        rawValue = `92${rawValue}`;
      }
      const formattedValue = `+${rawValue.slice(0, 2)} ${rawValue.slice(
        2,
        5
      )}-${rawValue.slice(5, 12)}`; // Format phone number
      setFormData({ ...formData, [name]: formattedValue });
    } else if (name === "cnic") {
      const cleanedValue = value.replace(/\D/g, "");
      const truncatedValue = cleanedValue.slice(0, 13);
      if (truncatedValue.length <= 5) {
        setFormData({ ...formData, [name]: truncatedValue });
      } else if (truncatedValue.length <= 12) {
        setFormData({
          ...formData,
          [name]: `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`,
        });
      } else {
        setFormData({
          ...formData,
          [name]: `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
            5,
            12
          )}-${truncatedValue.slice(12)}`,
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addScholarship(formData));

      // Show success notification
      enqueueSnackbar("Application submitted successfully! 🎉", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });

      // Reset form
      setFormData({ name: "", phone: "", email: "", cnic: "", address: "" });
    } catch (error) {
      // Show error notification
      enqueueSnackbar("Error submitting application. Please try again. ⚠️", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-slate-900/50 backdrop-blur-xl rounded-3xl shadow-2xl border border-slate-700/50">
          {/* Header Section */}
          <div className="text-center py-10 px-6 border-b border-slate-700/50">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-teal-300 via-teal-200 to-teal-300 bg-clip-text text-transparent mb-4">
              Scholarship Application Form
            </h2>
            <p className="text-slate-400 text-lg max-w-2xl mx-auto">
              Take the first step towards your academic future. Fill out the
              form below with your details.
            </p>
          </div>

          {/* Form Section */}
          <form onSubmit={handleSubmit} className="p-8 lg:p-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-2">
                <label className="block text-teal-300 text-sm font-medium mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-5 py-4 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-300 focus:border-transparent text-slate-200 placeholder-slate-500 transition duration-200"
                  placeholder="Enter your full name"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block text-teal-300 text-sm font-medium mb-2">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-5 py-4 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-300 focus:border-transparent text-slate-200 placeholder-slate-500 transition duration-200"
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block text-teal-300 text-sm font-medium mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-5 py-4 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-300 focus:border-transparent text-slate-200 placeholder-slate-500 transition duration-200"
                  placeholder="Enter your email address"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block text-teal-300 text-sm font-medium mb-2">
                  CNIC Number
                </label>
                <input
                  type="text"
                  name="cnic"
                  value={formData.cnic}
                  onChange={handleChange}
                  className="w-full px-5 py-4 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-300 focus:border-transparent text-slate-200 placeholder-slate-500 transition duration-200"
                  placeholder="Enter your CNIC number"
                  required
                />
              </div>
              <div className="md:col-span-2 space-y-2">
                <label className="block text-teal-300 text-sm font-medium mb-2">
                  Complete Address
                </label>
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-5 py-4 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-300 focus:border-transparent text-slate-200 placeholder-slate-500 transition duration-200 resize-none"
                  placeholder="Enter your complete address"
                  required
                ></textarea>
              </div>
            </div>

            {/* Submit Button Section */}
            <div className="mt-12">
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-teal-400 via-teal-500 to-teal-400 text-white text-lg font-semibold py-4 px-8 rounded-xl hover:from-teal-500 hover:via-teal-600 hover:to-teal-500 transition-all duration-300 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2 focus:ring-offset-slate-900 shadow-lg hover:shadow-teal-500/25"
              >
                Submit Application
              </button>
              <p className="text-slate-400 text-sm text-center mt-4">
                By submitting this form, you agree to our terms and conditions
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddScholarship;
