// import {
//   Avatar,
//   Box,
//   IconButton,
//   Typography,
//   useTheme,
//   AppBar,
//   Toolbar,
//   alpha,
//   Tooltip,
//   Badge,
//   Divider,
// } from "@mui/material";
// import { useContext, useEffect, useState } from "react";
// import { ColorModeContext, tokens } from "../../theme";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import MenuIcon from "@mui/icons-material/Menu";
// import Logout from "../../Auth/Logout";
// import { useDispatch, useSelector } from "react-redux";
// import { checkActiveUser } from "../../store/slices/authSlice";
// import { fetchSocieties } from "../../store/slices/societySlice";
// import { useSidebarContext } from "./sidebar/SidebarContext";
// import { useProSidebar } from "react-pro-sidebar";

// const Topbar = ({ onLogout }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const colorMode = useContext(ColorModeContext);
//   const { activeUser, availableSocieties, isAuthenticated } = useSelector(
//     (state) => state.auth
//   );
//   const [selectedSociety, setSelectedSociety] = useState("");
//   const dispatch = useDispatch();
//   const { societies } = useSelector((state) => state.societies);
//   const { setActiveSociety, activeSociety } = useSidebarContext();
//   const { toggleSidebar, broken } = useProSidebar();

//   useEffect(() => {
//     dispatch(checkActiveUser());
//     dispatch(fetchSocieties());
//   }, [dispatch]);

//   useEffect(() => {
//     dispatch(checkActiveUser());
//     dispatch(fetchSocieties());
//   }, [dispatch]);

//   const handleSocietyChange = (event) => {
//     const societyName = event.target.value;
//     const selectedSocietyObj = societies.find(
//       (society) => society.name === societyName
//     );

//     if (selectedSocietyObj) {
//       setSelectedSociety(societyName);
//       setActiveSociety(selectedSocietyObj);
//     }
//   };

//   return (
//     <AppBar
//       position="sticky"
//       sx={{
//         background:
//           theme.palette.mode === "dark"
//             ? alpha(colors.primary[400], 0.95)
//             : alpha(colors.primary[400], 0.95),
//         backdropFilter: "blur(6px)",
//         boxShadow:
//           "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
//         transition: "all 0.3s ease-in-out",
//       }}
//     >
//       <Toolbar
//         sx={{
//           justifyContent: "space-between",
//           padding: { xs: 2, md: "16px 24px" },
//         }}
//       >
//         {/* Left Section */}
//         <Box display="flex" alignItems="center" gap={2}>
//           <IconButton
//             edge="start"
//             color="inherit"
//             onClick={broken ? toggleSidebar : undefined}
//             sx={{
//               mr: 2,
//               "&:hover": {
//                 backgroundColor: alpha(colors.grey[100], 0.1),
//               },
//               transition: "background-color 0.2s ease",
//             }}
//           >
//             <MenuIcon />
//           </IconButton>

//           <div className="relative min-w-[200px]">
//             <label
//               htmlFor="society-select"
//               className="absolute -top-2 left-2 px-1 text-xs text-gray-200 bg-gray-800"
//             >
//               Select Society
//             </label>
//             <select
//               id="society-select"
//               value={selectedSociety}
//               onChange={handleSocietyChange}
//               className="w-full px-4 py-2 bg-opacity-10 hover:bg-opacity-20
//                  border border-gray-600 rounded-md
//                  text-gray-200
//                  focus:outline-none focus:border-gray-400
//                  appearance-none cursor-pointer
//                  transition-all duration-200
//                  bg-gray-700"
//             >
//               <option value="" disabled>
//                 Select Society
//               </option>
//               {societies.map((society) => (
//                 <option
//                   key={society.id}
//                   value={society.name}
//                   className="bg-gray-800 text-gray-200 hover:bg-gray-700"
//                 >
//                   {society.name}
//                 </option>
//               ))}
//             </select>
//             <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
//               <svg
//                 className="w-4 h-4 text-gray-200"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M19 9l-7 7-7-7"
//                 />
//               </svg>
//             </div>
//           </div>
//         </Box>

//         {/* Right Section */}
//         <Box display="flex" alignItems="center" gap={1}>
//           <Tooltip title="Notifications">
//             <IconButton
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               <Badge
//                 badgeContent={4}
//                 color="error"
//                 sx={{
//                   "& .MuiBadge-badge": {
//                     transition: "transform 0.2s ease",
//                     "&:hover": {
//                       transform: "scale(1.1)",
//                     },
//                   },
//                 }}
//               >
//                 <NotificationsOutlinedIcon />
//               </Badge>
//             </IconButton>
//           </Tooltip>

//           <Tooltip title="Toggle theme">
//             <IconButton
//               onClick={colorMode.toggleColorMode}
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               {theme.palette.mode === "dark" ? (
//                 <DarkModeOutlinedIcon />
//               ) : (
//                 <LightModeOutlinedIcon />
//               )}
//             </IconButton>
//           </Tooltip>

//           <Tooltip title="Settings">
//             <IconButton
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               <SettingsOutlinedIcon />
//             </IconButton>
//           </Tooltip>

//           <Divider
//             orientation="vertical"
//             flexItem
//             sx={{ mx: 1, bgcolor: alpha(colors.grey[100], 0.2) }}
//           />

//           {isAuthenticated && activeUser && (
//             <Box
//               display="flex"
//               alignItems="center"
//               gap={2}
//               sx={{
//                 padding: "8px 16px",
//                 borderRadius: "8px",
//                 transition: "all 0.2s ease",
//                 "&:hover": {
//                   backgroundColor: alpha(colors.grey[100], 0.1),
//                 },
//                 cursor: "pointer",
//               }}
//             >
//               <Box>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{
//                     color: colors.grey[100],
//                     fontWeight: 600,
//                   }}
//                 >
//                   {activeUser.username}
//                 </Typography>
//                 <Typography
//                   variant="caption"
//                   sx={{
//                     color: alpha(colors.grey[100], 0.7),
//                     display: "block",
//                   }}
//                 >
//                   {activeUser.designation || activeUser.role}
//                 </Typography>
//               </Box>

//               <Avatar
//                 sx={{
//                   width: 40,
//                   height: 40,
//                   border: `2px solid ${alpha(colors.grey[100], 0.2)}`,
//                   backgroundColor: colors.primary[500],
//                   transition: "transform 0.2s ease",
//                   "&:hover": {
//                     transform: "scale(1.05)",
//                   },
//                 }}
//                 src={activeUser.profileImage}
//               >
//                 {!activeUser.profileImage &&
//                   activeUser.username.charAt(0).toUpperCase()}
//               </Avatar>
//             </Box>
//           )}

//           <Logout onLogout={onLogout} />
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Topbar;

// import {
//   Avatar,
//   Box,
//   IconButton,
//   Typography,
//   useTheme,
//   AppBar,
//   Toolbar,
//   alpha,
//   Tooltip,
//   Badge,
//   Divider,
// } from "@mui/material";
// import { useContext, useEffect, useState } from "react";
// import { ColorModeContext, tokens } from "../../theme";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import MenuIcon from "@mui/icons-material/Menu";
// import Logout from "../../Auth/Logout";
// import { useDispatch, useSelector } from "react-redux";
// import { checkActiveUser } from "../../store/slices/authSlice";
// import { fetchSocieties } from "../../store/slices/societySlice";
// import { useSidebarContext } from "./sidebar/SidebarContext";
// import { useProSidebar } from "react-pro-sidebar";

// const Topbar = ({ onLogout }) => {
// const theme = useTheme();
// const colors = tokens(theme.palette.mode);
// const colorMode = useContext(ColorModeContext);
// const { activeUser, isAuthenticated } = useSelector((state) => state.auth);
// const [selectedSociety, setSelectedSociety] = useState("");
// const dispatch = useDispatch();
// const { societies } = useSelector((state) => state.societies);
// const { setActiveSociety, activeSociety } = useSidebarContext();
// const { toggleSidebar, broken } = useProSidebar();

// useEffect(() => {
//   dispatch(checkActiveUser());
//   dispatch(fetchSocieties());
// }, [dispatch]);

// // If there's one society, set it as selected by default
// // If there's one society, set it as selected by default
// useEffect(() => {
//   if (activeUser && activeUser.societies.length === 1) {
//     const userSociety = activeUser.societies[0];
//     setSelectedSociety(userSociety.name); // Set the society based on activeUser
//     setActiveSociety(userSociety); // Set activeSociety in Sidebar context
//   }
// }, [activeUser, setActiveSociety]); // Only trigger if activeUser changes

// const handleSocietyChange = (event) => {
//   const societyName = event.target.value;
//   const selectedSocietyObj = societies.find(
//     (society) => society.name === societyName
//   );

//   if (selectedSocietyObj) {
//     setSelectedSociety(societyName);
//     setActiveSociety(selectedSocietyObj); // Update activeSociety in Sidebar context
//   }
// };

//   return (
//     <AppBar
//       position="sticky"
//       sx={{
//         background:
//           theme.palette.mode === "dark"
//             ? alpha(colors.primary[400], 0.95)
//             : alpha(colors.primary[400], 0.95),
//         backdropFilter: "blur(6px)",
//         boxShadow:
//           "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
//         transition: "all 0.3s ease-in-out",
//       }}
//     >
//       <Toolbar
//         sx={{
//           justifyContent: "space-between",
//           padding: { xs: 2, md: "16px 24px" },
//         }}
//       >
//         {/* Left Section */}
//         <Box display="flex" alignItems="center" gap={2}>
//           <IconButton
//             edge="start"
//             color="inherit"
//             onClick={broken ? toggleSidebar : undefined}
//             sx={{
//               mr: 2,
//               "&:hover": {
//                 backgroundColor: alpha(colors.grey[100], 0.1),
//               },
//               transition: "background-color 0.2s ease",
//             }}
//           >
//             <MenuIcon />
//           </IconButton>

//           <div className="relative min-w-[200px]">
//             <label
//               htmlFor="society-select"
//               className="absolute -top-2 left-2 px-1 text-xs text-gray-200 bg-gray-800"
//             >
//               Select Society
//             </label>
//             <select
//               id="society-select"
//               value={selectedSociety}
//               onChange={handleSocietyChange}
//               disabled={activeUser && activeUser.societies.length === 1} // Disable if only 1 society
//               className="w-full px-4 py-2 bg-opacity-10 hover:bg-opacity-20
//                  border border-gray-600 rounded-md
//                  text-gray-200
//                  focus:outline-none focus:border-gray-400
//                  appearance-none cursor-pointer
//                  transition-all duration-200
//                  bg-gray-700"
//             >
//               {societies.map((society) => (
//                 <option
//                   key={`${society.name}`}
//                   className="bg-gray-800 text-gray-200 hover:bg-gray-700"
//                 >
//                   {society.name}
//                 </option>
//               ))}
//             </select>
//             <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
//               <svg
//                 className="w-4 h-4 text-gray-200"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M19 9l-7 7-7-7"
//                 />
//               </svg>
//             </div>
//           </div>
//         </Box>

//         {/* Right Section */}
//         <Box display="flex" alignItems="center" gap={1}>
//           <Tooltip title="Notifications">
//             <IconButton
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               <Badge
//                 badgeContent={4}
//                 color="error"
//                 sx={{
//                   "& .MuiBadge-badge": {
//                     transition: "transform 0.2s ease",
//                     "&:hover": {
//                       transform: "scale(1.1)",
//                     },
//                   },
//                 }}
//               >
//                 <NotificationsOutlinedIcon />
//               </Badge>
//             </IconButton>
//           </Tooltip>

//           <Tooltip title="Toggle theme">
//             <IconButton
//               onClick={colorMode.toggleColorMode}
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               {theme.palette.mode === "dark" ? (
//                 <DarkModeOutlinedIcon />
//               ) : (
//                 <LightModeOutlinedIcon />
//               )}
//             </IconButton>
//           </Tooltip>

//           <Tooltip title="Settings">
//             <IconButton
//               sx={{
//                 color: colors.grey[100],
//                 transition: "background-color 0.2s ease",
//                 "&:hover": { backgroundColor: alpha(colors.grey[100], 0.1) },
//               }}
//             >
//               <SettingsOutlinedIcon />
//             </IconButton>
//           </Tooltip>

//           <Divider
//             orientation="vertical"
//             flexItem
//             sx={{ mx: 1, bgcolor: alpha(colors.grey[100], 0.2) }}
//           />

//           {isAuthenticated && activeUser && (
//             <Box
//               display="flex"
//               alignItems="center"
//               gap={2}
//               sx={{
//                 padding: "8px 16px",
//                 borderRadius: "8px",
//                 transition: "all 0.2s ease",
//                 "&:hover": {
//                   backgroundColor: alpha(colors.grey[100], 0.1),
//                 },
//                 cursor: "pointer",
//               }}
//             >
//               <Box>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{
//                     color: colors.grey[100],
//                     fontWeight: 600,
//                   }}
//                 >
//                   {activeUser.username}
//                 </Typography>
//                 <Typography
//                   variant="caption"
//                   sx={{
//                     color: alpha(colors.grey[100], 0.7),
//                     display: "block",
//                   }}
//                 >
//                   {activeUser.designation || activeUser.role}
//                 </Typography>
//               </Box>

//               <Avatar
//                 sx={{
//                   width: 40,
//                   height: 40,
//                   border: `2px solid ${alpha(colors.grey[100], 0.2)}`,
//                   backgroundColor: colors.primary[500],
//                   transition: "transform 0.2s ease",
//                   "&:hover": {
//                     transform: "scale(1.05)",
//                   },
//                 }}
//                 src={activeUser.profileImage}
//               >
//                 {!activeUser.profileImage &&
//                   activeUser.username.charAt(0).toUpperCase()}
//               </Avatar>
//             </Box>
//           )}

//           <Logout onLogout={onLogout} />
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Topbar;

// import React, { useEffect, useState } from "react";
// import {
//   Menu,
//   Bell,
//   ChevronDown,
//   User,
//   Settings,
//   Building2,
//   Check,
//   Trash2,
// } from "lucide-react";

// import { useSidebarContext } from "./sidebar/SidebarContext";
// import { useProSidebar } from "react-pro-sidebar";
// import Logout from "../../Auth/Logout";
// import { useDispatch, useSelector } from "react-redux";
// import { checkActiveUser } from "../../store/slices/authSlice";
// import { fetchSocieties } from "../../store/slices/societySlice";
// import {
//   getUserNotifications,
//   markAsRead,
//   deleteNotificationThunk,
// } from "../../store/slices/notificationSlice";

// const Topbar = ({ onLogout }) => {
//   // State Management
//   const [isProfileOpen, setIsProfileOpen] = useState(false);
//   const [isSocietyDropdownOpen, setIsSocietyDropdownOpen] = useState(false);
//   const [selectedSociety, setSelectedSociety] = useState("");
//   const [isNotificationOpen, setIsNotificationOpen] = useState(false);
//   const { notifications, loading } = useSelector(
//     (state) => state.notifications
//   );

//   // Redux State
//   const dispatch = useDispatch();
//   const { activeUser, isAuthenticated } = useSelector((state) => state.auth);
//   const { societies } = useSelector((state) => state.societies);

//   // Sidebar Context
//   const { setActiveSociety } = useSidebarContext();
//   const { toggleSidebar, broken } = useProSidebar();

//   // Initial Data Fetching
//   useEffect(() => {
//     if (isAuthenticated) {
//       dispatch(checkActiveUser());
//       dispatch(fetchSocieties());
//       if (activeUser?.role === "Admin") {
//         dispatch(getUserNotifications(null));
//       } else if (activeUser?.id) {
//         dispatch(getUserNotifications(activeUser.id));
//       }
//     }
//   }, [dispatch, isAuthenticated]);

//   // Set Default Society
//   useEffect(() => {
//     if (activeUser?.societies?.length === 1) {
//       const userSociety = activeUser.societies[0];
//       setSelectedSociety(userSociety.name);
//       setActiveSociety(userSociety);
//     }
//   }, [activeUser, setActiveSociety]);

//   // Handle Society Selection
//   const handleSocietyChange = (society) => {
//     if (society) {
//       setSelectedSociety(society.name);
//       setActiveSociety(society);
//       setIsSocietyDropdownOpen(false);
//     }
//   };

//   // Handle Click Outside
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         !event.target.closest(".profile-dropdown") &&
//         !event.target.closest(".society-dropdown")
//       ) {
//         setIsProfileOpen(false);
//         setIsSocietyDropdownOpen(false);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);
//     return () => document.removeEventListener("click", handleClickOutside);
//   }, []);

//   // Guard clause for unauthenticated users
//   if (!isAuthenticated || !activeUser) {
//     return null;
//   }

//   const handleMarkAsRead = (notificationId) => {
//     dispatch(markAsRead(notificationId));
//   };

//   // Delete Notification
//   const handleDeleteNotification = (notificationId) => {
//     dispatch(deleteNotificationThunk(notificationId));
//   };
//   return (
//     <nav className="sticky top-0 z-50 w-full bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-lg backdrop-blur-sm">
//       <div className="mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex h-16 items-center justify-between">
//           {/* Left Section */}
//           <div className="flex items-center space-x-4">
//             <button
//               className="p-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200"
//               onClick={() => broken && toggleSidebar()}
//             >
//               <Menu className="h-6 w-6 text-teal-300" />
//             </button>

//             {/* Society Selector */}
//             <div className="society-dropdown relative">
//               <button
//                 onClick={() => setIsSocietyDropdownOpen(!isSocietyDropdownOpen)}
//                 className="flex items-center space-x-2 bg-slate-700/50 text-teal-300 px-4 py-2 rounded-lg hover:bg-slate-700/70 transition-all duration-200"
//               >
//                 <Building2 className="h-5 w-5" />
//                 <span className="hidden sm:block">
//                   {selectedSociety || "Select Society"}
//                 </span>
//                 <ChevronDown
//                   className={`h-4 w-4 transition-transform duration-200 ${
//                     isSocietyDropdownOpen ? "rotate-180" : ""
//                   }`}
//                 />
//               </button>

//               {/* Society Dropdown */}
//               {isSocietyDropdownOpen && societies?.length > 0 && (
//                 <div className="absolute top-full left-0 mt-2 w-64 rounded-lg bg-slate-800 shadow-lg border border-slate-700 py-2 animate-fadeIn">
//                   {societies.map((society) => (
//                     <button
//                       key={society.id}
//                       onClick={() => handleSocietyChange(society)}
//                       className={`w-full px-4 py-2 text-left hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2
//                         ${
//                           selectedSociety === society.name
//                             ? "text-teal-300 bg-slate-700/30"
//                             : "text-teal-300/80"
//                         }`}
//                     >
//                       <Building2 className="h-4 w-4" />
//                       <span>{society.name}</span>
//                     </button>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>

//           {/* Right Section */}

//           {/* Notification Button */}
//           <div className="flex items-center space-x-4">
//             {/* Notification Button */}
//             <div className="relative">
//               {/* Notification Bell Button */}
//               <button
//                 className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-2.5 rounded-xl hover:bg-opacity-80 transition-all duration-300 group relative"
//                 onClick={() => setIsNotificationOpen(!isNotificationOpen)}
//               >
//                 <Bell className="h-6 w-6 text-teal-300 group-hover:scale-110 transition-transform" />
//                 {notifications.filter((n) => !n.isRead).length > 0 && (
//                   <span className="absolute -top-1.5 -right-1.5 h-5 w-5 text-xs flex items-center justify-center bg-red-500 text-white rounded-full border-2 border-slate-900">
//                     {notifications.filter((n) => !n.isRead).length}
//                   </span>
//                 )}
//               </button>

//               {/* Notification Dropdown */}
//               {isNotificationOpen && (
//                 <div className="absolute right-0 top-full mt-3 w-80 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-2xl rounded-2xl border border-slate-700/50 overflow-hidden z-50 animate-dropdown">
//                   {/* Header */}
//                   <div className="px-5 py-3 bg-slate-700/30 flex justify-between items-center">
//                     <h3 className="text-lg font-semibold text-teal-300">
//                       Notifications
//                     </h3>
//                     <button
//                       className="text-xs text-teal-400 hover:underline"
//                       onClick={() =>
//                         setIsNotificationOpen(
//                           notifications.map((n) => ({ ...n, isRead: true }))
//                         )
//                       }
//                     >
//                       Mark All Read
//                     </button>
//                   </div>

//                   {/* Content */}
//                   {notifications.length === 0 ? (
//                     <div className="px-5 py-4 text-center text-teal-300/70">
//                       No notifications
//                     </div>
//                   ) : (
//                     <ul className="divide-y divide-slate-700/30 max-h-[300px] overflow-y-auto">
//                       {notifications.map((notification) => (
//                         <li
//                           key={notification._id}
//                           className={`px-5 py-3 flex items-start justify-between group ${
//                             notification.isRead
//                               ? "bg-transparent"
//                               : "bg-slate-700/20"
//                           } hover:bg-slate-700/30 transition-colors`}
//                         >
//                           <div className="flex-grow mr-3">
//                             <p className="text-sm text-teal-300 line-clamp-2">
//                               {notification.message}
//                             </p>
//                             <p className="text-xs text-teal-300/60 mt-1">
//                               {new Date(
//                                 notification.createdAt
//                               ).toLocaleString()}
//                             </p>
//                           </div>
//                           <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
//                             {!notification.isRead && (
//                               <button
//                                 onClick={() =>
//                                   handleMarkAsRead(notification._id)
//                                 }
//                                 className="text-teal-400 hover:text-teal-300 transition-colors"
//                               >
//                                 <Check className="h-4 w-4" />
//                               </button>
//                             )}
//                             <button
//                               onClick={() =>
//                                 handleDeleteNotification(notification._id)
//                               }
//                               className="text-red-500 hover:text-red-400 transition-colors"
//                             >
//                               <Trash2 className="h-4 w-4" />
//                             </button>
//                           </div>
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </div>
//               )}
//             </div>

//         {/* Profile Dropdown */}
//         <div className="profile-dropdown relative">
//           <button
//             onClick={() => setIsProfileOpen(!isProfileOpen)}
//             className="flex items-center space-x-3 px-3 py-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200"
//           >
//             <div className="hidden sm:block text-right">
//               <p className="text-sm font-semibold text-teal-300">
//                 {activeUser.username}
//               </p>
//               <p className="text-xs text-teal-300/70">
//                 {activeUser.designation || activeUser.role}
//               </p>
//             </div>
//             <div className="h-10 w-10 rounded-full bg-teal-500/20 flex items-center justify-center border-2 border-teal-500/50 hover:border-teal-400 transition-colors duration-200">
//               {activeUser.profileImage ? (
//                 <img
//                   src={activeUser.profileImage}
//                   alt={activeUser.username}
//                   className="h-full w-full rounded-full object-cover"
//                 />
//               ) : (
//                 <span className="text-teal-300 font-semibold">
//                   {activeUser.username.charAt(0).toUpperCase()}
//                 </span>
//               )}
//             </div>
//             <ChevronDown
//               className={`h-4 w-4 text-teal-300 transition-transform duration-200 ${
//                 isProfileOpen ? "rotate-180" : ""
//               }`}
//             />
//           </button>

//           {/* Profile Dropdown Menu */}
//           {isProfileOpen && (
//             <div className="absolute top-full right-0 mt-2 w-72 rounded-lg bg-slate-800 shadow-lg border border-slate-700 overflow-hidden animate-fadeIn">
//               {/* Profile Header */}
//               <div className="px-4 py-3 bg-slate-700/30">
//                 <p className="text-sm font-semibold text-teal-300">
//                   {activeUser.username}
//                 </p>
//                 <p className="text-xs text-teal-300/70">
//                   {activeUser.email}
//                 </p>
//                 <div className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-500/10 text-teal-300 border border-teal-500/20">
//                   {activeUser.role}
//                 </div>
//               </div>

//               {/* Dropdown Items */}
//               <div className="py-2">
//                 <button
//                   onClick={() => {
//                     // Handle profile view
//                     setIsProfileOpen(false);
//                   }}
//                   className="w-full px-4 py-2 text-left text-teal-300 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
//                 >
//                   <User className="h-4 w-4" />
//                   <span>View Profile</span>
//                 </button>
//                 <button
//                   onClick={() => {
//                     // Handle settings
//                     setIsProfileOpen(false);
//                   }}
//                   className="w-full px-4 py-2 text-left text-teal-300 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
//                 >
//                   <Settings className="h-4 w-4" />
//                   <span>Settings</span>
//                 </button>
//                 <div className="my-2 border-t border-slate-700"></div>
//                 {/* <button
//                   onClick={() => {
//                     setIsProfileOpen(false);
//                     onLogout();
//                   }}
//                   className="w-full px-4 py-2 text-left text-red-400 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
//                 >
//                   <LogOut className="h-4 w-4" />
//                   <span>Sign Out</span>
//                 </button> */}

//                 <Logout />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   </div>
// </nav>
//   );
// };

// export default Topbar;

import React, { useEffect, useState, useRef } from "react";
import {
  Menu,
  Bell,
  ChevronDown,
  User,
  Settings,
  Building2,
  Check,
  Trash2,
} from "lucide-react";

import { useSidebarContext } from "./sidebar/SidebarContext";
import { useProSidebar } from "react-pro-sidebar";
import Logout from "../../Auth/Logout";
import { useDispatch, useSelector } from "react-redux";
import { checkActiveUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import {
  getUserNotifications,
  markAsRead,
  deleteNotificationThunk,
} from "../../store/slices/notificationSlice";
import { Link } from "react-router-dom";

const Topbar = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSocietyDropdownOpen, setIsSocietyDropdownOpen] = useState(false);
  const [selectedSociety, setSelectedSociety] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const notificationRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const societyDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const { activeUser, isAuthenticated } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);
  const { notifications } = useSelector((state) => state.notifications);

  const { setActiveSociety } = useSidebarContext();
  const { toggleSidebar, broken } = useProSidebar();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(checkActiveUser());
      dispatch(fetchSocieties());
      if (activeUser?.role === "Admin") {
        dispatch(getUserNotifications(activeUser.id));
      } else if (activeUser?.id) {
        dispatch(getUserNotifications(activeUser.id));
      }
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (activeUser?.role === "Admin") {
      if (societies?.length > 0 && !selectedSociety) {
        const defaultSociety = societies[0];
        setSelectedSociety(defaultSociety.name);
        setActiveSociety(defaultSociety);
      }
    } else if (activeUser?.societies?.length === 1) {
      const userSociety = activeUser.societies[0];
      setSelectedSociety(userSociety.name);
      setActiveSociety(userSociety);
    } else if (societies?.length > 0 && !selectedSociety) {
      const defaultSociety = societies[0];
      setSelectedSociety(defaultSociety.name);
      setActiveSociety(defaultSociety);
    }
  }, [activeUser, societies, selectedSociety, setActiveSociety]);

  const handleMarkAsRead = (notificationId) => {
    dispatch(markAsRead(notificationId));
  };

  const handleDeleteNotification = (notificationId) => {
    dispatch(deleteNotificationThunk(notificationId));
  };

  const notificationsToDisplay = notifications || [];

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target) &&
      societyDropdownRef.current &&
      !societyDropdownRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
      setIsProfileOpen(false);
      setIsSocietyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  if (!isAuthenticated || !activeUser) {
    return null;
  }

  const getColorFromUsername = (username) => {
    const colors = [
      "bg-red-500",
      "bg-green-500",
      "bg-blue-500",
      "bg-purple-500",
    ];
    const index = username ? username.charCodeAt(0) % colors.length : 0;
    return colors[index];
  };

  return (
    <nav className="sticky top-0 z-50 w-full bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-lg backdrop-blur-sm">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              className="p-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200
    xl:hidden lg:block"
              onClick={() => broken && toggleSidebar()}
            >
              <Menu className="h-6 w-6 text-teal-300" />
            </button>

            <div className="society-dropdown relative" ref={societyDropdownRef}>
              <button
                onClick={() => setIsSocietyDropdownOpen(!isSocietyDropdownOpen)}
                className="flex items-center space-x-2 bg-slate-700/50 text-teal-300 px-4 py-2 rounded-lg hover:bg-slate-700/70 transition-all duration-200"
              >
                <Building2 className="h-5 w-5" />
                <span className="hidden sm:block">{selectedSociety}</span>{" "}
                {/* Display the active user's society */}
                <ChevronDown
                  className={`h-4 w-4 transition-transform duration-200 ${
                    isSocietyDropdownOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {isSocietyDropdownOpen && (
                <div className="absolute top-full left-0 mt-2 w-64 rounded-lg bg-slate-800 shadow-lg border border-slate-700 py-2 animate-fadeIn">
                  {(activeUser?.role === "Admin"
                    ? societies
                    : activeUser?.societies
                  )?.map((society) => (
                    <button
                      key={society._id}
                      onClick={() => {
                        setSelectedSociety(society.name);
                        setActiveSociety(society);
                        setIsSocietyDropdownOpen(false);
                      }}
                      className={`w-full px-4 py-2 text-left hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2 ${
                        selectedSociety === society.name
                          ? "text-teal-300 bg-slate-700/30"
                          : "text-teal-300/80"
                      }`}
                    >
                      <Building2 className="h-4 w-4" />
                      <span>{society.name}</span>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="relative" ref={notificationRef}>
              <button
                className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-2.5 rounded-xl hover:bg-opacity-80 transition-all duration-300 group relative"
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              >
                <Bell className="h-6 w-6 text-teal-300 group-hover:scale-110 transition-transform" />
                {notificationsToDisplay.filter((n) => !n.isRead).length > 0 && (
                  <span className="absolute -top-1.5 -right-1.5 h-5 w-5 text-xs flex items-center justify-center bg-red-500 text-white rounded-full border-2 border-slate-900">
                    {notificationsToDisplay.filter((n) => !n.isRead).length}
                  </span>
                )}
              </button>

              {isNotificationOpen && (
                <div className="absolute right-0 top-full mt-3 w-80 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-2xl rounded-2xl border border-slate-700/50 overflow-hidden z-50 animate-dropdown">
                  <div className="px-5 py-3 bg-slate-700/30 flex justify-between items-center">
                    <h3 className="text-lg font-semibold text-teal-300">
                      Notifications
                    </h3>
                    <button
                      className="text-xs text-teal-400 hover:underline"
                      onClick={() => {
                        notificationsToDisplay.forEach((n) =>
                          handleMarkAsRead(n._id)
                        );
                      }}
                    >
                      Mark All Read
                    </button>
                  </div>

                  {notificationsToDisplay.length === 0 ? (
                    <div className="px-5 py-4 text-center text-teal-300/70">
                      No notifications
                    </div>
                  ) : (
                    <ul className="divide-y divide-slate-700/30 max-h-[300px] overflow-y-auto">
                      {notificationsToDisplay.map((notification) => (
                        <li
                          key={notification._id}
                          className={`px-5 py-3 flex items-start justify-between group ${
                            notification.isRead
                              ? "bg-transparent"
                              : "bg-slate-700/20"
                          } hover:bg-slate-700/30 transition-colors`}
                        >
                          <div className="flex-grow mr-3">
                            <p className="text-sm text-teal-300 line-clamp-2">
                              {notification.message}
                            </p>
                            <p className="text-xs text-teal-300/60 mt-1">
                              {new Date(
                                notification.createdAt
                              ).toLocaleString()}
                            </p>
                          </div>
                          <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                            {!notification.isRead && (
                              <button
                                onClick={() =>
                                  handleMarkAsRead(notification._id)
                                }
                                className="text-teal-300"
                              >
                                <Check className="h-4 w-4" />
                              </button>
                            )}
                            <button
                              onClick={() =>
                                handleDeleteNotification(notification._id)
                              }
                              className="text-teal-300"
                            >
                              <Trash2 className="h-4 w-4" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>

            {/* Profile Dropdown */}
            <div className="profile-dropdown relative" ref={profileDropdownRef}>
              <button
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center space-x-3 px-3 py-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200"
              >
                <div className="hidden sm:block text-right">
                  <p className="text-sm font-semibold text-teal-300">
                    {activeUser.username}
                  </p>
                  <p className="text-xs text-teal-300/70">
                    {activeUser.designation || activeUser.role}
                  </p>
                </div>
                <div className="h-10 w-10 rounded-full bg-teal-500/20 flex items-center justify-center border-2 border-teal-500/50 hover:border-teal-400 transition-colors duration-200">
                  {activeUser.profile_picture ? (
                    <img
                      src={activeUser.profile_picture}
                      alt={`${activeUser.username || "User"}'s profile`}
                      className="h-full w-full rounded-full object-cover"
                    />
                  ) : (
                    <div
                      className={`h-10 w-10 rounded-full ${getColorFromUsername(
                        activeUser.username
                      )} flex items-center justify-center`}
                    >
                      <span className="text-white font-semibold">
                        {activeUser.username?.charAt(0).toUpperCase() || "U"}
                      </span>
                    </div>
                  )}
                </div>

                <ChevronDown
                  className={`h-4 w-4 text-teal-300 transition-transform duration-200 ${
                    isProfileOpen ? "rotate-180" : ""
                  }`}
                />
              </button>

              {/* Profile Dropdown Menu */}
              {isProfileOpen && (
                <div className="absolute top-full right-0 mt-2 w-72 rounded-lg bg-slate-800 shadow-lg border border-slate-700 overflow-hidden animate-fadeIn">
                  {/* Profile Header */}
                  <div className="px-4 py-3 bg-slate-700/30">
                    <p className="text-sm font-semibold text-teal-300">
                      {activeUser.username}
                    </p>
                    <p className="text-xs text-teal-300/70">
                      {activeUser.additionalData.designation}
                    </p>
                    <div className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-500/10 text-teal-300 border border-teal-500/20">
                      {activeUser.role}
                    </div>
                  </div>

                  {/* Dropdown Items */}
                  <div className="py-2">
                    <Link
                      to="/user-creation/user-profile"
                      onClick={() => setIsProfileOpen(false)}
                      className="flex items-center space-x-3 px-3 py-2 rounded-lg text-teal-300 hover:bg-slate-700/50 transition-colors duration-200"
                    >
                      <User className="h-4 w-4" />
                      <span>View Profile</span>
                    </Link>
                    {activeUser.role === "Admin" && (
  <Link
    to="/tabs-settings"
    onClick={() => {
      setIsProfileOpen(false);
    }}
    className="w-full px-4 py-2 text-left text-teal-300 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
  >
    <Settings className="h-4 w-4" />
    <span>Settings</span>
  </Link>
)}
                    <div className="my-2 border-t border-slate-700"></div>
                    <Logout />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
