// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Swal from "sweetalert2";
// import {
//   createTransaction,
//   updateTransaction,
// } from "../store/slices/generalTransactionSlice";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { motion, AnimatePresence } from 'framer-motion';

// const TransactionForm = ({
//   existingTransaction = null,
//   onClose = () => {},
// }) => {
//   const dispatch = useDispatch();
//   const activeUser = useSelector((state) => state.auth.activeUser);

//   // Show error alert for missing user data
//   const showErrorAlert = (message) => {
//     Swal.fire({
//       title: "Error",
//       text: message,
//       icon: "error",
//       confirmButtonColor: "#DC2626", // red-600
//       background: "#1E293B", // slate-800
//       color: "#5EEAD4", // teal-300
//     });
//   };

//   // Show success alert
//   const showSuccessAlert = () => {
//     Swal.fire({
//       title: "Success",
//       text: "Transaction created successfully",
//       icon: "success",
//       confirmButtonColor: "#0D9488", // teal-600
//       background: "#1E293B", // slate-800
//       color: "#5EEAD4", // teal-300
//     });
//   };

//   const formik = useFormik({
//     initialValues: {
//       type: existingTransaction?.type || "income",
//       description: existingTransaction?.description || "",
//       amount: existingTransaction?.amount || "",
//       category: existingTransaction?.category || "",
//       date: existingTransaction?.date || "",
//       role: activeUser?.role || "", // Ensure role is populated from activeUser
//       username: activeUser?.username || "", // Ensure username is populated from activeUser
//     },
//     validationSchema: Yup.object({
//       type: Yup.string().required("Type is required"),
//       description: Yup.string().required("Description is required"),
//       amount: Yup.number()
//         .required("Amount is required")
//         .positive("Amount must be positive"),
//       category: Yup.string().required("Category is required"),
//       date: Yup.date().nullable(),
//     }),
//     onSubmit: (values) => {
//       // Check if activeUser is available
//       if (!activeUser || !activeUser.role || !activeUser.username) {
//         showErrorAlert("User data is missing. Please log in again.");
//         return;
//       }

//       const { role, username, designation } = activeUser || {};

//       const transactionData = {
//         ...values,
//         designation: role === "Employee" ? designation : null,
//         role,
//         username,
//       };

//       console.log("Submitting transaction data:", transactionData);

//       if (existingTransaction) {
//         dispatch(
//           updateTransaction({
//             id: existingTransaction._id,
//             data: transactionData,
//           })
//         )
//           .then(() => {
//             showSuccessAlert();
//             onClose();
//           })
//           .catch((error) => {
//             showErrorAlert("Failed to update transaction");
//             console.error("Error updating transaction:", error);
//           });
//       } else {
//         dispatch(createTransaction(transactionData))
//           .then(() => {
//             showSuccessAlert();
//             formik.resetForm();
//             onClose();
//           })
//           .catch((error) => {
//             showErrorAlert("Failed to create transaction");
//             console.error("Error creating transaction:", error);
//           });
//       }
//     },
//   });

//   useEffect(() => {
//     if (
//       activeUser &&
//       existingTransaction &&
//       formik.values.username !== activeUser.username
//     ) {
//       formik.setValues({
//         type: existingTransaction?.type || "income",
//         description: existingTransaction?.description || "",
//         amount: existingTransaction?.amount || "",
//         category: existingTransaction?.category || "",
//         date: existingTransaction?.date || "",
//         role: activeUser.role || "",
//         username: activeUser.username || "",
//       });
//     }
//   }, [activeUser, existingTransaction, formik.values.username]);

//   const inputClassName = `
//     w-full bg-opacity-10 bg-white backdrop-blur-lg
//     border border-slate-600/30 text-slate-200
//     rounded-xl p-4 placeholder-slate-400
//     focus:outline-none focus:ring-2 focus:ring-teal-500/50
//     focus:border-transparent transition-all duration-300
//     hover:border-teal-400/30 hover:bg-opacity-15
//     shadow-lg shadow-black/5
//   `;

//   const labelClassName = "block text-slate-300 font-medium mb-2 ml-1";

//   return (
//     <div className="relative inset-0 backdrop-blur-sm flex items-center justify-center z-50">
//       <motion.div
//         initial={{ opacity: 0, scale: 0.9 }}
//         animate={{ opacity: 1, scale: 1 }}
//         exit={{ opacity: 0, scale: 0.9 }}
//         className="w-full max-w-3xl mx-auto relative"
//       >
//         {/* Gradient orbs for background effect */}
//         {/* <div className="absolute -z-10 w-96 h-96 bg-teal-500/20 rounded-full blur-3xl top-0 -left-48" />
//         <div className="absolute -z-10 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl bottom-0 -right-48" /> */}

//         {/* Main form container */}
//         <div className="bg-slate-900/70 backdrop-blur-xl rounded-2xl shadow-2xl border border-slate-700/50">
//           <div className="p-8 relative overflow-hidden">
//             {/* Subtle gradient overlay */}
//             <div className="absolute inset-0 bg-gradient-to-br from-slate-800/50 via-transparent to-slate-900/50" />

//             <form onSubmit={formik.handleSubmit} className="relative">
//               {/* Header section */}
//               <motion.div
//                 initial={{ y: -20, opacity: 0 }}
//                 animate={{ y: 0, opacity: 1 }}
//                 className="text-center mb-10"
//               >
//                 <h2 className="text-3xl font-bold bg-gradient-to-r from-teal-400 to-teal-400 bg-clip-text text-transparent">
//                   {existingTransaction ? "Edit Transaction" : "New Transaction"}
//                 </h2>
//                 <p className="text-slate-400 mt-2">
//                   Enter transaction details below
//                 </p>
//               </motion.div>

//               {/* Form fields grid */}
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                 {/* Type Select */}
//                 <div className="group">
//                   <label className={labelClassName}>Type</label>
//                   <select
//                     name="type"
//                     value={formik.values.type}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className={`${inputClassName} cursor-pointer hover:scale-[1.02] transition-transform duration-300`}
//                   >
//                     <option value="income">Income</option>
//                     <option value="expense">Expense</option>
//                   </select>
//                   {formik.touched.type && formik.errors.type && (
//                     <motion.div
//                       initial={{ opacity: 0, y: -10 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       className="mt-2 text-red-400 text-sm"
//                     >
//                       {formik.errors.type}
//                     </motion.div>
//                   )}
//                 </div>

//                 {/* Description Input */}
//                 <div className="group">
//                   <label className={labelClassName}>Description</label>
//                   <input
//                     type="text"
//                     name="description"
//                     placeholder="Enter description"
//                     value={formik.values.description}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className={`${inputClassName} hover:scale-[1.02] transition-transform duration-300`}
//                   />
//                   {formik.touched.description && formik.errors.description && (
//                     <motion.div
//                       initial={{ opacity: 0, y: -10 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       className="mt-2 text-red-400 text-sm"
//                     >
//                       {formik.errors.description}
//                     </motion.div>
//                   )}
//                 </div>

//                 {/* Amount Input */}
//                 <div className="group">
//                   <label className={labelClassName}>Amount</label>
//                   <input
//                     type="number"
//                     name="amount"
//                     placeholder="Enter amount"
//                     value={formik.values.amount}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className={`${inputClassName} hover:scale-[1.02] transition-transform duration-300`}
//                   />
//                   {formik.touched.amount && formik.errors.amount && (
//                     <motion.div
//                       initial={{ opacity: 0, y: -10 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       className="mt-2 text-red-400 text-sm"
//                     >
//                       {formik.errors.amount}
//                     </motion.div>
//                   )}
//                 </div>

//                 {/* Category Input */}
//                 <div className="group">
//                   <label className={labelClassName}>Category</label>
//                   <input
//                     type="text"
//                     name="category"
//                     placeholder="Enter category"
//                     value={formik.values.category}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className={`${inputClassName} hover:scale-[1.02] transition-transform duration-300`}
//                   />
//                   {formik.touched.category && formik.errors.category && (
//                     <motion.div
//                       initial={{ opacity: 0, y: -10 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       className="mt-2 text-red-400 text-sm"
//                     >
//                       {formik.errors.category}
//                     </motion.div>
//                   )}
//                 </div>

//                 {/* Date Input */}
//                 <div className="md:col-span-2">
//                   <label className={labelClassName}>Date</label>
//                   <input
//                     type="date"
//                     name="date"
//                     value={formik.values.date}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     className={`${inputClassName} hover:scale-[1.02] transition-transform duration-300`}
//                   />
//                 </div>
//               </div>

//               {/* Action Buttons */}
//               <div className="flex gap-4 mt-10">
//                 <motion.button
//                   whileHover={{ scale: 1.02 }}
//                   whileTap={{ scale: 0.98 }}
//                   type="button"
//                   onClick={onClose}
//                   className="flex-1 px-6 py-4 bg-slate-800/50 backdrop-blur-sm text-slate-300
//                     rounded-xl border border-slate-700/50 hover:bg-slate-700/50
//                     transition duration-300 ease-in-out focus:outline-none
//                     focus:ring-2 focus:ring-slate-500 shadow-lg"
//                 >
//                   Cancel
//                 </motion.button>

//                 <motion.button
//                   whileHover={{ scale: 1.02 }}
//                   whileTap={{ scale: 0.98 }}
//                   type="submit"
//                   disabled={!formik.isValid || formik.isSubmitting}
//                   className="flex-1 px-6 py-4 bg-gradient-to-r from-teal-600 to-teal-500
//                     text-white rounded-xl hover:from-teal-500 hover:to-teal-400
//                     disabled:opacity-50 disabled:cursor-not-allowed transition duration-300
//                     ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 shadow-lg
//                     disabled:hover:from-teal-600 disabled:hover:to-teal-500"
//                 >
//                   {existingTransaction ? "Update" : "Create"} Transaction
//                 </motion.button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </motion.div>
//     </div>
//   );
// };

// export default TransactionForm;
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  createTransaction,
  updateTransaction,
} from "../store/slices/generalTransactionSlice";
import { useFormik } from "formik";
import * as Yup from "yup";

const TransactionForm = ({
  existingTransaction = null,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.auth.activeUser);

  // Show error alert for missing user data
  const showErrorAlert = (message) => {
    Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonColor: "#DC2626", // red-600
      background: "#1E293B", // slate-800
      color: "#5EEAD4", // teal-300
    });
  };

  // Show success alert
  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Transaction created successfully",
      icon: "success",
      confirmButtonColor: "#0D9488", // teal-600
      background: "#1E293B", // slate-800
      color: "#5EEAD4", // teal-300
    });
  };

  const formik = useFormik({
    initialValues: {
      type: existingTransaction?.type || "income",
      description: existingTransaction?.description || "",
      amount: existingTransaction?.amount || "",
      category: existingTransaction?.category || "",
      date: existingTransaction?.date || "",
      role: activeUser?.role || "", // Ensure role is populated from activeUser
      username: activeUser?.username || "", // Ensure username is populated from activeUser
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      description: Yup.string().required("Description is required"),
      amount: Yup.number()
        .required("Amount is required")
        .positive("Amount must be positive"),
      category: Yup.string().required("Category is required"),
      date: Yup.date().nullable(),
    }),
    onSubmit: (values) => {
      // Check if activeUser is available
      if (!activeUser || !activeUser.role || !activeUser.username) {
        showErrorAlert("User data is missing. Please log in again.");
        return;
      }

      const { role, username, designation } = activeUser || {};

      const transactionData = {
        ...values,
        designation: role === "Employee" ? designation : null,
        role,
        username,
      };

      console.log("Submitting transaction data:", transactionData);

      if (existingTransaction) {
        dispatch(
          updateTransaction({
            id: existingTransaction._id,
            data: transactionData,
          })
        )
          .then(() => {
            showSuccessAlert();
            onClose();
          })
          .catch((error) => {
            showErrorAlert("Failed to update transaction");
            console.error("Error updating transaction:", error);
          });
      } else {
        dispatch(createTransaction(transactionData))
          .then(() => {
            showSuccessAlert();
            formik.resetForm();
            onClose();
          })
          .catch((error) => {
            showErrorAlert("Failed to create transaction");
            console.error("Error creating transaction:", error);
          });
      }
    },
  });

  useEffect(() => {
    if (
      activeUser &&
      existingTransaction &&
      formik.values.username !== activeUser.username
    ) {
      formik.setValues({
        type: existingTransaction?.type || "income",
        description: existingTransaction?.description || "",
        amount: existingTransaction?.amount || "",
        category: existingTransaction?.category || "",
        date: existingTransaction?.date || "",
        role: activeUser.role || "",
        username: activeUser.username || "",
      });
    }
  }, [activeUser, existingTransaction, formik.values.username]);

  const inputClassName = `
    w-full bg-slate-800/30 backdrop-blur-lg 
    border border-slate-600/30 text-slate-200
    rounded-xl p-3 placeholder-slate-400
    focus:outline-none focus:ring-2 focus:ring-teal-500/50 
    focus:border-transparent transition-all duration-300
    hover:border-teal-400/30 hover:bg-slate-700/20
    shadow-md
  `;

  const labelClassName = "block text-slate-300 font-medium mb-2 ml-1";

  return (
    <div className="relative inset-0 z-50 flex items-center justify-center   p-4">
      <div className="w-full max-w-3xl bg-slate-900/80 rounded-2xl shadow-2xl border border-slate-700/50 overflow-hidden">
        <div className="p-8">
          {/* Header section */}
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-teal-400 to-teal-500 bg-clip-text text-transparent">
              {existingTransaction ? "Edit Transaction" : "New Transaction"}
            </h2>
            <p className="text-slate-400 mt-2">
              Enter transaction details below
            </p>
          </div>

          <form onSubmit={formik.handleSubmit}>
            {/* Form fields grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Type Select */}
              <div className="group">
                <label className={labelClassName}>Type</label>
                <select
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${inputClassName} cursor-pointer bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-300`}
                >
                  <option value="income" className="bg-slate-800 text-teal-300">
                    Income
                  </option>
                  <option
                    value="expense"
                    className="bg-slate-800 text-teal-300"
                  >
                    Expense
                  </option>
                </select>
                {formik.touched.type && formik.errors.type && (
                  <div className="mt-2 text-red-400 text-sm">
                    {formik.errors.type}
                  </div>
                )}
              </div>

              {/* Description Input */}
              <div className="group">
                <label className={labelClassName}>Description</label>
                <input
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={inputClassName}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="mt-2 text-red-400 text-sm">
                    {formik.errors.description}
                  </div>
                )}
              </div>

              {/* Amount Input */}
              <div className="group">
                <label className={labelClassName}>Amount</label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={inputClassName}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className="mt-2 text-red-400 text-sm">
                    {formik.errors.amount}
                  </div>
                )}
              </div>

              {/* Category Input */}
              <div className="group">
                <label className={labelClassName}>Category</label>
                <input
                  type="text"
                  name="category"
                  placeholder="Enter category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={inputClassName}
                />
                {formik.touched.category && formik.errors.category && (
                  <div className="mt-2 text-red-400 text-sm">
                    {formik.errors.category}
                  </div>
                )}
              </div>

              {/* Date Input */}
              <div className="md:col-span-2">
                <label className={labelClassName}>Date</label>
                <input
                  type="date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={inputClassName}
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4 mt-8">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 px-6 py-3 bg-slate-800/50 backdrop-blur-sm text-slate-300 
                  rounded-xl border border-slate-700/50 hover:bg-slate-700/50 
                  transition duration-300 ease-in-out focus:outline-none 
                  focus:ring-2 focus:ring-slate-500 shadow-md"
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
                className="flex-1 px-6 py-3 bg-gradient-to-r from-teal-600 to-teal-500 
                  text-white rounded-xl hover:from-teal-500 hover:to-teal-400
                  disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 
                  ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 shadow-md
                  disabled:hover:from-teal-600 disabled:hover:to-teal-500"
              >
                {existingTransaction ? "Update" : "Create"} Transaction
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TransactionForm;
