import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings } from "../store/slices/bookingSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import { FileText, Printer } from "lucide-react";
import printJS from "print-js";
import Logo from "../assets/Logo/Logo.png";

const FullPaymentBookings = () => {
  const dispatch = useDispatch();
  const { bookings = [] } = useSelector((state) => state.booking);
  const { societies = [] } = useSelector((state) => state.societies);

  useEffect(() => {
    dispatch(getAllBookings());
    dispatch(fetchSocieties());
  }, [dispatch]);

  const getCustomerName = (customer) => {
    return customer && customer._id && customer._id.username
      ? customer._id.username
      : "Unknown";
  };

  const getSocietyDetails = (societyId) => {
    const society = societies.find((s) => s._id === societyId);
    return society
      ? {
          name: society.name,
          location: society.location,
          image: society.society_image,
        }
      : {
          name: "Housing City",
          location: "Main Branch, City Center",
          image: Logo,
        };
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-PK", {
      style: "currency",
      currency: "PKR",
    }).format(amount);
  };

  const handlePrint = (booking) => {
    const customer = booking.customer_id;
    const societyDetails =
      booking.plot_id && booking.plot_id.society_id
        ? getSocietyDetails(booking.plot_id.society_id)
        : {
            name: "Housing City",
            location: "Main Branch, City Center",
            society_image: Logo,
          };

    if (!customer || !customer.contactInfo) {
      console.error("Customer details are missing.");
      return;
    }

    const printContent = `
  <div style="font-family: 'Inter', Arial, sans-serif; max-width: 900px; margin: 0 auto; padding: 20px; background-color: #ffffff; color: #000000;">
    <!-- Header -->
    <div style="display: flex; justify-content: space-between; align-items: center; border-bottom: 2px solid #000000; padding-bottom: 10px; margin-bottom: 20px;">
      <div style="display: flex; align-items: center; gap: 10px;">
        ${
          societyDetails.image
            ? `<img src="${societyDetails.image}" alt="Society Logo" style="width: 60px; height: auto; border-radius: 4px;" />`
            : ""
        }
        <div>
          <h1 style="margin: 0; font-size: 24px; font-weight: 700; color: #000;">${
            societyDetails.name
          }</h1>
          <h4 style="margin: 5px 0 0; font-size: 16px; color: #333;">${
            societyDetails.location
          }</h4>
        </div>
      </div>
      <div style="text-align: right;">
        <p style="margin: 0; font-size: 14px;"><strong>Booking Number:</strong> ${
          booking.booking_number
        }</p>
        <p style="margin: 5px 0 0; font-size: 12px;"><strong>Booking Date:</strong> ${new Date(
          booking.booking_date
        ).toLocaleDateString()}</p>
      </div>
    </div>

    <!-- Title -->
    <h2 style="text-align: center; font-size: 20px; margin-bottom: 20px; font-weight: bold; border-bottom: 1px solid #000; padding-bottom: 5px;">
      Official Booking Confirmation
    </h2>

    <!-- Details Section -->
    <div style="display: flex; gap: 20px; margin-bottom: 20px;">
      <!-- Customer Details -->
      <div style="flex: 1; background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 15px;">
        <h3 style="margin: 0 0 10px; font-size: 16px; border-bottom: 1px solid #000; padding-bottom: 5px;">Customer Details</h3>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Name:</strong> ${
          customer.name || "Unknown"
        }</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Phone:</strong> ${
          customer.contactInfo?.phone || "N/A"
        }</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Email:</strong> ${
          customer.contactInfo?.email || "N/A"
        }</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>CNIC:</strong> ${
          customer.contactInfo?.cnic || "N/A"
        }</p>
      </div>

      <!-- Property Details -->
      <div style="flex: 1; background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 15px;">
        <h3 style="margin: 0 0 10px; font-size: 16px; border-bottom: 1px solid #000; padding-bottom: 5px;">Property Details</h3>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Plot Number:</strong> ${
          booking.plot_id?.plot_number || "N/A"
        }</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Plot Type:</strong> ${
          booking.plot_id?.plot_type || "N/A"
        }</p>
         <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Plot size (Marla):</strong> ${
           booking.plot_id?.size || "N/A"
         } Marla</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Category:</strong> ${
          booking.plot_id?.category || "N/A"
        }</p>
        <p style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 5px;"><strong>Total Price:</strong> ${formatCurrency(
          booking.total_amount
        )}</p>
      </div>
    </div>

    <!-- Signature Section -->
    <div style="display: flex; justify-content: space-between; margin-bottom: 20px; margin-top: 100px;">
      <div style="text-align: center; width: 45%; border-top: 1px solid #000; padding-top: 10px;">
        <p>Customer Signature</p>
      </div>
      <div style="text-align: center; width: 45%; border-top: 1px solid #000; padding-top: 10px;">
        <p>Authorized Signature</p>
      </div>
    </div>

    <!-- Terms and Conditions -->
    <div style="background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 8px; padding: 15px;">
      <h3 style="margin: 0 0 10px; font-size: 18px; border-bottom: 1px solid #000; padding-bottom: 5px;">Terms and Conditions</h3>
      <ol style="padding-left: 15px; line-height: 1.5; font-size: 14px; margin: 0;">
        <li>This booking is subject to final approval by Housing City management.</li>
        <li>Payment plans are non-transferable and non-negotiable.</li>
        <li>Any changes to the plot allocation require written consent from Housing City.</li>
        <li>Default in payment may result in cancellation of booking.</li>
        <li>All legal disputes will be settled in the jurisdiction of the city where the property is located.</li>
      </ol>
    </div>

    <!-- Footer -->
    <div style="text-align: center; font-size: 12px; margin-top: 10px; border-top: 1px solid #000; padding-top: 10px;">
      <p>This is a computer-generated booking slip and does not require a physical signature.</p>
      <p>© ${new Date().getFullYear()} ${
      societyDetails.name
    }. All rights reserved.</p>
    </div>
  </div>
`;

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
    @page { size: A4; margin: 10mm; }
    body { margin: 0; font-family: 'Inter', Arial, sans-serif; }
  `,
    });
  };

  const fullPaymentBookings = bookings.filter(
    (booking) => booking.payment_mode === "Full"
  );

  return (
    <div className="flex items-center justify-center p-4">
      <div className="w-full max-w-7xl bg-slate-900/60 backdrop-blur-2xl rounded-3xl shadow-2xl border border-teal-500/30 overflow-hidden">
        <div className="bg-gradient-to-r from-teal-600/50 to-teal-800/50 p-6 backdrop-blur-xl border-b border-teal-500/20">
          <h2 className="text-4xl font-extrabold text-teal-300 text-center flex items-center justify-center gap-6 tracking-tight">
            <FileText className="w-12 h-12 text-teal-400 transform hover:scale-110 transition-transform duration-300" />
            Full Payment Bookings
            <FileText className="w-12 h-12 text-teal-400 transform hover:scale-110 transition-transform duration-300" />
          </h2>
        </div>

        <div className="overflow-x-auto scrollbar-thin scrollbar-track-slate-800 scrollbar-thumb-teal-500">
          <table className="w-full">
            <thead className="bg-slate-900/80 backdrop-blur-sm sticky top-0 z-10">
              <tr>
                {["Booking Number", "Customer", "Amount (PKR)", "Actions"].map(
                  (header) => (
                    <th
                      key={header}
                      className="px-6 py-4 text-left text-sm font-semibold text-teal-300 uppercase tracking-widest border-b border-teal-500/30"
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-slate-700/50">
              {fullPaymentBookings.map((booking, index) => (
                <tr
                  key={booking._id}
                  className="hover:bg-teal-500/10 transition-all duration-300 group"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {booking.booking_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {getCustomerName(booking.customer_id)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300 font-semibold">
                    {formatCurrency(booking.total_amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => handlePrint(booking)}
                      className="flex items-center gap-2 
                      bg-gradient-to-r from-teal-600 to-teal-800 
                      text-teal-300 px-5 py-2.5 rounded-xl 
                      hover:scale-105 hover:shadow-xl 
                      transform transition-all duration-300 
                      group-hover:opacity-100 
                      focus:outline-none focus:ring-2 focus:ring-teal-500
                      opacity-80"
                    >
                      <Printer className="w-6 h-6 text-teal-300" />
                      Print Slip
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FullPaymentBookings;
