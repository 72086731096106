// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
// import { fetchSocieties } from "../store/slices/societySlice";
// import { transferPlotThunk } from "../store/slices/transferPlotSlice";
// import { Search } from "lucide-react";
// const TransferPlotForm = () => {
//   const dispatch = useDispatch();
//   const inputRef = useRef(null);

//   const { users } = useSelector((state) => state.auth);
//   const { plots: customerPlots } = useSelector((state) => state.auth);

//   const { societies } = useSelector((state) => state.societies);

//   const [customers, setCustomers] = useState([]);
//   const [filteredCustomers, setFilteredCustomers] = useState([]);
//   const [selectedCustomer, setSelectedCustomer] = useState("");
//   const [selectedPlot, setSelectedPlot] = useState("");
//   const [formData, setFormData] = useState({
//     plot_id: "",
//     new_owner_id: "",
//     transfer_fee: "",
//   });
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isFocused, setIsFocused] = useState(false);

//   useEffect(() => {
//     dispatch(fetchAllUsers());
//     dispatch(fetchSocieties());
//   }, [dispatch]);

//   useEffect(() => {
//     if (users?.length > 0) {
//       const filtered = users.filter((user) => user.role === "Customer");
//       setCustomers(filtered);
//       setFilteredCustomers(filtered);
//     } else {
//       setCustomers([]);
//       setFilteredCustomers([]);
//     }
//   }, [users]);

//   useEffect(() => {
//     if (searchTerm) {
//       const filtered = customers.filter((customer) =>
//         customer.username.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//       setFilteredCustomers(filtered);
//     } else {
//       setFilteredCustomers(customers);
//     }
//   }, [searchTerm, customers]);

//   useEffect(() => {
//     if (selectedCustomer) {
//       dispatch(fetchCustomerPlots(selectedCustomer));
//     }
//   }, [selectedCustomer, dispatch]);

//   const getSocietyAndPlotDetails = (plotId) => {
//     if (!societies || societies.length === 0) return null;

//     for (let soc of societies) {
//       const plot = soc.plots.find((plot) => plot._id === plotId);
//       if (plot) {
//         return {
//           societyName: soc.name,
//           plotNumber: plot.plot_number,
//           plotType: plot.plot_type,
//           block: plot.block,
//           category: plot.category,
//           bookingStatus: plot.booking_status,
//         };
//       }
//     }
//     return null;
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleCustomerSelect = (customerId, customerName) => {
//     setSelectedCustomer(customerId);
//     setSearchTerm(customerName);
//     setFormData({ ...formData, new_owner_id: customerId, plot_id: "" });
//     setFilteredCustomers([]);
//     setIsFocused(false);
//     setSelectedPlot("");
//   };

//   const handleInputFocus = () => {
//     setIsFocused(true);
//   };

//   const handleInputBlur = () => {
//     setTimeout(() => setIsFocused(false), 200);
//   };

//   const handlePlotSelect = (plot) => {
//     const details = getSocietyAndPlotDetails(plot._id);
//     if (details?.bookingStatus === "Transfer") {
//       alert("This plot has already been transferred and cannot be selected.");
//       return;
//     }
//     setSelectedPlot(plot._id);
//     setFormData({ ...formData, plot_id: plot._id });
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!formData.plot_id || !formData.new_owner_id || !formData.transfer_fee) {
//       alert("Please fill in all required fields.");
//       return;
//     }
//     dispatch(transferPlotThunk(formData));
//   };

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6">
//       <div className="max-w-7xl mx-auto">
//         <div className="space-y-8">
//           {/* Header Section */}
//           <div className="text-center space-y-4">
//             <h1 className="text-3xl font-bold bg-gradient-to-r from-teal-300 via-teal-200 to-teal-400 bg-clip-text text-transparent">
//               TRANSFER PLOT OWNERSHIP
//             </h1>
//             <p className="text-teal-300/70">
//               Transfer property ownership seamlessly and securely
//             </p>
//           </div>
//           <div className="backdrop-blur-xl bg-white/5 rounded-3xl border border-white/10 shadow-2xl p-8">
//             <form onSubmit={handleSubmit} className="space-y-8">
//               <div className="relative">
//                 <label className="inline-block text-teal-300 text-sm font-medium mb-2">
//                   Search New Owner
//                 </label>
//                 <div className="relative">
//                   <input
//                     ref={inputRef}
//                     type="text"
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                     onFocus={handleInputFocus}
//                     onBlur={handleInputBlur}
//                     className="w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border border-teal-500/20 focus:border-teal-500/50 focus:ring-2 focus:ring-teal-500/20 transition-all duration-300"
//                     placeholder="Enter customer name..."
//                   />
//                   <div className="absolute right-4 top-1/2 -translate-y-1/2">
//                     <Search className="h-6 w-6 rounded-full text-teal-300 flex items-center justify-center" />
//                   </div>
//                 </div>

//                 {/* Dropdown Results */}
//                 {isFocused && filteredCustomers.length > 0 && (
//                   <div className="absolute w-full mt-2 py-2 bg-slate-800/90 backdrop-blur-xl border border-teal-500/20 rounded-2xl z-50 shadow-xl">
//                     {filteredCustomers.map((customer) => (
//                       <div
//                         key={customer._id}
//                         onClick={() =>
//                           handleCustomerSelect(customer._id, customer.username)
//                         }
//                         className="px-6 py-3 hover:bg-teal-500/10 cursor-pointer text-teal-300 transition-colors duration-200"
//                       >
//                         {customer.username}
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>

//               {/* Plots Grid */}
//               {selectedCustomer && customerPlots.length > 0 && (
//                 <div className="space-y-6">
//                   <div className="flex items-center gap-3 px-4 py-3 bg-teal-500/10 rounded-2xl">
//                     <div className="h-2 w-2 rounded-full bg-teal-400 animate-pulse" />
//                     <p className="text-teal-300 font-medium">
//                       Available Plots ({customerPlots.length})
//                     </p>
//                   </div>

//                   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                     {customerPlots.map((plot) => {
//                       const details = getSocietyAndPlotDetails(plot._id);
//                       return (
//                         <div
//                           key={plot._id}
//                           onClick={() =>
//                             details?.bookingStatus !== "Transfer" &&
//                             handlePlotSelect(plot)
//                           }
//                           className={`relative group rounded-2xl border transition-all duration-500 cursor-pointer ${
//                             details?.bookingStatus === "Transfer"
//                               ? "bg-gray-700 border-gray-500 cursor-not-allowed"
//                               : selectedPlot === plot._id
//                               ? "bg-teal-500/10 border-teal-400/50 shadow-lg shadow-teal-500/20"
//                               : "bg-slate-800/40 border-white/5 hover:border-teal-300/20"
//                           }`}
//                         >
//                           <div className="p-6 space-y-4">
//                             <div className="flex justify-between items-center">
//                               <span className="px-4 py-1 rounded-full bg-teal-500/10 text-sm font-medium text-teal-300">
//                                 Plot {details?.plotNumber}
//                               </span>
//                               <div className="h-8 w-8 rounded-full bg-teal-500/10 flex items-center justify-center">
//                                 <div className="h-2 w-2 rounded-full bg-teal-400 group-hover:animate-ping" />
//                               </div>
//                             </div>
//                             <div className="space-y-4">
//                               <div className="flex items-center gap-2">
//                                 <span className="text-teal-300/70">
//                                   Society:
//                                 </span>
//                                 <span className="text-teal-300 font-medium">
//                                   {details?.societyName}
//                                 </span>
//                               </div>
//                               <div className="grid grid-cols-2 gap-3">
//                                 <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
//                                   <p className="text-sm text-teal-300/70">
//                                     Size
//                                   </p>
//                                   <p className="text-teal-300 font-medium">
//                                     {details?.plotType} Marla
//                                   </p>
//                                 </div>
//                                 <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
//                                   <p className="text-sm text-teal-300/70">
//                                     Block
//                                   </p>
//                                   <p className="text-teal-300 font-medium">
//                                     {details?.block}
//                                   </p>
//                                 </div>
//                               </div>
//                               <div className="flex justify-between items-center pt-2">
//                                 <div>
//                                   <p className="text-sm text-teal-300/70">
//                                     Status
//                                   </p>
//                                   <p
//                                     className={`font-medium ${
//                                       details?.bookingStatus === "Transfer"
//                                         ? "text-red-500"
//                                         : "text-teal-300"
//                                     }`}
//                                   >
//                                     {details?.bookingStatus}
//                                   </p>
//                                 </div>
//                                 <div>
//                                   <p className="text-sm text-teal-300/70">
//                                     Category
//                                   </p>
//                                   <p className="text-teal-300 font-medium">
//                                     {details?.category}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               )}

//               {/* Transfer Fee Input */}
//               {selectedPlot && (
//                 <div className="space-y-2">
//                   <label className="inline-block text-teal-300 text-sm font-medium mb-2">
//                     Transfer Fee
//                   </label>
//                   <div className="relative">
//                     <input
//                       type="text"
//                       name="transfer_fee"
//                       value={formData.transfer_fee}
//                       onChange={handleInputChange}
//                       className="w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border border-teal-500/20 focus:border-teal-500/50 focus:ring-2 focus:ring-teal-500/20 transition-all duration-300"
//                       placeholder="Enter transfer fee amount..."
//                     />
//                     <div className="absolute right-4 top-1/2 -translate-y-1/2 text-teal-300/50">
//                       PKR
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {/* Submit Button */}
//               <button
//                 type="submit"
//                 className="w-full bg-gradient-to-r from-teal-500 to-teal-400 h-12 rounded-2xl text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/25 hover:-translate-y-0.5 active:translate-y-0 disabled:opacity-50 disabled:pointer-events-none"
//                 disabled={!selectedPlot || !formData.transfer_fee}
//               >
//                 Complete Transfer
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransferPlotForm;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import { transferPlotThunk } from "../store/slices/transferPlotSlice";
import { Search } from "lucide-react";

const TransferPlotForm = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const { users } = useSelector((state) => state.auth);
  const { plots: customerPlots } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  // Validation Schema
  const TransferPlotSchema = Yup.object().shape({
    new_owner_id: Yup.string().required("Please select a new owner"),
    plot_id: Yup.string().required("Please select a plot"),
    transfer_fee: Yup.number()
      .positive("Transfer fee must be a positive number")
      .required("Transfer fee is required")
      .typeError("Transfer fee must be a valid number"),
  });

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (users?.length > 0) {
      const filtered = users.filter((user) => user.role === "Customer");
      setCustomers(filtered);
      setFilteredCustomers(filtered);
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }
  }, [users]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = customers.filter((customer) =>
        customer.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers);
    }
  }, [searchTerm, customers]);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(fetchCustomerPlots(selectedCustomer));
    }
  }, [selectedCustomer, dispatch]);

  const getSocietyAndPlotDetails = (plotId) => {
    if (!societies || societies.length === 0) return null;

    for (let soc of societies) {
      const plot = soc.plots.find((plot) => plot._id === plotId);
      if (plot) {
        return {
          societyName: soc.name,
          plotNumber: plot.plot_number,
          plotType: plot.plot_type,
          block: plot.block,
          category: plot.category,
          bookingStatus: plot.booking_status,
        };
      }
    }
    return null;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCustomerSelect = (customerId, customerName, setFieldValue) => {
    setSelectedCustomer(customerId);
    setSearchTerm(customerName);
    setFieldValue("new_owner_id", customerId);
    setFieldValue("plot_id", "");
    setFilteredCustomers([]);
    setIsFocused(false);
    setSelectedPlot("");
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setIsFocused(false), 200);
  };

  const handlePlotSelect = (plot, setFieldValue) => {
    const details = getSocietyAndPlotDetails(plot._id);
    if (details?.bookingStatus === "Transfer") {
      enqueueSnackbar(
        "This plot has already been transferred and cannot be selected.",
        { variant: "error" }
      );
      return;
    }
    setSelectedPlot(plot._id);
    setFieldValue("plot_id", plot._id);
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(transferPlotThunk(values))
      .then((response) => {
        enqueueSnackbar("Plot transfer completed successfully!", {
          variant: "success",
        });
        resetForm();
        setSelectedCustomer("");
        setSelectedPlot("");
        setSearchTerm("");
      })
      .catch((error) => {
        enqueueSnackbar(error.message || "Failed to transfer plot", {
          variant: "error",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="space-y-8">
          {/* Header Section */}
          <div className="text-center space-y-4">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-teal-300 via-teal-200 to-teal-400 bg-clip-text text-transparent">
              TRANSFER PLOT OWNERSHIP
            </h1>
            <p className="text-teal-300/70">
              Transfer property ownership seamlessly and securely
            </p>
          </div>
          <div className="backdrop-blur-xl bg-white/5 rounded-3xl border border-white/10 shadow-2xl p-8">
            <Formik
              initialValues={{
                plot_id: "",
                new_owner_id: "",
                transfer_fee: "",
              }}
              validationSchema={TransferPlotSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form className="space-y-8">
                  <div className="relative">
                    <label className="inline-block text-teal-300 text-sm font-medium mb-2">
                      Search New Owner
                    </label>
                    <div className="relative">
                      <input
                        ref={inputRef}
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className={`w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border ${
                          touched.new_owner_id && errors.new_owner_id
                            ? "border-red-500"
                            : "border-teal-500/20 focus:border-teal-500/50"
                        } focus:ring-2 focus:ring-teal-500/20 transition-all duration-300`}
                        placeholder="Enter customer name..."
                      />
                      <div className="absolute right-4 top-1/2 -translate-y-1/2">
                        <Search className="h-6 w-6 rounded-full text-teal-300 flex items-center justify-center" />
                      </div>
                    </div>

                    {/* Error Message for New Owner */}
                    <ErrorMessage
                      name="new_owner_id"
                      component="div"
                      className="text-red-500 text-sm mt-2"
                    />

                    {/* Dropdown Results */}
                    {isFocused && filteredCustomers.length > 0 && (
                      <div className="absolute w-full mt-2 py-2 bg-slate-800/90 backdrop-blur-xl border border-teal-500/20 rounded-2xl z-50 shadow-xl">
                        {filteredCustomers.map((customer) => (
                          <div
                            key={customer._id}
                            onClick={() =>
                              handleCustomerSelect(
                                customer._id,
                                customer.username,
                                setFieldValue
                              )
                            }
                            className="px-6 py-3 hover:bg-teal-500/10 cursor-pointer text-teal-300 transition-colors duration-200"
                          >
                            {customer.username}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Plots Grid */}
                  {selectedCustomer && customerPlots.length > 0 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-3 px-4 py-3 bg-teal-500/10 rounded-2xl">
                        <div className="h-2 w-2 rounded-full bg-teal-400 animate-pulse" />
                        <p className="text-teal-300 font-medium">
                          Available Plots ({customerPlots.length})
                        </p>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {customerPlots.map((plot) => {
                          const details = getSocietyAndPlotDetails(plot._id);
                          return (
                            <div
                              key={plot._id}
                              onClick={() =>
                                details?.bookingStatus !== "Transfer" &&
                                handlePlotSelect(plot, setFieldValue)
                              }
                              className={`relative group rounded-2xl border transition-all duration-500 cursor-pointer ${
                                details?.bookingStatus === "Transfer"
                                  ? "bg-gray-700 border-gray-500 cursor-not-allowed"
                                  : selectedPlot === plot._id
                                  ? "bg-teal-500/10 border-teal-400/50 shadow-lg shadow-teal-500/20"
                                  : "bg-slate-800/40 border-white/5 hover:border-teal-300/20"
                              }`}
                            >
                              <div className="p-6 space-y-4">
                                <div className="flex justify-between items-center">
                                  <span className="px-4 py-1 rounded-full bg-teal-500/10 text-sm font-medium text-teal-300">
                                    Plot {details?.plotNumber}
                                  </span>
                                  <div className="h-8 w-8 rounded-full bg-teal-500/10 flex items-center justify-center">
                                    <div className="h-2 w-2 rounded-full bg-teal-400 group-hover:animate-ping" />
                                  </div>
                                </div>
                                <div className="space-y-4">
                                  <div className="flex items-center gap-2">
                                    <span className="text-teal-300/70">
                                      Society:
                                    </span>
                                    <span className="text-teal-300 font-medium">
                                      {details?.societyName}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2 gap-3">
                                    <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
                                      <p className="text-sm text-teal-300/70">
                                        Size
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.plotType} Marla
                                      </p>
                                    </div>
                                    <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
                                      <p className="text-sm text-teal-300/70">
                                        Block
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.block}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex justify-between items-center pt-2">
                                    <div>
                                      <p className="text-sm text-teal-300/70">
                                        Status
                                      </p>
                                      <p
                                        className={`font-medium ${
                                          details?.bookingStatus === "Transfer"
                                            ? "text-red-500"
                                            : "text-teal-300"
                                        }`}
                                      >
                                        {details?.bookingStatus}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm text-teal-300/70">
                                        Category
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.category}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Plot Selection Error */}
                      <ErrorMessage
                        name="plot_id"
                        component="div"
                        className="text-red-500 text-sm mt-2"
                      />
                    </div>
                  )}

                  {/* Transfer Fee Input */}
                  {selectedPlot && (
                    <div className="space-y-2">
                      <label className="inline-block text-teal-300 text-sm font-medium mb-2">
                        Transfer Fee
                      </label>
                      <div className="relative">
                        <Field
                          type="text"
                          name="transfer_fee"
                          className={`w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border ${
                            touched.transfer_fee && errors.transfer_fee
                              ? "border-red-500"
                              : "border-teal-500/20 focus:border-teal-500/50"
                          } focus:ring-2 focus:ring-teal-500/20 transition-all duration-300`}
                          placeholder="Enter transfer fee amount..."
                        />
                        <div className="absolute right-4 top-1/2 -translate-y-1/2 text-teal-300/50">
                          PKR
                        </div>
                      </div>

                      {/* Transfer Fee Error Message */}
                      <ErrorMessage
                        name="transfer_fee"
                        component="div"
                        className="text-red-500 text-sm mt-2"
                      />
                    </div>
                  )}

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-teal-500 to-teal-400 h-12 rounded-2xl text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/25 hover:-translate-y-0.5 active:translate-y-0 disabled:opacity-50 disabled:pointer-events-none"
                    disabled={
                      isSubmitting || !selectedPlot || !values.transfer_fee
                    }
                  >
                    {isSubmitting ? "Processing..." : "Complete Transfer"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPlotForm;
