import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionSummary } from "../store/slices/generalTransactionSlice";
import { ArrowUpCircle, ArrowDownCircle, Wallet } from "lucide-react";

const TransactionSummary = () => {
  const dispatch = useDispatch();
  const { summary, loading, error } = useSelector(
    (state) => state.generalTransactions
  );

  useEffect(() => {
    dispatch(fetchTransactionSummary());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl">
        <div className="flex items-center justify-center h-40">
          <div className="text-teal-300 animate-pulse">Loading summary...</div>
        </div>
      </div>
    );
  }

  if (error) {
    const errorMessage = error?.message || "An unknown error occurred.";
    return (
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl">
        <div className="bg-red-500/10 text-red-400 p-6 rounded-xl backdrop-blur-sm">
          Error fetching summary: {errorMessage}
        </div>
      </div>
    );
  }

  if (!summary) {
    return (
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl">
        <div className="text-teal-300/70 text-center p-6">
          No transaction summary available
        </div>
      </div>
    );
  }

  const summaryItems = [
    {
      title: "Total Income",
      amount: summary.income || 0,
      icon: <ArrowUpCircle className="w-6 h-6" />,
      bgColor: "bg-emerald-500/10",
      textColor: "text-emerald-400",
      borderColor: "border-emerald-500/20",
    },
    {
      title: "Total Expense",
      amount: summary.expense || 0,
      icon: <ArrowDownCircle className="w-6 h-6" />,
      bgColor: "bg-rose-500/10",
      textColor: "text-rose-400",
      borderColor: "border-rose-500/20",
    },
    {
      title: "Balance",
      amount: summary.balance || 0,
      icon: <Wallet className="w-6 h-6" />,
      bgColor: "bg-teal-500/10",
      textColor: "text-teal-300",
      borderColor: "border-teal-500/20",
    },
  ];

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl">
      <h2 className="text-3xl font-bold text-teal-300 mb-8 tracking-tight">
        Transaction Summary
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {summaryItems.map((item, index) => (
          <div
            key={index}
            className={`${item.bgColor} border ${item.borderColor} rounded-xl p-6 backdrop-blur-sm transition-transform duration-300 hover:scale-105`}
          >
            <div className="flex items-center justify-between mb-4">
              <span className={`${item.textColor}`}>{item.icon}</span>
              <span className={`${item.textColor} text-sm font-medium`}>
                {item.title}
              </span>
            </div>
            <div className={`${item.textColor} text-2xl font-bold`}>
              Rs. {item.amount.toLocaleString()}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 overflow-hidden rounded-xl border border-slate-700/30">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-slate-800/50 backdrop-blur-sm">
              <th className="text-teal-300 px-6 py-4 text-left font-medium">
                Category
              </th>
              <th className="text-teal-300 px-6 py-4 text-right font-medium">
                Amount (Rs.)
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-slate-700/30">
            {summaryItems.map((item, index) => (
              <tr
                key={index}
                className="hover:bg-slate-800/30 transition-colors duration-200"
              >
                <td className={`px-6 py-4 font-medium ${item.textColor}`}>
                  <div className="flex items-center space-x-2">
                    {item.icon}
                    <span>{item.title}</span>
                  </div>
                </td>
                <td className={`px-6 py-4 text-right ${item.textColor}`}>
                  {item.amount.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionSummary;
