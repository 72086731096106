import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, AlertCircle } from "lucide-react";
import {
  fetchOverdueInstallments,
  payInstallmentAsync,
} from "../store/slices/installmentSlice";
import { fetchAllUsers } from "../store/slices/authSlice";
import { getAllBookings } from "../store/slices/bookingSlice";
import { addNotification } from "../store/slices/notificationSlice";

const OverduePayments = () => {
  const dispatch = useDispatch();
  const [reminderSent, setReminderSent] = useState({});

  const {
    overdueInstallments = [],
    status: installmentsStatus,
    error: installmentsError,
  } = useSelector((state) => state.installments || {});
  const {
    users = [],
    status: userStatus,
    error: userError,
  } = useSelector((state) => state.auth || {});
  const {
    bookings = [],
    loading: bookingsLoading,
    error: bookingsError,
  } = useSelector((state) => state.booking || {});

  useEffect(() => {
    dispatch(fetchOverdueInstallments());
    dispatch(getAllBookings());
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const handleSendReminder = (installmentId, customerId, plotId) => {
    dispatch(
      addNotification({
        userId: customerId,
        message: `Your installment for Plot #${plotId} is overdue. Please make the payment.`,
        type: "Overdue",
        recipientRole: "Customer",
      })
    );

    dispatch(
      addNotification({
        userId: null,
        message: `Installment for Plot #${plotId} is overdue. Please follow up with the customer.`,
        type: "Overdue",
        recipientRole: "Admin",
      })
    );

    setReminderSent((prev) => ({
      ...prev,
      [installmentId]: Date.now(),
    }));
  };

  const handlePayInstallment = (installmentId) => {
    dispatch(payInstallmentAsync({ id: installmentId, status: "Completed" }));

    const installment = overdueInstallments.find(
      (installment) => installment._id === installmentId
    );

    if (installment) {
      dispatch(
        addNotification({
          userId: installment.customer_id,
          message: `Your installment for Plot #${installment.plot_id} has been marked as completed.`,
          type: "Completed",
          recipientRole: "Customer",
        })
      );
    }
  };

  if (
    installmentsStatus === "loading" ||
    userStatus === "loading" ||
    bookingsLoading
  ) {
    return (
      <div className="flex items-center justify-center h-64 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="flex items-center space-x-2 text-teal-300">
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300"></div>
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-75"></div>
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-150"></div>
        </div>
      </div>
    );
  }

  if (
    installmentsStatus === "failed" ||
    userStatus === "failed" ||
    bookingsError
  ) {
    return (
      <div className="flex items-center justify-center h-64 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="flex items-center space-x-2 text-red-400">
          <AlertCircle className="w-5 h-5" />
          <span>
            Error:{" "}
            {installmentsError || userError || bookingsError || "Unknown error"}
          </span>
        </div>
      </div>
    );
  }

  const isReminderDisabled = (installmentId) => {
    const reminderTimestamp = reminderSent[installmentId];
    return reminderTimestamp && Date.now() - reminderTimestamp < 86400000;
  };

  const filteredInstallments = overdueInstallments.filter((installment) => {
    if (installment.status === "Completed") {
      const completedAt = new Date(installment.payment_date);
      return Date.now() - completedAt.getTime() < 86400000;
    }
    return true;
  });

  return (
    <div className="p-6 m-8 rounded-xl bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-xl">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-bold text-teal-300">Overdue Payments</h2>
        <div className="text-teal-300 text-sm">
          {filteredInstallments.length} pending payments
        </div>
      </div>

      <div className="overflow-x-auto">
        {!filteredInstallments.length ? (
          <div className="flex flex-col items-center justify-center h-32 space-y-3">
            <CheckCircle className="w-8 h-8 text-teal-300" />
            <span className="text-teal-300 text-lg font-medium">
              No overdue installments
            </span>
          </div>
        ) : (
          <table className="w-full">
            <thead>
              <tr className="border-b border-slate-700/50">
                <th className="p-3 text-left text-teal-300 font-medium">
                  Customer
                </th>
                <th className="p-3 text-left text-teal-300 font-medium">
                  Plot Details
                </th>
                <th className="p-3 text-left text-teal-300 font-medium">
                  Amount
                </th>
                <th className="p-3 text-left text-teal-300 font-medium">
                  Due Date
                </th>
                <th className="p-3 text-left text-teal-300 font-medium">
                  Status
                </th>
                <th className="p-3 text-left text-teal-300 font-medium">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInstallments.map((installment, index) => {
                const customer = users.find(
                  (user) =>
                    user._id === installment.customer_id &&
                    user.role === "Customer"
                );
                const booking = bookings.find(
                  (booking) => booking.plot_id?._id === installment.plot_id
                );

                return (
                  <tr
                    key={installment._id}
                    className={`border-b border-slate-700/30 transition-colors duration-300 hover:bg-slate-700/30`}
                  >
                    <td className="p-4">
                      <div className="flex items-center space-x-3">
                        <div className="w-10 h-10 rounded-full overflow-hidden bg-slate-600">
                          <img
                            src={
                              customer?.profile_picture ||
                              "/default-profile-pic.png"
                            }
                            alt={customer?.customerData?.name || "Customer"}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div>
                          <div className="font-medium text-teal-300">
                            {customer?.customerData?.name || "Unknown"}
                          </div>
                          <div className="text-sm text-teal-300/70">
                            {customer?.customerData?.contactInfo?.phone ||
                              "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="p-4">
                      <div className="text-teal-300">
                        <div className="font-medium">
                          Plot #{booking?.plot_id?.plot_number}
                        </div>
                        <div className="text-sm text-teal-300/70">
                          {booking?.plot_id?.size} Marla •{" "}
                          {booking?.plot_id?.plot_type}
                        </div>
                      </div>
                    </td>
                    <td className="p-4">
                      <div className="flex items-center space-x-1 text-teal-300">
                        <span className="text-sm font-semibold">PKR</span>
                        <span>
                          {installment.amount.toLocaleString("en-PK", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </td>
                    {/* <td className="p-4">
                      <div className="flex items-center space-x-1 text-teal-300">
                        <span className="text-sm font-medium">₨</span>
                        <span>
                          {installment.amount.toLocaleString("en-PK")}
                        </span>
                      </div>
                    </td> */}
                    <td className="p-4 text-teal-300">
                      {new Date(installment.due_date).toLocaleDateString()}
                    </td>
                    <td className="p-4">
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-medium
                        ${
                          installment.status === "Completed"
                            ? "bg-green-500/20 text-green-300"
                            : "bg-red-900/50 text-red-300"
                        }`}
                      >
                        {installment.status}
                      </span>
                    </td>
                    <td className="p-4">
                      <div className="flex space-x-2">
                        <button
                          onClick={() =>
                            handleSendReminder(
                              installment._id,
                              installment.customer_id,
                              installment.plot_id
                            )
                          }
                          disabled={
                            installment.status === "Completed" ||
                            isReminderDisabled(installment._id)
                          }
                          className="px-4 py-2 shadow-full rounded-lg bg-amber-500/10 hover:bg-amber-500/20 
        disabled:opacity-50 disabled:cursor-not-allowed 
        transition-colors duration-200 
        focus:outline-none focus:ring-2 focus:ring-amber-500/50
        text-amber-400 group hover:text-amber-300"
                        >
                          Send Reminder
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default OverduePayments;
