import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";
import { payEmployeeSalary } from "../store/slices/salarySlice";
import { fetchAllUsers } from "../store/slices/authSlice";
import SalarySlipModal from "./SalarySlipModal";
import { FileText, DollarSign, ChevronDown, User } from "lucide-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const SalaryHistory = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  // State for filtering
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedEmployeeForSlip, setSelectedEmployeeForSlip] = useState(null);
  const [paidStatus, setPaidStatus] = useState({});

  // Generate years array from 2023 to the current year
  const years = Array.from(
    { length: new Date().getFullYear() - 2023 + 1 },
    (_, i) => 2023 + i
  );

  // Months array
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  // Load saved paidStatus from localStorage
  useEffect(() => {
    const savedPaidStatus =
      JSON.parse(localStorage.getItem("paidStatus")) || {};
    setPaidStatus(savedPaidStatus);
  }, []);

  // Save paidStatus to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("paidStatus", JSON.stringify(paidStatus));
  }, [paidStatus]);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  // Pay Salary Handler
  const handlePaySalary = async (employeeId, employeeDetails) => {
    const paidKey = `${employeeId}-${selectedYear}-${selectedMonth}`;

    // Check if salary is already paid for the selected employee and month
    if (paidStatus[paidKey]) {
      enqueueSnackbar(
        `Salary already paid for ${employeeDetails.username} in ${selectedYear}-${selectedMonth}`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      return;
    }

    // Ask for confirmation to pay salary
    const result = await MySwal.fire({
      title: "Pay Salary Confirmation",
      html: `
        <div class="text-teal-300">
          <p><strong>Username:</strong> ${employeeDetails.username}</p>
          <p><strong>Designation:</strong> ${employeeDetails.designation}</p>
          <p><strong>Salary:</strong> PKR${employeeDetails.salaryDetails.salary.toLocaleString()}</p>
          <p>Do you want to proceed with salary payment?</p>
        </div>`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Pay Salary",
      cancelButtonText: "Cancel",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      customClass: {
        title: "text-teal-300",
        confirmButton:
          "bg-teal-600 hover:bg-teal-700 text-white w-26 h-12 p-2 rounded-lg",
        cancelButton:
          "bg-gray-600 hover:bg-gray-700 text-white ml-3 w-26 h-12 p-2 rounded-lg",
      },
      buttonsStyling: false,
    });

    // If the user confirms to pay
    if (result.isConfirmed) {
      try {
        // Dispatch action to pay salary
        const response = await dispatch(payEmployeeSalary(employeeId));

        if (response.error) {
          // If salary payment already processed, show an error

          MySwal.fire({
            title: "Payment Already Made",
            html: `
              <div class="text-teal-300">
                <p><strong>Error:</strong> Payment could not be processed.</p>
                <p>Please check the details and try again.</p>
              </div>`,
            icon: "error",
            text:
              response.error.message || "Error processing the salary payment.",
            showCancelButton: false,
            confirmButtonText: "Close",
            background:
              "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
            customClass: {
              title: "text-teal-300",
              confirmButton:
                "bg-teal-600 hover:bg-teal-700 text-white w-26 h-12 p-2 rounded-lg",
            },
            buttonsStyling: false,
          });
        } else {
          // Mark the employee as paid for the current month
          setPaidStatus((prevStatus) => ({
            ...prevStatus,
            [paidKey]: true,
          }));

          enqueueSnackbar(
            `Salary paid successfully for ${employeeDetails.username}`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        }
      } catch (error) {
        console.error("Error paying salary: ", error);

        await MySwal.fire({
          title: "Payment Error",
          html: `
            <div class="text-teal-300">
              <p><strong>Error:</strong> Payment could not be processed.</p>
              <p>Please check the details and try again.</p>
            </div>`,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Close",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          customClass: {
            title: "text-teal-300",
            confirmButton:
              "bg-teal-600 hover:bg-teal-700 text-white w-26 h-12 p-2 rounded-lg",
          },
          buttonsStyling: false,
        });
      }
    }
  };

  // Generate Salary Slip Handler
  const handleGenerateSalarySlip = (user) => {
    if (user && user.employeeData) {
      setSelectedEmployeeForSlip(user);
    } else {
      console.error("Invalid user data for salary slip", user);
      enqueueSnackbar(
        "Unable to generate salary slip. Invalid employee data.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
  };
  const usersNeedingSalaryPayment = users.filter((user) => {
    if (!user.employeeData || !user.employeeData.salaryDetails) return false;

    const { dateOfJoining } = user.employeeData.personalDetails;
    const joiningDate = new Date(dateOfJoining);
    if (
      joiningDate.getFullYear() > selectedYear ||
      (joiningDate.getFullYear() === selectedYear &&
        joiningDate.getMonth() + 1 > selectedMonth)
    ) {
      return false;
    }

    return true;
  });

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 min-h-screen p-6">
      <div className="bg-slate-900/60 backdrop-blur-sm rounded-2xl shadow-2xl border border-teal-300/20 h-full">
        {/* Header */}
        <div className="p-6 border-b border-teal-300/20 flex justify-between items-center">
          <h2 className="text-3xl font-bold text-teal-300 flex items-center">
            <DollarSign className="mr-3 w-8 h-8" />
            Salary Management Dashboard
          </h2>
        </div>

        {/* Filters */}
        <div className="p-6 flex space-x-4">
          {/* Year Dropdown */}
          <div className="flex-1">
            <label className="block text-teal-300 mb-2">Year</label>
            <div className="relative">
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 appearance-none"
              >
                {years.map((year) => (
                  <option key={year} value={year} className="bg-slate-800">
                    {year}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-teal-300">
                <ChevronDown className="w-5 h-5" />
              </div>
            </div>
          </div>

          {/* Month Dropdown */}
          <div className="flex-1">
            <label className="block text-teal-300 mb-2">Month</label>
            <div className="relative">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 appearance-none"
              >
                {months.map((month) => (
                  <option
                    key={month.value}
                    value={month.value}
                    className="bg-slate-800"
                  >
                    {month.label}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-teal-300">
                <ChevronDown className="w-5 h-5" />
              </div>
            </div>
          </div>
        </div>

        {/* Employees Salary Table */}
        <div className="p-6">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse bg-slate-800 rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-slate-700 text-teal-300">
                  <th className="p-4 text-left">Employee</th>
                  <th className="p-4 text-left">Designation</th>
                  <th className="p-4 text-left">Basic Salary</th>
                  <th className="p-4 text-left">Allowances</th>
                  <th className="p-4 text-left">Deductions</th>
                  <th className="p-4 text-left">Net Salary</th>
                  <th className="p-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {usersNeedingSalaryPayment.length > 0 ? (
                  usersNeedingSalaryPayment.map((user) => {
                    const salaryDetails = user.employeeData.salaryDetails || {};
                    const netSalary =
                      (salaryDetails.salary || 0) +
                      (salaryDetails.allowances || 0) -
                      (salaryDetails.deductions || 0);

                    const paidKey = `${user.employeeData._id}-${selectedYear}-${selectedMonth}`;
                    const isPaid = paidStatus[paidKey];

                    return (
                      <tr
                        key={user._id}
                        className="border-b border-slate-700 hover:bg-slate-700/50 transition-all duration-300"
                      >
                        <td className="p-4 text-teal-300 flex items-center">
                          <User className="mr-2 w-5 h-5" />
                          {user.username}
                        </td>
                        <td className="p-4 text-teal-300">
                          {user.employeeData.designation}
                        </td>
                        <td className="p-4 text-teal-300">
                          PKR {(salaryDetails.salary || 0).toLocaleString()}
                        </td>
                        <td className="p-4 text-teal-300">
                          PKR {(salaryDetails.allowances || 0).toLocaleString()}
                        </td>
                        <td className="p-4 text-teal-300">
                          PKR {(salaryDetails.deductions || 0).toLocaleString()}
                        </td>
                        <td className="p-4 text-teal-300 font-bold">
                          PKR {netSalary.toLocaleString()}
                        </td>
                        <td className="p-4 text-center">
                          <div className="flex space-x-2 justify-center">
                            <button
                              onClick={() => {
                                const paidKey = `${user.employeeData._id}-${selectedYear}-${selectedMonth}`;
                                if (paidStatus[paidKey]) {
                                  MySwal.fire({
                                    icon: "error",
                                    title: "Payment Error",
                                    text: `Salary for ${user.username} has already been paid for ${selectedMonth}-${selectedYear}.`,
                                  });
                                } else {
                                  handlePaySalary(user.employeeData._id, {
                                    username: user.username,
                                    designation: user.employeeData.designation,
                                    salaryDetails:
                                      user.employeeData.salaryDetails,
                                  });
                                }
                              }}
                              className={`px-3 py-2 rounded-lg flex items-center ${
                                paidStatus[
                                  `${user.employeeData._id}-${selectedYear}-${selectedMonth}`
                                ]
                                  ? "bg-gray-500 cursor-not-allowed opacity-50"
                                  : "bg-teal-600 hover:bg-teal-700 text-white"
                              }`}
                              disabled={
                                paidStatus[
                                  `${user.employeeData._id}-${selectedYear}-${selectedMonth}`
                                ]
                              }
                            >
                              {paidStatus[
                                `${user.employeeData._id}-${selectedYear}-${selectedMonth}`
                              ] ? (
                                "Paid"
                              ) : (
                                <>
                                  <DollarSign className="mr-1 w-4 h-4" />
                                  Pay
                                </>
                              )}
                            </button>

                            <button
                              onClick={() => handleGenerateSalarySlip(user)}
                              className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 rounded-lg flex items-center"
                            >
                              <FileText className="mr-1 w-4 h-4" />
                              Slip
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7" className="p-4 text-center text-teal-300">
                      No records found for the selected month and year.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {selectedEmployeeForSlip && (
          <SalarySlipModal
            employeeDetails={selectedEmployeeForSlip}
            salaryDetails={selectedEmployeeForSlip.employeeData.salaryDetails}
            onClose={() => setSelectedEmployeeForSlip(null)}
          />
        )}
      </div>
    </div>
  );
};

// Wrapper component to provide SnackbarProvider
const SalaryHistoryWrapper = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <SalaryHistory />
    </SnackbarProvider>
  );
};

export default SalaryHistoryWrapper;
