import React from "react";
import Topbar from "../Topbar";
import { MyProSidebarProvider } from "./SidebarContext";
const LayoutWithSidebarTopbar = ({ component, onLogout }) => {
  return (
    <MyProSidebarProvider>
      <div style={{ display: "flex", height: "100vh", width: "100%" }}>
        {/* Main content on the right */}
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          <Topbar onLogout={onLogout} />
          <div style={{ padding: "20px" }}>{component}</div>
        </div>
      </div>
    </MyProSidebarProvider>
  );
};

export default LayoutWithSidebarTopbar;
