// // SimpleLineChart.js
// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// const SimpleLineChart = ({
//   data,
//   isDashboard,
//   selectedTimeFrame,
//   handleTimeFrameChange,
// }) => {
//   return (
//     <div style={{ width: "100%", height: 350 }}>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         <h3>Income, Expense, and Total Revenue</h3>
//         <FormControl sx={{ minWidth: 120 }}>
//           <InputLabel>Time Period</InputLabel>
//           <Select
//             value={selectedTimeFrame}
//             label="Time Period"
//             onChange={(e) => handleTimeFrameChange(e.target.value)}
//           >
//             <MenuItem value="Today">Today</MenuItem>
//             <MenuItem value="Weekly">Weekly</MenuItem>
//             <MenuItem value="Monthly">Monthly</MenuItem>
//             <MenuItem value="Yearly">Yearly</MenuItem>
//           </Select>
//         </FormControl>
//       </div>

//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart data={data}>
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Line
//             type="monotone"
//             dataKey="income"
//             stroke="#82ca9d"
//             activeDot={{ r: 8 }}
//           />
//           <Line type="monotone" dataKey="expense" stroke="#8884d8" />
//           <Line type="monotone" dataKey="totalRevenue" stroke="#ff7300" />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default SimpleLineChart;

// SimpleLineChart.js
// SimpleLineChart.js
// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// const SimpleLineChart = ({
//   data,
//   selectedTimeFrame,
//   handleTimeFrameChange,
// }) => {
//   return (
//     <div style={{ width: "100%", height: 350 }}>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>

//         <FormControl sx={{ minWidth: 120 }}>
//           <InputLabel>Time Period</InputLabel>
//           <Select
//             value={selectedTimeFrame}
//             label="Time Period"
//             onChange={(e) => handleTimeFrameChange(e.target.value)}
//           >
//             <MenuItem value="Today">Today</MenuItem>
//             <MenuItem value="Weekly">Weekly</MenuItem>
//             <MenuItem value="Monthly">Monthly</MenuItem>
//             <MenuItem value="Yearly">Yearly</MenuItem>
//           </Select>
//         </FormControl>
//       </div>

//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart data={data}>
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Line
//             type="monotone"
//             dataKey="income"
//             stroke="#82ca9d"
//             activeDot={{ r: 8 }}
//           />
//           <Line type="monotone" dataKey="expense" stroke="#8884d8" />
//           <Line type="monotone" dataKey="totalRevenue" stroke="#ff7300" />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default SimpleLineChart;
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const SimpleLineChart = ({
  data,
  selectedTimeFrame,
  handleTimeFrameChange,
}) => {
  return (
    <div className="w-full max-w-7xl p-2 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-xl">
      <div className="flex flex-col md:flex-row p-6 justify-between items-start md:items-center mb-6 gap-4">
        <div>
          <h2 className="text-2xl font-bold text-teal-300 mb-2">
            Financial Overview
          </h2>
          <p className="text-slate-400">
            Track your financial metrics over time
          </p>
        </div>

        <div className="relative">
          <select
            value={selectedTimeFrame}
            onChange={(e) => handleTimeFrameChange(e.target.value)}
            className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-teal-500 shadow-sm hover:bg-slate-600 transition-colors w-24"
          >
            <option value="Today">Today</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>

          <div className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
            <svg
              className="w-4 h-4 text-teal-300"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
            <XAxis dataKey="name" stroke="#5eead4" tick={{ fill: "#5eead4" }} />
            <YAxis stroke="#5eead4" tick={{ fill: "#5eead4" }} />
            <Tooltip
              contentStyle={{
                backgroundColor: "#1e293b",
                border: "none",
                borderRadius: "0.5rem",
                color: "#5eead4",
              }}
              itemStyle={{ color: "#5eead4" }}
              labelStyle={{ color: "#5eead4" }}
            />
            <Legend
              wrapperStyle={{
                padding: "20px",
                color: "#5eead4",
              }}
            />
            <Line
              type="monotone"
              dataKey="income"
              stroke="#10b981"
              strokeWidth={2}
              dot={{ fill: "#10b981", strokeWidth: 2 }}
              activeDot={{ r: 8, fill: "#10b981" }}
            />
            <Line
              type="monotone"
              dataKey="expense"
              stroke="#f43f5e"
              strokeWidth={2}
              dot={{ fill: "#f43f5e", strokeWidth: 2 }}
              activeDot={{ r: 8, fill: "#f43f5e" }}
            />
            <Line
              type="monotone"
              dataKey="totalRevenue"
              stroke="#f59e0b"
              strokeWidth={2}
              dot={{ fill: "#f59e0b", strokeWidth: 2 }}
              activeDot={{ r: 8, fill: "#f59e0b" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SimpleLineChart;
