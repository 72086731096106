import React, { useRef, useEffect } from "react";
import { Award, Clock, CreditCard, DollarSign } from "lucide-react";
import printJS from "print-js";

const SalarySlipModal = ({ employeeDetails, salaryDetails, onClose }) => {
  // Ref for printable content
  const printRef = useRef();

  // Automatically print when component mounts
  useEffect(() => {
    handlePrint();
  }, []);

  // Calculate salary details
  const basicSalary = salaryDetails?.salary || 0;
  const allowances = salaryDetails?.allowances || 0;
  const deductions = salaryDetails?.deductions || 0;
  const bonuses = salaryDetails?.bonuses || 0;
  const netSalary = basicSalary + allowances + bonuses - deductions;

  // Handle print functionality
  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        @page {
          size: A4;
          margin: 10mm;
        }
        body {
          font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          line-height: 1.4;
          background: #f4f7f6;
          color: #2c3e50;
          width: 210mm;
          margin: 0 auto;
        }
      
        .salary-slip-container {
          width: 100%;
          max-width: 850px;
          background: white;
          border-radius: 10px;
          box-shadow: 
            0 10px 20px rgba(0, 0, 0, 0.05);
          overflow: hidden;
          border: 1px solid rgba(0,0,0,0.05);
          page-break-inside: avoid;
        }
        .header {
          background: linear-gradient(135deg, #2575fc 0%, #2575fc 100%);
          color: white;
          padding: 15px 30px;
          text-align: center;
          position: relative;
          clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        }
        .header h1 {
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
        .header-details {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  margin-top: 10px;
  font-size: 12px;
}

.header-details .flex {
  display: flex;
  align-items: center;
}

.header-details .flex span {
  line-height: 1;
}

.header-details .flex .mr-2 {
  margin-right: 8px;
}
        .company-info {
          text-align: center;
          padding: 10px 20px;
          background: #f8f9fa;
          border-bottom: 1px solid #e9ecef;
        }
        .company-info h2 {
          font-size: 18px;
        }
        .employee-details {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 10px;
          padding: 15px;
          background: white;
          border-bottom: 1px solid #e9ecef;
        }
        .employee-detail {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 8px;
          background: #f8f9fa;
          border-radius: 8px;
        }
        .employee-detail strong {
          color: black;
          margin-bottom: 5px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        .employee-detail span {
          font-size: 14px;
          color: #2c3e50;
          font-weight: 500;
        }
        .salary-breakdown {
          display: flex;
          justify-content: space-between;
          padding: 15px;
          background: linear-gradient(to right, #f4f7f6, #f8f9fa);
        }
        .earnings, .deductions {
          width: 48%;
          background: white;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0 5px 15px rgba(0,0,0,0.05);
        }
        .section-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          color: black;
          margin-bottom: 10px;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          font-size: 14px;
        }
        .section-title svg {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }
        .salary-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px dotted #e9ecef;
          font-size: 14px;
        }
        .net-salary {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 15px 30px;
          background: #f8f9fa;
          border-top: 2px solid black;
        }
        .net-salary-label {
          font-size: 16px;
          margin-right: 15px;
          color: #2c3e50;
        }
        .net-salary-amount {
          font-size: 24px;
          font-weight: 800;
          color: black;
        }
        .signatures {
          display: flex;
          justify-content: space-between;
          padding: 25px 30px;
          background: white;
          border-top: 1px solid #e9ecef;
        }
        .signature-block {
          width: 30%;
          text-align: center;
          padding: 10px;
          background: #f8f9fa;
          border-radius: 8px;
        }
        .signature-block strong {
          font-size: 12px;
        }
        .signature-line {
          border-top: 2px solid black;
          margin-top: 20px;
        }
        .signature-block span {
          font-size: 12px;
          display: block;
          margin-top: 5px;
        }
        .terms-conditions {
          padding: 10px 30px;
          background: #f4f7f6;
          font-size: 12px;
          color: #6c757d;
          border-top: 1px solid #e9ecef;
        }
        .terms-conditions ol {
          padding-left: 20px;
        }
      `,
    });
    if (onClose) onClose();
  };

  return (
    <div ref={printRef} className="hidden print:block">
      <div className="salary-slip-container">
        {/* Header */}
        <div className="header">
          <h1>Employee Salary Slip</h1>
          <div className="header-details">
            {/* First Row */}
            <div className="flex items-center text-white/100">
              <Clock className="mr-2 text-black h-4 w-4" />{" "}
              {/* Adjust size for alignment */}
              <span>
                Pay Period:{" "}
                {new Date().toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </div>
            {/* Second Row */}
            <div className="flex items-center text-white/80">
              <CreditCard className="mr-2 text-black h-4 w-4" />{" "}
              {/* Adjust size for alignment */}
              <span>Slip Generated: {new Date().toLocaleDateString()}</span>
            </div>
          </div>
        </div>

        {/* Company Info */}
        <div className="company-info">
          <h2 className="text-2xl font-bold text-gray-800">
            {employeeDetails?.societies && employeeDetails.societies.length > 0
              ? employeeDetails.societies
                  .map((society) => society.name)
                  .join(", ")
              : "Modern Tech Solutions"}
          </h2>
          <p className="flex items-center justify-center text-gray-600">
            Bhakkar, Punjab, Pakistan
          </p>
        </div>

        {/* Employee Details */}
        <div className="employee-details">
          <div className="employee-detail">
            <strong>Employee Name</strong>
            <span>{employeeDetails?.employeeData?.name || "N/A"}</span>
          </div>
          <div className="employee-detail">
            <strong>Designation</strong>
            <span>{employeeDetails?.employeeData?.designation || "N/A"}</span>
          </div>
          <div className="employee-detail">
            <strong>Date of Joining</strong>
            <span>
              {employeeDetails?.employeeData?.personalDetails?.dateOfJoining
                ? new Date(
                    employeeDetails.employeeData?.personalDetails.dateOfJoining
                  ).toLocaleDateString()
                : "N/A"}
            </span>
          </div>
          <div className="employee-detail">
            <strong>CNIC</strong>
            <span>
              {employeeDetails?.employeeData?.personalDetails?.cnic || "N/A"}
            </span>
          </div>
        </div>

        {/* Salary Breakdown */}
        <div className="salary-breakdown">
          {/* Earnings */}
          <div className="earnings">
            <div className="section-title">
              <Award className="w-6 h-6" />
              Earnings
            </div>
            <div className="salary-item">
              <span>Basic Salary</span>
              <span>PKR{basicSalary.toLocaleString()}</span>
            </div>
            <div className="salary-item">
              <span>Allowances</span>
              <span>PKR{allowances.toLocaleString()}</span>
            </div>
            <div className="salary-item">
              <span>Bonuses</span>
              <span>PKR{bonuses.toLocaleString()}</span>
            </div>
          </div>

          {/* Deductions */}
          <div className="deductions">
            <div className="section-title">
              <DollarSign className="w-6 h-6" />
              Deductions
            </div>
            <div className="salary-item">
              <span>Total Deductions</span>
              <span>-PKR{deductions.toLocaleString()}</span>
            </div>
          </div>
        </div>

        {/* Net Salary */}
        <div className="net-salary">
          <span className="net-salary-label">Net Salary:</span>
          <span className="net-salary-amount">
            PKR{netSalary.toLocaleString()}
          </span>
        </div>

        {/* Terms and Conditions */}
        <div className="terms-conditions">
          <strong>Terms & Conditions:</strong>
          <ol>
            <li>
              This is a computer-generated slip and does not require a physical
              signature.
            </li>
            <li>
              Salary is subject to tax deductions as per government regulations.
            </li>
            <li>
              Any discrepancies must be reported within 7 days of receipt.
            </li>
            <li>Salary includes all statutory benefits and allowances.</li>
          </ol>
        </div>

        {/* Signatures */}
        <div className="signatures">
          <div className="signature-block">
            <div className="signature-line"></div>
            <span>Prepared By</span>
            <strong>HR Department</strong>
          </div>
          <div className="signature-block">
            <div className="signature-line"></div>
            <span>Approved By</span>
            <strong>Finance Manager</strong>
          </div>
          <div className="signature-block">
            <div className="signature-line"></div>
            <span>Employee Signature</span>
            <strong>Received Payment</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalarySlipModal;
