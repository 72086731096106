import React, { useState } from "react";
import { Button, Typography, Paper, Grid, TextField } from "@mui/material";

const TransferLetters = () => {
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [description, setDescription] = useState("");
  const componentRef = React.useRef();

  const sampleLetters = [
    {
      id: 1,
      title: "Transfer Letter for Plot 101",
      details: "This is to certify the transfer of Plot 101 to the new owner.",
    },
    {
      id: 2,
      title: "Transfer Letter for Plot 202",
      details: "This is to certify the transfer of Plot 202 to the new owner.",
    },
  ];

  return (
    <div className="p-4">
      <Typography variant="h5" className="mb-4">
        Transfer Letters
      </Typography>
      {sampleLetters.map((letter) => (
        <Button
          key={letter.id}
          variant="contained"
          color="primary"
          className="mb-2"
          onClick={() => setSelectedLetter(letter)}
        >
          {letter.title}
        </Button>
      ))}

      {selectedLetter && (
        <Paper
          ref={componentRef}
          className="p-8 mt-6 bg-white shadow-md max-w-screen-lg mx-auto"
        >
          <Grid container className="mb-8">
            <Grid item xs={2}>
              <img
                src="/logo.png"
                alt="Company Logo"
                className="w-20 h-20 object-contain"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4" className="font-bold">
                Your Company Name
              </Typography>
              <Typography variant="subtitle1">
                123 Street Name, City, State, ZIP
              </Typography>
              <Typography variant="subtitle2">
                Phone: (123) 456-7890 | Email: info@company.com
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h5" className="text-center mb-4 font-semibold">
            Transfer Letter
          </Typography>
          <div className="border p-4 rounded-lg text-lg">
            <p>
              <strong>Transfer Date:</strong> {new Date().toLocaleDateString()}
            </p>
            <p>
              <strong>Plot Number:</strong> {selectedLetter.title.split(" ")[3]}
            </p>
            <p>
              <strong>Details:</strong> {selectedLetter.details}
            </p>

            <Typography variant="h6" className="mt-6 font-semibold">
              Transfer Description:
            </Typography>
            <TextField
              label="Enter Detailed Transfer Description"
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              className="mt-2 mb-4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {description && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-50">
              <Typography variant="h6" className="font-semibold">
                Description:
              </Typography>
              {description.split("\n").map((paragraph, index) => (
                <p key={index} className="mt-2 text-justify">
                  {paragraph}
                </p>
              ))}
            </div>
          )}

          <Typography variant="subtitle2" className="mt-8">
            Authorized Signature: ________________________
          </Typography>

          <Button variant="outlined" color="secondary" className="mt-6">
            Print Transfer Letter
          </Button>
        </Paper>
      )}
    </div>
  );
};

export default TransferLetters;
