import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createResell, clearResell } from "../store/slices/resellSlice";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import {
  Users,
  MapPin,
  Calendar,
  CreditCard,
  FileText,
  Building2,
  UserPlus,
  PlusCircle,
} from "lucide-react";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const ResellManagement = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state.auth.users) || [];
  const { societies } = useSelector((state) => state.societies) || {
    societies: [],
  };
  const [formData, setFormData] = useState({
    plot_id: "",
    previous_customer_id: "",
    new_customer_id: "",
    resell_fee: 0,
    resell_date: new Date().toISOString().split("T")[0],
    description: "",
  });
  const [previousCustomerPlots, setPreviousCustomerPlots] = useState([]);

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (formData.previous_customer_id) {
      dispatch(fetchCustomerPlots(formData.previous_customer_id)).then(
        (result) => {
          setPreviousCustomerPlots(result.payload || []);
        }
      );
    } else {
      setPreviousCustomerPlots([]);
    }
  }, [formData.previous_customer_id, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "resell_fee" ? Number(value) : value,
    }));
  };

  // const handleCreate = () => {
  //   // Show SweetAlert for confirmation
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Do you want to create this resell record?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
  //     color: "#5EEAD4",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, create it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       dispatch(createResell(formData))
  //         .then((result) => {
  //           if (result?.success || result?.user) {
  //             Swal.fire({
  //               icon: "success",
  //               title: "Success",
  //               text: "Resell record created successfully.",
  //               confirmButtonColor: "#008000",
  //               background:
  //                 "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
  //               color: "#5EEAD4",
  //             });
  //             setFormData({
  //               plot_id: "",
  //               previous_customer_id: "",
  //               new_customer_id: "",
  //               resell_fee: 0,
  //               resell_date: new Date().toISOString().split("T")[0],
  //               description: "",
  //             });
  //             dispatch(clearResell());
  //           } else {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Failed",
  //               text: result.message || "An unexpected error occurred.",
  //               confirmButtonColor: "#f27474",
  //               background:
  //                 "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
  //               color: "#5EEAD4",
  //             });

  //             enqueueSnackbar(
  //               "Failed to create resell record. Please try again.",
  //               {
  //                 variant: "error",
  //               }
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error creating resell record:", error);
  //           enqueueSnackbar(
  //             "Failed to create resell record. Please try again.",
  //             {
  //               variant: "error",
  //             }
  //           );
  //           Swal.fire({
  //             icon: "error",
  //             title: "Failed",
  //             text: "An error occurred while creating the record.",
  //             confirmButtonColor: "#f27474",
  //             background:
  //               "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
  //             color: "#FCA5A5",
  //           });
  //         });
  //     }
  //   });
  // };
  const handleCreate = async () => {
    // Show SweetAlert for confirmation
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create this resell record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
    });

    if (result.isConfirmed) {
      try {
        const response = await dispatch(createResell(formData));
        console.log("Response from backend:", response);
        if (response?.type === "resell/createResell/fulfilled") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text:
              response?.payload?.message ||
              "Resell record created successfully.",
            confirmButtonColor: "#008000",
            background:
              "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
            color: "#5EEAD4",
          });
          setFormData({
            plot_id: "",
            previous_customer_id: "",
            new_customer_id: "",
            resell_fee: 0,
            resell_date: new Date().toISOString().split("T")[0],
            description: "",
          });
          enqueueSnackbar(
            response?.payload?.message || "created successfully resell record.",
            {
              variant: "success",
            }
          );
          dispatch(clearResell());
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.payload?.message || "An unexpected error occurred.",
            confirmButtonColor: "#f27474",
            background:
              "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
            color: "#FCA5A5",
          });
          enqueueSnackbar(
            response?.payload?.message || "Failed to create resell record.",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        // In case the backend call fails or there's a network error
        console.error("Error creating resell record:", error);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while creating the record.",
          confirmButtonColor: "#f27474",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#FCA5A5",
        });

        // Show error notification
        enqueueSnackbar("An error occurred. Please try again.", {
          variant: "error",
        });
      }
    }
  };

  const stats = [
    {
      title: "Total Plots",
      value: previousCustomerPlots.length,
      icon: <Building2 className="w-6 h-6" />,
      description: "Available for resell",
    },
    {
      title: "Total Customers",
      value: users.length,
      icon: <Users className="w-6 h-6" />,
      description: "Active in system",
    },
    {
      title: "Total Societies",
      value: societies.length,
      icon: <MapPin className="w-6 h-6" />,
      description: "Registered societies",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="max-w-7xl mx-auto p-6 lg:p-8 space-y-8">
        {/* Header Section */}
        <div className="relative overflow-hidden rounded-2xl bg-slate-800/50 backdrop-blur-xl border border-slate-700/50 p-8">
          <div className="absolute inset-0 bg-gradient-to-br from-teal-500/10 via-transparent to-slate-500/10" />
          <div className="relative">
            <h1 className="text-4xl font-bold text-teal-300">
              Resell Management
            </h1>
            <p className="mt-2 text-slate-400">
              Manage property transfers and resell operations
            </p>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="group relative overflow-hidden rounded-2xl bg-slate-800/50 backdrop-blur-xl border border-slate-700/50 p-6 transition-all duration-300 hover:bg-slate-800/70 hover:border-teal-500/50"
            >
              <div className="flex items-center gap-4">
                <div className="p-3 rounded-xl bg-gradient-to-br from-teal-500/20 to-purple-500/20 text-teal-300">
                  {stat.icon}
                </div>
                <div>
                  <p className="text-sm text-slate-400">{stat.title}</p>
                  <p className="text-3xl font-bold text-teal-300">
                    {stat.value}
                  </p>
                  <p className="text-xs text-slate-500 mt-1">
                    {stat.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Main Form Section */}
        <div className="rounded-2xl bg-slate-800/50 backdrop-blur-xl border border-slate-700/50 p-8">
          <div className="space-y-8">
            {/* Customer Selection */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Previous Customer Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  Previous Customer
                </label>
                <div className="relative">
                  <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select
                    name="previous_customer_id"
                    value={formData.previous_customer_id}
                    onChange={handleInputChange}
                    className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                  >
                    <option value="">Select Customer</option>
                    {users
                      .filter((user) => user.role === "Customer")
                      .map((user) => (
                        <option
                          key={user._id}
                          value={user._id}
                          className="bg-slate-800 text-teal-300"
                        >
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              {/* New Customer Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  New Customer
                </label>
                <div className="relative">
                  <UserPlus className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select
                    name="new_customer_id"
                    value={formData.new_customer_id}
                    onChange={handleInputChange}
                    className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                  >
                    <option value="">Select New Customer</option>
                    {users
                      .filter(
                        (user) =>
                          user.role === "Customer" &&
                          user._id !== formData.previous_customer_id
                      )
                      .map((user) => (
                        <option
                          key={user._id}
                          value={user._id}
                          className="bg-slate-800 text-teal-300"
                        >
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Plots Grid */}
            {previousCustomerPlots.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-teal-300">
                  Available Plots
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {previousCustomerPlots.map((plot) => (
                    <div
                      key={plot._id}
                      onClick={() =>
                        setFormData((prev) => ({ ...prev, plot_id: plot._id }))
                      }
                      className={`relative overflow-hidden rounded-xl cursor-pointer transition-all duration-300 ${
                        formData.plot_id === plot._id
                          ? "bg-gradient-to-br from-teal-500/20 to-purple-500/20 border-teal-500"
                          : "bg-slate-900/50 hover:bg-slate-800/70"
                      } border border-slate-700/50 p-4 group`}
                    >
                      <div className="space-y-3">
                        <div className="flex items-center justify-between">
                          <span className="text-teal-300 font-medium">
                            Plot #{plot.plot_number}
                          </span>
                          <span className="px-2 py-1 rounded-full text-xs bg-slate-700/50 text-slate-300">
                            {plot.category}
                          </span>
                        </div>
                        <div className="space-y-1 text-sm text-slate-400">
                          <p>Block: {plot.block}</p>
                          <p>Type: {plot.plot_type}</p>
                        </div>
                      </div>
                      <div className="absolute inset-0 border border-teal-500/0 group-hover:border-teal-500/50 rounded-xl transition-all duration-300" />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Additional Fields */}
            {formData.plot_id && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Resell Fee Input */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-teal-300">
                      Resell Fee
                    </label>
                    <div className="relative">
                      <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                      <input
                        type="number"
                        name="resell_fee"
                        value={formData.resell_fee}
                        onChange={handleInputChange}
                        className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                        placeholder="Enter amount"
                      />
                    </div>
                  </div>

                  {/* Resell Date Input */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-teal-300">
                      Resell Date
                    </label>
                    <div className="relative">
                      <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                      <input
                        type="date"
                        name="resell_date"
                        value={formData.resell_date}
                        onChange={handleInputChange}
                        className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                      />
                    </div>
                  </div>
                </div>

                {/* Description Textarea */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-teal-300">
                    Description
                  </label>
                  <div className="relative">
                    <FileText className="absolute left-3 top-3 text-slate-400 w-5 h-5" />
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                      placeholder="Enter description"
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  onClick={handleCreate}
                  className="w-full group relative overflow-hidden rounded-xl bg-gradient-to-br from-teal-500 to-teal-500 px-8 py-3 text-white transition-all duration-300 hover:from-teal-600 hover:to-purple-600"
                >
                  <div className="relative flex items-center justify-center gap-2">
                    <PlusCircle className="w-5 h-5" />
                    <span className="font-medium">Resell the Plot</span>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellManagement;
