import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserNotifications,
  markNotificationAsRead,
  deleteNotification,
  createNotification,
} from "../../api";

// Thunks

// Create a new notification
export const addNotification = createAsyncThunk(
  "notifications/addNotification",
  async (notificationData, { rejectWithValue }) => {
    try {
      const data = await createNotification(notificationData);
      return data.notification; // Assuming API returns the created notification object
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Fetch notifications for a user
export const getUserNotifications = createAsyncThunk(
  "notifications/getUserNotifications",
  async (userId, { rejectWithValue }) => {
    try {
      const data = await fetchUserNotifications(userId);
      return data.notifications; // The response structure must be aligned with this
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Mark a notification as read
export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notificationId, { rejectWithValue }) => {
    try {
      const data = await markNotificationAsRead(notificationId);
      return data.notification; // Assuming API returns the updated notification
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete a notification
export const deleteNotificationThunk = createAsyncThunk(
  "notifications/deleteNotification",
  async (notificationId, { rejectWithValue }) => {
    try {
      await deleteNotification(notificationId);
      return { notificationId };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    adminNotifications: [], // Holds all fetched admin notifications
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch user notifications
      .addCase(getUserNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
      })
      .addCase(getUserNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Mark as read
      .addCase(markAsRead.fulfilled, (state, action) => {
        const index = state.notifications.findIndex(
          (n) => n._id === action.payload._id
        );
        if (index !== -1) {
          state.notifications[index].isRead = true;
        }
      })

      // Delete notification
      .addCase(deleteNotificationThunk.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (n) => n._id !== action.payload.notificationId
        );
      })

      .addCase(addNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.notifications.unshift(action.payload); // Add new notification to the top
        state.loading = false;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
