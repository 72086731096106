// import React from "react";
// import { useDispatch } from "react-redux";
// import { LogOut } from "lucide-react";
// import { logout } from "../store/slices/authSlice";

// const Logout = () => {
//   const dispatch = useDispatch();

//   const handleLogout = async () => {
//     await dispatch(logout());
//   };

//   return (
//     <button
//       onClick={handleLogout}
//       className="w-full px-4 py-2 text-left text-red-400 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
//     >
//       <LogOut className="h-4 w-4 text-red-400" />
//       <span className="text-red-400">Sign Out</span>
//     </button>
//   );
// };

// export default Logout;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LogOut } from "lucide-react";
import { logout } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);

    await dispatch(logout());

    setLoading(false);
    navigate("/login", { replace: true });

    window.location.reload();
  };

  return (
    <button
      onClick={handleLogout}
      className="w-full px-4 py-2 text-left text-red-400 hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2"
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        // Show spinner while logging out
        <div className="animate-spin h-4 w-4 border-t-2 border-red-400 border-solid rounded-full" />
      ) : (
        <LogOut className="h-4 w-4 text-red-400" />
      )}
      <span className="text-red-400">
        {loading ? "Logging Out..." : "Sign Out"}
      </span>
    </button>
  );
};

export default Logout;
