// import React, {
//   useState,
//   createContext,
//   useContext,
//   useCallback,
//   useMemo,
// } from "react";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import MyProSidebar from "./MyProSidebar";

// const SidebarContext = createContext(null);

// export const MyProSidebarProvider = ({ children }) => {
//   const [sidebarBackgroundColor, setSidebarBackgroundColor] =
//     useState(undefined);
//   const [sidebarImage, setSidebarImage] = useState(undefined);

//   const [activeSociety, setActiveSocietyState] = useState({
//     name: "Al-Noor Developers",
//     description: "Real Estate Agency",
//   });

//   // Track if a user manually selected a society
//   const [userSelectedSociety, setUserSelectedSociety] = useState(false);

//   // Memoized callback for updating the active society
//   const updateActiveSociety = useCallback((society) => {
//     setActiveSocietyState(society);
//     setUserSelectedSociety(true); // Mark as manually selected
//   }, []);

//   // Memoize the context value to avoid unnecessary re-renders
//   const contextValue = useMemo(
//     () => ({
//       sidebarBackgroundColor,
//       setSidebarBackgroundColor,
//       sidebarImage,
//       setSidebarImage,
//       activeSociety,
//       setActiveSociety: updateActiveSociety,
//       userSelectedSociety,
//     }),
//     [
//       sidebarBackgroundColor,
//       sidebarImage,
//       activeSociety,
//       updateActiveSociety,
//       userSelectedSociety,
//     ]
//   );

//   return (
//     <ProSidebarProvider>
//       <SidebarContext.Provider value={contextValue}>
//         <div style={{ display: "flex" }}>
//           <MyProSidebar
//             activeSociety={activeSociety}
//             setActiveSociety={updateActiveSociety}
//           />
//           {children}
//         </div>
//       </SidebarContext.Provider>
//     </ProSidebarProvider>
//   );
// };

// export const useSidebarContext = () => {
//   const context = useContext(SidebarContext);
//   if (!context) {
//     throw new Error(
//       "useSidebarContext must be used within a MyProSidebarProvider"
//     );
//   }
//   return context;
// };

// import React, {
//   useState,
//   useEffect,
//   createContext,
//   useContext,
//   useMemo,
// } from "react";
// import { useSelector } from "react-redux";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import MyProSidebar from "./MyProSidebar";

// const SidebarContext = createContext(null);

// export const MyProSidebarProvider = ({ children }) => {
//   const { societies } = useSelector((state) => state.societies);
//   const { activeUser } = useSelector((state) => state.auth);

//   const [activeSociety, setActiveSocietyState] = useState(null);

//   // Use effect only to set initial activeSociety from Redux store if not set
//   useEffect(() => {
//     // Check if we need to initialize activeSociety with user data
//     if (activeUser && societies.length > 0 && !activeSociety) {
//       const userSociety = societies.find(
//         (society) => society.id === activeUser.societyId
//       );
//       if (userSociety) {
//         setActiveSocietyState(userSociety); // Initialize activeSociety if it's not already set
//       }
//     }
//   }, [activeUser, societies, activeSociety]); // Only trigger this effect once if no activeSociety

//   const updateActiveSociety = (society) => {
//     setActiveSocietyState(society); // Update activeSociety when needed
//   };

//   const contextValue = useMemo(
//     () => ({
//       activeSociety,
//       setActiveSociety: updateActiveSociety,
//     }),
//     [activeSociety]
//   );

//   return (
//     <ProSidebarProvider>
//       <SidebarContext.Provider value={contextValue}>
//         <div style={{ display: "flex" }}>
//           <MyProSidebar />
//           {children}
//         </div>
//       </SidebarContext.Provider>
//     </ProSidebarProvider>
//   );
// };

// export const useSidebarContext = () => {
//   const context = useContext(SidebarContext);
//   if (!context) {
//     throw new Error(
//       "useSidebarContext must be used within MyProSidebarProvider"
//     );
//   }
//   return context;
// };

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

const SidebarContext = createContext(null);

export const MyProSidebarProvider = ({ children }) => {
  const { societies } = useSelector((state) => state.societies);
  const { activeUser } = useSelector((state) => state.auth);

  const [activeSociety, setActiveSocietyState] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (
      activeUser &&
      activeUser.societies &&
      activeUser.societies.length === 1
    ) {
      const userSociety = activeUser.societies[0];
      setActiveSocietyState(userSociety);
    }
  }, [activeUser, societies]);

  const updateActiveSociety = (society) => {
    setActiveSocietyState(society);
  };

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  const contextValue = useMemo(
    () => ({
      activeSociety,
      setActiveSociety: updateActiveSociety,
      collapsed,
      toggleSidebar,
    }),
    [activeSociety, collapsed]
  );

  return (
    <ProSidebarProvider>
      <SidebarContext.Provider value={contextValue}>
        <div style={{ display: "flex" }}>
          <MyProSidebar />
          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error(
      "useSidebarContext must be used within MyProSidebarProvider"
    );
  }
  return context;
};
