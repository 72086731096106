import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { fetchAllUsers, registerUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import {
  User,
  Mail,
  Phone,
  Camera,
  Lock,
  Percent,
  BookUser,
  UserRoundPen,
  IdCard,
  Banknote,
  ChevronUp,
  ChevronDown,
  Eye,
  EyeOff,
  MapPinHouse,
} from "lucide-react";

const InvestorCreation = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);

  const [societiesFetched, setSocietiesFetched] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSocieties, setSelectedSocieties] = useState([]);
  const { users, status } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!societiesFetched) {
      dispatch(fetchSocieties()).then(() => setSocietiesFetched(true));
    }
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, societiesFetched, status, users.length]);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    investorData: Yup.object().shape({
      name: Yup.string().required("Investor name is required"),
      investmentAmount: Yup.string().required("Investment amount is required"),
      profitPercentage: Yup.number()
        .required("Profit percentage is required")
        .positive("Must be a positive number")
        .max(100, "Percentage cannot exceed 100"),
      contactInfo: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        phone: Yup.string()
          .required("Phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Phone number must be in format +92 3XX-XXXXXXX"
          ),
        address: Yup.string().required("Address is required"),
        cnic: Yup.string()
          .required("CNIC is required")
          .matches(/^\d{5}-\d{7}-\d{1}$/, "Invalid CNIC format"),
      }),
    }),
    societies: Yup.array()
      .min(1, "At least one society must be selected")
      .required("Society selection is required"),
  });

  const handleSocietyChange = (societyId, setFieldValue) => {
    const currentSocieties = [...selectedSocieties];
    const index = currentSocieties.indexOf(societyId);

    if (index > -1) {
      currentSocieties.splice(index, 1);
    } else {
      currentSocieties.push(societyId);
    }

    setSelectedSocieties(currentSocieties);
    setFieldValue("societies", currentSocieties);
  };

  const handleProfileImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatCNIC = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.slice(0, 13);
    if (truncatedValue.length <= 5) {
      return truncatedValue;
    } else if (truncatedValue.length <= 12) {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`;
    } else {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
        5,
        12
      )}-${truncatedValue.slice(12)}`;
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Build the user payload
      const userPayload = {
        username: values.username,
        password: values.password,
        role: "Investor",
        societies: values.societies || [],
        profile_picture: profileImage || null,
        investorData: { ...values.investorData },
      };

      console.log("Submitting User Payload:", userPayload);

      const result = await dispatch(registerUser(userPayload)).unwrap();
      const newUser = result?.user;

      if (!newUser || !newUser._id) {
        throw new Error("User registration failed. Missing user ID.");
      }

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Employee registered successfully",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#5EEAD4",
        confirmButtonColor: "#008000",
      });

      resetForm();
      setSelectedSocieties([]);
      setProfileImage(null);
    } catch (error) {
      console.error(
        "Error in handleSubmit:",
        error.response || error.message || error
      );

      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
        confirmButtonColor: "#f27474",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen py-12 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="relative container mx-auto px-4">
        <Formik
          initialValues={{
            username: "",
            password: "",
            societies: [],
            profile_picture: "",
            investorData: {
              name: "",
              investmentAmount: "",
              profitPercentage: "",
              contactInfo: {
                phone: "",
                email: "",
                address: "",
                cnic: "",
              },
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <Form className="relative max-w-4xl mx-auto backdrop-blur-xl bg-white/10 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-8 border border-white/20 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none"></div>

              <div className="relative">
                <h2 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-teal-300 via-teal-300 to-teal-300 text-transparent bg-clip-text">
                  Investor Registration
                </h2>
                <p className="text-teal-300/60 text-center mb-8">
                  Join our society today
                </p>

                <div className="mb-12 flex flex-col items-center">
                  <div className="relative group">
                    <div className="relative w-32 h-32">
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile Preview"
                          className="w-full h-full rounded-full object-cover ring-4 ring-teal-300/50"
                        />
                      ) : (
                        <div className="w-full h-full rounded-full bg-gradient-to-br from-slate-700/50 to-slate-800/50 flex items-center justify-center ring-4 ring-teal-300/50 backdrop-blur-sm">
                          <Camera size={40} className="text-teal-300" />
                        </div>
                      )}
                      <label className="absolute bottom-0 right-0 bg-gradient-to-r from-teal-400 to-cyan-400 p-2 rounded-full cursor-pointer hover:scale-110 transition-transform duration-300 shadow-lg">
                        <Camera size={20} className="text-white" />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            handleProfileImageChange(e, setFieldValue)
                          }
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* Username & Password Section */}
                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-teal-300 mb-4">
                      Basic Information
                    </h3>
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <User
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="text"
                          name="username"
                          placeholder="Username"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <Lock
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-3 top-3"
                        >
                          {showPassword ? (
                            <Eye size={20} className="text-teal-300" />
                          ) : (
                            <EyeOff size={20} className="text-teal-300" />
                          )}
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Society Selection */}
                    <div className="space-y-6">
                      <h3 className="text-xl font-semibold text-teal-300">
                        Society Information
                      </h3>
                      <div className="relative">
                        <div className="flex items-center border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50">
                          <MapPinHouse
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <input
                            type="text"
                            readOnly
                            placeholder="Societies"
                            value={selectedSocieties
                              .map(
                                (id) =>
                                  societies.find(
                                    (society) => society._id === id
                                  )?.name
                              )
                              .join(", ")}
                            onClick={() => setIsOpen(!isOpen)}
                            className="block w-full max-w-2xl pl-10 pr-4 py-3 border border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50"
                          />
                          <span
                            className="absolute right-2 cursor-pointer"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            {isOpen ? (
                              <ChevronUp size={20} className="text-teal-300" />
                            ) : (
                              <ChevronDown
                                size={20}
                                className="text-teal-300"
                              />
                            )}
                          </span>
                        </div>
                        {isOpen && (
                          <ul className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 outline-none transition-all duration-300 backdrop-blur-md">
                            <li className="px-4 py-2 text-gray-500">
                              Select Society
                            </li>
                            {societies.map((option) => (
                              <li
                                key={option._id}
                                onClick={() =>
                                  handleSocietyChange(option._id, setFieldValue)
                                }
                                className="block w-full py-2 pl-10 pr-4 gap-4 border cursor-pointer border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50"
                              >
                                {option.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <ErrorMessage
                        name="societies"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>

                  {/* Investor Details Section */}
                  <div className="space-y-6">
                    <h3 className="text-xl font-semibold text-teal-300 mb-4">
                      Investor Information
                    </h3>

                    {/* Name */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <UserRoundPen
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="text"
                          name="investorData.name"
                          placeholder="Investor Name"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Investment Amount */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <Banknote
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="text"
                          name="investorData.investmentAmount"
                          placeholder="Investment Amount"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.investmentAmount"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Profit Percentage */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <Percent
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="number"
                          name="investorData.profitPercentage"
                          autoComplete="off"
                          placeholder="Profit Percentage"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.profitPercentage"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Email */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <Mail
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="email"
                          name="investorData.contactInfo.email"
                          placeholder="Investor Email"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.contactInfo.email"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* CNIC */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <IdCard
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="text"
                          name="investorData.contactInfo.cnic"
                          placeholder="XXXXX-XXXXXXX-X"
                          autoComplete="off"
                          value={formatCNIC(
                            values.investorData.contactInfo.cnic || ""
                          )}
                          onChange={(e) => {
                            const formattedValue = formatCNIC(e.target.value);
                            setFieldValue(
                              "investorData.contactInfo.cnic",
                              formattedValue
                            );
                          }}
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.contactInfo.cnic"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Phone */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <Phone
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />

                        <Field
                          type="tel"
                          name="investorData.contactInfo.phone"
                          placeholder="+92 3XX-XXXXXXX"
                          autoComplete="off"
                          value={values.investorData.contactInfo.phone}
                          onChange={(e) => {
                            let rawValue = e.target.value.replace(/[^\d]/g, "");
                            if (!rawValue.startsWith("92")) {
                              rawValue = `92${rawValue}`;
                            }
                            const formattedValue = `+${rawValue.slice(
                              0,
                              2
                            )} ${rawValue.slice(2, 5)}-${rawValue.slice(
                              5,
                              12
                            )}`;
                            setFieldValue(
                              "investorData.contactInfo.phone",
                              formattedValue
                            );
                          }}
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.contactInfo.phone"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    {/* Address */}
                    <div className="relative flex flex-col">
                      <div className="relative flex items-center">
                        <BookUser
                          className="absolute left-3 top-3 text-teal-300"
                          size={20}
                        />
                        <Field
                          type="text"
                          name="investorData.contactInfo.address"
                          placeholder="Investor Address"
                          autoComplete="off"
                          className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        />
                      </div>
                      <ErrorMessage
                        name="investorData.contactInfo.address"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="col-span-2 flex items-center justify-center mt-8">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="py-3 px-8 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold text-lg shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                  >
                    {isSubmitting ? "Registering..." : "Register Investor"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InvestorCreation;
