import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import BookingProcess from "./Plot & Booking/BookingProcess";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import ManageInstallments from "./Installment_Payment/ManageInstallments";
import OverduePayments from "./Installment_Payment/OverduePayments";
import { SnackbarProvider } from "notistack";
import TransferLetters from "./Plots_Transfer_Resell/TransferLetters";
import Login from "./Auth/Login";
import ProtectedRoute from "./Auth/ProtectedRoute";
import LayoutWithSidebarTopbar from "./scenes/global/sidebar/LayoutWithSidebarTopbar";
import UserManagement from "./Auth/Register";
import { useDispatch, useSelector } from "react-redux";
import TransactionCreate from "./Expense/TransactionCreate";
import {
  logoutUser,
  updateLastActivity,
  checkActiveUser,
  addTabCloseListener,
} from "./store/slices/authSlice"; // Adjust this path based on your project structure
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PaidInstallments from "./Installment_Payment/PaidInstallments";
import BookingDetailsTable from "./Plot & Booking/BookingDetailsTable";
import PaymentsExpense from "./Expense/PaymentsExpense";
import ExpenseIncomeTrack from "./Expense/ExpenseIncomeTrack";
import ExpenseDirection from "./Expense/ExpenseDirection";
import IncomeDirection from "./Expense/IncomeDirection";
import SalaryExpense from "./Expense/SalaryExpense";
import OtherExpense from "./Expense/OtherExpense";
import EmployeeCreation from "./Auth/Users Management/EmployeeCreation";
import CustomerCreation from "./Auth/Users Management/CustomerCreation";
import InvestorCreation from "./Auth/Users Management/InvestorCreation";
import CreateSociety from "./Society_Management/SocietyCreation";
import PlotCreation from "./Society_Management/PlotCreation";
import PlotList from "./Society_Management/PlotList";
import GeneralTransactionForm from "./General_Transactions/GeneralTransactionForm";
import GeneralTransactionList from "./General_Transactions/GeneralTransactionList";
import GeneralTransactionSummary from "./General_Transactions/GeneralTransactionSummary";
import UserProfile from "./Auth/UserProfile/index";
import CustomerTable from "./Customer_Management/CustomerManagement";
import EmployeeTable from "./Employee_Management/EmployeeManagement";
import InvestorTable from "./Investor_Management/InvestorManagement";
import ResellManagement from "./Plot_Resell/ResellManagement";
import ResellPlotsList from "./Plot_Resell/ResellPlotsList";
import TransferPlotForm from "./Plots_Transfer_Resell/TransferPlotForm";
import PlotTransferPage from "./Plots_Transfer_Resell/PlotTransferPage";
import SalaryManagement from "./Employee_Management/SalaryManagement";
import SalaryHistory from "./Employee_Management/SalaryHistory";
import BookingDetailsInstallments from "./Plot & Booking/BookingDetailsInstallments";
import Notifications from "./Alert/Notifications";
import MessagingDashboard from "./Messages/MessagingDashboard";
import ScholarshipList from "./Scholarship/ScholarshipList";
import AddScholarship from "./Scholarship/AddScholarship";
import ShortlistedStudents from "./Scholarship/ShortlistedStudents";
import ApprovedScholarships from "./Scholarship/ApprovedScholarships";
import AccessManagement from "./scenes/global/AccessManagement";
const INACTIVITY_LIMIT_MINUTES = 5;
const INACTIVITY_CHECK_INTERVAL_MS = 600000;

const App = () => {
  const [theme, colorMode] = useMode();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, lastActivity, activeUser } = useSelector(
    (state) => state.auth
  );
  const [hasNavigated, setHasNavigated] = useState(false);

  const handleActivity = useCallback(() => {
    dispatch(updateLastActivity());
  }, [dispatch]);
  useEffect(() => {
    // Add tab close listener when the app loads
    const cleanup = addTabCloseListener(dispatch);

    // Cleanup the listener when the app unmounts
    return cleanup;
  }, [dispatch]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(checkActiveUser());
    }
    if (!isAuthenticated) return;

    const checkInactivity = () => {
      const now = Date.now();
      const inactiveTime = (now - lastActivity) / 1000 / 60;
      if (inactiveTime >= INACTIVITY_LIMIT_MINUTES) {
        dispatch(logoutUser());
        sessionStorage.removeItem("lastPath");
      }
    };

    const activityEvents = ["mousemove", "keydown", "click"];
    const throttledHandleActivity = throttle(handleActivity, 200); // Throttle events

    activityEvents.forEach((event) =>
      window.addEventListener(event, throttledHandleActivity)
    );

    const inactivityTimer = setInterval(
      checkInactivity,
      INACTIVITY_CHECK_INTERVAL_MS
    );

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, throttledHandleActivity)
      );
      clearInterval(inactivityTimer);
    };
  }, [isAuthenticated, lastActivity, dispatch, handleActivity]);

  // useEffect(() => {
  //   if (isAuthenticated && !hasNavigated) {
  //     setHasNavigated(true);

  //     const storedLastPath = sessionStorage.getItem("lastPath");

  //     if (storedLastPath && window.location.pathname !== storedLastPath) {
  //       navigate(storedLastPath, { replace: true });
  //     } else if (!storedLastPath) {
  //       const rolePaths = {
  //         Admin: "/",
  //         Employee: "/",
  //         Customer: "/installments-payment/paid-installments",
  //         Investor: "/alert/notifications",
  //       };

  //       const defaultPath =
  //         activeUser && activeUser.role
  //           ? rolePaths[activeUser.role] || "/login"
  //           : "/login";
  //       navigate(defaultPath, { replace: true });
  //     }
  //   }
  // }, [isAuthenticated, activeUser, hasNavigated, navigate]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event.clientY < 0) {
        if (isAuthenticated) {
          localStorage.removeItem("token");
          sessionStorage.removeItem("lastPath");
          sessionStorage.setItem("forcedLogout", "true");
          dispatch(logoutUser());
        }
      } else {
        sessionStorage.setItem("lastPath", window.location.pathname);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const forcedLogout = sessionStorage.getItem("forcedLogout");
    if (forcedLogout === "true") {
      sessionStorage.removeItem("forcedLogout");
      navigate("/login");
      return;
    }
    if (isAuthenticated && !hasNavigated) {
      setHasNavigated(true);
      const storedLastPath = sessionStorage.getItem("lastPath");
      const defaultPath = activeUser?.role
        ? rolePaths[activeUser.role] || "/login"
        : "/login";
      navigate(storedLastPath || defaultPath, { replace: true });
    }
  }, [isAuthenticated, activeUser, hasNavigated, navigate]);
  const rolePaths = useMemo(
    () => ({
      Admin: "/",
      Employee: "/",
      Customer: "/installments-payment/paid-installments",
      Investor: "/alert/notifications",
    }),
    []
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              <Routes>
                {/* Login Route (No Sidebar or Topbar) */}
                <Route
                  path="/login"
                  element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
                />
                {/* Protected Routes (with Sidebar and Topbar) */}
                {/* <Route
                  path="/"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<Dashboard />} />
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="/"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      {activeUser?.role === "Customer" ||
                      activeUser?.role === "Investor" ? (
                        <Navigate
                          to={
                            activeUser?.role === "Customer"
                              ? "/installments-payment/paid-installments"
                              : "/alert/notifications"
                          }
                        />
                      ) : (
                        <LayoutWithSidebarTopbar component={<Dashboard />} />
                      )}
                    </ProtectedRoute>
                  }
                />
                {/* User Create and Management */}
                <Route
                  path="/user-creation/all-users"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<UserManagement />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-creation/employee-creation"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<EmployeeCreation />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-creation/customer-creation"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<CustomerCreation />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-creation/investor-creation"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<InvestorCreation />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-creation/user-profile"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<UserProfile />} />
                    </ProtectedRoute>
                  }
                />
                {/* Plots and Booking */}
                <Route
                  path="/plots-booking/booking-process"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<BookingProcess />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plots-booking/booking-full"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<BookingDetailsTable />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plots-booking/booking-installments"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<BookingDetailsInstallments />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plots-booking/booking-process"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<BookingProcess />} />
                    </ProtectedRoute>
                  }
                />

                {/* INSTALLMENTS AND PAY MANAGEMENT */}
                <Route
                  path="/installments-payment/manage-installments"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ManageInstallments />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/installments-payment/paid-installments"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<PaidInstallments />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/installments-payment/overdue-payments"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<OverduePayments />}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* PLOTS TRANSFER AND RESELL MANAGEMENT */}
                <Route
                  path="/plot-transfer-resell/plot-resale-listings"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ResellPlotsList />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plot-transfer-resell/plot-transfer-creation"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<TransferPlotForm />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plot-transfer-resell/plot-transfer-page"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<PlotTransferPage />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plot-transfer-resell/view-transfer-letters"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<TransferLetters />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/plot-transfer-resell/transfer-plot-ownership"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ResellManagement />}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* INCOME AND EXPENSE MANAGEMENT */}
                <Route
                  path="/income-expense/transaction"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ExpenseIncomeTrack />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/expense/transaction"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ExpenseDirection />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/income/transaction"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<IncomeDirection />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create/transaction"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<TransactionCreate />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/income-expense/payments-expense"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<PaymentsExpense />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/income-expense/manage-salaries"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<SalaryExpense />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/income-expense/other-expense"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<OtherExpense />} />
                    </ProtectedRoute>
                  }
                />
                {/* Housing Society MANAGEMENT */}
                <Route
                  path="/Housing-Society-Management/create-social-projects"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<CreateSociety />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/housing-society-management/plots-management"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<PlotCreation />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/housing-society-management/plots-list"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<PlotList />} />
                    </ProtectedRoute>
                  }
                />
                {/* General Transaction MANAGEMENT */}
                <Route
                  path="/general-transaction"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<GeneralTransactionForm />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/general-trans-list"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<GeneralTransactionList />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/general-trans-summary"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<GeneralTransactionSummary />}
                      />
                    </ProtectedRoute>
                  }
                />
                {/* Customer Management */}
                <Route
                  path="/customer/information"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<CustomerTable />} />
                    </ProtectedRoute>
                  }
                />
                {/* /employee-management/manage-employee-info */}
                <Route
                  path="/employee-management/manage-employee-info"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<EmployeeTable />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/employee-management/salary_management"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<SalaryManagement />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/employee-management/salary_history"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<SalaryHistory />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/investor-management/manage-investor-info"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<InvestorTable />} />
                    </ProtectedRoute>
                  }
                />
                {/* /alert/notifications */}
                <Route
                  path="/alert/notifications"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<Notifications />} />
                    </ProtectedRoute>
                  }
                />

                {/* <Route
                  path="/messages"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<MessagingDashboard />}
                      />
                    </ProtectedRoute>
                  }
                /> */}

                {/* /Scholarship */}
                <Route
                  path="/scholarship-management/shortlist"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ShortlistedStudents />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/scholarship-management/list"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ScholarshipList />}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/scholarship-management/form"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar component={<AddScholarship />} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/scholarship-management/Approval"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<ApprovedScholarships />}
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tabs-settings"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <LayoutWithSidebarTopbar
                        component={<AccessManagement />}
                      />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </main>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
/**
 * Throttle function to limit the rate of execution.
 * @param {Function} func - The function to throttle.
 * @param {number} limit - Throttle limit in ms.
 */
function throttle(func, limit) {
  let lastFunc;
  let lastRan;

  return function (...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}
