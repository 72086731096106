import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Building, Home, Edit, Settings, Mail, Phone } from "lucide-react";
import { checkActiveUser, updateUserInfo } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";

const UserProfile = () => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.auth.activeUser);
  const [isEditing, setIsEditing] = useState(false);
  const [userForm, setUserForm] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    // Fetch the active user and societies
    dispatch(checkActiveUser());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (activeUser) {
      // Initialize form with user data
      setUserForm({
        username: activeUser.username,
        role: activeUser.role,
        profile_picture:
          activeUser.profile_picture || "/api/placeholder/150/150",
        societies: activeUser.societies || [],
        adminData: activeUser.additionalData || {
          name: "",
          contactInfo: { phone: "", email: "", cnic: "", address: "" },
        },
        customerData: activeUser.additionalData || {
          name: "",
          contactInfo: {
            phone: "",
            email: "",
          },
        },
        employeeData: activeUser.additionalData || {
          name: "",
          designation: "",
          personalDetails: {
            phone: "",
            address: "",
          },
        },
        investorData: activeUser.additionalData || {
          name: "",
          investmentAmount: 0,
          contactInfo: {
            phone: "",
            email: "",
          },
        },
      });
    }
  }, [activeUser]);

  if (!userForm) return <p>Loading...</p>;

  const handleEditProfile = async () => {
    if (isEditing && profilePicture) {
      try {
        if (!activeUser?.id) {
          console.error("User ID is missing");
          return;
        }

        const updatedData = {
          profile_picture: profilePicture, // or the formData if you need it
          // Include other form data as needed
        };

        // Dispatch the action and wait for the result
        const result = await dispatch(
          updateUserInfo({ userId: activeUser.id, updatedData })
        ).unwrap();

        console.log("Profile updated successfully:", result);

        setUserForm((prev) => ({
          ...prev,
          profile_picture: result.profile_picture || prev.profile_picture,
        }));
        setProfilePicture(null);
      } catch (error) {
        // Log more error details
        console.error("Error updating profile:", error);
        alert(`Error: ${error.message || "An unknown error occurred"}`);
      }
    }
    setIsEditing(!isEditing);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setProfilePicture(base64String); // Set base64 string
        setUserForm((prev) => ({
          ...prev,
          profile_picture: base64String, // Update preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const { name, contactInfo, designation, personalDetails } =
    userForm.role === "Customer"
      ? userForm.customerData
      : userForm.role === "Employee"
      ? userForm.employeeData
      : userForm.role === "Investor"
      ? userForm.investorData
      : userForm.role === "Admin"
      ? userForm.adminData
      : {};

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Profile Header */}
        <div className="relative bg-gradient-to-r from-slate-800/80 to-slate-900/80 backdrop-blur-xl rounded-2xl shadow-2xl p-8 mb-8 border border-slate-700/50">
          <div className="relative flex flex-col md:flex-row items-start md:items-center gap-8">
            <div className="relative group">
              <img
                src={userForm.profile_picture}
                alt="Profile"
                className="relative w-36 h-36 rounded-full border-4 border-slate-800"
              />
              {isEditing && (
                <label className="absolute bottom-2 right-2 bg-gradient-to-r from-teal-500 to-emerald-500 p-2 rounded-full cursor-pointer shadow-lg transform hover:scale-110 transition-transform duration-300">
                  <Edit size={18} className="text-white" />
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              )}
            </div>
            <div className="flex-1 space-y-4">
              <div className="flex items-center gap-3">
                <h1 className="text-4xl font-bold bg-gradient-to-r from-teal-300 to-emerald-300 text-transparent bg-clip-text">
                  {name || "No Name"}
                </h1>
                <span className="bg-gradient-to-r from-teal-500/20 to-emerald-500/20 text-teal-300 px-3 py-1 rounded-full text-sm font-medium border border-teal-500/20">
                  {userForm.role}
                </span>
              </div>
              {userForm.role === "Employee" && (
                <p className="text-teal-300/80 text-lg mt-2">
                  {designation || "No Designation"}
                </p>
              )}

              {userForm.role === "Employee" && (
                <div className="flex items-center gap-4 mt-3">
                  <div className="flex items-center gap-2 text-slate-400">
                    <Mail size={16} className="text-teal-400" />
                    <span>{personalDetails?.cnic || "No CNIC"}</span>
                  </div>
                  <div className="flex items-center gap-2 text-slate-400">
                    <Phone size={16} className="text-teal-400" />
                    <span>{personalDetails?.phone || "No Phone"}</span>
                  </div>
                  <div className="flex items-center gap-2 text-slate-400">
                    <Mail size={16} className="text-teal-400" />
                    <span>{personalDetails?.address || "No Address"}</span>
                  </div>
                </div>
              )}
              {userForm.role !== "Employee" && (
                <div className="flex items-center gap-4 mt-3">
                  <div className="flex items-center gap-2 text-slate-400">
                    <Mail size={16} className="text-teal-400" />
                    <span>{contactInfo?.email || "No Email"}</span>
                  </div>
                  <div className="flex items-center gap-2 text-slate-400">
                    <Phone size={16} className="text-teal-400" />
                    <span>{contactInfo?.phone || "No Phone"}</span>
                  </div>
                </div>
              )}
              <button
                onClick={handleEditProfile}
                className="bg-gradient-to-r from-teal-500 to-emerald-500 text-white px-6 py-3 rounded-xl flex items-center gap-2 transform hover:scale-105 transition-all duration-300 shadow-lg"
              >
                <Settings size={18} />
                {isEditing ? "Save Changes" : "Edit Profile"}
              </button>
            </div>
          </div>
        </div>

        {/* Assigned Societies */}
        <div className="bg-gradient-to-r from-slate-800/80 to-slate-900/80 backdrop-blur-xl rounded-2xl shadow-2xl p-6 border border-slate-700/50">
          <h2 className="text-2xl font-bold text-teal-300 mb-6 flex items-center gap-2">
            <Building className="text-teal-400" />
            Assigned Societies
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {userForm.societies && userForm.societies.length > 0 ? (
              userForm.societies.map((society, idx) => (
                <div
                  key={idx}
                  className="bg-slate-800/50 rounded-xl p-4 flex items-center gap-3 transform hover:scale-105 transition-all duration-300 border border-slate-700/50 hover:border-teal-500/50"
                >
                  <Home className="text-teal-400" size={24} />
                  <span className="text-slate-300">{society.name}</span>
                </div>
              ))
            ) : (
              <div className="text-slate-300 col-span-full">
                No societies assigned
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
