import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { transferPlot, fetchTransferPlots } from "../../api";

// Thunks
export const transferPlotThunk = createAsyncThunk(
  "transferPlot/transfer",
  async (plotData, { rejectWithValue }) => {
    console.log("Submitting data to API:", plotData); // Debugging API data
    try {
      return await transferPlot(plotData);
    } catch (error) {
      console.error("Error during transfer:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchTransferPlotsThunk = createAsyncThunk(
  "transferPlot/fetchAll",
  async (query, { rejectWithValue }) => {
    try {
      return await fetchTransferPlots(query);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const transferPlotSlice = createSlice({
  name: "transferPlot",
  initialState: {
    transferPlots: [],
    status: "idle", // 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetching transfer plots
      .addCase(fetchTransferPlotsThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransferPlotsThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.transferPlots = action.payload.data;
      })
      .addCase(fetchTransferPlotsThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      // Handle transferring a plot
      .addCase(transferPlotThunk.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(transferPlotThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      });
  },
});

export default transferPlotSlice.reducer;
