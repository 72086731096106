import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTransaction,
  getAllTransactions,
  getTransactionById,
  updateTransaction,
  deleteTransaction,
} from "../../api";

// Thunks for async actions
export const fetchTransactions = createAsyncThunk(
  "transactions/fetchAll",
  async () => {
    const data = await getAllTransactions();
    return data;
  }
);

export const fetchTransactionById = createAsyncThunk(
  "transactions/fetchById",
  async (id) => {
    const data = await getTransactionById(id);
    return data;
  }
);

export const addTransaction = createAsyncThunk(
  "transactions/add",
  async (transactionData) => {
    const data = await createTransaction(transactionData);
    return data;
  }
);

export const modifyTransaction = createAsyncThunk(
  "transactions/update",
  async ({ id, transactionData }) => {
    const data = await updateTransaction(id, transactionData);
    return data;
  }
);

export const removeTransaction = createAsyncThunk(
  "transactions/delete",
  async (id) => {
    await deleteTransaction(id);
    return id;
  }
);

// Slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    transactions: [],
    currentTransaction: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTransactionById.fulfilled, (state, action) => {
        state.currentTransaction = action.payload;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.transactions.push(action.payload);
      })
      .addCase(modifyTransaction.fulfilled, (state, action) => {
        const index = state.transactions.findIndex(
          (t) => t._id === action.payload._id
        );
        if (index !== -1) {
          state.transactions[index] = action.payload;
        }
      })
      .addCase(removeTransaction.fulfilled, (state, action) => {
        state.transactions = state.transactions.filter(
          (t) => t._id !== action.payload
        );
      });
  },
});

export default transactionSlice.reducer;
