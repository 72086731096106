import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransferPlotsThunk } from "../store/slices/transferPlotSlice";
import { FileText, Loader2, Printer } from "lucide-react";

import printJS from "print-js";

const TransferPlotList = () => {
  const dispatch = useDispatch();
  const { transferPlots, status, error } = useSelector(
    (state) => state.transferPlot
  );
  const { activeUser } = useSelector((state) => state.auth);

  useEffect(() => {
    // Check if user is authorized
    if (
      activeUser?.role === "Admin" ||
      (activeUser?.role === "Employee" &&
        activeUser?.additionalData?.designation === "Manager")
    ) {
      dispatch(fetchTransferPlotsThunk());
    }
  }, [dispatch, activeUser]);

  const handlePrintSlip = (plot) => {
    const slipContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Plot Transfer Certificate</title>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" rel="stylesheet">
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
            
            :root {
                --primary-color: #2563eb;
                --secondary-color: #1e40af;
                --background-light: #f0f4f8;
                --text-dark: #0f172a;
                --text-muted: #64748b;
            }

            * {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
            }

            @page {
                size: A4;
                margin: 10mm;
            }

            body {
                font-family: 'Inter', sans-serif;
                background-color: white;
                width: 210mm;
                height: 297mm;
                margin: 0 auto;
                padding: 10mm;
                font-size: 10pt;
                line-height: 1.4;
            }

            .certificate-container {
                border: 2px solid var(--primary-color);
                border-radius: 12px;
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .header {
                background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
                color: white;
                padding: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
                padding: 1rem;
                flex-grow: 1;
                overflow: hidden;
            }

            .section {
                background-color: #f9fafb;
                border-radius: 8px;
                padding: 1rem;
                border: 1px solid #e2e8f0;
            }

            .section-title {
                font-weight: 700;
                color: var(--primary-color);
                border-bottom: 2px solid var(--primary-color);
                padding-bottom: 0.5rem;
                margin-bottom: 0.75rem;
                font-size: 1rem;
            }

            .info-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.5rem;
                padding: 0.25rem 0;
                border-bottom: 1px solid #e2e8f0;
            }

            .info-label {
                color: var(--text-muted);
                font-weight: 500;
                font-size: 0.9rem;
            }

            .info-value {
                font-weight: 600;
                color: var(--text-dark);
                text-align: right;
                font-size: 0.9rem;
            }

            .signature-area {
                grid-column: span 2;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;
                margin-top: 1rem;
                padding-top: 1rem;
                border-top: 2px solid #e2e8f0;
            }

            .signature-box {
                text-align: center;
                padding: 0.5rem;
                border: 1px dashed var(--primary-color);
                border-radius: 8px;
            }

            .terms-section {
                grid-column: span 2;
                background-color: #f1f5f9;
                border-radius: 8px;
                padding: 1rem;
                margin-top: 1rem;
            }

            .terms-section h3 {
                color: var(--primary-color);
                margin-bottom: 0.5rem;
                font-weight: 700;
            }

            .terms-section ol {
                color: var(--text-muted);
                padding-left: 1.5rem;
                font-size: 0.8rem;
            }

            .footer {
                background: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
                height: 0.5rem;
            }

            @media print {
                body {
                    margin: 0;
                    padding: 0;
                }
                .certificate-container {
                    border: none;
                    height: 100%;
                }
            }
        </style>
    </head>
    <body>
        <div class="certificate-container">
            <!-- Header -->
            <div class="header">
                <div class="flex items-center space-x-4">
    <!-- Image -->
    <img src= ${
      plot.plot_id?.society_id?.society_image
    } alt="Society Image" class="w-16 h-16 object-cover rounded-full">

    <!-- Text content -->
    <div>
        <h1 class="text-2xl font-bold">
            ${plot.plot_id?.society_id?.name || "Real Estate Society"}
        </h1>
        <h4 class="text-sm opacity-80 mt-1 font-bold">
            ${plot.plot_id?.society_id?.location || "Real Estate Society"}
        </h4>
    </div>
</div>

                <div class="text-right">
                    <p class="text-lg font-semibold">Transfer Reference</p>
                    <p class="text-xs bg-white/20 px-2 py-1 rounded mt-1">
                        PCT-${new Date().getFullYear()}-${String(
      Math.floor(Math.random() * 10000)
    ).padStart(4, "0")}
                    </p>
                </div>
            </div>

            <!-- Content -->
            <div class="content">
                <!-- Plot Details -->
                <div class="section">
                    <h2 class="section-title">Plot Details</h2>
                    <div class="info-row">
                        <span class="info-label">Plot Number</span>
                        <span class="info-value">${
                          plot.plot_id?.plot_number || "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Location</span>
                        <span class="info-value">${
                          plot.plot_id?.block || "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Area</span>
                        <span class="info-value">${
                          plot.plot_id?.size || "N/A"
                        } Marla</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Land Use</span>
                        <span class="info-value">${
                          plot.plot_id?.plot_type || "Residential"
                        }</span>
                    </div>
                </div>

                <!-- Transfer Information -->
                <div class="section">
                    <h2 class="section-title">Transfer Information</h2>
                    <div class="info-row">
                        <span class="info-label">Transfer Date</span>
                        <span class="info-value">${
                          plot.transfer_date
                            ? new Date(plot.transfer_date).toLocaleDateString()
                            : "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Transfer Fee</span>
                        <span class="info-value">PKR ${
                          plot.transfer_fee || "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Status</span>
                        <span class="info-value text-green-600">● ${
                          plot.plot_id?.booking_status || "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Processing Time</span>
                        <span class="info-value">7-10 Business Days</span>
                    </div>
                </div>

              
                <!-- New Owner -->
                <div class="section">
                    <h2 class="section-title">Owner Information</h2>
                    <div class="info-row">
                        <span class="info-label">Name</span>
                        <span class="info-value">${
                          plot.new_owner_id?.customerData?.name || "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Contact</span>
                        <span class="info-value">${
                          plot.new_owner_id?.customerData?.contactInfo?.phone ||
                          "N/A"
                        }</span>
                    </div>
                    <div class="info-row">
                        <span class="info-label">Email</span>
                        <span class="info-value">${
                          plot.new_owner_id?.customerData?.contactInfo?.email ||
                          "N/A"
                        }</span>
                    </div>
                </div>

                <!-- Signature Area -->
                <div class="signature-area">
                    <div class="signature-box">
                        <p class="font-semibold text-sm text-blue-600">Nominee signature </p>
                        <p class="text-xs text-gray-500 mt-1">Signature & Date</p>
                    </div>
                    <div class="signature-box">
                        <p class="font-semibold text-sm text-blue-600">New Owner</p>
                        <p class="text-xs text-gray-500 mt-1">Signature & Date</p>
                    </div>
                    <div class="signature-box">
                        <p class="font-semibold text-sm text-blue-600">Authorized Signatory</p>
                        <p class="text-xs text-gray-500 mt-1">Official Stamp</p>
                    </div>
                </div>

                <!-- Terms and Conditions -->
                <div class="terms-section">
                    <h3 class="text-lg">Terms and Conditions</h3>
                    <ol class="list-decimal pl-4 space-y-1">
                        <li>This certificate confirms the legal transfer of plot ownership within the society.</li>
                        <li>The new owner must comply with all society bylaws and regulations.</li>
                        <li>Transfer fees are non-refundable and subject to change without prior notice.</li>
                        <li>All property taxes and outstanding dues must be cleared before transfer.</li>
                        <li>The society reserves the right to verify and validate all transfer documents.</li>
                        <li>This certificate is valid only with original signatures and society seal.</li>
                        <li>Any disputes shall be subject to the jurisdiction of local real estate regulations.</li>
                    </ol>
                </div>
            </div>

            <!-- Footer -->
            <div class="footer"></div>
        </div>
    </body>
    </html>
    `;

    // Use printJS to generate printable slip
    printJS({
      printable: slipContent,
      type: "raw-html",
      style: `
            @media print {
                body {
                    -webkit-print-color-adjust: exact;
                    color-adjust: exact;
                }
                @page {
                    size: A4;
                    margin: 10mm;
                }
                html, body {
                    width: 210mm;
                    height: 297mm;
                    margin: 0;
                    padding: 0;
                }
            }
        `,
    });
  };

  // if (status === "loading") {
  //   return (
  //     <div className="min-h-screen bg-gray-900 flex items-center justify-center">
  //       <div className="flex flex-col items-center space-y-4">
  //         <Loader2 className="w-12 h-12 text-teal-400 animate-spin" />
  //         <p className="text-teal-400 text-lg font-medium">
  //           Loading transfers...
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (status === "failed") {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
          <h2 className="text-red-400 text-xl font-bold mb-2">Error</h2>
          <p className="text-gray-300">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center mb-10 space-x-4">
          <FileText className="w-10 h-10 text-teal-300" />
          <h1 className="text-4xl font-extrabold text-teal-300 tracking-tight">
            Transferred Plots
          </h1>
        </div>

        <div className="bg-slate-900/60 backdrop-blur-sm rounded-2xl shadow-2xl border border-slate-700/50 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-slate-800/70 border-b border-slate-700/50">
                <tr>
                  {[
                    "Plot ID",
                    "New Owner",
                    "Society",
                    "Contact",
                    "Transfer Fee",
                    "Transfer Date",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-4 text-left text-sm font-semibold text-teal-300 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transferPlots.map((plot, index) => (
                  <tr
                    key={plot.plot_id.plot_number}
                    className={`
                    transition-all duration-300 ease-in-out
                    hover:bg-slate-800/80 
                    ${index % 2 === 0 ? "bg-slate-900/50" : "bg-slate-800/50"}
                  `}
                  >
                    {[
                      plot.plot_id.plot_number,
                      plot.new_owner_id?.username || "N/A",
                      plot.plot_id.society_id?.name || "N/A",
                      plot.new_owner_id?.customerData?.contactInfo.phone ||
                        "N/A",
                      plot.transfer_fee ? `$${plot.transfer_fee}` : "N/A",
                      plot.transfer_date
                        ? new Date(plot.transfer_date).toLocaleDateString()
                        : "N/A",
                    ].map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        className="px-6 py-4 text-teal-300 whitespace-nowrap text-sm"
                      >
                        {cell}
                      </td>
                    ))}
                    <td className="px-6 py-4">
                      <button
                        onClick={() => handlePrintSlip(plot)}
                        className="
                        flex items-center space-x-2
                        bg-teal-600/50 text-teal-300 
                        px-4 py-2 rounded-lg 
                        hover:bg-teal-600/70 
                        transition-all duration-300 
                        border border-teal-500/30
                        shadow-md hover:shadow-lg
                      "
                      >
                        <Printer className="w-4 h-4" />
                        <span>Print Slip</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPlotList;
