import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  paySalary,
  getPaymentHistory,
  generateSalarySlip,
  updateSalaryDetails,
} from "../../api";

// Async actions (thunks)
export const payEmployeeSalary = createAsyncThunk(
  "salary/payEmployeeSalary",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await paySalary(employeeId);
      return response.paymentRecord;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchPaymentHistory = createAsyncThunk(
  "salary/fetchPaymentHistory",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await getPaymentHistory(employeeId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const generateEmployeeSalarySlip = createAsyncThunk(
  "salary/generateEmployeeSalarySlip",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await generateSalarySlip(employeeId);
      return response.salarySlip; // Assuming the API returns the salary slip URL or content
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateEmployeeSalaryDetails = createAsyncThunk(
  "salary/updateEmployeeSalaryDetails",
  async ({ employeeId, salaryDetails }, { rejectWithValue }) => {
    try {
      const response = await updateSalaryDetails(employeeId, salaryDetails);
      return response.salaryDetails;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Salary Slice
const salarySlice = createSlice({
  name: "salary",
  initialState: {
    paymentHistory: [],
    currentSalarySlip: null,
    loading: false,
    error: null,
    lastPaid: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Pay Salary
      .addCase(payEmployeeSalary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(payEmployeeSalary.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPaid = action.payload;
        // Update payment status
        const paymentMonth = new Date(action.payload.date).getMonth();
        const currentMonth = new Date().getMonth();
        state.isPaid = paymentMonth === currentMonth;
      })
      .addCase(payEmployeeSalary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Payment History
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentHistory = action.payload; // Store payment history
      })
      .addCase(fetchPaymentHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Generate Salary Slip
      .addCase(generateEmployeeSalarySlip.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateEmployeeSalarySlip.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSalarySlip = action.payload; // Store the generated salary slip
      })
      .addCase(generateEmployeeSalarySlip.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Salary Details
      .addCase(updateEmployeeSalaryDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmployeeSalaryDetails.fulfilled, (state, action) => {
        state.loading = false;
        // Assuming the new salary details are returned and stored in state
      })
      .addCase(updateEmployeeSalaryDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = salarySlice.actions;

export default salarySlice.reducer;
