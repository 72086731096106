import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAllUsers, registerUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import {
  User,
  Mail,
  Phone,
  Camera,
  Lock,
  IdCard,
  MapPinHouse,
  BookUser,
  ChevronUp,
  ChevronDown,
  Eye,
  EyeOff,
} from "lucide-react";

const CustomerRegistrationForm = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);

  const [societiesFetched, setSocietiesFetched] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSocieties, setSelectedSocieties] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { users, status } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (!societiesFetched) {
  //     dispatch(fetchSocieties()).then(() => setSocietiesFetched(true));
  //   }
  //   dispatch(fetchAllUsers());
  // }, [dispatch, societiesFetched]);
  useEffect(() => {
    const initialize = async () => {
      if (!societiesFetched) {
        await dispatch(fetchSocieties()); // Ensure societies are fetched first
        setSocietiesFetched(true); // Set flag after fetching
      }

      // Only dispatch fetchAllUsers once or when needed
      if (status === "idle" || (status === "failed" && users.length === 0)) {
        dispatch(fetchAllUsers());
      }
    };

    initialize(); // Call the async function
  }, [dispatch, societiesFetched, status, users.length]); // Dependencies: Run when dispatch or societiesFetched change

  // Validation Schema
  const RegistrationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .max(20, "Username must be at most 20 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must include uppercase, lowercase, number, and special character"
      ),
    selectedSocieties: Yup.array()
      .min(1, "Please select at least one society")
      .required("Please select a society"),
    customerData: Yup.object().shape({
      name: Yup.string()
        .required("Full name is required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters"),
      contactInfo: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email format"),
        phone: Yup.string()
          .required("Phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Phone number must be in format +92 3XX-XXXXXXX"
          ),
        cnic: Yup.string()
          .required("CNIC is required")
          .matches(
            /^\d{5}-\d{7}-\d{1}$/,
            "CNIC must be in format XXXXX-XXXXXXX-X"
          ),
        address: Yup.string()
          .required("Address is required")
          .min(10, "Address must be at least 10 characters"),
      }),
    }),
  });

  const handleSocietyChange = (societyId, setFieldValue) => {
    const currentSocieties = [...selectedSocieties];
    const index = currentSocieties.indexOf(societyId);

    if (index > -1) {
      currentSocieties.splice(index, 1);
    } else {
      currentSocieties.push(societyId);
    }

    setSelectedSocieties(currentSocieties);
    setFieldValue("selectedSocieties", currentSocieties);
  };

  const handleProfileImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatCNIC = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.slice(0, 13);
    if (truncatedValue.length <= 5) {
      return truncatedValue;
    } else if (truncatedValue.length <= 12) {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`;
    } else {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
        5,
        12
      )}-${truncatedValue.slice(12)}`;
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setSubmitting, setErrors }
  ) => {
    console.log("Handling form submission with values:", values);

    if (selectedSocieties.length === 0) {
      setErrors({ selectedSocieties: "Please select at least one society" });
      setSubmitting(false);
      return;
    }

    try {
      const submissionPayload = {
        username: values.username,
        password: values.password,
        role: "Customer",
        profile_picture: profileImage,
        societies: selectedSocieties,
        customerData: {
          ...values.customerData,
          societies: selectedSocieties,
        },
      };

      console.log("Payload to submit:", submissionPayload);
      const result = await dispatch(registerUser(submissionPayload)).unwrap();

      if (result?.success || result?.user) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer registered successfully.",
          confirmButtonColor: "#008000",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#5EEAD4",
        });
        resetForm();
        setProfileImage(null);
        setSelectedSocieties([]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: result.message || "An unexpected error occurred.",
          confirmButtonColor: "#f27474",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#FCA5A5",
        });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred. Please try again.";

      Swal.fire({
        icon: "error",
        title: "Submission Error",
        text: errorMessage,
        confirmButtonColor: "#f27474",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)", // Dark gradient background
        color: "#FCA5A5", // Light red text color for error
      });
      console.error("Full error details:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    username: "",
    password: "",
    role: "Customer",
    selectedSocieties: [],
    profile_picture: "",
    customerData: {
      name: "",
      contactInfo: {
        phone: "",
        email: "",
        cnic: "",
        address: "",
      },
    },
  };
  return (
    <div className="min-h-screen py-12 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="relative container mx-auto px-4">
        <Formik
          initialValues={initialValues}
          validationSchema={RegistrationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <>
              <Form className="relative max-w-4xl mx-auto backdrop-blur-xl bg-white/10 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-8 border border-white/20 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none"></div>

                <div className="relative">
                  <h2 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-teal-300 via-teal-300 to-teal-300 text-transparent bg-clip-text">
                    Customer Registration
                  </h2>
                  <p className="text-teal-300/60 text-center mb-8">
                    Join our society today
                  </p>

                  <div className="mb-12 flex flex-col items-center">
                    <div className="relative group">
                      <div className="relative w-32 h-32">
                        {profileImage ? (
                          <img
                            src={profileImage}
                            alt="Profile Preview"
                            className="w-full h-full rounded-full object-cover ring-4 ring-teal-300/50"
                          />
                        ) : (
                          <div className="w-full h-full rounded-full bg-gradient-to-br from-slate-700/50 to-slate-800/50 flex items-center justify-center ring-4 ring-teal-300/50 backdrop-blur-sm">
                            <Camera size={40} className="text-teal-300" />
                          </div>
                        )}
                        <label className="absolute bottom-0 right-0 bg-gradient-to-r from-teal-400 to-cyan-400 p-2 rounded-full cursor-pointer hover:scale-110 transition-transform duration-300 shadow-lg">
                          <Camera size={20} className="text-white" />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(event) =>
                              handleProfileImageChange(event, setFieldValue)
                            }
                            className="hidden"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="space-y-6">
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Basic Information
                      </h3>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <User
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="username"
                            placeholder="Username"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Lock
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            className="w-full pl-10 pr-10 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-3 text-teal-300 hover:text-teal-400 focus:outline-none"
                          >
                            {showPassword ? (
                              <EyeOff size={20} />
                            ) : (
                              <Eye size={20} />
                            )}
                          </button>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <h3 className="text-xl font-semibold text-teal-300">
                        Society Information
                      </h3>
                      <div className="relative flex flex-col mb-6">
                        <div className="flex items-center border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50">
                          <MapPinHouse
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <input
                            type="text"
                            readOnly
                            placeholder="Societies"
                            value={selectedSocieties
                              .map(
                                (id) =>
                                  societies.find(
                                    (society) => society._id === id
                                  )?.name
                              )
                              .join(", ")}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="block w-full max-w-2xl pl-10 pr-4 py-3 border border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50"
                          />
                          <span
                            className="absolute right-2 cursor-pointer"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            {isDropdownOpen ? (
                              <ChevronUp size={20} className="text-teal-300" />
                            ) : (
                              <ChevronDown
                                size={20}
                                className="text-teal-300"
                              />
                            )}
                          </span>
                        </div>
                        {isDropdownOpen && (
                          <ul className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 outline-none transition-all duration-300 backdrop-blur-md">
                            <li className="px-4 py-2 text-gray-500">
                              Select Society
                            </li>
                            {societies.map((option) => (
                              <li
                                key={option._id}
                                onClick={() =>
                                  handleSocietyChange(option._id, setFieldValue)
                                }
                                className={`block w-full py-2 pl-10 pr-4 gap-2 border cursor-pointer border-white/10 rounded-xl bg-transparent text-teal-500 placeholder:text-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500/50 ${
                                  selectedSocieties.includes(option._id)
                                    ? "bg-teal-500/20"
                                    : ""
                                }`}
                              >
                                {option.name}
                              </li>
                            ))}
                          </ul>
                        )}
                        {errors.selectedSocieties && (
                          <div className="text-red-400 text-sm ml-2">
                            {errors.selectedSocieties}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Contact Info */}
                    <div className="space-y-6">
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Contact Information
                      </h3>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <User
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.name"
                            placeholder="Full Name"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.name"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <IdCard
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.cnic"
                            placeholder="XXXXX-XXXXXXX-X"
                            value={formatCNIC(
                              values.customerData.contactInfo.cnic
                            )}
                            onChange={(e) => {
                              const formattedValue = formatCNIC(e.target.value);
                              setFieldValue(
                                "customerData.contactInfo.cnic",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.cnic"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Mail
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type="email"
                            name="customerData.contactInfo.email"
                            placeholder="Email"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.email"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Phone
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />

                          <Field
                            type="tel"
                            name="customerData.contactInfo.phone"
                            placeholder="+92 3XX-XXXXXXX"
                            autoComplete="off"
                            value={values.customerData.contactInfo.phone}
                            onChange={(e) => {
                              let rawValue = e.target.value.replace(
                                /[^\d]/g,
                                ""
                              );
                              if (!rawValue.startsWith("92")) {
                                rawValue = `92${rawValue}`;
                              }
                              const formattedValue = `+${rawValue.slice(
                                0,
                                2
                              )} ${rawValue.slice(2, 5)}-${rawValue.slice(
                                5,
                                12
                              )}`;
                              setFieldValue(
                                "customerData.contactInfo.phone",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.phone"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <BookUser
                            className="absolute left-3 top-3 text-teal-300"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.address"
                            placeholder="Customer Address"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.address"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-2 flex items-center justify-center mt-8">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="py-3 px-8 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold text-lg shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                    >
                      {isSubmitting ? "Registering..." : "Register Customer"}
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CustomerRegistrationForm;
