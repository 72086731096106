import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { X } from "lucide-react";
import {
  fetchSocieties,
  updateSocietyAction,
} from "../store/slices/societySlice";

// Validation Schema
const plotValidationSchema = Yup.object().shape({
  society: Yup.string().required("Society is required"),
  plots: Yup.array().of(
    Yup.object().shape({
      plot_number: Yup.string()
        .required("Plot number is required")
        .matches(
          /^([a-zA-Z]*)(\d+)-?([a-zA-Z]*)(\d+)?$/,
          "Invalid plot number format"
        ),
      block: Yup.string().required("Block is required"),
      size: Yup.string()
        .required("Size is required")
        .matches(/^\d+(\.\d+)?$/, "Size must be a number"),
      plot_type: Yup.string().required("Plot type is required"),
      category: Yup.string().required("Category is required"),
    })
  ),
});

const PlotCreation = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(fetchSocieties());
  }, [dispatch]);

  const expandPlotRange = (plotInput) => {
    const rangePattern = /^([a-zA-Z]*)(\d+)-([a-zA-Z]*)(\d+)$/;
    const match = plotInput.match(rangePattern);

    if (!match) return [plotInput]; // Not a range, return as-is.

    const [, prefix1, start, prefix2, end] = match;

    if (prefix1 !== prefix2) {
      return []; // Prefixes don't match
    }

    const expandedPlots = [];
    for (let i = parseInt(start); i <= parseInt(end); i++) {
      expandedPlots.push(`${prefix1}${i}`);
    }

    return expandedPlots;
  };

  const handleSubmitPlots = async (values, { resetForm, setSubmitting }) => {
    try {
      const expandedPlots = values.plots.flatMap((plot) => {
        const expandedNumbers = expandPlotRange(plot.plot_number);
        return expandedNumbers.map((num) => ({
          ...plot,
          plot_number: num,
        }));
      });

      const selectedSociety = societies.find(
        (society) => society._id === values.society
      );

      await dispatch(
        updateSocietyAction({
          ...selectedSociety,
          plots: [...(selectedSociety.plots || []), ...expandedPlots],
        })
      ).unwrap();

      enqueueSnackbar("Plots added successfully!", { variant: "success" });
      resetForm();
      setSubmitting(false);
    } catch (error) {
      enqueueSnackbar("Failed to add plots", { variant: "error" });
      setSubmitting(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-gradient-to-br">
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl p-8 mb-8">
        <h2 className="text-3xl font-bold mb-8 text-teal-300">
          Add Plots to Society
        </h2>

        <Formik
          initialValues={{
            society: "",
            plots: [
              {
                plot_number: "",
                block: "",
                plot_type: "",
                size: "",
                category: "",
              },
            ],
          }}
          validationSchema={plotValidationSchema}
          onSubmit={handleSubmitPlots}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              {/* Society Selection */}
              <div className="mb-8">
                <label className="block text-teal-300 text-lg mb-3">
                  Select Society
                </label>
                <Field
                  as="select"
                  name="society"
                  className="w-full bg-slate-600 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300 focus:border-transparent"
                >
                  <option value="">Select a society</option>
                  {societies.map((society) => (
                    <option key={society._id} value={society._id}>
                      {society.name}
                    </option>
                  ))}
                </Field>
                {errors.society && touched.society && (
                  <div className="text-red-500 mt-2">{errors.society}</div>
                )}
              </div>

              <FieldArray name="plots">
                {({ remove, push }) => (
                  <>
                    {values.plots.map((plot, index) => (
                      <div
                        key={index}
                        className="relative rounded-lg p-6 mb-6 shadow-xl border border-teal-500"
                      >
                        {/* Remove Plot Button */}
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="absolute top-2 right-2 group p-1"
                            aria-label="Remove plot"
                          >
                            <div className="relative">
                              <div className="absolute -inset-1 bg-red-500 rounded-full opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
                              <div className="relative bg-white rounded-full p-1 border-2 border-red-500 group-hover:border-red-600 transition-colors">
                                <X
                                  size={15}
                                  className="text-red-500 group-hover:text-red-600 transition-colors"
                                />
                              </div>
                            </div>
                          </button>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                          {/* Plot Number */}
                          <div className="space-y-2">
                            <label className="block text-teal-300">
                              Plot Number Range
                            </label>
                            <Field
                              type="text"
                              name={`plots.${index}.plot_number`}
                              className="w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300"
                              placeholder="e.g., 101-110, AR-3430-AR-3440"
                            />
                            {errors.plots?.[index]?.plot_number &&
                              touched.plots?.[index]?.plot_number && (
                                <div className="text-red-500 mt-2">
                                  {errors.plots[index].plot_number}
                                </div>
                              )}
                          </div>

                          {/* Block */}
                          <div className="space-y-2">
                            <label className="block text-teal-300">Block</label>
                            <Field
                              type="text"
                              name={`plots.${index}.block`}
                              className="w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300"
                            />
                            {errors.plots?.[index]?.block &&
                              touched.plots?.[index]?.block && (
                                <div className="text-red-500 mt-2">
                                  {errors.plots[index].block}
                                </div>
                              )}
                          </div>

                          {/* Size */}
                          <div className="space-y-2">
                            <label className="block text-teal-300">
                              Size (Marla)
                            </label>
                            <Field
                              type="text"
                              name={`plots.${index}.size`}
                              className="w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300"
                            />
                            {errors.plots?.[index]?.size &&
                              touched.plots?.[index]?.size && (
                                <div className="text-red-500 mt-2">
                                  {errors.plots[index].size}
                                </div>
                              )}
                          </div>

                          {/* Plot Type */}
                          <div className="space-y-2">
                            <label className="block text-teal-300">
                              Plot Type
                            </label>
                            <Field
                              as="select"
                              name={`plots.${index}.plot_type`}
                              className="w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300"
                            >
                              <option value="">Select type</option>
                              <option value="Residential">Residential</option>
                              <option value="Commercial">Commercial</option>
                            </Field>
                            {errors.plots?.[index]?.plot_type &&
                              touched.plots?.[index]?.plot_type && (
                                <div className="text-red-500 mt-2">
                                  {errors.plots[index].plot_type}
                                </div>
                              )}
                          </div>

                          {/* Category */}
                          <div className="space-y-2">
                            <label className="block text-teal-300">
                              Category
                            </label>
                            <Field
                              as="select"
                              name={`plots.${index}.category`}
                              className="w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300"
                            >
                              <option value="">Select category</option>
                              <option value="General">General</option>
                              <option value="Corner">Corner</option>
                              <option value="Park Face">Park Face</option>
                              <option value="Boulevard">Boulevard</option>
                            </Field>
                            {errors.plots?.[index]?.category &&
                              touched.plots?.[index]?.category && (
                                <div className="text-red-500 mt-2">
                                  {errors.plots[index].category}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="flex flex-col sm:flex-row justify-between gap-4 mt-8">
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            plot_number: "",
                            block: "",
                            plot_type: "",
                            size: "",
                            category: "",
                          })
                        }
                        className="px-6 py-3 bg-slate-600 text-teal-300 rounded-lg hover:bg-slate-500 transition-colors duration-200 flex items-center justify-center"
                      >
                        <span className="mr-2">+</span> Add Another Plot
                      </button>
                      <button
                        type="submit"
                        className="px-8 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-500 transition-colors duration-200 font-semibold"
                      >
                        Submit Plots
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PlotCreation;
