import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editPlotFromSociety, removePlotFromSociety } from "../../api";

// Thunk for editing a plot within a society
export const editPlot = createAsyncThunk(
  "plots/editPlot",
  async ({ societyId, plotId, plotData }, { rejectWithValue }) => {
    try {
      const response = await editPlotFromSociety(societyId, plotId, plotData);
      return response; // return the updated plot data
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Thunk for removing a plot from a society
export const deletePlot = createAsyncThunk(
  "plots/deletePlot",
  async ({ societyId, plotId }, { rejectWithValue }) => {
    try {
      await removePlotFromSociety(societyId, plotId);
      console.log("Plot successfully deleted:", plotId);
      return plotId;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const plotSlice = createSlice({
  name: "plots",
  initialState: {
    plots: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editPlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPlot.fulfilled, (state, action) => {
        state.loading = false;
        const updatedPlotIndex = state.plots.findIndex(
          (plot) => plot._id === action.payload._id
        );
        if (updatedPlotIndex !== -1) {
          state.plots[updatedPlotIndex] = action.payload;
        }
      })
      .addCase(editPlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deletePlot.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePlot.fulfilled, (state, action) => {
        state.loading = false;
        state.plots = state.plots.filter((plot) => plot._id !== action.payload);
      })
      .addCase(deletePlot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default plotSlice.reducer;
