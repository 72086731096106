import { configureStore } from "@reduxjs/toolkit";
import societyReducer from "./slices/societySlice";
import authReducer from "./slices/authSlice";
import bookingReducer from "./slices/bookingSlice";
import installmentReducer from "./slices/installmentSlice";
import transactionReducer from "./slices/transactionSlice";
import plotReducer from "./slices/plotSlice";
import generalTransactionReducer from "./slices/generalTransactionSlice";
import resellReducer from "./slices/resellSlice";
import aggregationReducer from "./slices/aggregationSlice";
import transferPlotReducer from "./slices/transferPlotSlice";
import notificationReducer from "./slices/notificationSlice";
import salaryReducer from "./slices/salarySlice";
import messageReducer from "./slices/messageSlice";
import scholarshipReducer from "./slices/scholarshipSlice";

const loggerMiddleware = (store) => (next) => (action) => {
  // console.log("Dispatching", action);
  let result = next(action);
  // console.log("Next State", store.getState());
  return result;
};

const store = configureStore({
  reducer: {
    societies: societyReducer,
    auth: authReducer,
    booking: bookingReducer,
    installments: installmentReducer,
    transactions: transactionReducer,
    plots: plotReducer,
    generalTransactions: generalTransactionReducer,
    resell: resellReducer,
    aggregation: aggregationReducer,
    transferPlot: transferPlotReducer,
    notifications: notificationReducer,
    salary: salaryReducer,
    messages: messageReducer,
    scholarship: scholarshipReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});

export default store;
