import React from "react";
import {
  FileText,
  Building,
  Calendar,
  CreditCard,
  User,
  Phone,
  MapPin,
  CheckCircle,
  IdCard,
} from "lucide-react";
import printJS from "print-js";

const LandscapeInstallmentBookingSlip = ({
  installment,
  companyInfo,
  payment,
}) => {
  if (!installment || !companyInfo || !payment) {
    return (
      <div className="flex justify-center items-center h-full">Loading...</div>
    );
  }

  const plotId = installment.plot_id;

  // Extract payment details for the specific plot
  const plotPaymentDetails = payment[plotId] || {
    totalPaidAmount: 0,
    totalPendingInstallmentsAmount: 0,
    totalPlotAmount: 0,
  };

  const formatCNIC = (cnic) => {
    if (!cnic) return "";
    return cnic.replace(/^(\d{5})(\d{7})(\d{1})$/, "$1-$2-$3");
  };

  const handlePrint = () => {
    const printContent = `
      <div style="font-family: 'Arial', sans-serif; margin: 0; padding: 20px;">
        <!-- Header -->
        <div style="background: linear-gradient(to right, #1e40af, #1e4faf); color: white; padding: 20px; border-radius: 12px; margin-bottom: 20px;">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; align-items: center;">
              <img src="${
                companyInfo.soc.society_image
              }" alt="Society" style="width: 64px; height: 64px; border-radius: 12px; margin-right: 16px; border: 3px solid rgba(255,255,255,0.3);" />
              <div>
                <h1 style="margin: 0; font-size: 24px; font-weight: bold;">${
                  companyInfo.soc.name
                }</h1>
                <p style="margin: 0; opacity: 0.8;">${
                  companyInfo.soc.location
                }</p>
              </div>
            </div>
            <div style="text-align: right; background: rgba(255,255,255,0.1); padding: 12px; border-radius: 8px;">
              <p style="margin: 0; opacity: 0.8;">Installment Payment Slip</p>
              <p style="margin: 5px 0 0; font-size: 24px; font-weight: bold;">Invoice #${
                installment.receipt_no
              }</p>
            </div>
          </div>
        </div>

        <!-- Main Content Grid -->
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 24px; margin-bottom: 24px;">
          <!-- Customer Info -->
          <div style="background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
            <h3 style="font-size: 18px; margin-bottom: 16px; color: #1e40af;">Customer Details</h3>
            <div style="background: #f8fafc; padding: 12px; border-radius: 8px; margin-bottom: 12px;">
              <p style="margin: 0; color: #64748b; font-size: 12px;">Name</p>
              <p style="margin: 4px 0 0; font-weight: 300;">${
                installment.customer_id.name
              }</p>
            </div>
            <div style="background: #f8fafc; padding: 12px; border-radius: 8px; margin-bottom: 12px;">
              <p style="margin: 0; color: #64748b; font-size: 12px;">Contact</p>
              <p style="margin: 4px 0 0; font-weight: 300;">${
                installment.customer_id.contactInfo.phone
              }</p>
            </div>
            <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
              <p style="margin: 0; color: #64748b; font-size: 12px;">CNIC</p>
              <p style="margin: 4px 0 0; font-weight: 300;">${formatCNIC(
                installment.customer_id.contactInfo.cnic
              )}</p>
            </div>
            
          </div>

          <!-- Property Details -->
          <div style="background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
            <h3 style="font-size: 18px; margin-bottom: 16px; color: #1e40af;">Property Information</h3>
            <div style="background: #f8fafc; padding: 12px; border-radius: 8px; margin-bottom: 12px;">
              <p style="margin: 0; color: #64748b; font-size: 12px;">Plot Details</p>
             <p style="margin: 4px 0 0; font-weight: 400;">
                ${companyInfo.plot_type} - Plot #${companyInfo.plot_number}
             </p>

              <p style="margin: 4px 0 0;">${companyInfo.block}</p>
              <p style="margin: 4px 0 0;">${companyInfo.size} Marla</p>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 12px;">
              <div style="background: #eff6ff; padding: 12px; border-radius: 8px; text-align: center;">
                <p style="margin: 0; color: #1e40af; font-size: 12px;">Installment</p>
                <p style="margin: 4px 0 0; font-weight: 300; font-size: 18px;">#${
                  installment.installment_number
                }</p>
              </div>
              <div style="background: #f0fdf4; padding: 12px; border-radius: 8px; text-align: center;">
                <p style="margin: 0; color: #15803d; font-size: 12px;">Due Date</p>
                <p style="margin: 4px 0 0; font-weight: 300;">${new Date(
                  installment.due_date
                ).toLocaleDateString()}</p>
              </div>
            </div>
          </div>

          <!-- Payment Details -->
          <div style="background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
            <h3 style="font-size: 18px; margin-bottom: 16px; color: #1e40af;">Payment Details</h3>
            <div style="background: #f0fdf4; padding: 12px; border-radius: 8px; margin-bottom: 12px;">
              <p style="margin: 0; color: #15803d; font-size: 12px;">Total Amount</p>
              <p style="margin: 4px 0 0; font-weight: 300; font-size: 18px;">PKR ${installment.amount.toFixed(
                2
              )}</p>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 12px;">
              <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Paid Amount</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${installment.paid_amount.toFixed(
                  2
                )}</p>
              </div>
              <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Remaining</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${installment.remaining_amount.toFixed(
                  2
                )}</p>
              </div>
              <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Payment Date</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${new Date(
                  installment.payment_date
                ).toLocaleDateString()}</p>
              </div>
               <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Total Paid Amount</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${plotPaymentDetails.totalPaidAmount.toFixed(
                  2
                )}</p>
              </div>
               <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Pending Insyallment Amount</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${plotPaymentDetails.totalPendingInstallmentsAmount.toFixed(
                  2
                )}</p>
              </div>
               <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
                <p style="margin: 0; color: #64748b; font-size: 12px;">Total Plot Amount</p>
                <p style="margin: 4px 0 0; font-weight: 300;">PKR ${plotPaymentDetails.totalPlotAmount.toFixed(
                  2
                )}</p>
              </div>
            </div>
          </div>
        </div>

       <!-- Terms and Conditions -->
<div style="background: white; padding: 10px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); margin-bottom: 24px;">
  <h3 style="font-size: 18px; margin-bottom: 16px; color: #1e40af;">Terms & Conditions</h3>
  <div style="display: grid; grid-template-columns: 1fr; gap: 16px;">
    <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">1</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">Payment must be made on or before the due date to avoid late payment charges.</p>
    </div>
    <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">2</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">A late payment fee of 2% per month will be charged on overdue amounts.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">3</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">All payments should be made through authorized channels only.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">4</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">Installment receipts must be preserved and presented upon request.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">5</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">The company reserves the right to cancel the booking in case of three consecutive missed payments.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">6</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">Any changes in payment schedule must be approved by the management in writing.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">7</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">Bounced checks will incur additional processing charges.</p>
    </div>
     <div style="display: flex; gap: 8px;">
      <div style="background: #eff6ff; width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; flex-shrink: 0;">
        <span style="color: #1e40af; font-size: 12px; font-weight: 600;">8</span>
      </div>
      <p style="margin: 0; font-size: 14px; color: #4b5563;">Transfer of property ownership is subject to full payment clearance.</p>
    </div>
   
  </div>
</div>


        <!-- Signatures -->
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 12px; margin-bottom: 12px;">
          <div style="background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); text-align: center;">
            <div style="height: 64px; border-bottom: 2px solid #e2e8f0;"></div>
            <p style="margin: 8px 0 0; font-size: 14px; color: #64748b;">Customer Signature</p>
          </div>
          <div style="background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); text-align: center;">
            <div style="height: 64px; border-bottom: 2px solid #e2e8f0;"></div>
            <p style="margin: 8px 0 0; font-size: 14px; color: #64748b;">Company Representative</p>
          </div>
          
        </div>
      </div>
    `;

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
        @page { 
  size: A4 portrait; 
  margin: 0; 
}

        body { margin: 0; font-family: Arial, sans-serif; }
      `,
    });
  };

  return (
    <div className="bg-white w-full rounded-lg">
      <div className="bg-blue-600 text-white p-4 flex justify-between items-center rounded-t-lg">
        <div className="flex items-center space-x-3">
          <img
            src={companyInfo.soc.society_image}
            alt="Society"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <h1 className="text-xl font-bold">{companyInfo.soc.name}</h1>
            <p className="text-sm opacity-80">{companyInfo.soc.location}</p>
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs opacity-80">Installment Payment Slip</p>
          <p className="text-lg font-semibold">
            Invoice #{installment.receipt_no}
          </p>
        </div>
      </div>

      {/* Main Content in Landscape Layout */}
      <div className="grid grid-cols-3 gap-4 p-4 bg-gray-50">
        {/* Customer Info Column */}
        <div className="col-span-1 space-y-3">
          <div className="flex items-center space-x-2 bg-white p-2 rounded-xl shadow-sm">
            <User className="text-blue-500 w-5 h-5" />
            <div>
              <p className="text-xs text-gray-500">Customer Name</p>
              <p className="font-bold text-gray-800 text-sm">
                {installment.customer_id.name}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2 bg-white p-2 rounded-xl shadow-sm">
            <Phone className="text-green-500 w-5 h-5" />
            <div>
              <p className="text-xs text-gray-500">Contact Number</p>
              <p className="font-bold text-gray-800 text-sm">
                {installment.customer_id.contactInfo.phone}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2 bg-white p-2 rounded-xl shadow-sm">
            <MapPin className="text-red-500 w-5 h-5" />
            <div>
              <p className="text-xs text-gray-500">Address</p>
              <p className="font-bold text-gray-800 text-sm">
                {installment.customer_id.contactInfo.address}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2 bg-white p-2 rounded-xl shadow-sm">
            <IdCard className="text-red-500 w-5 h-5" />
            <div>
              <p className="text-xs text-gray-500">CNIC</p>
              <p className="font-bold text-gray-800 text-sm">
                {formatCNIC(installment.customer_id.contactInfo.cnic)}
              </p>
            </div>
          </div>
        </div>

        {/* Property & Installment Details Column */}
        <div className="col-span-1 space-y-3">
          <div className="flex items-center space-x-2 bg-white p-2 rounded-xl shadow-sm">
            <Building className="text-purple-500 w-5 h-5" />
            <div>
              <p className="text-xs text-gray-500">Property Details</p>
              <p className="font-bold text-gray-800 text-sm">
                {companyInfo.plot_type} - Plot #{companyInfo.plot_number},{" "}
              </p>
              <p className="font-bold text-gray-800 text-sm">
                {companyInfo.block}
              </p>
              <p className="font-bold text-gray-800 text-sm">
                {companyInfo.size} Marla
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2">
            <div className="bg-blue-50 p-2 rounded-xl text-center space-y-1">
              <Calendar className="mx-auto text-blue-600 w-5 h-5" />
              <p className="text-xs text-gray-600">Installment No.</p>
              <p className="font-bold text-gray-800 text-sm">
                {installment.installment_number}
              </p>
            </div>
            <div className="bg-green-50 p-2 rounded-xl text-center space-y-1">
              <CreditCard className="mx-auto text-green-600 w-5 h-5" />
              <p className="text-xs text-gray-600">Total Amount</p>
              <p className="font-bold text-gray-800 text-sm">
                PKR {installment.amount.toFixed(2)}
              </p>
            </div>
            <div className="bg-orange-50 p-2 rounded-xl text-center space-y-1">
              <FileText className="mx-auto text-orange-600 w-5 h-5" />
              <p className="text-xs text-gray-600">Due Date</p>
              <p className="font-bold text-gray-800 text-sm">
                {new Date(installment.due_date).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        {/* Payment Details Column */}
        <div className="col-span-1 space-y-3 bg-gray-100 p-3 rounded-xl">
          <h3 className="text-lg font-semibold text-gray-700 mb-2 flex items-center">
            <CheckCircle className="mr-2 text-green-500 w-5 h-5" /> Payment
            Details
          </h3>
          {[
            {
              label: "Total Amount",
              value: `PKR ${installment.amount.toFixed(2)}`,
            },
            {
              label: "Paid Amount",
              value: `PKR ${installment.paid_amount.toFixed(2)}`,
            },
            {
              label: "Remaining Amount",
              value: `PKR ${installment.remaining_amount.toFixed(2)}`,
            },
            {
              label: "Payment Date",
              value: new Date(installment.payment_date).toLocaleDateString(),
            },
            {
              label: "Total Paid Amount",
              value: `PKR ${plotPaymentDetails.totalPaidAmount.toFixed(2)}`,
            },
            {
              label: "Total Pending Installments Amount",
              value: `PKR ${plotPaymentDetails.totalPendingInstallmentsAmount.toFixed(
                2
              )}`,
            },
            {
              label: "Total Plot Amount",
              value: `PKR ${plotPaymentDetails.totalPlotAmount.toFixed(2)}`,
            },
          ].map(({ label, value }) => (
            <div
              key={label}
              className="bg-white p-2 rounded-xl flex justify-between items-center shadow-sm"
            >
              <span className="text-xs text-gray-600">{label}</span>
              <span className="font-bold text-gray-800 text-sm">{value}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Signature Section */}
      <div className="flex justify-between p-4 border-t">
        <div className="w-1/2 pr-2">
          <div className="h-10 border-b border-gray-300"></div>
          <p className="text-xs text-gray-600 text-center mt-1">
            Customer Signature
          </p>
        </div>
        <div className="w-1/2 pl-2">
          <div className="h-10 border-b border-gray-300"></div>
          <p className="text-xs text-gray-600 text-center mt-1">
            Company Representative
          </p>
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-4">
        {/* <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 close-btn">
          Close
        </button> */}
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 print-btn"
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default LandscapeInstallmentBookingSlip;
