import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers,
  deleteUser,
  updateUserInfo,
  updateUserStatus,
} from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import Switcher3 from "../components/Switcher3";
import {
  Phone,
  Mail,
  UserPlus,
  Edit2,
  Trash2,
  Search,
  User,
  IdCard,
  Upload,
  ChevronDown,
  CalendarClock,
} from "lucide-react";

const EmployeeTable = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeFilter, setActiveFilter] = useState("All Users");
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    setUpdatedUsers(users);
  }, [users]);

  const filteredUsers = updatedUsers
    .filter(
      (user) =>
        user.role === "Employee" &&
        (activeFilter === "All Users" ||
          user.societies.some((soc) => soc.name === activeFilter))
    )
    .filter((user) => {
      const name = user.employeeData?.name || "";
      const designation = user.employeeData?.designation || "";
      const phone = user.employeeData?.personalDetails?.phone || "";
      const address = user.employeeData?.personalDetails?.address || "";
      const dateOfJoining =
        user.employeeData?.personalDetails?.dateOfJoining || "";
      return (
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        dateOfJoining.toLowerCase().includes(searchQuery.toLowerCase()) ||
        designation.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

  const handleEdit = (user) => {
    setSelectedEmployee(user);
    setModalOpen(true);
  };

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this Employee?")) {
      console.log("Deleting user with ID:", userId);
      dispatch(deleteUser(userId));
      setUpdatedUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== userId)
      );
      dispatch(fetchAllUsers());
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setSelectedEmployee((prev) => ({
          ...prev,
          profile_picture: reader.result,
        }));
        setIsUploading(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setSelectedEmployee((prevSelectedEmployee) => ({
      ...prevSelectedEmployee,
      employeeData: {
        ...prevSelectedEmployee.employeeData,
        personalDetails: {
          ...prevSelectedEmployee.employeeData.personalDetails,
          [field]: value,
        },
        salaryDetails: {
          ...prevSelectedEmployee.employeeData.salaryDetails,
          [field]: value,
        },
      },
    }));
  };

  const handleUpdate = () => {
    if (!selectedEmployee?._id) {
      console.error("User ID is missing, cannot update.");
      return; // Return early if _id is undefined
    }

    const updatedData = {
      username: selectedEmployee.username,
      profile_picture: selectedEmployee.profile_picture,
      societies: selectedEmployee.societies,
      status: selectedEmployee.status,
      role: "Employee",
      employeeData: {
        name: selectedEmployee.employeeData?.name,
        designation: selectedEmployee.employeeData?.designation,
        personalDetails: {
          phone: selectedEmployee.employeeData?.personalDetails?.phone,
          address: selectedEmployee.employeeData?.personalDetails?.address,
          cnic: selectedEmployee.employeeData?.personalDetails?.cnic,
          dateOfJoining:
            selectedEmployee.employeeData?.personalDetails?.dateOfJoining,
        },
        salaryDetails: {
          salary: selectedEmployee.employeeData?.salaryDetails?.salary,
        },
      },
    };

    console.log("Sending updated data:", updatedData);

    // Dispatch the update action
    dispatch(updateUserInfo({ userId: selectedEmployee._id, updatedData }))
      .then((res) => {
        console.log("Update response:", res);

        if (res.meta.requestStatus === "fulfilled") {
          console.log("Update successful, fetching users...");
          dispatch(fetchAllUsers()).then((res) => {
            setUpdatedUsers(res.payload);
          });
          setModalOpen(false);
        } else {
          console.error("Update failed:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  const handleStatusChange = (userId, newStatus) => {
    const updatedData = { status: newStatus }; // Only update the status field
    console.log("Sending status update:", { userId, updatedData });
    dispatch(updateUserStatus({ userId, status: newStatus }))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          // Optional: refetch all users to ensure data consistency
          dispatch(fetchAllUsers());
          console.log("Status updated successfully for user ID:", userId);
        } else {
          console.error("Status update failed:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating user status:", error);
      });
  };

  function formatCNIC(cnic) {
    if (!cnic) return "";
    cnic = cnic.replace(/\D/g, "");
    cnic = cnic.slice(0, 13);
    return cnic.replace(/^(\d{5})(\d{7})(\d{1})$/, "$1-$2-$3");
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 animate-gradient-x">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Enhanced Header Section */}
        <div className="relative group p-6 rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-all duration-500">
          <div className="flex flex-col space-y-2">
            <h1 className="text-5xl font-bold tracking-tight bg-gradient-to-r from-teal-300 via-teal-200 to-teal-400 text-transparent bg-clip-text transform group-hover:scale-[1.02] transition-all duration-500">
              Employee Management
            </h1>
            <p className="text-teal-300/70 text-lg transform group-hover:translate-x-2 transition-all duration-500">
              Manage and monitor your Employee base efficiently
            </p>
          </div>
          <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-teal-300/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
        </div>

        {/* Enhanced Search Bar */}
        <div className="relative group transform hover:-translate-y-1 transition-all duration-500">
          <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-teal-300/20 to-teal-400/20 blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <Search className="h-6 w-6 text-teal-300 group-hover:rotate-12 transition-transform duration-500" />
          </div>
          <input
            type="text"
            placeholder="Search users by name, email, or CNIC..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-12 pr-4 py-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
            placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
            focus:border-teal-300/20 transition-all duration-500 backdrop-blur-xl
            hover:bg-white/10 hover:shadow-lg hover:shadow-teal-300/10"
          />
        </div>

        {/* Society Filter */}

        <div className="relative">
          <select
            onChange={(e) => setActiveFilter(e.target.value)}
            className="bg-slate-800 text-teal-300 p-3 rounded-xl shadow-lg shadow-teal-500/40 hover:shadow-teal-500/60 transition-all duration-300 cursor-pointer"
          >
            <option value="All Users">All Users</option>
            {societies.map((society) => (
              <option key={society._id} value={society.name}>
                {society.name}
              </option>
            ))}
          </select>
        </div>

        {/* Table with Glassmorphism and Hover Effects */}
        <div className="relative group">
          <div className="absolute inset-0 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
          <div
            className="relative overflow-hidden rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10 
              hover:border-white/20 transition-all duration-500 hover:shadow-xl hover:shadow-teal-300/10"
          >
            <table className="w-full">
              <thead>
                <tr className="border-b border-white/10 bg-white/5">
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    User Details
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Society
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Personal Details
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    salary
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Status
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr
                    key={user._id}
                    onMouseEnter={() => setHoveredRow(user._id)}
                    onMouseLeave={() => setHoveredRow(null)}
                    className="group/row border-b border-white/5 hover:bg-white/10 transition-all duration-500 cursor-pointer"
                  >
                    <td className="px-6 py-4">
                      <div className="flex items-center space-x-4">
                        <div className="relative group/avatar">
                          {user.profile_picture ? (
                            <img
                              src={user.profile_picture}
                              alt={user.employeeData?.name}
                              className="w-12 h-12 rounded-full object-cover border-2 border-teal-300/20 
                                group-hover/avatar:scale-110 transition-all duration-500"
                            />
                          ) : (
                            <div
                              className="w-12 h-12 rounded-full bg-teal-300/10 flex items-center justify-center 
                                border-2 border-teal-300/20 group-hover/avatar:scale-110 transition-all duration-500"
                            >
                              <User className="h-6 w-6 text-teal-300" />
                            </div>
                          )}
                          <div
                            className="absolute -bottom-1 -right-1 w-4 h-4 rounded-full bg-teal-400 border-2 border-slate-800
                              group-hover/avatar:scale-125 transition-all duration-500"
                          />
                        </div>
                        <div>
                          <div className="text-teal-300 font-medium group-hover/row:translate-x-2 transition-transform duration-500">
                            {user.employeeData?.name || "N/A"}
                          </div>
                          <div className="text-teal-300/60 text-sm group-hover/row:translate-x-2 transition-transform duration-500 delay-75">
                            {user.username}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-teal-300">
                      {user.societies
                        .map((society) => society.name)
                        .join(", ") || "N/A"}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col space-y-2">
                        <div className="flex items-center space-x-2 text-teal-300">
                          <Phone className="h-4 w-4" />
                          <span>
                            {user.employeeData?.personalDetails?.phone || "N/A"}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 text-teal-300/60">
                          <Mail className="h-4 w-4" />
                          <span>
                            {user.employeeData?.personalDetails?.address ||
                              "N/A"}
                          </span>
                        </div>

                        <div className="flex items-center space-x-2 text-teal-300/60">
                          <IdCard className="h-4 w-4" />
                          <span>
                            {user.employeeData?.personalDetails?.cnic
                              ? formatCNIC(
                                  user.employeeData.personalDetails.cnic
                                )
                              : "N/A"}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2 text-teal-300/60">
                          <CalendarClock className="h-4 w-4" />
                          <span>
                            {new Date(
                              user.employeeData.personalDetails.dateOfJoining
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3">
                      <div className="flex items-center space-x-2 text-teal-300">
                        Rs.{user.employeeData?.salaryDetails?.salary || "N/A"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <Switcher3
                        isChecked={user.status === "active"}
                        onToggle={(newStatus) =>
                          handleStatusChange(
                            user._id,
                            newStatus ? "active" : "inactive"
                          )
                        }
                      />
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleEdit(user)}
                          className="p-2 rounded-lg bg-teal-300/10 hover:bg-teal-300/20 text-teal-300 
                        transform hover:scale-110 transition-all duration-300"
                        >
                          <Edit2 className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(user._id)}
                          className="p-2 rounded-lg bg-red-400/10 hover:bg-red-400/20 text-red-400 
                        transform hover:scale-110 transition-all duration-300"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal with Glassmorphism */}
        {isModalOpen && selectedEmployee && (
          <div className="fixed inset-x-0 -top-16 bottom-0 bg-slate-700/80 backdrop-blur-sm flex items-center justify-center z-50">
            <div
              className="relative group bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-3xl 
                border border-white/10 shadow-2xl backdrop-blur-xl w-full max-w-2xl transform 
                transition-all duration-500 hover:scale-[1.02] hover:border-white/20"
            >
              <div
                className="absolute inset-0 rounded-3xl bg-gradient-to-r from-teal-300/10 to-transparent 
                  opacity-0 group-hover:opacity-100 transition-all duration-500"
              />
              {/* Header */}
              <h2
                className="text-3xl font-bold mb-8 bg-gradient-to-r from-teal-300 to-teal-400 
                     text-transparent bg-clip-text text-center"
              >
                Edit Employee Info
              </h2>
              {/* Profile Image Section */}
              <div className="flex justify-center mb-8">
                <div className="relative group">
                  <div
                    className="w-32 h-32 rounded-full overflow-hidden border-4 border-teal-300/30 
                          group-hover:border-teal-300/50 transition-all duration-300"
                  >
                    <img
                      src={
                        previewImage ||
                        selectedEmployee.profile_picture ||
                        "/api/placeholder/128/128"
                      }
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                    <div
                      className="absolute inset-0 bg-black/50 flex items-center justify-center 
                            opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    >
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <Upload className="h-8 w-8 text-teal-300" />
                      </label>
                    </div>
                  </div>
                  {isUploading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-teal-300"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-6">
                {/* First Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="text"
                      placeholder="Employee Name"
                      value={selectedEmployee.employeeData?.name || ""}
                      onChange={(e) =>
                        setSelectedEmployee((prev) => ({
                          ...prev,
                          employeeData: {
                            ...prev.employeeData,
                            name: e.target.value,
                          },
                        }))
                      }
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                          placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                          focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>

                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <IdCard className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="text"
                      placeholder="Phone"
                      value={
                        selectedEmployee.employeeData?.personalDetails?.phone ||
                        ""
                      }
                      onChange={(e) => handleInputChange(e, "phone")}
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                          placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                          focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>
                </div>

                {/* Second Row */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Phone className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="text"
                      placeholder="Address"
                      value={
                        selectedEmployee.employeeData?.personalDetails
                          ?.address || ""
                      }
                      onChange={(e) => handleInputChange(e, "address")}
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                          placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                          focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>

                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="date"
                      placeholder="Date Of Joining"
                      value={
                        selectedEmployee?.employeeData.personalDetails
                          .dateOfJoining || ""
                      }
                      onChange={(e) => handleInputChange(e, "dateOfJoining")}
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                          placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                          focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>
                </div>

                {/* Username Field */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserPlus className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="text"
                      placeholder="Username"
                      value={selectedEmployee.username || ""}
                      onChange={(e) =>
                        setSelectedEmployee((prev) => ({
                          ...prev,
                          username: e.target.value,
                        }))
                      }
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                        placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                        focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>
                  <div className="relative group">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                    </div>
                    <input
                      type="number"
                      placeholder="Employee Salary"
                      value={
                        selectedEmployee.employeeData?.salaryDetails?.salary ||
                        ""
                      }
                      onChange={(e) => handleInputChange(e, "salary")}
                      className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                          placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                          focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                    />
                  </div>
                </div>
                <div className="relative group">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <IdCard className="h-5 w-5 text-teal-300/50 group-hover:text-teal-300/80 transition-colors duration-300" />
                  </div>
                  <input
                    type="text"
                    placeholder="CNIC"
                    value={
                      selectedEmployee.employeeData?.personalDetails?.cnic
                        ? formatCNIC(
                            selectedEmployee.employeeData.personalDetails.cnic
                          )
                        : ""
                    }
                    onChange={(e) => handleInputChange(e, "cnic")}
                    className="w-full pl-10 p-4 rounded-xl bg-white/5 border border-white/10 text-teal-300 
                placeholder-teal-300/50 focus:outline-none focus:ring-2 focus:ring-teal-300/20 
                focus:border-teal-300/20 transition-all duration-300 hover:bg-white/10"
                  />
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex space-x-4 mt-8">
                <button
                  onClick={handleUpdate}
                  className="flex-1 bg-gradient-to-r from-teal-400 to-teal-500 text-white px-6 py-3 rounded-xl 
                     font-semibold transform hover:scale-105 transition-all duration-300 
                     hover:shadow-lg hover:shadow-teal-500/25 active:scale-95"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setModalOpen(false)}
                  className="flex-1 bg-gradient-to-r from-red-400 to-red-500 text-white px-6 py-3 rounded-xl 
                     font-semibold transform hover:scale-105 transition-all duration-300 
                     hover:shadow-lg hover:shadow-red-500/25 active:scale-95"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeTable;
