import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  payInstallmentAsync,
  fetchInstallmentsByPlotIdAndStatus,
  fetchInstallmentsByPlotId,
  fetchPaidInstallmentsForUser,
} from "../store/slices/installmentSlice";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import InstallmentBookingSlip from "./util/InstallmentBookingSlip";
import Modal from "react-modal";
import {
  MapPin,
  Home,
  Tag,
  CheckCircle,
  Info,
  HandCoins,
  BookCopy,
  ReceiptText,
} from "lucide-react";

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  backdropFilter: "blur(4px)",
};

const modalStyle = {
  position: "relative",
  background: "#ffffff",
  borderRadius: "8px",
  padding: "20px",
  maxWidth: "1200px",
  width: "90%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  zIndex: 1001,
};
const printStyles = `
  @media print {
    .print-btn, .close-btn {
      display: none !important;
    }
  }
`;

const ManageInstallments = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { installments, error } = useSelector((state) => state.installments);
  const users = useSelector((state) => state.auth.users);
  const customerPlots = useSelector((state) => state.auth.plots || []);
  const { societies } = useSelector((state) => state.societies);

  const [paymentMode, setPaymentMode] = useState("");
  const [installmentId, setInstallmentId] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [isSlipModalOpen, setIsSlipModalOpen] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [receiptNo, setReceiptNo] = useState("");
  const [totals, setTotals] = useState({
    totalPaidAmount: 0,
    totalPendingInstallmentsAmount: 0,
    totalPlotAmount: 0,
  });

  // Fetch all users on component mount
  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  // Fetch customer plots whenever a new customer is selected
  useEffect(() => {
    if (selectedCustomer && selectedCustomer.customerData) {
      dispatch(fetchCustomerPlots(selectedCustomer.customerData._id));
    }
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      const customerId = selectedCustomer._id;

      // console.log("Fetching data for customerId:", customerId);

      customerPlots.forEach((plot) => {
        const plotId = plot._id;

        // console.log("Fetching data for plotId:", plotId);

        dispatch(fetchPaidInstallmentsForUser({ customerId, plotId }))
          .unwrap()
          .then((result) => {
            // console.log("Dispatch Result for plot:", plotId, result);

            setTotals((prevTotals) => ({
              ...prevTotals,
              [plotId]: {
                totalPaidAmount: result.totalPaidAmount || 0,
                totalPendingInstallmentsAmount:
                  result.totalPendingInstallmentsAmount || 0,
                totalPlotAmount: result.totalPlotAmount || 0,
              },
            }));
          })
          .catch((error) => {
            console.error("Detailed Dispatch Error:", error);
            alert(
              "There was an issue fetching your data. Please check the console for details."
            );
          });
      });
    }
  }, [dispatch, selectedCustomer, customerPlots]);

  useEffect(() => {
    if (selectedPlot) {
      // Fetch installments by plot ID
      dispatch(fetchInstallmentsByPlotId(selectedPlot._id));

      const statuses = ["Pending", "Partially Paid", "Completed", "Overdue"];
      statuses.forEach((status) => {
        dispatch(
          fetchInstallmentsByPlotIdAndStatus({
            plotId: selectedPlot._id,
            status,
          })
        );
      });

      // Logging to check if installments are fetched correctly
      // console.log(`Fetching installments for Plot ID: ${selectedPlot._id}`);
    }
  }, [dispatch, selectedPlot]);
  useEffect(() => {
    // Set the app element for accessibility
    Modal.setAppElement("#root"); // Assuming your root element is the div with id 'root'
  }, []);

  const getSocietyAndPlotDetails = (plotId) => {
    for (let soc of societies) {
      const plot = soc.plots.find((plot) => plot._id === plotId);
      if (plot) {
        // console.log("Found plot details:", {
        //   societyName: soc.name,
        //   societyLocation: soc.location,
        //   societyImage: soc.society_image,
        //   plotNumber: plot.plot_number,
        //   plotType: plot.plot_type,
        //   block: plot.block,
        //   category: plot.category,
        //   bookingStatus: plot.booking_status,
        // });
        return {
          societyName: soc.name,
          societyLocation: soc.location,
          societyImage: soc.society_image,
          plotNumber: plot.plot_number,
          plotType: plot.plot_type,
          block: plot.block,
          category: plot.category,
          bookingStatus: plot.booking_status,
          // totalPaidAmount:,
          // totalPendingInstallmentsAmount:,
          // totalPlotAmount:,
        };
      }
    }
    // console.log("No society or plot found for the given plotId.");
    return null;
  };
  const customerOptions = useMemo(
    () =>
      users
        .filter((user) => user.role === "Customer")
        .map((customer) => (
          <option key={customer._id} value={customer._id}>
            {customer.username}
          </option>
        )),
    [users]
  );

  const handlePaymentModeChange = useCallback((value) => {
    setPaymentMode(value);
    setSelectedCustomer(null);
    setSelectedPlot(null);
    setInstallmentId("");
    setPaymentAmount("");
  }, []);

  const handleCustomerChange = useCallback(
    (customerId) => {
      const customer = users.find((user) => user._id === customerId);
      if (customer) {
        setSelectedCustomer(customer);
        setSelectedPlot(null);
      }
    },
    [users]
  );

  const handlePlotSelect = (plot) => {
    const plotDetails = getSocietyAndPlotDetails(plot._id);
    if (plotDetails) {
      setSelectedPlot({
        ...plot,
        plot_number: plotDetails.plotNumber,
        soc: {
          name: plotDetails.societyName,
          location: plotDetails.societyLocation,
          society_image: plotDetails.societyImage,
        },
      });
    }
  };

  const handleInstallmentChange = useCallback(
    (installment) => {
      const plotDetails = getSocietyAndPlotDetails(selectedPlot._id);
      if (plotDetails) {
        setInstallmentId(installment._id);
        setPaymentAmount(installment.amount);
        setIsModalOpen(true);
        setSelectedPlot({
          ...selectedPlot,
          plotNumber: plotDetails.plotNumber,
          societyName: plotDetails.societyName,
        });
      }
    },
    [selectedPlot]
  );

  // const handlePaymentAmountChange = (e) => {
  //   const value = e.target.value;
  //   const integerValue = parseInt(value, 10); // Parse as an integer

  //   // Check if the parsed value is a valid integer and matches the original value
  //   if (!isNaN(integerValue) && integerValue.toString() === value) {
  //     setPaymentAmount(integerValue);
  //   } else {
  //     setPaymentAmount(""); // Clear the field if the value is not a valid integer
  //   }
  // };
  const handlePaymentAmountChange = (e) => {
    const value = e.target.value;
    const integerValue = parseFloat(value, 10); // Parse as an integer

    // Check if the input is a valid positive integer or an empty field
    if (!isNaN(integerValue) && integerValue >= 0) {
      setPaymentAmount(integerValue);
    } else if (value === "") {
      setPaymentAmount(""); // Allow clearing the input field
    }
  };
  const handlePayment = async () => {
    if (!installmentId || !paymentAmount) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    const paymentData = {
      installment_id: installmentId,
      payment_amount: parseFloat(paymentAmount, 10),
      receipt_no: receiptNo,
    };

    try {
      setLoading(true);
      const response = await dispatch(
        payInstallmentAsync(paymentData)
      ).unwrap();
      // console.log("Payment Response:", response); // Log the successful response
      enqueueSnackbar("Payment processed successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      setLoading(false);
      setIsModalOpen(false);
    } catch (err) {
      setLoading(false);
      console.error("Payment Error:", err); // Log the error to inspect it
      enqueueSnackbar(error || "Payment failed. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };

  const filteredInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      (installment.status === "Pending" ||
        installment.status === "Partially Paid" ||
        installment.status === "Overdue")
  );

  const CompleteInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      installment.status === "Completed"
  );

  const paginatedFilteredInstallments = filteredInstallments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const paginatedCompleteInstallments = CompleteInstallments.slice(
    (completedCurrentPage - 1) * itemsPerPage,
    completedCurrentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredInstallments.length / itemsPerPage);
  const completedTotalPages = Math.ceil(
    CompleteInstallments.length / itemsPerPage
  );

  const handleViewSlip = (installment) => {
    setSelectedInstallment(installment);
    setIsSlipModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSlipModalOpen(false);
    setSelectedInstallment(null);
  };

  return (
    <div className=" p-8">
      <div className="max-w-6xl mx-auto">
        <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 backdrop-blur-sm rounded-xl shadow-2xl overflow-hidden border border-gray-700">
          <div className="p-6 border-b border-gray-700">
            <h2 className="text-3xl font-bold text-center text-teal-500">
              Installment Payment Management
            </h2>
          </div>

          <div className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-teal-300 mb-2">
                Payment Mode
              </label>
              <select
                value={paymentMode}
                onChange={(e) => handlePaymentModeChange(e.target.value)}
                className="w-full px-4 py-2 bg-gray-800 text-teal-300 border border-teal-600 rounded-lg focus:ring-2 focus:ring-[#3a8e81] focus:border-transparent transition-all duration-200"
                disabled
              >
                <option value="Installment">Installment</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-teal-300 mb-2">
                Select Customer
              </label>
              <select
                value={selectedCustomer ? selectedCustomer._id : ""}
                onChange={(e) => handleCustomerChange(e.target.value)}
                required
                className="w-full px-4 py-2 bg-gray-800 text-teal-300 border border-teal-600 rounded-lg focus:ring-2 focus:ring-[#3a8e81] focus:border-transparent transition-all duration-200"
              >
                <option value="">Select Customer</option>
                {customerOptions}
              </select>
            </div>

            {/* Customer Plot Details */}
            {selectedCustomer && (
              <div className="bg-gradient-to-br from-gray-900 to-gray-800 p-8 ">
                <div className="max-w-4xl mx-auto bg-gray-800/60 rounded-3xl shadow-2xl overflow-hidden border border-teal-700/30 backdrop-blur-xl">
                  <div className="p-8 space-y-6">
                    {/* Header Section */}
                    <div className="flex items-center justify-between mb-6">
                      <h2 className="text-3xl font-bold text-white flex items-center gap-4">
                        <Home className="text-teal-400" size={36} />
                        {selectedCustomer.username}'s Plot Portfolio
                      </h2>
                      <div className="bg-teal-500/20 text-teal-300 px-4 py-2 rounded-full text-sm">
                        Total Plots: {customerPlots.length}
                      </div>
                    </div>

                    {customerPlots.length === 0 ? (
                      <div className="text-center py-16 bg-gray-800/50 rounded-2xl">
                        <Info
                          size={64}
                          className="mx-auto text-gray-500 mb-4"
                        />
                        <p className="text-gray-400 text-xl">
                          No plots found for this customer
                        </p>
                      </div>
                    ) : (
                      <div className="grid md:grid-cols-2 gap-6">
                        {customerPlots.map((plot, index) => {
                          const details = getSocietyAndPlotDetails(plot._id);
                          const plotTotals = totals[plot._id] || {};
                          return (
                            <div
                              key={`plot-${plot._id}-${index}`} // Add a prefix to make the key more unique
                              onClick={() => handlePlotSelect(plot)}
                              className={`
                      group relative p-6 rounded-2xl cursor-pointer transition-all duration-500 ease-in-out
                      ${
                        selectedPlot?._id === plot._id
                          ? "ring-4 ring-teal-500 bg-teal-900/30 scale-105"
                          : "hover:bg-gray-700/30 bg-gray-800/40 hover:scale-105"
                      }
                    `}
                            >
                              {/* Animated Gradient Background */}
                              <div className="absolute inset-0 bg-gradient-to-br from-teal-900/10 to-gray-800/20 rounded-2xl opacity-70 transition-all duration-500 group-hover:opacity-90"></div>

                              <div className="relative z-10 space-y-4">
                                {/* Society Logo and Name */}
                                <div className="flex items-center space-x-4">
                                  {details?.societyImage ? (
                                    <img
                                      src={details.societyImage}
                                      alt={details.societyName}
                                      className="w-20 h-20 object-cover rounded-full border-2 border-teal-500/30 shadow-lg"
                                    />
                                  ) : (
                                    <div className="w-20 h-20 bg-gray-700/30 rounded-full flex items-center justify-center">
                                      <Home
                                        size={32}
                                        className="text-gray-500"
                                      />
                                    </div>
                                  )}

                                  <div className="flex flex-col">
                                    <h3 className="text-xl font-semibold text-white">
                                      {details?.societyName}
                                    </h3>
                                    <p className="text-sm text-gray-300 flex items-center">
                                      <MapPin
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      {details?.societyLocation}
                                    </p>
                                  </div>
                                </div>

                                {/* Status Tags */}
                                <div className="flex justify-between">
                                  <span className="px-3 py-1 bg-teal-500/20 text-teal-300 rounded-full text-xs flex items-center">
                                    <Tag size={14} className="mr-2" />
                                    {details?.plotType || "Unknown Type"}
                                  </span>
                                  <span className="px-3 py-1 bg-gray-700/50 text-gray-300 rounded-full text-xs flex items-center">
                                    <CheckCircle size={14} className="mr-2" />
                                    {details?.bookingStatus || "Unknown Status"}
                                  </span>
                                </div>

                                {/* Plot Details */}
                                <div className="space-y-2 border-t border-gray-700/30 pt-4">
                                  <p className="flex justify-between text-gray-300">
                                    <span className="flex items-center">
                                      <Tag
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      Plot Number
                                    </span>
                                    <span className="text-white font-semibold">
                                      {details?.plotNumber}
                                    </span>
                                  </p>
                                  <p className="flex justify-between text-gray-300">
                                    <span className="flex items-center">
                                      <CheckCircle
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      Category
                                    </span>
                                    <span className="text-white font-semibold">
                                      {details?.category}
                                    </span>
                                  </p>

                                  <p className="flex justify-between text-gray-300">
                                    <span className="flex items-center">
                                      <BookCopy
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      Total Paid Amount:
                                    </span>
                                    <span className="text-white font-semibold">
                                      {plotTotals.totalPaidAmount} PKR
                                    </span>
                                  </p>
                                  <p className="flex justify-between text-gray-300">
                                    <span className="flex items-center">
                                      <HandCoins
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      Total Pending Installments Amount:
                                    </span>
                                    <span className="text-white font-semibold">
                                      {
                                        plotTotals.totalPendingInstallmentsAmount
                                      }{" "}
                                      PKR
                                    </span>
                                  </p>
                                  <p className="flex justify-between text-gray-300">
                                    <span className="flex items-center">
                                      <ReceiptText
                                        size={16}
                                        className="mr-2 text-teal-400"
                                      />
                                      Total Plot Amount:
                                    </span>
                                    <span className="text-white font-semibold">
                                      {plotTotals.totalPlotAmount} PKR
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Installments Tables */}
            {selectedPlot && (
              <div className="space-y-8">
                {/* Console log to check selectedPlot */}
                {/* {console.log("Selected Plot:", selectedPlot)} */}

                {/* Pending Installments */}
                <div>
                  <h3 className="text-xl font-semibold text-white mb-4">
                    Pending Installments for Plot {selectedPlot.plot_number} in{" "}
                    <span className="text-red-500 font-extrabold">
                      {selectedPlot.soc.name}
                    </span>
                  </h3>
                  {/* {console.log("Selected Plot Society Info:", selectedPlot.soc)}{" "} */}
                  {/* Log the society info */}
                  {paginatedFilteredInstallments.length > 0 ? (
                    <div className="overflow-x-auto rounded-lg border border-gray-700">
                      <table className="min-w-full">
                        <thead className="bg-gray-800">
                          <tr>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Installment Number
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Installment Amount (PKR)
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Remaining Amount
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Paid Amount
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Due Date
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Status
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-700">
                          {paginatedFilteredInstallments.map((installment) => (
                            <tr
                              key={`pending-installment-${installment._id}`}
                              className="hover:bg-gray-800/50"
                            >
                              <td className="py-3 px-4 text-gray-300">
                                {installment.installment_number}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                PKR {installment.amount.toLocaleString()}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                PKR{" "}
                                {installment.remaining_amount.toLocaleString()}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                PKR {installment.paid_amount.toLocaleString()}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                {new Date(
                                  installment.due_date
                                ).toLocaleDateString()}
                              </td>
                              <td className="py-3 px-4">
                                <span
                                  className={`px-3 py-1 rounded-full text-xs font-semibold ${
                                    installment.status === "Completed"
                                      ? "bg-green-900/50 text-green-300"
                                      : installment.status === "Overdue"
                                      ? "bg-red-900/50 text-red-300"
                                      : "bg-yellow-900/50 text-yellow-300"
                                  }`}
                                >
                                  {installment.status === "Overdue"
                                    ? "Overdue"
                                    : installment.status}
                                </span>
                              </td>
                              <td className="py-3 px-4">
                                <button
                                  onClick={() =>
                                    handleInstallmentChange(installment)
                                  }
                                  className={`px-4 py-1.5 rounded-lg text-sm font-medium transition-all duration-200 ${
                                    installment.status === "Completed"
                                      ? "bg-gray-700 text-gray-400 cursor-not-allowed"
                                      : "bg-[#3a8e81] hover:bg-[#3a8e81] text-white"
                                  }`}
                                  disabled={installment.status === "Completed"}
                                >
                                  Select
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-gray-400">
                      No pending installments for this plot.
                    </p>
                  )}
                </div>

                {/* Pagination for Pending Installments */}
                {totalPages > 1 && (
                  <div className="flex justify-center gap-4 mt-6 mb-5">
                    {Array.from(
                      { length: totalPages },
                      (_, index) => index + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                          pageNumber === currentPage
                            ? "bg-teal-500 text-white"
                            : "bg-slate-600 text-teal-300 hover:bg-slate-500"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                )}

                {/* Completed Installments */}
                {CompleteInstallments.length > 0 && (
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-4">
                      Paid Installments
                    </h3>
                    <div className="overflow-x-auto rounded-lg border border-gray-700">
                      <table className="min-w-full">
                        <thead className="bg-gray-800">
                          <tr>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Installment Receipt no.
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Installment Number
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Amount Paid (PKR)
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Payment Date
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Status
                            </th>
                            <th className="py-3 px-4 text-left text-sm font-semibold text-gray-300 border-b border-gray-700">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-700">
                          {paginatedCompleteInstallments.map((installment) => (
                            <tr
                              key={`completed-installment-${installment._id}`}
                              className="hover:bg-gray-800/50"
                            >
                              <td className="py-3 px-4 text-gray-300">
                                {installment.receipt_no}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                {installment.installment_number}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                PKR {installment.paid_amount.toLocaleString()}
                              </td>
                              <td className="py-3 px-4 text-gray-300">
                                {new Date(
                                  installment.payment_date
                                ).toLocaleDateString()}
                              </td>
                              <td className="py-3 px-4">
                                <span className="px-3 py-1 rounded-full text-xs font-semibold bg-green-900/50 text-green-300">
                                  Completed
                                </span>
                              </td>
                              <td className="py-3 px-4">
                                <button
                                  onClick={() => handleViewSlip(installment)}
                                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {completedTotalPages > 1 && (
                        <div className="flex justify-center gap-4 mt-6 mb-5">
                          {Array.from(
                            { length: completedTotalPages },
                            (_, index) => index + 1
                          ).map((pageNumber) => (
                            <button
                              key={pageNumber}
                              onClick={() =>
                                setCompletedCurrentPage(pageNumber)
                              }
                              className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                                pageNumber === completedCurrentPage
                                  ? "bg-teal-500 text-white"
                                  : "bg-slate-600 text-teal-300 hover:bg-slate-500"
                              }`}
                            >
                              {pageNumber}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        <style>{printStyles}</style>
        <Modal
          isOpen={isSlipModalOpen}
          onRequestClose={handleCloseModal}
          style={{ overlay: modalOverlayStyle, content: modalStyle }}
        >
          <div className="p-6 bg-white rounded-md shadow-lg">
            <InstallmentBookingSlip
              installment={selectedInstallment}
              companyInfo={selectedPlot}
              payment={totals}
            />
          </div>
        </Modal>
      </>

      {/* Payment Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900/80 backdrop-blur-sm">
          <div className="bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4 border border-gray-700 shadow-2xl">
            <h2 className="text-xl font-semibold text-white mb-4">
              Confirm Payment
            </h2>
            <p className="mb-6 text-gray-300">
              You are about to process a payment for installment number{" "}
              <span className="text-red-500 font-extrabold">
                `{installmentId}`
              </span>
              , plot number{" "}
              <span className="text-red-500 font-extrabold">
                `{selectedPlot?.plotNumber}`
              </span>{" "}
              in{" "}
              <span className="text-red-500 font-extrabold">
                `{selectedPlot?.societyName}`
              </span>
              .
            </p>
            <label className="mb-6 text-gray-300">
              <strong>Payment Amount (PKR):</strong>
              <input
                type="number"
                className="w-full mt-2 mb-3 px-4 py-2 bg-gray-700 text-white rounded-lg"
                value={paymentAmount}
                onChange={(e) => handlePaymentAmountChange(e)}
                placeholder="Enter payment amount"
              />
            </label>

            <label className="mb-6 text-gray-300">
              <strong>Receipt no.:</strong>
              <input
                type="text"
                className="w-full mt-2 mb-3 px-4 py-2 bg-gray-700 text-white rounded-lg"
                value={receiptNo}
                onChange={(e) => setReceiptNo(e.target.value)}
              />
            </label>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors duration-200"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 bg-green-500/80 text-white rounded-lg hover:bg-green-500 transition-colors duration-200 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handlePayment}
                disabled={loading}
              >
                {loading ? "Processing..." : "Confirm Payment"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageInstallments;
