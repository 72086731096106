import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactions,
  modifyTransaction,
  removeTransaction,
} from "../store/slices/transactionSlice";
import { Blocks, Loader2, Edit, Trash } from "lucide-react";

const OtherExpense = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);
  const { societies } = useSelector((state) => state.societies);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [directionFilter, setDirectionFilter] = useState("");
  const [editingTransaction, setEditingTransaction] = useState(null);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const excludedTransactionTypes = [
    "Full Payment",
    "Partial Payment",
    "Installment Payment",
    "Salary Payment",
    "Expense Payment",
    "Resell Payment",
  ];

  // Filtering logic
  const filteredTransactions = transactions
    .filter((t) => !excludedTransactionTypes.includes(t.transaction_type))
    .filter((t) => !descriptionFilter || t.description === descriptionFilter)
    .filter(
      (t) => !directionFilter || t.transaction_direction === directionFilter
    );

  // Unique descriptions and directions for filters
  const descriptions = [
    ...new Set(
      filteredTransactions.map((t) => t.description || "No description")
    ),
  ];
  const directions = [
    ...new Set(
      filteredTransactions.map((t) => t.transaction_direction || "Not Defined")
    ),
  ];

  // Pagination logic
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  // Edit transaction handler with immediate update
  const handleEditTransaction = (updatedTransaction) => {
    const updateTransaction = async () => {
      try {
        await dispatch(
          modifyTransaction({
            id: updatedTransaction._id,
            transactionData: updatedTransaction,
          })
        ).unwrap();

        // Optimistically update the transaction in the local state
        dispatch({
          type: "transactions/updateTransaction",
          payload: updatedTransaction,
        });

        setEditingTransaction(null);
      } catch (error) {
        console.error("Error updating transaction:", error);
      }
    };

    updateTransaction();
  };

  // Delete transaction with confirmation
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this transaction?"
    );
    if (confirmDelete) {
      dispatch(removeTransaction(id));
    }
  };

  // Render edit modal as a separate component for cleaner code
  const EditTransactionModal = ({ transaction, onClose, onSave }) => {
    const [editForm, setEditForm] = useState({
      ...transaction,
      societies: transaction.societies?.[0]?.name || "",
      transaction_direction: transaction.transaction_direction || "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;

      setEditForm((prev) => {
        switch (name) {
          case "societies":
            return { ...prev, societies: value }; // handle societies as string
          case "transaction_date":
            return { ...prev, [name]: value }; // directly update the date
          default:
            return { ...prev, [name]: value }; // for other fields
        }
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      // Basic validation
      if (!editForm.societies || !editForm.transaction_direction) {
        alert("Please select a society and transaction direction");
        return;
      }

      // Prepare the transaction data for saving
      const updatedTransaction = {
        ...transaction,
        ...editForm,
        societies: [{ name: editForm.societies }],
        transaction_direction: editForm.transaction_direction,
      };

      onSave(updatedTransaction);
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
        <div className="bg-gray-800 rounded-2xl shadow-2xl p-8 max-w-md w-full animate-fade-in-up">
          <h2 className="text-2xl font-bold text-teal-300 mb-6 border-b border-teal-700 pb-3">
            Edit Transaction
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Society Dropdown */}
            <div>
              <label className="block text-teal-300 mb-2">Society</label>
              <select
                name="societies"
                value={editForm.societies || ""}
                onChange={handleChange}
                className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
                required
              >
                <option value="">Select Society</option>
                {societies.map((society) => (
                  <option key={society.id} value={society.name}>
                    {society.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Amount Input */}
            <div>
              <label className="block text-teal-300 mb-2">Amount</label>
              <input
                type="number"
                name="amount"
                value={editForm.amount || ""}
                onChange={handleChange}
                className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
              />
            </div>

            {/* Direction Dropdown */}
            <div>
              <label className="block text-teal-300 mb-2">Direction</label>
              <select
                name="transaction_direction"
                value={editForm.transaction_direction || ""}
                onChange={handleChange}
                className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
              >
                <option value="">Select Direction</option>
                {directions.map((dir, index) => (
                  <option key={index} value={dir}>
                    {dir}
                  </option>
                ))}
              </select>
            </div>

            {/* Date Input */}
            <div>
              <label className="block text-teal-300 mb-2">Date</label>
              <input
                type="date"
                name="transaction_date"
                value={
                  editForm.transaction_date
                    ? new Date(editForm.transaction_date)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={handleChange}
                className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
              />
            </div>

            {/* Description Input */}
            <div>
              <label className="block text-teal-300 mb-2">Description</label>
              <input
                type="text"
                name="description"
                value={editForm.description || ""}
                onChange={handleChange}
                className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
              />
            </div>

            <div className="flex justify-end space-x-4 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl p-6">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="flex items-center space-x-4 mb-8">
          <div className="bg-teal-500/10 p-3 rounded-lg">
            <Blocks className="w-6 h-6 text-teal-400" />
          </div>
          <h1 className="text-4xl font-bold text-teal-300">
            Miscellaneous Expenses
          </h1>
        </div>

        {/* Filters */}
        <div className="mb-6 flex space-x-4">
          <div className="flex-1">
            <label className="block text-teal-300 mb-2">
              Description Filter
            </label>
            <select
              value={descriptionFilter}
              onChange={(e) => {
                setDescriptionFilter(e.target.value);
                setCurrentPage(1);
              }}
              className="w-full bg-gray-800 text-teal-300 rounded-lg py-2 px-3"
            >
              <option value="">All Descriptions</option>
              {descriptions.map((desc, index) => (
                <option key={index} value={desc}>
                  {desc}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label className="block text-teal-300 mb-2">Direction Filter</label>
            <select
              value={directionFilter}
              onChange={(e) => {
                setDirectionFilter(e.target.value);
                setCurrentPage(1);
              }}
              className="w-full bg-gray-800 text-teal-300 rounded-lg py-2 px-3"
            >
              <option value="">All Directions</option>
              {directions.map((dir, index) => (
                <option key={index} value={dir}>
                  {dir}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Transactions Table */}
        <div className="bg-gray-800/50 rounded-xl shadow-2xl overflow-hidden">
          {loading ? (
            <div className="flex flex-col items-center justify-center py-16">
              <Loader2 className="w-12 h-12 text-teal-400 animate-spin mb-4" />
              <p className="text-teal-300">Loading transactions...</p>
            </div>
          ) : filteredTransactions.length === 0 ? (
            <div className="text-center py-16 text-teal-300">
              No transactions found
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <table className="w-full text-teal-300">
                  <thead className="bg-gray-900">
                    <tr>
                      {[
                        "Society",
                        "Type",
                        "Amount",
                        "Direction",
                        "Date",
                        "Description",
                        "Actions",
                      ].map((header) => (
                        <th key={header} className="px-6 py-4 text-left">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTransactions.map((transaction) => (
                      <tr
                        key={transaction._id}
                        className="border-b border-gray-700 hover:bg-gray-700/30 transition-colors"
                      >
                        <td className="px-6 py-4">
                          {transaction.societies?.[0]?.name || "No Society"}
                        </td>
                        <td className="px-6 py-4">
                          {transaction.transaction_type}
                        </td>
                        <td className="px-6 py-4">
                          {new Intl.NumberFormat("en-PK", {
                            style: "currency",
                            currency: "PKR",
                          }).format(transaction.amount)}
                        </td>
                        <td className="px-6 py-4">
                          {transaction.transaction_direction || "Not Defined"}
                        </td>
                        <td className="px-6 py-4">
                          {new Date(
                            transaction.transaction_date
                          ).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4">
                          {transaction.description || "No Description"}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex space-x-2">
                            <button
                              onClick={() => setEditingTransaction(transaction)}
                              className="text-teal-400 hover:text-teal-200 transition-colors"
                            >
                              <Edit className="w-5 h-5" />
                            </button>
                            <button
                              onClick={() => handleDelete(transaction._id)}
                              className="text-red-500 hover:text-red-400 transition-colors"
                            >
                              <Trash className="w-5 h-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="flex justify-center items-center space-x-4 py-4">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(1, prev - 1))
                  }
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-teal-500 text-white rounded-lg disabled:bg-gray-600 hover:bg-teal-600 transition-colors"
                >
                  Previous
                </button>
                <span className="text-teal-300">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                  }
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 bg-teal-500 text-white rounded-lg disabled:bg-gray-600 hover:bg-teal-600 transition-colors"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Edit Transaction Modal */}
      {editingTransaction && (
        <EditTransactionModal
          transaction={editingTransaction}
          onClose={() => setEditingTransaction(null)}
          onSave={handleEditTransaction}
        />
      )}
    </div>
  );
};

export default OtherExpense;
