import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookings } from "../store/slices/bookingSlice";

const BookingDetailsInstallments = () => {
  const dispatch = useDispatch();
  const { bookings = [] } = useSelector((state) => state.booking);

  useEffect(() => {
    dispatch(getAllBookings());
  }, [dispatch]);

  const getCustomerName = (customer) => {
    return customer && customer._id && customer._id.username
      ? customer._id.username
      : "Unknown";
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-PK", {
      style: "currency",
      currency: "PKR",
    }).format(amount);
  };

  const fullPaymentBookings = bookings.filter(
    (booking) => booking.payment_mode === "Installment"
  );

  return (
    <div className="flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-slate-900 rounded-2xl shadow-2xl border-2 border-teal-400 overflow-hidden">
        <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
          <h2 className="text-3xl font-extrabold text-white text-center tracking-wide">
            Installment Payment Bookings
          </h2>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-slate-800">
              <tr>
                {[
                  "Booking Number",
                  "Customer",
                  "Amount (PKR)",
                  "Initial Payment",
                  "Status",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-4 text-left text-xs font-semibold text-teal-300 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-slate-700">
              {fullPaymentBookings.map((booking, index) => (
                <tr
                  key={booking._id}
                  className={`transition-all duration-300 hover:bg-slate-700 ${
                    index % 2 === 0 ? "bg-slate-800" : "bg-slate-850"
                  }`}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {booking.booking_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {getCustomerName(booking.customer_id)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {formatCurrency(booking.total_amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-teal-300">
                    {formatCurrency(booking.initial_payment)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`
                      px-3 py-1 rounded-full text-xs font-bold uppercase tracking-wide
                      ${
                        booking.payment_mode === "Pending"
                          ? "bg-red-500 bg-opacity-20 text-red-400"
                          : "bg-yellow-500 bg-opacity-20 text-yellow-400"
                      }
                    `}
                    >
                      {booking.payment_mode}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsInstallments;
