// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import {
//   createGeneralTransaction,
//   fetchGeneralTransactions,
//   updateGeneralTransaction,
//   deleteGeneralTransaction,
//   fetchGeneralTransactionSummary,
// } from "../../api";

// // Async Thunks for API calls

// // Create a new transaction
// export const createTransaction = createAsyncThunk(
//   "transactions/createTransaction",
//   async (transactionData, { rejectWithValue }) => {
//     try {
//       const response = await createGeneralTransaction(transactionData);
//       return response;
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data || "Error creating transaction"
//       );
//     }
//   }
// );

// // Fetch all transactions with optional filters
// export const fetchTransactions = createAsyncThunk(
//   "transactions/fetchTransactions",
//   async (filters = {}, { rejectWithValue }) => {
//     try {
//       const response = await fetchGeneralTransactions(filters);
//       return response;
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data || "Error fetching transactions"
//       );
//     }
//   }
// );

// // Update a specific transaction by ID
// export const updateTransaction = createAsyncThunk(
//   "transactions/updateTransaction",
//   async ({ transactionId, updatedData }, { rejectWithValue }) => {
//     try {
//       const response = await updateGeneralTransaction(
//         transactionId,
//         updatedData
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data || "Error updating transaction"
//       );
//     }
//   }
// );

// // Delete a specific transaction by ID
// export const deleteTransaction = createAsyncThunk(
//   "transactions/deleteTransaction",
//   async (transactionId, { rejectWithValue }) => {
//     try {
//       const response = await deleteGeneralTransaction(transactionId);
//       return response; // Return the ID of the deleted transaction to remove it from the state
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data || "Error deleting transaction"
//       );
//     }
//   }
// );

// // Fetch the transaction summary (total income, expense, and balance)
// export const fetchTransactionSummary = createAsyncThunk(
//   "transactions/fetchTransactionSummary",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await fetchGeneralTransactionSummary();
//       return response;
//     } catch (error) {
//       return rejectWithValue(
//         error.response?.data || "Error fetching transaction summary"
//       );
//     }
//   }
// );

// // Initial state
// const initialState = {
//   transactions: [],
//   summary: null,
//   loading: false,
//   error: null,
// };

// // Slice
// const transactionSlice = createSlice({
//   name: "generalTransactions",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(createTransaction.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(createTransaction.fulfilled, (state, action) => {
//         state.loading = false;
//         state.transactions.push(action.payload);
//       })
//       .addCase(createTransaction.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "Error creating transaction";
//       })

//       .addCase(fetchTransactions.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchTransactions.fulfilled, (state, action) => {
//         state.loading = false;
//         state.transactions = action.payload;
//       })
//       .addCase(fetchTransactions.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "Error fetching transactions";
//       })

//       .addCase(updateTransaction.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(updateTransaction.fulfilled, (state, action) => {
//         state.loading = false;
//         const index = state.transactions.findIndex(
//           (txn) => txn._id === action.payload._id
//         );
//         if (index !== -1) {
//           state.transactions[index] = action.payload;
//         }
//       })
//       .addCase(updateTransaction.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "Error updating transaction";
//       })

//       .addCase(deleteTransaction.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(deleteTransaction.fulfilled, (state, action) => {
//         state.loading = false;
//         state.transactions = state.transactions.filter(
//           (txn) => txn._id !== action.payload
//         );
//       })
//       .addCase(deleteTransaction.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "Error deleting transaction";
//       })

//       .addCase(fetchTransactionSummary.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchTransactionSummary.fulfilled, (state, action) => {
//         state.loading = false;
//         state.summary = action.payload;
//       })
//       .addCase(fetchTransactionSummary.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "An error occurred";
//       });
//   },
// });

// // Export reducer
// export default transactionSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createGeneralTransaction,
  fetchGeneralTransactions,
  updateGeneralTransaction,
  deleteGeneralTransaction,
  fetchGeneralTransactionSummary,
} from "../../api";

// Async Thunks for API calls

// Create a new transaction
// export const createTransaction = createAsyncThunk(
//   "transactions/createTransaction",
//   async (transactionData, { rejectWithValue, getState }) => {
//     try {
//       const { auth } = getState();
//       const user = auth.user;

//       // Log the entire auth state to see what data is available
//       console.log("Redux auth state:", auth);

//       if (!user || !user.role || !user.username) {
//         console.error("User role or user data is missing");
//         return rejectWithValue("User data is missing");
//       }

//       const userRole = user.role;
//       const userDesignation =
//         user.role === "Employee" ? user.designation : null;
//       const username = user.username;

//       console.log("Transaction data to be dispatched:", {
//         ...transactionData,
//         role: userRole,
//         designation: userDesignation,
//         username: username,
//       });

//       const response = await createGeneralTransaction({
//         ...transactionData,
//         role: userRole,
//         designation: userDesignation,
//         username: username,
//       });

//       return response; // Ensure the backend returns the saved transaction data
//     } catch (error) {
//       console.error("Error creating transaction:", error);
//       return rejectWithValue(
//         error.response?.data || "Error creating transaction"
//       );
//     }
//   }
// );

export const createTransaction = createAsyncThunk(
  "transactions/createTransaction",
  async (transactionData, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const user = auth.activeUser; // Changed from auth.user to auth.activeUser

      // Log the entire auth state to see what data is available
      console.log("Redux auth state:", auth);

      if (!user || !user.role || !user.username) {
        console.error("User role or user data is missing");
        return rejectWithValue("User data is missing");
      }

      const userRole = user.role;
      const userDesignation =
        user.role === "Employee" ? user.designation : null;
      const username = user.username;

      console.log("Transaction data to be dispatched:", {
        ...transactionData,
        role: userRole,
        designation: userDesignation,
        username: username,
      });

      const response = await createGeneralTransaction({
        ...transactionData,
        role: userRole,
        designation: userDesignation,
        username: username,
      });

      return response; // Ensure the backend returns the saved transaction data
    } catch (error) {
      console.error("Error creating transaction:", error);
      return rejectWithValue(
        error.response?.data || "Error creating transaction"
      );
    }
  }
);

// Fetch all transactions with optional filters
export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (filters = {}, { rejectWithValue }) => {
    try {
      const response = await fetchGeneralTransactions(filters);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching transactions"
      );
    }
  }
);

// Update a specific transaction by ID
export const updateTransaction = createAsyncThunk(
  "transactions/updateTransaction",
  async ({ transactionId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await updateGeneralTransaction(
        transactionId,
        updatedData
      );
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error updating transaction"
      );
    }
  }
);

// Delete a specific transaction by ID
export const deleteTransaction = createAsyncThunk(
  "transactions/deleteTransaction",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await deleteGeneralTransaction(transactionId);
      return response; // Return the ID of the deleted transaction
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error deleting transaction"
      );
    }
  }
);

// Fetch the transaction summary (total income, expense, and balance)
export const fetchTransactionSummary = createAsyncThunk(
  "transactions/fetchTransactionSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchGeneralTransactionSummary();
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching transaction summary"
      );
    }
  }
);

// Initial state
const initialState = {
  transactions: [],
  summary: null,
  loading: false,
  error: null,
};

// Slice
const transactionSlice = createSlice({
  name: "generalTransactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle createTransaction actions
      .addCase(createTransaction.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error before new request
      })
      .addCase(createTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions.push(action.payload); // Add the new transaction to the state
        console.log("Transaction saved in state:", action.payload); // Add logging
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error creating transaction"; // Show error
        console.error("Error creating transaction:", action.payload);
      })

      // Handle fetchTransactions actions
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error fetching transactions";
      })

      // Handle updateTransaction actions
      .addCase(updateTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTransaction.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.transactions.findIndex(
          (txn) => txn._id === action.payload._id
        );
        if (index !== -1) {
          state.transactions[index] = action.payload;
        }
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error updating transaction";
      })

      // Handle deleteTransaction actions
      .addCase(deleteTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = state.transactions.filter(
          (txn) => txn._id !== action.payload
        );
      })
      .addCase(deleteTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error deleting transaction";
      })

      // Handle fetchTransactionSummary actions
      .addCase(fetchTransactionSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summary = action.payload;
      })
      .addCase(fetchTransactionSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

// Export reducer
export default transactionSlice.reducer;
