import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createBooking, getAllBookings } from "../store/slices/bookingSlice";
import { fetchAllUsers } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import { useSnackbar } from "notistack";

const BookingForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { users, status } = useSelector((state) => state.auth);

  const societies = useSelector((state) => state.societies.societies);

  // State for plot selection and customer search
  const [plotId, setPlotId] = useState("");
  const [selectedSociety, setSelectedSociety] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const plotsPerPage = 10;

  // Fetch required data on component load
  useEffect(() => {
    dispatch(getAllBookings());
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
    dispatch(fetchSocieties());
  }, [dispatch, status, users.length]);

  // Validation Schema
  const BookingValidationSchema = Yup.object().shape({
    society: Yup.string().required("Please select a society"),
    plot: Yup.string().required("Please select a plot"),
    customer: Yup.string().required("Please select a customer"),
    paymentMode: Yup.string()
      .oneOf(["Full", "Installment"], "Invalid payment mode")
      .required("Payment mode is required"),
    bookingAmount: Yup.number()
      .positive("Amount must be positive")
      .required("Total plot amount is required"),
    dealercommision: Yup.number().min(
      0,
      "Dealer commission cannot be negative"
    ),
    // .required("Dealer commission is required"),

    initialPayment: Yup.number().when("paymentMode", {
      is: "Installment",
      then: (schema) =>
        schema
          .positive("Initial payment must be positive")
          .required("Initial payment is required")
          .max(
            Yup.ref("bookingAmount"),
            "Initial payment cannot be more than total amount"
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    installmentYears: Yup.number().when("paymentMode", {
      is: "Installment",
      then: (schema) =>
        schema
          .positive("Installment years must be positive")
          .required("Installment years are required")
          .max(10, "Installment years cannot exceed 10"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  // Handle society change and reset plot selection
  const handleSocietyChange = (e, setFieldValue) => {
    const societyId = e.target.value;
    setSelectedSociety(societyId);
    setPlotId("");
    setCurrentPage(1);
    setFieldValue("society", societyId);
    setFieldValue("plot", "");
  };

  // Toggle plot selection
  const handlePlotSelect = (id, setFieldValue) => {
    const newPlotId = plotId === id ? "" : id;
    setPlotId(newPlotId);
    setFieldValue("plot", newPlotId);
  };

  // Sorting and pagination logic for plots
  const societyPlots =
    societies.find((society) => society._id === selectedSociety)?.plots || [];
  const sortedPlots = [...societyPlots].sort(
    (a, b) => a.plot_number - b.plot_number
  );
  const totalPages = Math.ceil(sortedPlots.length / plotsPerPage);
  const currentPlots = sortedPlots.slice(
    (currentPage - 1) * plotsPerPage,
    currentPage * plotsPerPage
  );

  // Filter users based on role and search query
  const filteredUsers = users
    .filter((user) => user.role === "Customer")
    .filter((user) =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

  // Handle customer selection
  const handleSelectCustomer = (user, setFieldValue) => {
    setSearchQuery(user.username);
    setSelectedCustomer(user);
    setFieldValue("customer", user._id);
    setIsDropdownOpen(false);
  };

  return (
    <div className="p-8 py-12 px-6">
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-xl rounded-lg border border-teal-500 p-8">
          <h2 className="text-4xl font-semibold text-center text-teal-300 mb-6">
            Booking Management
          </h2>

          <Formik
            initialValues={{
              society: "",
              plot: "",
              customer: "",
              paymentMode: "Full",
              bookingAmount: "",
              dealercommision: "",
              initialPayment: "",
              installmentYears: "",
            }}
            validationSchema={BookingValidationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                const bookingData = {
                  plot_id: values.plot,
                  customer_id: values.customer,
                  total_amount: parseFloat(values.bookingAmount),
                  dealer_comission: parseFloat(values.dealerCommission),

                  initial_payment: parseFloat(values.initialPayment) || 0,
                  payment_mode: values.paymentMode,
                  installment_years:
                    values.paymentMode !== "Full"
                      ? parseInt(values.installmentYears)
                      : 0,
                };

                const createdBooking = await dispatch(
                  createBooking(bookingData)
                ).unwrap();

                if (createdBooking && createdBooking.booking) {
                  enqueueSnackbar("Booking created successfully!", {
                    variant: "success",
                  });
                  await dispatch(getAllBookings());
                  resetForm({
                    values: {
                      society: "",
                      plot: "",
                      customer: "",
                      paymentMode: "Full",
                      bookingAmount: "",
                      dealerCommission: "", // Reset this value
                      initialPayment: "",
                      installmentYears: "",
                    },
                  });
                  setPlotId("");
                  setSelectedSociety("");
                  setSelectedCustomer(null);
                  setSearchQuery("");
                }
              } catch (err) {
                enqueueSnackbar(`Error: ${err.message || err}`, {
                  variant: "error",
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form className="space-y-4">
                {/* Society Selection */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Society:
                  </label>
                  <Field
                    as="select"
                    name="society"
                    onChange={(e) => handleSocietyChange(e, setFieldValue)}
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-200 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                  >
                    <option value="">Select Society</option>
                    {societies.map((society) => (
                      <option key={society._id} value={society._id}>
                        {society.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="society"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Available Plots */}
                {selectedSociety && societyPlots.length > 0 && (
                  <div className="overflow-x-auto rounded-xl shadow-xl backdrop-blur-sm overflow-hidden border border-slate-500">
                    <div className="p-6 border-b border-slate-700">
                      <h3 className="text-2xl font-medium text-teal-300 mt-4">
                        Plots Details
                      </h3>
                    </div>
                    <table className="min-w-full bg-slate-700 shadow-lg rounded-lg overflow-hidden mt-4 border border-slate-600">
                      <thead className="bg-slate-900 rounded-xl text-teal-300 uppercase font-semibold">
                        <tr>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Plot Number
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Type
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Block
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Size
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Category
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Booking Status
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Status
                          </th>
                          <th className="py-4 px-6 border-b border-slate-600 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-700">
                        {currentPlots.map((plot, index) => (
                          <tr
                            key={plot._id}
                            className={`border-b transition-colors duration-300 ${
                              index % 2 === 0 ? "bg-slate-700" : "bg-slate-800"
                            } hover:bg-slate-600`}
                          >
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              {plot.plot_number}
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              {plot.plot_type}
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              {plot.block}
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              {plot.size}
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              {plot.category}
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-semibold ${
                                  plot.booking_status === "Booked"
                                    ? "bg-yellow-100 text-yellow-600"
                                    : "bg-green-100 text-green-600"
                                }`}
                              >
                                {plot.booking_status}
                              </span>
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-semibold ${
                                  plot.status === "Available"
                                    ? "bg-green-100 text-green-600"
                                    : "bg-red-100 text-red-600"
                                }`}
                              >
                                {plot.status}
                              </span>
                            </td>
                            <td className="py-4 px-6 border-b text-teal-300 text-left">
                              <button
                                type="button"
                                onClick={() =>
                                  handlePlotSelect(plot._id, setFieldValue)
                                }
                                disabled={
                                  plot.booking_status === "Sold" ||
                                  plot.booking_status === "Booked" ||
                                  plot.booking_status === "Transfer"
                                }
                                className={`text-sm font-semibold rounded px-2 py-1 ${
                                  plotId === plot._id
                                    ? "bg-red-500 text-white"
                                    : plot.booking_status === "Sold" ||
                                      plot.booking_status === "Booked" ||
                                      plot.booking_status === "Transfer"
                                    ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                                    : "bg-white text-teal-500"
                                }`}
                              >
                                {plotId === plot._id ? "Cancel" : "Select"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex justify-center gap-4 mt-6 mb-5">
                      {Array.from(
                        { length: totalPages },
                        (_, index) => index + 1
                      ).map((pageNumber) => (
                        <button
                          key={pageNumber}
                          onClick={() => setCurrentPage(pageNumber)}
                          className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                            pageNumber === currentPage
                              ? "bg-teal-500 text-white"
                              : "bg-slate-600 text-teal-300 hover:bg-slate-500"
                          }`}
                        >
                          {pageNumber}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Plot ID Field */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Plot ID:
                  </label>
                  <Field
                    type="text"
                    name="plot"
                    readOnly
                    value={values.plot}
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-200 rounded-md cursor-not-allowed"
                  />
                  <ErrorMessage
                    name="plot"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Customer Selection */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Customer:
                  </label>
                  <Field
                    type="text"
                    name="customerSearch"
                    disabled={!values.society || !values.plot}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setIsDropdownOpen(true);
                    }}
                    onFocus={() => setIsDropdownOpen(true)}
                    onBlur={() =>
                      setTimeout(() => setIsDropdownOpen(false), 200)
                    }
                    placeholder="Search customer"
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-200 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                  />
                  <ErrorMessage
                    name="customer"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />

                  {/* Customer Dropdown */}
                  {isDropdownOpen && filteredUsers.length > 0 && (
                    <div className="w-full bg-slate-700 text-teal-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg">
                      {filteredUsers.slice(0, 10).map((user) => (
                        <div
                          key={user._id}
                          onClick={() =>
                            handleSelectCustomer(user, setFieldValue)
                          }
                          className="px-4 py-2 cursor-pointer hover:bg-teal-600"
                        >
                          {user.username}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Payment Mode Selection */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Payment Mode:
                  </label>
                  <Field
                    as="select"
                    name="paymentMode"
                    disabled={!values.society || !values.plot}
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                  >
                    <option value="Full">Full</option>
                    <option value="Installment">Installment</option>
                  </Field>
                </div>

                {/* Booking Amount */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Total Plot Amount:
                  </label>
                  <Field
                    type="number"
                    name="bookingAmount"
                    disabled={!values.society || !values.plot}
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                  />
                  <ErrorMessage
                    name="bookingAmount"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                {/* dealer Amount */}
                <div>
                  <label className="block text-teal-300 font-medium">
                    Dealer Commission:
                  </label>
                  <Field
                    type="number"
                    name="dealerCommission"
                    disabled={!values.society || !values.plot}
                    className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                  />
                  <ErrorMessage
                    name="dealerCommission"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                {/* Installment-specific Fields */}
                {values.paymentMode === "Installment" && (
                  <>
                    <div>
                      <label className="block text-teal-300 font-medium">
                        Initial Payment:
                      </label>
                      <Field
                        type="number"
                        name="initialPayment"
                        className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                      />
                      <ErrorMessage
                        name="initialPayment"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>

                    <div>
                      <label className="block text-teal-300 font-medium">
                        Installments Years:
                      </label>
                      <Field
                        type="number"
                        name="installmentYears"
                        className="w-full px-4 py-2 border text-teal-300 bg-slate-700 focus:bg-slate-600 border-teal-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 transition"
                      />
                      <ErrorMessage
                        name="installmentYears"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </>
                )}

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full py-2 px-4 rounded-md transition ${
                    isSubmitting
                      ? "bg-slate-700 text-teal-300"
                      : "bg-teal-500 text-white shadow-lg shadow-teal-500/30 hover:bg-teal-600"
                  }`}
                >
                  {isSubmitting ? "Creating Booking..." : "Create Booking"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
