// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchScholarshipsByStatus,
//   updateScholarship,
// } from "../store/slices/scholarshipSlice";
// import { useSnackbar } from "notistack";
// import { CheckCircle, XCircle, Search, Award, ChevronDown } from "lucide-react";

// const ScholarshipList = () => {
//   const dispatch = useDispatch();
//   const { scholarships, loading, error } = useSelector(
//     (state) => state.scholarship
//   );
//   const { enqueueSnackbar } = useSnackbar();
//   const [searchTerm, setSearchTerm] = useState("");

//   useEffect(() => {
//     dispatch(fetchScholarshipsByStatus("Pending"));
//   }, [dispatch]);

//   const handleShortlist = (id, name) => {
//     dispatch(updateScholarship({ id, statusUpdate: { status: "Shortlisted" } }))
//       .then(() => {
//         enqueueSnackbar(`${name} has been shortlisted!`, {
//           variant: "success",
//           anchorOrigin: { vertical: "bottom", horizontal: "right" },
//         });
//       })
//       .catch((error) => {
//         enqueueSnackbar(error.message || "Failed to shortlist scholarship", {
//           variant: "error",
//           anchorOrigin: { vertical: "bottom", horizontal: "right" },
//         });
//       });
//   };

//   const handleReject = (id, name) => {
//     dispatch(updateScholarship({ id, statusUpdate: { status: "Rejected" } }))
//       .then(() => {
//         enqueueSnackbar(`${name} has been rejected`, {
//           variant: "info",
//           anchorOrigin: { vertical: "bottom", horizontal: "right" },
//         });
//       })
//       .catch((error) => {
//         enqueueSnackbar(error.message || "Failed to reject scholarship", {
//           variant: "error",
//           anchorOrigin: { vertical: "bottom", horizontal: "right" },
//         });
//       });
//   };

//   const filteredScholarships = scholarships.filter(
//     (scholarship) =>
//       scholarship.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       scholarship.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   if (loading)
//     return (
//       <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
//         <div className="relative">
//           <div className="w-16 h-16 border-t-4 border-b-4 border-teal-300 rounded-full animate-spin"></div>
//           <div
//             className="w-16 h-16 border-t-4 border-b-4 border-teal-500/30 rounded-full animate-spin absolute top-0 left-0"
//             style={{ animationDelay: "-0.3s" }}
//           ></div>
//         </div>
//       </div>
//     );

//   if (error)
//     return (
//       <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
//         <div className="max-w-7xl mx-auto bg-red-500/10 border border-red-500/50 rounded-2xl p-8 backdrop-blur-lg shadow-2xl">
//           <div className="flex items-center space-x-4">
//             <XCircle className="w-8 h-8 text-red-400" />
//             <p className="text-red-400 text-lg">
//               {error?.message || "An error occurred"}
//             </p>
//           </div>
//         </div>
//       </div>
//     );

//   return (
//     <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
//       <div className="max-w-7xl mx-auto space-y-8">
//         {/* Header Section */}
//         <div className="flex items-center justify-between">
//           <div className="flex items-center space-x-4">
//             <Award className="w-10 h-10 text-teal-300" />
//             <h2 className="text-4xl font-bold text-teal-300 tracking-tight">
//               Pending Scholarships
//             </h2>
//           </div>

//           {/* Search Bar */}
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search scholarships..."
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="w-64 px-4 py-2 pl-11 rounded-xl bg-slate-700/50 border border-slate-600/50
//                          focus:border-teal-300/50 focus:ring-2 focus:ring-teal-300/20 focus:outline-none
//                          text-slate-300 placeholder-slate-400 transition-all duration-300"
//             />
//             <Search className="w-5 h-5 text-slate-400 absolute left-3 top-2.5" />
//           </div>
//         </div>

//         {/* Main Content */}
//         <div
//           className="relative rounded-2xl border border-slate-700/50 bg-slate-900/30 backdrop-blur-xl
//                         shadow-[0_0_40px_rgba(45,212,191,0.1)] overflow-hidden"
//         >
//           <div className="absolute inset-0 bg-gradient-to-b from-teal-500/5 to-transparent pointer-events-none"></div>

//           <div className="overflow-x-auto">
//             <table className="w-full">
//               <thead>
//                 <tr className="border-b border-slate-700/50">
//                   <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
//                     <div className="flex items-center space-x-2">
//                       <span>Name</span>
//                       <ChevronDown className="w-4 h-4" />
//                     </div>
//                   </th>
//                   <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
//                     Email
//                   </th>
//                   <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
//                     Status
//                   </th>
//                   <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
//                     Actions
//                   </th>
//                 </tr>
//               </thead>
//               <tbody className="divide-y divide-slate-700/50">
//                 {filteredScholarships.map((scholarship) => (
//                   <tr
//                     key={scholarship._id}
//                     className="hover:bg-slate-700/20 transition-colors duration-200"
//                   >
//                     <td className="px-8 py-6">
//                       <div className="flex items-center space-x-3">
//                         <div
//                           className="w-10 h-10 rounded-full bg-gradient-to-br from-teal-400 to-teal-600
//                                       flex items-center justify-center text-white font-bold"
//                         >
//                           {scholarship.name.charAt(0)}
//                         </div>
//                         <span className="text-slate-300 font-medium">
//                           {scholarship.name}
//                         </span>
//                       </div>
//                     </td>
//                     <td className="px-8 py-6">
//                       <span className="text-slate-300">
//                         {scholarship.email}
//                       </span>
//                     </td>
//                     <td className="px-8 py-6">
//                       <span
//                         className={`
//                         inline-flex items-center px-4 py-2 rounded-full text-sm font-medium space-x-2
//                         ${
//                           scholarship.status === "Pending"
//                             ? "bg-yellow-500/20 text-yellow-400 ring-1 ring-yellow-400/50"
//                             : ""
//                         }
//                         ${
//                           scholarship.status === "Shortlisted"
//                             ? "bg-green-500/20 text-green-400 ring-1 ring-green-400/50"
//                             : ""
//                         }
//                         ${
//                           scholarship.status === "Rejected"
//                             ? "bg-red-500/20 text-red-400 ring-1 ring-red-400/50"
//                             : ""
//                         }
//                       `}
//                       >
//                         <span
//                           className={`w-2 h-2 rounded-full
//                           ${
//                             scholarship.status === "Pending"
//                               ? "bg-yellow-400"
//                               : ""
//                           }
//                           ${
//                             scholarship.status === "Shortlisted"
//                               ? "bg-green-400"
//                               : ""
//                           }
//                           ${
//                             scholarship.status === "Rejected"
//                               ? "bg-red-400"
//                               : ""
//                           }
//                         `}
//                         ></span>
//                         <span>{scholarship.status}</span>
//                       </span>
//                     </td>
//                     <td className="px-8 py-6">
//                       <div className="flex space-x-4">
//                         {scholarship.status !== "Shortlisted" &&
//                           scholarship.status !== "Rejected" && (
//                             <button
//                               onClick={() =>
//                                 handleShortlist(
//                                   scholarship._id,
//                                   scholarship.name
//                                 )
//                               }
//                               className="inline-flex items-center px-4 py-2 rounded-lg bg-teal-500/20 text-teal-300
//                                      hover:bg-teal-500/30 active:bg-teal-500/40 transition-all duration-200
//                                      focus:outline-none focus:ring-2 focus:ring-teal-500/50 focus:ring-offset-2
//                                      focus:ring-offset-slate-900 space-x-2 shadow-lg shadow-teal-500/10"
//                             >
//                               <CheckCircle className="w-4 h-4" />
//                               <span>Shortlist</span>
//                             </button>
//                           )}
//                         {scholarship.status !== "Rejected" &&
//                           scholarship.status !== "Shortlisted" && (
//                             <button
//                               onClick={() =>
//                                 handleReject(scholarship._id, scholarship.name)
//                               }
//                               className="inline-flex items-center px-4 py-2 rounded-lg bg-red-500/20 text-red-300
//                                      hover:bg-red-500/30 active:bg-red-500/40 transition-all duration-200
//                                      focus:outline-none focus:ring-2 focus:ring-red-500/50 focus:ring-offset-2
//                                      focus:ring-offset-slate-900 space-x-2 shadow-lg shadow-red-500/10"
//                             >
//                               <XCircle className="w-4 h-4" />
//                               <span>Reject</span>
//                             </button>
//                           )}
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ScholarshipList;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchScholarshipsByStatus,
  updateScholarship,
} from "../store/slices/scholarshipSlice";
import { useSnackbar } from "notistack";
import { CheckCircle, XCircle, Search, Award, ChevronDown } from "lucide-react";

const ScholarshipList = () => {
  const dispatch = useDispatch();
  const { scholarships, loading, error } = useSelector(
    (state) => state.scholarship
  );
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [updatedScholarships, setUpdatedScholarships] = useState(scholarships);

  useEffect(() => {
    dispatch(fetchScholarshipsByStatus("Pending"));
  }, [dispatch]);

  useEffect(() => {
    setUpdatedScholarships(scholarships);
  }, [scholarships]);

  const handleShortlist = (id, name) => {
    dispatch(updateScholarship({ id, statusUpdate: { status: "Shortlisted" } }))
      .then(() => {
        setUpdatedScholarships((prevScholarships) =>
          prevScholarships.filter((scholarship) => scholarship._id !== id)
        );
        enqueueSnackbar(`${name} has been shortlisted!`, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message || "Failed to shortlist scholarship", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  const handleReject = (id, name) => {
    dispatch(updateScholarship({ id, statusUpdate: { status: "Rejected" } }))
      .then(() => {
        setUpdatedScholarships((prevScholarships) =>
          prevScholarships.filter((scholarship) => scholarship._id !== id)
        );
        enqueueSnackbar(`${name} has been rejected`, {
          variant: "info",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message || "Failed to reject scholarship", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  const filteredScholarships = updatedScholarships.filter(
    (scholarship) =>
      scholarship.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      scholarship.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading)
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="relative">
          <div className="w-16 h-16 border-t-4 border-b-4 border-teal-300 rounded-full animate-spin"></div>
          <div
            className="w-16 h-16 border-t-4 border-b-4 border-teal-500/30 rounded-full animate-spin absolute top-0 left-0"
            style={{ animationDelay: "-0.3s" }}
          ></div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
        <div className="max-w-7xl mx-auto bg-red-500/10 border border-red-500/50 rounded-2xl p-8 backdrop-blur-lg shadow-2xl">
          <div className="flex items-center space-x-4">
            <XCircle className="w-8 h-8 text-red-400" />
            <p className="text-red-400 text-lg">
              {error?.message || "An error occurred"}
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header Section */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Award className="w-10 h-10 text-teal-300" />
            <h2 className="text-4xl font-bold text-teal-300 tracking-tight">
              Pending Scholarships
            </h2>
          </div>

          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search scholarships..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-64 px-4 py-2 pl-11 rounded-xl bg-slate-700/50 border border-slate-600/50 
                         focus:border-teal-300/50 focus:ring-2 focus:ring-teal-300/20 focus:outline-none
                         text-slate-300 placeholder-slate-400 transition-all duration-300"
            />
            <Search className="w-5 h-5 text-slate-400 absolute left-3 top-2.5" />
          </div>
        </div>

        {/* Main Content */}
        <div
          className="relative rounded-2xl border border-slate-700/50 bg-slate-900/30 backdrop-blur-xl 
                        shadow-[0_0_40px_rgba(45,212,191,0.1)] overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-b from-teal-500/5 to-transparent pointer-events-none"></div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-slate-700/50">
                  <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
                    <div className="flex items-center space-x-2">
                      <span>Name</span>
                      <ChevronDown className="w-4 h-4" />
                    </div>
                  </th>
                  <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-8 py-5 text-left text-sm font-medium text-teal-300 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-700/50">
                {filteredScholarships.map((scholarship) => (
                  <tr
                    key={scholarship._id}
                    className="hover:bg-slate-700/20 transition-colors duration-200"
                  >
                    <td className="px-8 py-6">
                      <div className="flex items-center space-x-3">
                        <div
                          className="w-10 h-10 rounded-full bg-gradient-to-br from-teal-400 to-teal-600 
                                      flex items-center justify-center text-white font-bold"
                        >
                          {scholarship.name.charAt(0)}
                        </div>
                        <span className="text-slate-300 font-medium">
                          {scholarship.name}
                        </span>
                      </div>
                    </td>
                    <td className="px-8 py-6">
                      <span className="text-slate-300">
                        {scholarship.email}
                      </span>
                    </td>
                    <td className="px-8 py-6">
                      <span
                        className={`
                        inline-flex items-center px-4 py-2 rounded-full text-sm font-medium space-x-2
                        ${
                          scholarship.status === "Pending"
                            ? "bg-yellow-500/20 text-yellow-400 ring-1 ring-yellow-400/50"
                            : ""
                        }
                        ${
                          scholarship.status === "Shortlisted"
                            ? "bg-green-500/20 text-green-400 ring-1 ring-green-400/50"
                            : ""
                        }
                        ${
                          scholarship.status === "Rejected"
                            ? "bg-red-500/20 text-red-400 ring-1 ring-red-400/50"
                            : ""
                        }
                      `}
                      >
                        <span
                          className={`w-2 h-2 rounded-full
                          ${
                            scholarship.status === "Pending"
                              ? "bg-yellow-400"
                              : ""
                          }
                          ${
                            scholarship.status === "Shortlisted"
                              ? "bg-green-400"
                              : ""
                          }
                          ${
                            scholarship.status === "Rejected"
                              ? "bg-red-400"
                              : ""
                          }
                        `}
                        ></span>
                        <span>{scholarship.status}</span>
                      </span>
                    </td>
                    <td className="px-8 py-6">
                      <div className="flex space-x-4">
                        {scholarship.status !== "Shortlisted" &&
                          scholarship.status !== "Rejected" && (
                            <button
                              onClick={() =>
                                handleShortlist(
                                  scholarship._id,
                                  scholarship.name
                                )
                              }
                              className="inline-flex items-center px-4 py-2 rounded-lg bg-teal-500/20 text-teal-300 
                                     hover:bg-teal-500/30 active:bg-teal-500/40 transition-all duration-200
                                     focus:outline-none focus:ring-2 focus:ring-teal-500/50 focus:ring-offset-2 
                                     focus:ring-offset-slate-900 space-x-2 shadow-lg shadow-teal-500/10"
                            >
                              <CheckCircle className="w-4 h-4" />
                              <span>Shortlist</span>
                            </button>
                          )}
                        {scholarship.status !== "Rejected" &&
                          scholarship.status !== "Shortlisted" && (
                            <button
                              onClick={() =>
                                handleReject(scholarship._id, scholarship.name)
                              }
                              className="inline-flex items-center px-4 py-2 rounded-lg bg-red-500/20 text-red-300 
                                     hover:bg-red-500/30 active:bg-red-500/40 transition-all duration-200
                                     focus:outline-none focus:ring-2 focus:ring-red-500/50 focus:ring-offset-2 
                                     focus:ring-offset-slate-900 space-x-2 shadow-lg shadow-red-500/10"
                            >
                              <XCircle className="w-4 h-4" />
                              <span>Reject</span>
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipList;
