// import React, { PureComponent } from "react";
// import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

// // Render active shape for the chart
// const renderActiveShape = (props) => {
//   const RADIAN = Math.PI / 180;
//   const {
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     startAngle,
//     endAngle,
//     fill,
//     payload,
//     percent,
//     value,
//   } = props;
//   const sin = Math.sin(-RADIAN * midAngle);
//   const cos = Math.cos(-RADIAN * midAngle);
//   const sx = cx + (outerRadius + 10) * cos;
//   const sy = cy + (outerRadius + 10) * sin;
//   const mx = cx + (outerRadius + 30) * cos;
//   const my = cy + (outerRadius + 30) * sin;
//   const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//   const ey = my;
//   const textAnchor = cos >= 0 ? "start" : "end";

//   return (
//     <g>
//       <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
//         {payload.name}
//       </text>
//       <Sector
//         cx={cx}
//         cy={cy}
//         innerRadius={innerRadius}
//         outerRadius={outerRadius}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         fill={fill}
//       />
//       <Sector
//         cx={cx}
//         cy={cy}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         innerRadius={outerRadius + 6}
//         outerRadius={outerRadius + 10}
//         fill={fill}
//       />
//       <path
//         d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
//         stroke={fill}
//         fill="none"
//       />
//       <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//       <text
//         x={ex + (cos >= 0 ? 1 : -1) * 12}
//         y={ey}
//         textAnchor={textAnchor}
//         fill="#333"
//       >{`Value: ${value}`}</text>
//       <text
//         x={ex + (cos >= 0 ? 1 : -1) * 12}
//         y={ey}
//         dy={18}
//         textAnchor={textAnchor}
//         fill="#999"
//       >
//         {`(Rate ${(percent * 100).toFixed(2)}%)`}
//       </text>
//     </g>
//   );
// };

// // Main PieChart component
// export default class RevenuePieChart extends PureComponent {
//   state = {
//     activeIndex: 0,
//   };

//   onPieEnter = (_, index) => {
//     this.setState({
//       activeIndex: index,
//     });
//   };

//   render() {
//     const { aggregationData } = this.props;

//     // Prepare data for PieChart
//     const data = [
//       { name: "Income", value: aggregationData.totalIncome || 0 },
//       { name: "Expense", value: aggregationData.totalExpense || 0 },
//       {
//         name: "Total Revenue",
//         value:
//           (aggregationData.totalIncome || 0) -
//           (aggregationData.totalExpense || 0),
//       },
//     ];

//     return (
//       <ResponsiveContainer width="100%" height={400}>
//         <PieChart>
//           <Pie
//             activeIndex={this.state.activeIndex}
//             activeShape={renderActiveShape}
//             data={data}
//             cx="50%"
//             cy="50%"
//             innerRadius={60}
//             outerRadius={80}
//             fill="#82ca9d"
//             dataKey="value"
//             onMouseEnter={this.onPieEnter}
//           />
//         </PieChart>
//       </ResponsiveContainer>
//     );
//   }
// }

import React, { PureComponent } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import { PieChartIcon, LineChart, BarChart } from 'lucide-react'

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor='middle'
        fill='#4fd1c5'
        className='text-lg font-semibold'
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#4fd1c5'
      >{`Value: PKR ${value.toLocaleString()}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#4fd1c5'
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export default class RevenuePieChart extends PureComponent {
  state = {
    activeIndex: 0
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index
    })
  }

  render () {
    const { aggregationData } = this.props

    const data = [
      // { name: 'Total Revenue', value: aggregationData?.totalIncome || 0 },
      { name: 'Expense', value: aggregationData?.totalExpense || 0 },
      {
        name: 'Net Income',
        value:
          (aggregationData?.totalIncome || 0) -
          (aggregationData?.totalExpense || 0)
      }
    ]

    // const totalRevenue = data[2].value
    // const revenueChange = ((totalRevenue / data[0].value) * 100).toFixed(1)

    return (
      <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl shadow-xl'>
        <div className='flex items-center gap-3 mb-8'>
          <PieChartIcon className='text-teal-300 w-8 h-8' />
          <div>
            <h2 className='text-teal-300 text-2xl font-bold'>
              Financial Overview
            </h2>
            <p className='text-teal-300/80 text-sm'>
              Interactive visualization of revenue metrics
            </p>
            {/* <p className='text-teal-300/80 text-sm mt-2'>
              Revenue Change: {revenueChange}%
            </p> */}
          </div>
        </div>

        <div className='bg-slate-800/30 rounded-lg p-2'>
          <ResponsiveContainer width='100%' height={400}>
            <PieChart>
              <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx='50%'
                cy='50%'
                innerRadius={100}
                outerRadius={140}
                fill='#4fd1c5'
                dataKey='value'
                onMouseEnter={this.onPieEnter}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

{
  /* <div className="mt-0 grid grid-cols-1 md:grid-cols-2 gap-4">
  <div className="bg-slate-800/50 p-4 rounded-lg hover:bg-slate-800/70 transition-colors duration-200">
    <div className="flex items-center gap-2 mb-2">
      <LineChart
        className="w-5 h-5 text-teal-300"
        strokeWidth={1.5}
      />
      <h3 className="text-teal-300 font-semibold">
        Revenue Analysis
      </h3>
    </div>
    <p className="text-teal-300/70 text-sm">
      Current revenue represents {revenueChange}% of total income.
      Hover over the chart segments to see detailed breakdowns.
    </p>
  </div>
  <div className="bg-slate-800/50 p-4 rounded-lg hover:bg-slate-800/70 transition-colors duration-200">
    <div className="flex items-center gap-2 mb-2">
      <BarChart className="w-5 h-5 text-teal-300" strokeWidth={1.5} />
      <h3 className="text-teal-300 font-semibold">
        Performance Metrics
      </h3>
    </div>
    <p className="text-teal-300/70 text-sm">
      Interactive pie chart shows the distribution of income,
      expenses, and total revenue. Click on segments for detailed
      analysis.
    </p>
  </div>
</div> */
}
