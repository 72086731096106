import React from "react";
import { ChevronRight, FileText, Download } from "lucide-react";

const SalarySlip = () => {
  const companyInfo = {
    name: "Tech Innovations Pakistan",
    logo: "/api/placeholder/100/100",
    address: "Block F, Gulberg III, Lahore, Pakistan",
  };

  const salaryData = {
    employeeName: "Muhammad Ali",
    employeeId: "EMP-2024-001",
    department: "Software Engineering",
    designation: "Senior Developer",
    payPeriod: "November 2024",
    basicSalary: 175000,
    allowances: {
      house: 35000,
      travel: 15000,
      medical: 10000,
    },
    deductions: {
      providentFund: 12500,
      tax: 25000,
    },
  };

  const calculateTotalEarnings = () => {
    return (
      salaryData.basicSalary +
      salaryData.allowances.house +
      salaryData.allowances.travel +
      salaryData.allowances.medical
    );
  };

  const calculateTotalDeductions = () => {
    return salaryData.deductions.providentFund + salaryData.deductions.tax;
  };

  return (
    <div className="bg-white shadow-2xl rounded-2xl p-8 max-w-2xl mx-auto font-sans border-2 border-green-600">
      <div className="flex justify-between items-center mb-6 pb-4 border-b-2 border-green-600">
        <div className="flex items-center space-x-4">
          <img
            src={companyInfo.logo}
            alt="Company Logo"
            className="h-16 w-16 rounded-full"
          />
          <div>
            <h1 className="text-2xl font-bold text-green-800">
              {companyInfo.name}
            </h1>
            <p className="text-gray-600 text-sm">{companyInfo.address}</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <button className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600">
            <Download size={20} />
          </button>
          <button className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600">
            <FileText size={20} />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h2 className="text-lg font-semibold mb-2 text-green-700">
            Employee Details
          </h2>
          <div className="space-y-2 text-gray-700">
            <p>
              <strong>Name:</strong> {salaryData.employeeName}
            </p>
            <p>
              <strong>Employee ID:</strong> {salaryData.employeeId}
            </p>
            <p>
              <strong>Department:</strong> {salaryData.department}
            </p>
            <p>
              <strong>Designation:</strong> {salaryData.designation}
            </p>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-2 text-green-700">
            Salary Period
          </h2>
          <div className="space-y-2 text-gray-700">
            <p>
              <strong>Pay Period:</strong> {salaryData.payPeriod}
            </p>
            <p>
              <strong>Days Worked:</strong> 30
            </p>
            <p>
              <strong>Payment Method:</strong> Bank Transfer
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className="text-lg font-semibold mb-2 text-green-700">
            Earnings
          </h2>
          <div className="bg-green-50 p-4 rounded-md">
            <div className="flex justify-between mb-2">
              <span>Basic Salary</span>
              <span>Rs. {salaryData.basicSalary}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>House Allowance</span>
              <span>Rs. {salaryData.allowances.house}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Travel Allowance</span>
              <span>Rs. {salaryData.allowances.travel}</span>
            </div>
            <div className="flex justify-between">
              <span>Medical Allowance</span>
              <span>Rs. {salaryData.allowances.medical}</span>
            </div>
            <div className="border-t mt-2 pt-2 font-bold flex justify-between">
              <span>Total Earnings</span>
              <span>Rs. {calculateTotalEarnings()}</span>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-2 text-green-700">
            Deductions
          </h2>
          <div className="bg-red-50 p-4 rounded-md">
            <div className="flex justify-between mb-2">
              <span>Provident Fund</span>
              <span>Rs. {salaryData.deductions.providentFund}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Income Tax</span>
              <span>Rs. {salaryData.deductions.tax}</span>
            </div>
            <div className="border-t mt-2 pt-2 font-bold flex justify-between">
              <span>Total Deductions</span>
              <span>Rs. {calculateTotalDeductions()}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 bg-green-100 p-4 rounded-md text-center">
        <h2 className="text-xl font-bold text-green-800">
          Net Salary: Rs.{" "}
          {calculateTotalEarnings() - calculateTotalDeductions()}
        </h2>
      </div>
    </div>
  );
};

export default SalarySlip;
