import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../store/slices/transactionSlice";

import { Wallet, Loader2, AlertCircle } from "lucide-react";
import SalarySlip from "./component/Salaryslip";

const SalaryExpense = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const salaryTransactions = transactions.filter(
    (t) => t.transaction_type === "Salary Payment"
  );

  // Calculate total pages
  const totalPages = Math.ceil(salaryTransactions.length / itemsPerPage);

  // Pagination Logic
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = salaryTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl p-6">
      <div className="max-w-6xl mx-auto">
        <div className="mb-8">
          <div className="flex items-center space-x-3 mb-4">
            <div className="bg-emerald-500/10 p-3 rounded-lg">
              <Wallet className="w-6 h-6 text-teal-400" />
            </div>
            <h2 className="text-3xl font-bold text-teal-300">
              Salary Transaction
            </h2>
          </div>

          <div className="bg-gray-800/50 rounded-lg shadow-xl p-6">
            {loading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <Loader2 className="w-8 h-8 text-emerald-400 animate-spin mb-4" />
                <p className="text-gray-400">Loading transactions...</p>
              </div>
            ) : salaryTransactions.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-12">
                <Wallet className="w-12 h-12 text-gray-600 mb-4" />
                <p className="text-gray-400 text-lg">
                  No payment transactions found
                </p>
              </div>
            ) : (
              <div className="overflow-x-auto rounded-xl shadow-xl">
                <table className="min-w-full bg-gray-800 rounded-xl shadow-xl text-teal-300">
                  <thead>
                    <tr className="bg-gray-900 text-teal-300">
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Society Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Transaction Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {currentTransactions.map((transaction) => (
                      <tr
                        key={transaction._id}
                        className="transition-colors duration-300 hover:bg-gray-700/30"
                      >
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.employee_id?.username || "No Employee"}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.employee_id?.society?.name ||
                            "No Society"}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {transaction.transaction_type}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {new Intl.NumberFormat("en-PK", {
                            style: "currency",
                            currency: "PKR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(transaction.amount)}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          {new Date(
                            transaction.transaction_date
                          ).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 text-sm text-teal-300">
                          <button>
                            <SalarySlip />{" "}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination Controls */}
                <div className="flex justify-center mt-6">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-emerald-500 text-white rounded-lg disabled:bg-gray-600"
                  >
                    Previous
                  </button>
                  <span className="px-4 py-2 text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-emerald-500 text-white rounded-lg disabled:bg-gray-600"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryExpense;
