import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import printJS from "print-js";
import { fetchResells, deleteResell } from "../store/slices/resellSlice";
import { X, ArrowUp, ArrowDown, Printer, Trash2, Download } from "lucide-react";
import Logo from "../assets/Logo/Logo.jpg";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

const ResellPlotsList = () => {
  const dispatch = useDispatch();
  const { resells } = useSelector((state) => state.resell);
  const [selectedResell, setSelectedResell] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    dispatch(fetchResells());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteResell(id));
  };

  const handleViewSlip = (resell) => {
    setSelectedResell(resell);
  };

  const handlePrintSlip = () => {
    if (!selectedResell) return;

    const printContainer = document.createElement("div");
    printContainer.id = "slip-content";

    // Add print-specific styles
    const printStyles = `
    @media print {
      @page {
        size: A4;
        margin: 0;
        bleed: 0;
      }
      
      body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }
    }
  `;

    printContainer.innerHTML = `
    <style>${printStyles}</style>
    <div style="width: 210mm; height: 297mm; margin: 0; padding: 20mm 15mm; box-sizing: border-box; background-color: white; font-family: 'Inter', sans-serif; position: relative; color: #1a1a1a;">
      <!-- Header Background -->
      <div style="position: absolute; top: 0; left: 0; right: 0; height: 90mm; background: linear-gradient(135deg, #000428 0%, #004e92 100%); -webkit-print-color-adjust: exact;">
  <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 30mm; background: linear-gradient(to bottom right, transparent 49.5%, white 50%);"></div>
</div>

<!-- Content Wrapper -->
<div style="position: relative; height: 100%;">
  <!-- Header -->
  <div style="text-align: center; color: white; padding-top: 10mm; margin-bottom: 15mm;">
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5mm;">
      <!-- Logo -->
      <div style="width: 30mm; height: 30mm; border-radius: 50%; padding: 3mm;">
        <img src="${Logo}" alt="Logo" style="width: 100%; height: 100%; object-fit: fill;">
      </div>
      <!-- Main Title -->
      <div>
        <h1 style="margin: 0; font-size: 38px; font-weight: 900; letter-spacing: 3px; text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);">
          Al-Noor Developers
        </h1>
        <p style="margin: 2mm 0 0; font-size: 16pt; text-transform: uppercase; letter-spacing: 2px;">
          Resell Plot Slip
        </p>
      </div>
      <div style="width: 25mm;"></div> <!-- Spacer for balance -->
    </div>
  </div>



        <!-- Certificate Details -->
<div style="background: white; border-radius: 5mm; padding: 5mm; box-shadow: 0 2mm 5mm rgba(0,0,0,0.1); margin-bottom: 5mm; page-break-inside: avoid;">
  <div style="display: flex; justify-content: space-between; align-items: center; font-size: 12pt; font-family: Arial, sans-serif;">
    <div style="flex: 1; text-align: left;">
      <span style="color: #666; font-weight: bold;">Certificate No:</span>
      <span style="font-weight: 300; margin-left: 5px;">RC-${Math.random()
        .toString(36)
        .substr(2, 8)
        .toUpperCase()}</span>
    </div>
    <div style="flex: 1; text-align: right;">
      <span style="color: #666; font-weight: bold;">Date:</span>
      <span style="font-weight: 300; margin-left: 5px;">${new Date().toLocaleDateString()}</span>
    </div>
  </div>
</div>


        <!-- Main Content Grid -->
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 5mm; margin-bottom: 5mm;">
          <!-- Plot Details -->
          <div style="background: white; border-radius: 3mm; padding: 5mm; box-shadow: 0 1mm 3mm rgba(0,0,0,0.1);">
            <h2 style="margin: 0 0 3mm; font-size: 14pt; color: #000428;">Plot Information</h2>
            <table style="width: 100%; border-collapse: collapse;">
              <tr style="border-bottom: 0.3mm solid #eee;">
                <td style="padding: 2mm 0; color: #666;">Plot Number:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.plot_id?.plot_number || "N/A"
                }</td>
              </tr>
              <tr style="border-bottom: 0.3mm solid #eee;">
                <td style="padding: 2mm 0; color: #666;">Plot Size:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.plot_id?.size
                    ? `${selectedResell.plot_id.size} Marla`
                    : "N/A"
                }</td>
              </tr>
              <tr>
                <td style="padding: 2mm 0; color: #666;">Society:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.plot_id?.society_id?.name || "N/A"
                }</td>
              </tr>
            </table>
          </div>

          <!-- Transaction Details -->
          <div style="background: white; border-radius: 3mm; padding: 5mm; box-shadow: 0 1mm 3mm rgba(0,0,0,0.1);">
            <h2 style="margin: 0 0 3mm; font-size: 14pt; color: #000428;">Transaction Details</h2>
            <table style="width: 100%; border-collapse: collapse;">
              <tr style="border-bottom: 0.3mm solid #eee;">
                <td style="padding: 2mm 0; color: #666;">Previous Owner:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.previous_customer_id?.name || "N/A"
                }</td>
              </tr>
              <tr style="border-bottom: 0.3mm solid #eee;">
                <td style="padding: 2mm 0; color: #666;">New Owner:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.new_customer_id?.name || "N/A"
                }</td>
              </tr>
              <tr>
                <td style="padding: 2mm 0; color: #666;">Transfer Fee:</td>
                <td style="padding: 2mm 0; text-align: right; font-weight: 600;">${
                  selectedResell.resell_fee
                    ? `PKR ${selectedResell.resell_fee.toLocaleString()}`
                    : "N/A"
                }</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Terms -->
        <div style="background: white; border-radius: 3mm; padding: 5mm; box-shadow: 0 1mm 3mm rgba(0,0,0,0.1); margin-bottom: 5mm; page-break-inside: avoid;">
  <h2 style="margin: 0 0 3mm; font-size: 14pt; color: #000428; font-family: Arial, sans-serif;">Terms & Conditions</h2>
  <ol style="margin: 0; padding-left: 1.5em; color: #444; font-size: 9pt; line-height: 1.8; font-family: Arial, sans-serif; list-style-type: decimal;">
    <li style="margin-bottom: 5px; text-align: justify; word-wrap: break-word;">The transfer is final and legally binding upon signatures and payment.</li>
    <li style="margin-bottom: 5px; text-align: justify; word-wrap: break-word;">The new owner accepts the property as-is and assumes all responsibilities.</li>
    <li style="margin-bottom: 5px; text-align: justify; word-wrap: break-word;">Any outstanding dues, taxes, or charges must be cleared before transfer completion.</li>
    <li style="margin-bottom: 0; text-align: justify; word-wrap: break-word;">This certificate is only valid with authorized signatures and the official society stamp.</li>
  </ol>
</div>


        <!-- Signatures -->
        <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 5mm; margin-top: 10mm;">
          <div style="text-align: center;">
            <div style="border-bottom: 0.5mm solid #000; height: 15mm; margin-bottom: 2mm;"></div>
            <p style="margin: 0; font-size: 10pt; font-weight: 600;">Previous Owner</p>
            <p style="margin: 1mm 0 0; font-size: 8pt; color: #666;">Signature & Date</p>
          </div>
          <div style="text-align: center;">
            <div style="border-bottom: 0.5mm solid #000; height: 15mm; margin-bottom: 2mm;"></div>
            <p style="margin: 0; font-size: 10pt; font-weight: 600;">New Owner</p>
            <p style="margin: 1mm 0 0; font-size: 8pt; color: #666;">Signature & Date</p>
          </div>
          <div style="text-align: center;">
            <div style="border-bottom: 0.5mm solid #000; height: 15mm; margin-bottom: 2mm;"></div>
            <p style="margin: 0; font-size: 10pt; font-weight: 600;">Society Representative</p>
            <p style="margin: 1mm 0 0; font-size: 8pt; color: #666;">Signature & Date</p>
            <div style="margin-top: 5mm;">
              <div style="width: 25mm; height: 25mm; border: 0.5mm dashed #000; border-radius: 50%; margin: 0 auto; display: flex; align-items: center; justify-content: center;">
                <span style="font-size: 8pt; color: #666;">Official Stamp</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

    document.body.appendChild(printContainer);

    printJS({
      printable: "slip-content",
      type: "html",
      documentTitle: "Al-Noor Developers - Resell Plot Certificate",
      targetStyles: ["*"],
    });
    document.body.removeChild(printContainer);
  };
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedResells = sortColumn
    ? [...resells].sort((a, b) => {
        const aValue = getValueByPath(a, sortColumn);
        const bValue = getValueByPath(b, sortColumn);
        return sortDirection === "asc"
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      })
    : resells;

  const getValueByPath = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : null), obj);
  };

  const filterTransactionsByPeriod = (transactions, period) => {
    const currentDate = new Date();

    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.resell_date);

      switch (period) {
        case "day":
          return isSameDay(transactionDate, currentDate);
        case "week":
          return isSameWeek(transactionDate, currentDate);
        case "month":
          return isSameMonth(transactionDate, currentDate);
        case "year":
          return isSameYear(transactionDate, currentDate);
        case "all":
        default:
          return true; // No filter, show all
      }
    });
  };

  // Helper functions for date comparisons

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isSameWeek = (date1, date2) => {
    const startOfWeek = getStartOfWeek(date2);
    const endOfWeek = getEndOfWeek(date2);
    return date1 >= startOfWeek && date1 <= endOfWeek;
  };

  const isSameMonth = (date1, date2) => {
    return (
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isSameYear = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear();
  };

  const getStartOfWeek = (date) => {
    const startDate = new Date(date);
    startDate.setDate(date.getDate() - date.getDay()); // Set to the start of the week (Sunday)
    return startDate;
  };

  const getEndOfWeek = (date) => {
    const endDate = new Date(date);
    endDate.setDate(date.getDate() + (6 - date.getDay())); // Set to the end of the week (Saturday)
    return endDate;
  };

  const exportResellsToExcel = (resells, period) => {
    // Map the resell data to the structure you need for export
    const dataToExport = resells.map((resell) => ({
      "Plot ID": resell.plot_id?.plot_number || "N/A",
      "Previous Customer": resell.previous_customer_id?.name || "N/A",
      "New Customer": resell.new_customer_id?.name || "N/A",
      "Resell Fee": resell.resell_fee ? `PKR ${resell.resell_fee}` : "N/A",
      Society: resell.plot_id?.society_id?.name || "N/A",
      "Resell Date": new Date(resell.resell_date || "N/A").toLocaleDateString(),
    }));

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Resell History");

    // Set the filename based on the selected period
    const fileName = `resell_history_${period}.xlsx`;

    // Write the Excel file to disk
    XLSX.writeFile(workbook, fileName);
  };

  const handleExportToExcel = () => {
    Swal.fire({
      title: "Export Resell History",
      html: `
        <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl">
          <p class="text-teal-300 mb-4">Select the period for export:</p>
          <select 
            id="exportPeriod" 
            class="swal2-select bg-slate-800 text-teal-300 border border-slate-700 rounded-md p-2 outline-none focus:ring-2 focus:ring-teal-500">
            <option value="all" class="bg-slate-800 text-teal-300">All Time</option>
            <option value="day" class="bg-slate-800 text-teal-300">Today</option>
            <option value="week" class="bg-slate-800 text-teal-300">This Week</option>
            <option value="month" class="bg-slate-800 text-teal-300">This Month</option>
            <option value="year" class="bg-slate-800 text-teal-300">This Year</option>
          </select>
        </div>
      `,
      confirmButtonText: "Export",
      focusConfirm: false,
      background: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
      customClass: {
        popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
        title: "text-teal-300",
        confirmButton:
          "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
        content: "bg-slate-800 p-6 rounded-lg",
      },
      preConfirm: () => {
        return document.getElementById("exportPeriod").value;
      },
      willOpen: () => {
        const style = document.createElement("style");
        style.innerHTML = `
          #exportPeriod {
            color: #81E6D9; 
          }
          #exportPeriod:focus {
            background-color: #1E293B;
          }
        `;
        document.head.appendChild(style);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const period = result.value;
        const filteredResells = filterTransactionsByPeriod(resells, period); // assuming you have a filter function for resells
        exportResellsToExcel(filteredResells, period);
      }
    });
  };

  return (
    <div className="p-6 max-w-7xl mx-auto bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-2xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-extrabold text-teal-400 tracking-tight">
          Resell History
        </h2>
        <div className="flex space-x-3">
          <button
            onClick={handleExportToExcel}
            className="bg-teal-600 text-white px-4 py-2 rounded-lg hover:bg-teal-500 transition-colors duration-300 flex items-center shadow-md"
          >
            <Download className="mr-2 w-5 h-5" /> Export
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-separate border-spacing-0 bg-slate-800/50 rounded-2xl overflow-hidden shadow-xl">
          <thead>
            <tr className="bg-slate-900/70">
              {[
                { key: "plot_id.plot_number", label: "Plot Number" },
                {
                  key: "previous_customer_id.name",
                  label: "Previous Customer",
                },
                { key: "new_customer_id.name", label: "New Customer" },
                { key: "resell_fee", label: "Resell Fee" },
                { key: "plot_id.society_id.name", label: "Society" },
                { key: "resell_date", label: "Resell Date" },
              ].map(({ key, label }) => (
                <th
                  key={key}
                  className="px-6 py-4 text-left text-teal-300 font-semibold tracking-wider cursor-pointer hover:bg-slate-800/60 transition-colors duration-200"
                  onClick={() => handleSort(key)}
                >
                  <div className="flex items-center space-x-2">
                    <span>{label}</span>
                    {sortColumn === key &&
                      (sortDirection === "asc" ? (
                        <ArrowUp className="w-4 h-4 text-teal-400" />
                      ) : (
                        <ArrowDown className="w-4 h-4 text-teal-400" />
                      ))}
                  </div>
                </th>
              ))}
              <th className="px-6 py-4 text-left text-teal-300 font-semibold tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedResells.map((resell, index) => (
              <tr
                key={resell._id}
                className={`transition-colors duration-300 ${
                  index % 2 === 0 ? "bg-slate-800/50" : "bg-slate-900/30"
                } hover:bg-teal-900/20`}
              >
                <td className="px-6 py-4 text-teal-300">
                  {resell.plot_id?.plot_number || "N/A"}
                </td>
                <td className="px-6 py-4 text-teal-300">
                  {resell.previous_customer_id?.name || "N/A"}
                </td>
                <td className="px-6 py-4 text-teal-300">
                  {resell.new_customer_id?.name || "N/A"}
                </td>
                <td className="px-6 py-4 text-teal-300">
                  {resell.resell_fee ? `PKR ${resell.resell_fee}` : "N/A"}
                </td>
                <td className="px-6 py-4 text-teal-300">
                  {resell.plot_id?.society_id?.name || "N/A"}
                </td>
                <td className="px-6 py-4 text-teal-300">
                  {new Date(resell.resell_date || "N/A").toLocaleDateString()}
                </td>
                <td className="px-6 py-4 flex space-x-2">
                  <button
                    onClick={() => handleViewSlip(resell)}
                    className="bg-teal-600 text-white px-3 py-2 rounded-lg hover:bg-teal-500 transition-colors duration-300 flex items-center shadow-md"
                  >
                    <Printer className="w-4 h-4 mr-2" /> Slip
                  </button>
                  <button
                    onClick={() => handleDelete(resell._id)}
                    className="bg-red-600 text-white px-3 py-2 rounded-lg hover:bg-red-500 transition-colors duration-300 flex items-center shadow-md"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Slip View */}
      {selectedResell && (
        <div className="fixed inset-0 z-50 bg-black/40 backdrop-blur-sm flex items-center justify-center p-4">
          <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-3xl w-full max-w-2xl border border-slate-700 shadow-2xl overflow-hidden animate-fade-in">
            {/* Slip Header */}
            <div className="bg-teal-800/30 p-6 border-b border-slate-700">
              <div className="flex items-center space-x-6">
                <div className="w-24 h-24 bg-teal-300/10 rounded-2xl border border-teal-700 flex items-center justify-center shadow-lg">
                  <img
                    src={Logo}
                    alt="Company Logo"
                    className="w-16 h-16 object-contain rounded-xl"
                  />
                </div>
                <div>
                  <h1 className="text-4xl font-bold text-teal-200 mb-2">
                    Al-Noor Developers
                  </h1>
                  <p className="text-teal-400 text-lg tracking-wider">
                    Resell Plot Certificate
                  </p>
                </div>
              </div>
            </div>

            {/* Slip Details */}
            <div className="p-8">
              <div className="grid grid-cols-2 gap-4">
                {[
                  {
                    label: "Plot Number",
                    value: selectedResell.plot_id?.plot_number || "N/A",
                  },
                  {
                    label: "Plot Size",
                    value: selectedResell.plot_id?.size
                      ? `${selectedResell.plot_id.size} Marla`
                      : "N/A",
                  },
                  {
                    label: "Previous Customer",
                    value: selectedResell.previous_customer_id?.name || "N/A",
                  },
                  {
                    label: "New Customer",
                    value: selectedResell.new_customer_id?.name || "N/A",
                  },
                  {
                    label: "Resell Fee",
                    value: selectedResell.resell_fee
                      ? `PKR ${selectedResell.resell_fee}`
                      : "N/A",
                  },
                  {
                    label: "Resell Date",
                    value: new Date(
                      selectedResell.resell_date || new Date()
                    ).toLocaleDateString(),
                  },
                ].map(({ label, value }) => (
                  <div
                    key={label}
                    className="bg-slate-800/50 rounded-xl p-4 border border-slate-700"
                  >
                    <div className="text-teal-400 font-medium mb-2">
                      {label}
                    </div>
                    <div className="text-teal-300 font-semibold">{value}</div>
                  </div>
                ))}
              </div>
              <div className="mt-6 bg-slate-800/50 rounded-xl p-4 border border-slate-700">
                <div className="text-teal-400 font-medium mb-2">
                  Description
                </div>
                <div className="text-teal-300">
                  {selectedResell.description || "No description provided"}
                </div>
              </div>
            </div>

            {/* Slip Actions */}
            <div className="p-6 border-t border-slate-700 flex justify-between">
              <button
                onClick={handlePrintSlip}
                className="bg-teal-700 text-teal-200 px-6 py-3 rounded-lg hover:bg-teal-600 transition-colors duration-300 flex items-center shadow-lg"
              >
                <Printer className="w-5 h-5 mr-3" /> Print Slip
              </button>
              <button
                onClick={() => setSelectedResell(null)}
                className="bg-red-800/70 text-red-300 px-6 py-3 rounded-lg hover:bg-red-800 transition-colors duration-300 flex items-center shadow-lg"
              >
                <X className="w-5 h-5 mr-3" /> Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResellPlotsList;
