import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createScholarship,
  getAllScholarships,
  updateScholarshipStatus,
  deleteScholarship,
  getAllScholarshipsByStatus,
} from "../../api";

// Thunks for API calls

// Fetch all scholarships
export const fetchScholarships = createAsyncThunk(
  "scholarship/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllScholarships();
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch scholarships by status
export const fetchScholarshipsByStatus = createAsyncThunk(
  "scholarship/fetchByStatus",
  async (status, { rejectWithValue }) => {
    console.log("Fetching scholarships with status:", status); // Verify the status
    try {
      const response = await getAllScholarshipsByStatus(status); // Pass status directly
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add a new scholarship
export const addScholarship = createAsyncThunk(
  "scholarship/add",
  async (scholarshipData, { rejectWithValue }) => {
    try {
      const response = await createScholarship(scholarshipData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update scholarship (including status and amount for approval)
export const updateScholarship = createAsyncThunk(
  "scholarship/update",
  async ({ id, statusUpdate }, { rejectWithValue }) => {
    try {
      const response = await updateScholarshipStatus(id, statusUpdate); // Pass status and amount as part of statusUpdate
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Remove a scholarship
export const removeScholarship = createAsyncThunk(
  "scholarship/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteScholarship(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Initial State
const initialState = {
  scholarships: [],
  loading: false,
  error: null,
};

// Slice
const scholarshipSlice = createSlice({
  name: "scholarship",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch all Scholarships
    builder.addCase(fetchScholarships.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchScholarships.fulfilled, (state, action) => {
      state.scholarships = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchScholarships.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    /// Slice (extraReducers)
    builder.addCase(fetchScholarshipsByStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchScholarshipsByStatus.fulfilled, (state, action) => {
      state.scholarships = action.payload; // Set the scholarships with the filtered ones
      state.loading = false;
    });
    builder.addCase(fetchScholarshipsByStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add Scholarship
    builder.addCase(addScholarship.fulfilled, (state, action) => {
      state.scholarships.push(action.payload.scholarship);
    });

    // Update Scholarship (including status and amount)
    builder.addCase(updateScholarship.fulfilled, (state, action) => {
      const index = state.scholarships.findIndex(
        (s) => s._id === action.payload.scholarship._id
      );
      if (index !== -1) state.scholarships[index] = action.payload.scholarship;
    });

    // Delete Scholarship
    builder.addCase(removeScholarship.fulfilled, (state, action) => {
      state.scholarships = state.scholarships.filter(
        (s) => s._id !== action.meta.arg
      );
    });
    builder.addCase(
      "scholarship/removeScholarshipOptimistic",
      (state, action) => {
        state.scholarships = state.scholarships.filter(
          (scholarship) => scholarship._id !== action.payload
        );
      }
    );
  },
});

export default scholarshipSlice.reducer;
