import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchScholarshipsByStatus,
  updateScholarship,
} from "../store/slices/scholarshipSlice";
import { useSnackbar } from "notistack";
import {
  Award,
  User,
  Mail,
  Clock,
  AlertCircle,
  CheckCircle2,
  XCircle,
  DollarSign,
  Search,
  ArrowUpRight,
  Filter,
  ChevronRight,
} from "lucide-react";

const ShortlistedScholarships = () => {
  const dispatch = useDispatch();
  const { scholarships, loading, error } = useSelector(
    (state) => state.scholarship
  );
  const { enqueueSnackbar } = useSnackbar();

  const [amount, setAmount] = useState("");
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchScholarshipsByStatus("Shortlisted"));
  }, [dispatch]);

  const handleApprove = (scholarship) => {
    setSelectedScholarship(scholarship);
  };

  const handleConfirmApproval = () => {
    if (selectedScholarship && amount) {
      dispatch(
        updateScholarship({
          id: selectedScholarship._id,
          statusUpdate: { status: "Approved", amount: parseFloat(amount) },
        })
      ).then(() => {
        updateScholarship((prevScholarships) =>
          prevScholarships.filter(
            (scholarship) => scholarship._id !== selectedScholarship._id
          )
        );
        enqueueSnackbar("Scholarship approved successfully!", {
          variant: "success",
          icon: <CheckCircle2 className="w-5 h-5" />,
        });
      });

      setAmount("");
      setSelectedScholarship(null);
    } else {
      enqueueSnackbar("Please enter a valid amount", {
        variant: "error",
        icon: <AlertCircle className="w-5 h-5" />,
      });
    }
  };

  const handleReject = (scholarship) => {
    dispatch(
      updateScholarship({
        id: scholarship._id,
        statusUpdate: { status: "Rejected" },
      })
    );
    enqueueSnackbar("Scholarship application rejected", {
      variant: "info",
      icon: <XCircle className="w-5 h-5" />,
    });
  };

  const filteredScholarships = scholarships.filter(
    (scholarship) =>
      scholarship.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      scholarship.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const CurrencySymbol = () => (
    <span className="w-8 h-8 text-teal-300 font-medium">PKR</span>
  );

  const formatPKR = (value) => {
    if (!value) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (loading)
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="relative">
          <div className="w-20 h-20 border-t-4 border-r-4 border-teal-300 rounded-full animate-spin"></div>
          <Award className="w-8 h-8 text-teal-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="bg-red-500/10 px-6 py-4 rounded-xl backdrop-blur-lg border border-red-500/20">
          <div className="flex items-center gap-3 text-red-300">
            <AlertCircle className="w-6 h-6" />
            <p className="font-medium">
              Error: {error?.message || "An error occurred"}
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8">
      <div className="max-w-8xl mx-auto">
        {/* Header Section */}
        <div className="mb-10">
          <div className="flex items-center gap-4 mb-6">
            <div className="p-3 bg-teal-500/10 rounded-xl">
              <Award className="w-8 h-8 text-teal-300" />
            </div>
            <div>
              <h2 className="text-3xl font-bold bg-gradient-to-r from-teal-300 to-emerald-300 bg-clip-text text-transparent">
                Shortlisted Scholarships
              </h2>
              <p className="text-slate-400 mt-1">
                Review and manage scholarship applications
              </p>
            </div>
          </div>

          {/* Search and Filter Bar */}
          <div className="flex gap-4 mb-8">
            <div className="flex-1 relative">
              <Search className="w-5 h-5 text-slate-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
              <input
                type="text"
                placeholder="Search applications..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-12 pr-4 py-3 bg-slate-800/50 backdrop-blur-xl rounded-xl border border-slate-700/50 text-teal-300 placeholder-slate-500 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300"
              />
            </div>
            <button className="px-4 py-3 bg-slate-800/50 backdrop-blur-xl rounded-xl border border-slate-700/50 text-teal-300 hover:bg-slate-700/50 transition-all duration-300 flex items-center gap-2">
              <Filter className="w-5 h-5" />
              Filters
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid gap-6">
          {filteredScholarships.map((scholarship) => (
            <div
              key={scholarship._id}
              className="group bg-slate-800/30 backdrop-blur-xl rounded-xl border border-slate-700/50 hover:bg-slate-800/50 transition-all duration-300"
            >
              <div className="p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-teal-500/10 rounded-xl flex items-center justify-center">
                      <User className="w-6 h-6 text-teal-300" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 flex items-center gap-2">
                        {scholarship.name}
                        <ArrowUpRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                      </h3>
                      <div className="flex items-center gap-3 mt-1">
                        <div className="flex items-center gap-1.5 text-slate-400">
                          <Mail className="w-4 h-4" />
                          <span className="text-sm">{scholarship.email}</span>
                        </div>
                        <div className="w-1 h-1 rounded-full bg-slate-600" />
                        <div className="flex items-center gap-1.5">
                          <Clock className="w-4 h-4 text-teal-300" />
                          <span className="text-sm font-medium px-2 py-1 rounded-lg bg-teal-500/10 text-teal-300">
                            {scholarship.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => handleApprove(scholarship)}
                      className="flex items-center gap-2 px-5 py-2.5 rounded-xl bg-emerald-500/10 text-emerald-300 hover:bg-emerald-500/20 transition-all duration-300 group/button"
                    >
                      <CheckCircle2 className="w-5 h-5" />
                      <span>Approve</span>
                      <ChevronRight className="w-4 h-4 opacity-0 group-hover/button:opacity-100 transition-all duration-300 -mr-2 group-hover/button:mr-0" />
                    </button>
                    <button
                      onClick={() => handleReject(scholarship)}
                      className="flex items-center gap-2 px-5 py-2.5 rounded-xl bg-red-500/10 text-red-300 hover:bg-red-500/20 transition-all duration-300"
                    >
                      <XCircle className="w-5 h-5" />
                      <span>Reject</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Approval Modal */}
        {selectedScholarship && (
          <div className="fixed inset-0 bg-slate-900/90 backdrop-blur-lg flex items-center justify-center p-4 animate-fade">
            <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-2xl shadow-2xl border border-slate-700/50 p-8 w-full max-w-lg animate-scale">
              <div className="flex items-center gap-4 mb-8">
                <div className="p-3 bg-teal-500/10 rounded-xl">
                  <CurrencySymbol />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-teal-300">
                    Scholarship Amount
                  </h3>
                  <p className="text-slate-400 mt-1">
                    Enter the approved amount for {selectedScholarship.name}
                  </p>
                </div>
              </div>

              <div className="relative mb-8">
                <div className="absolute inset-y-0 left-4 flex items-center gap-1 pointer-events-none">
                  <CurrencySymbol />
                </div>
                <input
                  type="text"
                  value={formatPKR(amount)}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setAmount(value);
                  }}
                  className="w-full pl-20 pr-4 py-4 rounded-xl bg-slate-800/50 border border-slate-700/50 text-teal-300 placeholder-slate-500 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300 font-medium"
                  placeholder="Enter amount in PKR"
                />
                <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-slate-400 text-sm">
                  Pakistani Rupee
                </div>
              </div>

              <div className="flex justify-end items-center gap-3">
                <button
                  onClick={() => setSelectedScholarship(null)}
                  className="px-6 py-3 rounded-xl bg-slate-700/50 text-slate-300 hover:bg-slate-700 transition-all duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmApproval}
                  className="flex items-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-teal-500 to-emerald-500 text-white hover:from-teal-600 hover:to-emerald-600 transition-all duration-300"
                >
                  Confirm Approval
                  <ArrowUpRight className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShortlistedScholarships;
