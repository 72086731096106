import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSocieties,
  addSociety,
  removeSociety,
  updateSocietyAction,
} from "../store/slices/societySlice";
import { checkActiveUser } from "../store/slices/authSlice";
import Swal from "sweetalert2";
import {
  Camera,
  MapPin,
  Building2,
  Pencil,
  Trash2,
  X,
  Plus,
} from "lucide-react";

const CreateSociety = () => {
  const dispatch = useDispatch();
  const { activeUser } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);

  const [societyData, setSocietyData] = useState({
    name: "",
    location: "",
    society_image: "",
    created_by: "",
  });
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  const fileInputRef = useRef(null);

  // useEffect(() => {
  //   const initialize = async () => {
  //     await dispatch(fetchSocieties());
  //     const resultAction = await dispatch(checkActiveUser());
  //     if (!resultAction) {
  //       Swal.fire({
  //         icon: "warning",
  //         title: "Authentication Required",
  //         text: "Please log in.",
  //         background:
  //           "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
  //         color: "#FACC15",
  //         confirmButtonColor: "#FBBF24",
  //       });
  //     }
  //   };
  //   initialize();
  // }, [dispatch]);
  useEffect(() => {
    const initialize = async () => {
      // Dispatch the fetchSocieties action only once
      const societiesResult = await dispatch(fetchSocieties());

      // Check if societies data is fetched successfully
      if (societiesResult && societiesResult.error) {
        console.error("Failed to fetch societies:", societiesResult.error);
      }

      // Check the active user
      const resultAction = await dispatch(checkActiveUser());

      // If not authenticated, show the Swal modal
      if (resultAction.payload === null) {
        Swal.fire({
          icon: "warning",
          title: "Authentication Required",
          text: "Please log in.",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#FACC15",
          confirmButtonColor: "#FBBF24",
        });
      }
    };

    // Call the initialize function when the component mounts
    initialize();

    // Only run this effect once on mount, hence empty dependency array
  }, [dispatch]);

  const rows = useMemo(
    () =>
      societies.map((society, index) => ({
        id: society._id || `temp-${index}`,
        name: society.name || "",
        location: society.location || "",
        society_image: society.society_image || "",
        _id: society._id,
      })),
    [societies]
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSocietyData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSocietyData((prev) => ({ ...prev, society_image: reader.result }));
      };
      reader.readAsDataURL(file);
      setIsFieldDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const action = selectedSociety ? updateSocietyAction : addSociety;
    const payload = selectedSociety
      ? { ...societyData, _id: selectedSociety._id, created_by: activeUser.id }
      : { ...societyData, created_by: activeUser.id };

    try {
      const resultAction = await dispatch(action(payload));
      if (action.fulfilled.match(resultAction)) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: selectedSociety
            ? "Society updated successfully!"
            : "Society created successfully!",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#5EEAD4",
          confirmButtonColor: "#008000",
        });

        setSocietyData({
          name: "",
          location: "",
          society_image: "",
          created_by: "",
        });
        setSelectedSociety(null);
        dispatch(fetchSocieties());
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: `Failed to save society: ${error.message}`,
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
        confirmButtonColor: "#f27474",
      });
    }
  };

  const handleEdit = (society) => {
    setSocietyData({
      name: society.name,
      location: society.location,
      society_image: society.society_image,
      created_by: activeUser.id,
    });
    setSelectedSociety(society);
  };

  const handleDelete = (societyId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
      confirmButtonColor: "#FF0000",
      cancelButtonColor: "#64748b",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeSociety(societyId))
          .then(() => {
            Swal.fire({
              icon: "info",
              title: "Deletion is in progress!",
              text: "Request sent to admin.",
              background:
                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
              color: "#5EEAD4",
              confirmButtonColor: "#CCCC00",
            });
            dispatch(fetchSocieties());
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete society.",
              background:
                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
              color: "#5EEAD4",
              confirmButtonColor: "#FF0000",
            });
          });
      }
    });
  };

  return (
    <div className=" bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-12 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-4xl">
      <div className="max-w-7xl mx-auto">
        <div className="bg-slate-900/50 backdrop-blur-xl rounded-2xl shadow-xl p-6 md:p-8 border border-slate-700/50">
          <h1 className="text-3xl font-bold text-teal-300 mb-8 flex items-center gap-2">
            <Building2 className="w-8 h-8" />
            {selectedSociety ? "Edit Society" : "Create New Society"}
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label className="text-teal-300 text-sm font-medium">
                  Society Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={societyData.name}
                  onChange={handleInputChange}
                  className="w-full bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20"
                  placeholder="Enter society name"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-teal-300 text-sm font-medium">
                  Location
                </label>
                <div className="relative">
                  <MapPin className="absolute left-3 top-3 w-5 h-5 text-slate-500" />
                  <input
                    type="text"
                    name="location"
                    value={societyData.location}
                    onChange={handleInputChange}
                    className="w-full bg-slate-800/50 border border-slate-700 rounded-lg pl-10 pr-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20"
                    placeholder="Enter location"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-teal-300 text-sm font-medium">
                Society Image
              </label>
              <div className="flex items-center gap-4">
                <input
                  type="text"
                  name="society_image"
                  value={societyData.society_image}
                  onChange={handleInputChange}
                  disabled={isFieldDisabled}
                  className="flex-1 bg-slate-800/50 border border-slate-700 rounded-lg px-4 py-2.5 text-teal-300 placeholder-slate-500 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/20"
                  placeholder="Image URL or upload"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileUpload}
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="p-2.5 bg-teal-300/10 rounded-lg text-teal-300 hover:bg-teal-300/20 transition-colors"
                >
                  <Camera className="w-5 h-5" />
                </button>
                {isFieldDisabled && (
                  <button
                    type="button"
                    onClick={() => {
                      setSocietyData((prev) => ({
                        ...prev,
                        society_image: "",
                      }));
                      setIsFieldDisabled(false);
                    }}
                    className="p-2.5 bg-red-500/10 rounded-lg text-red-500 hover:bg-red-500/20 transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="w-full md:w-auto px-6 py-3 bg-teal-300 text-slate-900 rounded-lg font-medium hover:bg-teal-200 transition-colors flex items-center justify-center gap-2"
            >
              <Plus className="w-5 h-5" />
              {selectedSociety ? "Save Changes" : "Create Society"}
            </button>
          </form>

          <div className="mt-12">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {rows.map((society) => (
                <div
                  key={society.id}
                  className="bg-slate-800/50 border border-slate-700/50 rounded-xl p-6 hover:bg-slate-800/70 transition-colors"
                >
                  <div className="flex items-start justify-between mb-4">
                    <div className="flex items-center gap-4">
                      <img
                        src={
                          society.society_image || "/default_placeholder.jpg"
                        }
                        alt={society.name}
                        className="w-12 h-12 rounded-full object-cover border-2 border-teal-300/20"
                      />
                      <div>
                        <h3 className="text-teal-300 font-medium">
                          {society.name}
                        </h3>
                        <p className="text-slate-400 text-sm flex items-center gap-1">
                          <MapPin className="w-4 h-4" />
                          {society.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEdit(society)}
                      className="flex-1 py-2 px-4 bg-teal-300/10 text-teal-300 rounded-lg hover:bg-teal-300/20 transition-colors flex items-center justify-center gap-2"
                    >
                      <Pencil className="w-4 h-4" />
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(society._id)}
                      className="flex-1 py-2 px-4 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center justify-center gap-2"
                    >
                      <Trash2 className="w-4 h-4" />
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSociety;
