import React, { useEffect, useMemo, useState } from "react";
import { Menu, Sidebar } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocieties } from "../../../store/slices/societySlice";
import {
  Home,
  LayoutDashboard,
  CreditCard,
  FolderSync,
  Landmark,
  UserPlus,
  Bell,
  Users,
  Building,
  MessageCircle,
  Award,
  ChevronUp,
  ChevronDown,
  CornerDownRight,
} from "lucide-react";
import Logo from "../../../assets/Logo/Logo.png";
import { useSidebarContext } from "./SidebarContext";
import { Box, MenuItem, Typography, alpha, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const sidebarData = [
  {
    title: "Dashboard",
    icon: <LayoutDashboard />,
    link: "/",
    roles: ["Admin", "Employee"],
  },
  {
    icon: <UserPlus />,
    title: "Users",
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "All Users",
        link: "/user-creation/all-users",
        roles: ["Admin"],
      },
      {
        title: "Employees",
        link: "/user-creation/employee-creation",
        roles: ["Admin"],
      },
      {
        title: "Customers",
        link: "/user-creation/customer-creation",
        roles: ["Admin", "Employee"],
      },
      {
        title: "Investors",
        link: "/user-creation/investor-creation",
        roles: ["Admin"],
      },
    ],
  },
  {
    title: "Booking",
    icon: <Home />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Booking",
        link: "/plots-booking/booking-process",
        roles: ["Employee", "Admin"],
      },
      {
        title: "Full Bookings",
        link: "/plots-booking/booking-full",
        roles: ["Employee", "Admin"],
      },
      {
        title: "Installment Bookings",
        link: "/plots-booking/booking-installments",
        roles: ["Employee", "Admin"],
      },
    ],
  },
  {
    title: "Payments",
    icon: <CreditCard />,
    roles: ["Admin", "Employee", "Customer"],
    designation: ["Manager", "Sales Officer"],
    subItems: [
      {
        title: "Installments",
        link: "/installments-payment/manage-installments",
        roles: ["Admin", "Employee"],
      },
      {
        title: "Paid",
        link: "/installments-payment/paid-installments",
        roles: ["Customer"],
      },
      {
        title: "Overdue",
        link: "/installments-payment/overdue-payments",
        roles: ["Admin", "Employee"],
      },
    ],
  },
  {
    title: "Resell",
    icon: <FolderSync />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Resell OwnerShip",
        link: "/plot-transfer-resell/transfer-plot-ownership",
        roles: ["Admin"],
      },

      {
        title: "Resell List",
        link: "/plot-transfer-resell/plot-resale-listings",
        roles: ["Admin", "Employee"],
      },
    ],
  },

  {
    title: "Transfers Registry",
    icon: <FolderSync />,
    roles: ["Admin"],

    subItems: [
      {
        title: "Creation",
        link: "/plot-transfer-resell/plot-transfer-creation",
        roles: ["Admin"],
      },
      {
        title: "Transfer List",
        link: "/plot-transfer-resell/plot-transfer-page",
        roles: ["Admin"],
      },
    ],
  },
  {
    title: "Finance",
    icon: <Landmark />,
    roles: ["Admin"],
    subItems: [
      {
        title: "Create Transaction",
        link: "/create/transaction",
        roles: ["Admin"],
      },
      {
        title: "Finance History",
        link: "/income-expense/transaction",
        roles: ["Admin"],
      },
      {
        title: "Income & Expenses",
        link: "/income-expense/payments-expense",
        roles: ["Admin"],
      },
      {
        title: "Income ",
        link: "/income/transaction",
        roles: ["Admin"],
      },
      {
        title: "Expense",
        link: "/expense/transaction",
        roles: ["Admin"],
      },

      {
        title: "Miscellaneous Expenses",
        link: "/income-expense/other-expense",
        roles: ["Admin"],
      },
    ],
  },
  {
    title: "Transactions",
    icon: <CreditCard />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Form",
        link: "/general-transaction",
        roles: ["Admin", "Employee"],
      },
      {
        title: "List",
        link: "/general-trans-list",
        roles: ["Admin", "Employee"],
      },
      {
        title: "Summary",
        link: "/general-trans-summary",
        roles: ["Admin", "Employee"],
      },
    ],
  },
  {
    title: "Customers",
    icon: <Users />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Customer Info",
        link: "/customer/information",
        roles: ["Employee", "Admin"],
      },
    ],
  },
  // {
  //   title: "Files",
  //   icon: <FileText />,
  //   roles: ["Admin", "Employee"],
  //   designation: ["Manager"],
  //   subItems: [
  //     {
  //       title: "Documents",
  //       roles: ["Admin", "Employee"],
  //     },
  //     { title: "Edit Files", roles: ["Admin"] },
  //   ],
  // },
  {
    title: "Alerts",
    icon: <Bell />,
    roles: ["Admin", "Employee", "Customer", "Investor"],
    subItems: [
      {
        title: "Notifications",
        link: "/alert/notifications",
        roles: ["Admin", "Employee", "Customer", "Investor"],
      },
    ],
  },
  {
    title: "Employees",
    icon: <Users />,
    roles: ["Admin"],
    subItems: [
      {
        title: "Employee Info",
        link: "/employee-management/manage-employee-info",
        roles: ["Admin"],
      },
      {
        title: "Employee Salaries",
        link: "/employee-management/salary_history",
        roles: ["Admin"],
      },
      {
        title: "Salary Adjustments",
        link: "/employee-management/salary_management",
        roles: ["Admin"],
      },
    ],
  },
  {
    title: "Investors",
    icon: <Users />,
    roles: ["Admin"],
    subItems: [
      {
        title: "Investor Info",
        link: "/investor-management/manage-investor-info",
        roles: ["Admin"],
      },
      // { title: "Agreements", roles: ["Admin"] },
    ],
  },
  {
    title: "Society",
    icon: <Building />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Society Creation",
        link: "/housing-society-management/create-social-projects",
        roles: ["Admin"],
      },
      {
        title: "Plot Management",
        link: "/housing-society-management/plots-management",
        roles: ["Admin", "Employee"],
      },
      {
        title: "Plot Listings",
        link: "/housing-society-management/plots-list",
        roles: ["Admin", "Employee"],
      },
    ],
  },
  // {
  //   title: "Reports",
  //   icon: <BarChart />,
  //   roles: ["Admin"],

  //   subItems: [
  //     { title: "Sales", roles: ["Admin"] },
  //     { title: "Income", roles: ["Admin"] },
  //     { title: "Installments", roles: ["Admin"] },
  //   ],
  // },
  {
    title: "Messages",
    icon: <MessageCircle />,
    roles: ["Admin", "Employee"],
    designation: ["Manager", "Sales Officer"],
    subItems: [
      // { title: "Under Maintance", link: "/messages", roles: ["Admin", "Employee"] },
      { title: "Under Maintance" },
    ],
  },
  {
    title: "Scholarships",
    icon: <Award />,
    roles: ["Admin", "Employee"],
    designation: ["Manager"],
    subItems: [
      {
        title: "Form",
        link: "/scholarship-management/form",
        roles: ["Admin", "Employee"],
      },
      {
        title: "List",
        link: "/scholarship-management/list",
        roles: ["Admin", "Employee"],
      },
      {
        title: "ShortList",
        link: "/scholarship-management/shortlist",
        roles: ["Admin", "Employee"],
      },
      {
        title: "Approved",
        link: "/scholarship-management/Approval",
        roles: ["Admin", "Employee"],
      },
    ],
  },
];

const SubItem = ({ title, to, selected, setSelected }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubItemClick = () => {
    setSelected(`sub-${title}`);
    if (to) {
      navigate(to);
      sessionStorage.setItem("lastPath", to);
      localStorage.setItem("selectedMenuItem", `sub-${title}`);
    }
  };

  return (
    <MenuItem
      selected={selected === `sub-${title}`}
      onClick={handleSubItemClick}
      style={{
        position: "relative",
        overflow: "hidden",
        margin: "8px 0",
        paddingLeft: "40px",
        borderRadius: "12px",
        transition: "all 0.3s ease",
        border: `1px solid ${
          selected === `sub-${title}` ? "#57D5C4" : "transparent"
        }`,
        boxShadow:
          selected === `sub-${title}`
            ? "0 6px 12px rgba(87, 213, 196, 0.3)"
            : "none",
        background: selected === `sub-${title}` ? "#57D5C4" : "transparent",
      }}
      sx={{
        "&:hover": {
          borderColor: "#57D5C4",
          backgroundColor: alpha("#57D5C4", 0.1),
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1.5}
        sx={{
          color: selected === `sub-${title}` ? "white" : "#57D5C4",
        }}
      >
        <CornerDownRight
          size={16}
          strokeWidth={2}
          style={{
            opacity: 0.7,
            transform: selected === `sub-${title}` ? "translateX(3px)" : "none",
            transition: "transform 0.3s ease",
          }}
        />
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: selected === `sub-${title}` ? 600 : 500,
            transition: "all 0.3s ease",
            transform: selected === `sub-${title}` ? "translateX(5px)" : "none",
          }}
        >
          {title}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  expanded,
  onExpandClick,
  subItems,
  onClick, // Passed down from parent
}) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    if (!subItems || subItems.length === 0) {
      // Select the menu item
      setSelected(`item-${title}`);
      // Navigate to the specified route
      if (to) {
        navigate(to);
        sessionStorage.setItem("lastPath", to);
        sessionStorage.setItem("selectedMenuItem", `item-${title}`);
      }
    } else {
      // Trigger the expand/collapse logic for subItems
      onExpandClick();
    }
  };

  return (
    <Box>
      <MenuItem
        className={`menu-item ${selected === `item-${title}` ? "active" : ""}`}
        onClick={() => {
          // Execute local and parent `onClick`
          handleItemClick();
          if (onClick) onClick();
        }}
        sx={{
          position: "relative",
          overflow: "hidden",
          margin: "10px 0",
          borderRadius: "13px",
          transition: "all 0.3s ease",
          border: `1px solid ${
            selected === `item-${title}` ? "transparent" : "transparent"
          }`,
          boxShadow:
            selected === `item-${title}`
              ? "0 8px 15px rgba(87, 213, 196, 0.3)"
              : "0 4px 6px rgba(0,0,0,0.1)",
          background: selected === `item-${title}` ? "#57D5C4" : "transparent",
          "&:hover": {
            borderColor: "#57D5C4",
            backgroundColor: alpha("#57D5C4", 0.1),
            boxShadow: "0 6px 12px rgba(87, 213, 196, 0.2)",
          },
        }}
      >
        {/* Main Content */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="90%"
        >
          {/* Icon and Title */}
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              color: selected === `item-${title}` ? "white" : "#57D5C4",
            }}
          >
            {React.cloneElement(icon, {
              strokeWidth: 2,
              style: {
                opacity: 0.8,
                transform:
                  selected === `item-${title}` ? "translateX(3px)" : "none",
                transition: "transform 0.3s ease",
                color: selected === `item-${title}` ? "white" : "#57D5C4",
              },
            })}
            <Typography
              sx={{
                fontWeight: selected === `item-${title}` ? 600 : 400,
                fontSize: "1.1rem",
                transition: "all 0.3s ease",
                transform:
                  selected === `item-${title}` ? "translateX(5px)" : "none",
              }}
            >
              {title}
            </Typography>
          </Box>

          {/* Expand Icon */}
          {subItems && subItems.length > 0 && (
            <Box
              sx={{
                transform: `rotate(${expanded ? "180" : "0"}deg)`,
                transition: "transform 0.3s ease",
                color: selected === `item-${title}` ? "white" : "#57D5C4",
              }}
            >
              {expanded ? <ChevronUp /> : <ChevronDown />}
            </Box>
          )}
        </Box>
      </MenuItem>

      {/* SubItems */}
      {expanded &&
        subItems &&
        subItems.map((subItem, index) => (
          <SubItem
            key={index}
            title={subItem.title}
            to={subItem.link}
            selected={selected}
            setSelected={setSelected}
            onClick={() => {
              setSelected(`item-${subItem.title}`);
              navigate(subItem.link);
              sessionStorage.setItem(
                "selectedMenuItem",
                `item-${subItem.title}`
              );
              sessionStorage.setItem("lastPath", subItem.link);
            }}
          />
        ))}
    </Box>
  );
};

const MyProSidebar = () => {
  const { societies } = useSelector((state) => state.societies);
  const dispatch = useDispatch();
  const { activeSociety, setActiveSociety } = useSidebarContext();
  const { activeUser, isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(() => {
    const storedSelectedMenuItem = localStorage.getItem("selectedMenuItem");
    const defaultSelection = "item-Dashboard";

    // If the current path is root ("/"), ensure Dashboard is selected
    if (location.pathname === "/" || !storedSelectedMenuItem) {
      return defaultSelection;
    }

    return storedSelectedMenuItem || defaultSelection;
  });
  const [isLoading, setIsLoading] = useState(true);

  const [expandedSection, setExpandedSection] = useState(() => {
    return localStorage.getItem("expandedSection") || null;
  });
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // useEffect(() => {
  //   dispatch(fetchSocieties());
  //   if (societies.length > 0 && !activeSociety) {
  //     setActiveSociety(societies[0]);
  //   }

  //   const storedLastPath = localStorage.getItem("lastPath");
  //   const storedSelectedMenuItem = localStorage.getItem("selectedMenuItem");

  //   // Ensure we only navigate to the last path if it's different from the current path
  //   if (
  //     storedLastPath &&
  //     isAuthenticated &&
  //     window.location.pathname !== storedLastPath
  //   ) {
  //     navigate(storedLastPath);
  //   }

  //   if (storedSelectedMenuItem) {
  //     setSelected(storedSelectedMenuItem);
  //   }

  // }, [
  //   dispatch,
  //   societies,
  //   setActiveSociety,
  //   activeSociety,
  //   isAuthenticated,
  //   navigate,
  // ]);
  useEffect(() => {
    const initializeSidebar = async () => {
      try {
        // Dispatch societies fetch
        await dispatch(fetchSocieties());

        // Set first society if no active society
        if (societies.length > 0 && !activeSociety) {
          setActiveSociety(societies[0]);
        }

        // Restore last path and selected menu item
        const storedLastPath = localStorage.getItem("lastPath");
        const storedSelectedMenuItem = localStorage.getItem("selectedMenuItem");

        // Navigate to last path if authenticated
        if (
          storedLastPath &&
          isAuthenticated &&
          window.location.pathname !== storedLastPath
        ) {
          navigate(storedLastPath);
        }

        if (storedSelectedMenuItem) {
          setSelected(storedSelectedMenuItem);
        }

        // Set loading to false after initialization
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to initialize sidebar:", error);
        setIsLoading(false);
      }
    };

    initializeSidebar();
  }, [
    dispatch,
    societies,
    setActiveSociety,
    activeSociety,
    isAuthenticated,
    navigate,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const storedSelectedMenuItem = localStorage.getItem("selectedMenuItem");
      if (!storedSelectedMenuItem) {
        setSelected("item-Dashboard");
      } else {
        setSelected(storedSelectedMenuItem);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("lastPath");
      localStorage.removeItem("expandedSection");
      sessionStorage.removeItem("lastPath");

      const rolePaths = {
        Admin: "/",
        Employee: "/",
        Customer: "/installments-payment/paid-installments",
        Investor: "/alert/notifications",
      };

      const defaultPath = rolePaths[activeUser?.role] || "/login";
      navigate(defaultPath);
      setSelected("item-Dashboard");
      setExpandedSection(null);
    }
  }, [isAuthenticated, navigate, activeUser]);

  useEffect(() => {
    localStorage.setItem("selectedMenuItem", selected);
  }, [selected]);

  useEffect(() => {
    localStorage.setItem("expandedSection", expandedSection || "");
  }, [expandedSection]);

  useEffect(() => {
    // Automatically select Dashboard when on root path
    if (location.pathname === "/") {
      setSelected("item-Dashboard");
      sessionStorage.setItem("selectedMenuItem", "item-Dashboard");
      localStorage.setItem("selectedMenuItem", "item-Dashboard");
    }
  }, [location.pathname]);

  const handleItemClick = (title, to) => {
    const newSelected = `item-${title}`;
    setSelected(newSelected);

    sessionStorage.setItem("selectedMenuItem", newSelected);
    localStorage.setItem("selectedMenuItem", newSelected);

    if (to && window.location.pathname !== to) {
      navigate(to);
      sessionStorage.setItem("lastPath", to);
      localStorage.setItem("lastPath", to);
    }

    if (isMobile) {
      setCollapsed(true);
    }
  };
  const handleExpandClick = (title) => {
    const newExpandedSection = expandedSection === title ? null : title;
    setExpandedSection(newExpandedSection);
  };

  // const currentUserRole = activeUser?.role || "Guest";
  // const currentUserDesignation = activeUser?.designation;

  // const hasAccess = (item) => {
  //   const roleAccess = item.roles ? item.roles.includes(currentUserRole) : true;
  //   const designationAccess = item.designations
  //     ? item.designations.includes(currentUserDesignation)
  //     : true;
  //   return roleAccess && designationAccess;
  // };

  // const filteredSidebarData = sidebarData
  //   .map((section) => {
  //     const filteredSubItems = section.subItems
  //       ? section.subItems.filter((subItem) => hasAccess(subItem))
  //       : [];
  //     return {
  //       ...section,
  //       subItems: filteredSubItems,
  //       visible: hasAccess(section) || filteredSubItems.length > 0,
  //     };
  //   })
  //   .filter((section) => section.visible);
  const filteredSidebarData = useMemo(() => {
    const currentUserRole = activeUser?.role || "Guest";
    const currentUserDesignation = activeUser?.designation;

    const hasAccess = (item) => {
      const roleAccess = item.roles
        ? item.roles.includes(currentUserRole)
        : true;
      const designationAccess = item.designations
        ? item.designations.includes(currentUserDesignation)
        : true;
      return roleAccess && designationAccess;
    };

    return sidebarData
      .map((section) => {
        const filteredSubItems = section.subItems
          ? section.subItems.filter((subItem) => hasAccess(subItem))
          : [];
        return {
          ...section,
          subItems: filteredSubItems,
          visible: hasAccess(section) || filteredSubItems.length > 0,
        };
      })
      .filter((section) => section.visible);
  }, [activeUser]);

  // Render loading or skeleton state
  if (isLoading) {
    return (
      <div className="h-screen flex bg-slate-900 rounded-lg overflow-auto">
        <div className="w-full p-4 animate-pulse">
          <div className="flex justify-center mb-6">
            <div className="w-24 h-24 bg-slate-700 rounded-full"></div>
          </div>
          <div className="space-y-4">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="h-10 bg-slate-700 rounded-lg"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="h-screen flex">
      <div
        className={`
          ${
            isMobile
              ? "bg-slate-900"
              : "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800"
          } 
          rounded-lg overflow-auto h-full
        `}
      >
        <Sidebar
          breakPoint="md"
          backgroundColor={isMobile ? "rgba(15, 23, 42, 0.95)" : "transparent"}
          onBackdropClick={() => setCollapsed(!collapsed)}
        >
          <Menu iconShape="square">
            {/* Profile Section */}
            {!collapsed ? (
              <>
                <div className="mb-4 text-center">
                  <div className="flex justify-center">
                    <img
                      alt="profile-user"
                      width="100"
                      height="130"
                      src={activeSociety?.society_image || Logo}
                      className={`cursor-pointer rounded-full border-4 border-teal-500 p-1 shadow-[10px_10px_20px_rgba(31,41,55,0.4),-10px_-10px_20px_rgba(55,65,81,0.4)]`}
                    />
                  </div>
                  <div className="mt-2 text-teal-300 font-bold text-2xl">
                    {activeSociety && <div>{activeSociety.name}</div>}
                  </div>
                  <div className="text-teal-300 text-lg">
                    {activeSociety?.location || "Bhakkar"}
                  </div>
                </div>
                <div className="w-4/5 mx-auto my-4 border-b border-teal-300" />
              </>
            ) : (
              <div className="flex justify-center items-center p-2">
                <img
                  alt="profile-user"
                  width="50"
                  height="50"
                  src={Logo}
                  className={`cursor-pointer rounded-full border-2 border-teal-500 p-1 shadow-[10px_10px_20px_rgba(31,41,55,0.4),-10px_-10px_20px_rgba(55,65,81,0.4)]`}
                />
                <div className="w-4/5 mx-auto my-2 border-b border-teal-300" />
              </div>
            )}

            {/* Menu Items */}
            <div className={`${collapsed ? "" : "pl-3"}`}>
              {filteredSidebarData.map((section, idx) => (
                <Item
                  key={idx}
                  title={section.title}
                  to={section.link}
                  icon={section.icon}
                  selected={selected}
                  setSelected={setSelected}
                  expanded={expandedSection === section.title}
                  onExpandClick={() => handleExpandClick(section.title)}
                  onClick={() => handleItemClick(section.title, section.link)}
                  subItems={section.subItems}
                />
              ))}
            </div>
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};

export default MyProSidebar;
