import React, { useState, useEffect } from "react";
import { Lock, Unlock, Save, AlertTriangle } from "lucide-react";

const sidebarTabs = [
  { key: "dashboard", label: "Dashboard", icon: "dashboard" },
  { key: "users", label: "Users", icon: "users" },
  { key: "booking", label: "Booking", icon: "calendar" },
  { key: "payments", label: "Payments", icon: "credit-card" },
  { key: "resell", label: "Resell", icon: "repeat" },
  { key: "transfers", label: "Transfers", icon: "send" },
  { key: "finance", label: "Finance", icon: "dollar-sign" },
  { key: "transactions", label: "Transactions", icon: "list" },
  { key: "customers", label: "Customers", icon: "users" },
  { key: "files", label: "Files", icon: "file" },
  { key: "alerts", label: "Alerts", icon: "bell" },
  { key: "employees", label: "Employees", icon: "briefcase" },
  { key: "investors", label: "Investors", icon: "trending-up" },
  { key: "housing", label: "Housing", icon: "home" },
  { key: "messages", label: "Messages", icon: "message-circle" },
  { key: "scholarships", label: "Scholarships", icon: "graduation-cap" },
];

const roles = ["Admin", "Employee", "Customer", "Investor"];

const AccessManagement = () => {
  const [accessConfig, setAccessConfig] = useState({});
  const [selectedRole, setSelectedRole] = useState("Admin");
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);

  useEffect(() => {
    // Fetch initial access data (simulate with mock data)
    const initialAccess = {
      Admin: ["dashboard", "users", "payments"],
      Employee: ["dashboard", "booking", "payments"],
      Customer: ["payments"],
      Investor: ["payments", "alerts"],
    };
    setAccessConfig(initialAccess);
  }, []);

  const toggleAccess = (role, tab) => {
    if (!isFeatureAvailable) return;

    setAccessConfig((prev) => {
      const roleTabs = new Set(prev[role]);
      if (roleTabs.has(tab)) {
        roleTabs.delete(tab);
      } else {
        roleTabs.add(tab);
      }
      return { ...prev, [role]: Array.from(roleTabs) };
    });
  };

  const saveAccessConfig = () => {
    if (!isFeatureAvailable) return;

    // Simulate saving to server
    console.log("Saving access configuration:", accessConfig);
    alert("Access configuration saved successfully!");
  };

  return (
    <div
      className={`p-8 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-300 rounded-xl shadow-2xl`}
    >
      {/* Feature Availability Notice */}
      <div className="mb-6 p-4 bg-yellow-500/10 border-l-4 border-yellow-500 flex items-center space-x-4">
        <AlertTriangle className="text-yellow-500" />
        <p className="text-yellow-400">
          This feature is currently under development and will be available
          soon. Some functionalities are temporarily disabled.
        </p>
      </div>

      <div className="max-w-4xl mx-auto bg-slate-800/50 rounded-xl shadow-2xl overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-6 text-teal-300">
            Access Management
          </h1>

          {/* Role Selection */}
          <div className="mb-6">
            <label
              htmlFor="role-select"
              className="block mb-2 text-sm font-medium"
            >
              Select Role
            </label>
            <select
              id="role-select"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              disabled={!isFeatureAvailable}
              className="w-full px-4 py-2 bg-slate-700 rounded-lg text-teal-300 border border-slate-600 focus:ring-2 focus:ring-teal-500 transition-all"
            >
              {roles.map((role) => (
                <option
                  key={role}
                  value={role}
                  className="bg-slate-800 text-teal-300"
                >
                  {role}
                </option>
              ))}
            </select>
          </div>

          {/* Tabs Grid */}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
            {sidebarTabs.map((tab) => (
              <div
                key={tab.key}
                className={`flex items-center space-x-2 p-2 rounded-lg transition-all ${
                  isFeatureAvailable
                    ? "hover:bg-slate-700 cursor-pointer"
                    : "opacity-50 cursor-not-allowed"
                }`}
                onClick={() => toggleAccess(selectedRole, tab.key)}
              >
                {accessConfig[selectedRole]?.includes(tab.key) ? (
                  <Unlock className="text-green-400 w-5 h-5" />
                ) : (
                  <Lock className="text-red-400 w-5 h-5" />
                )}
                <span
                  className={`${
                    accessConfig[selectedRole]?.includes(tab.key)
                      ? "text-green-300"
                      : "text-red-300"
                  }`}
                >
                  {tab.label}
                </span>
              </div>
            ))}
          </div>

          {/* Save Configuration Button */}
          <button
            onClick={saveAccessConfig}
            disabled={!isFeatureAvailable}
            className={`w-full flex items-center justify-center space-x-2 px-6 py-3 rounded-lg transition-all ${
              isFeatureAvailable
                ? "bg-teal-600 hover:bg-teal-700 text-white"
                : "bg-slate-700 text-slate-500 cursor-not-allowed"
            }`}
          >
            <Save className="w-5 h-5" />
            <span>Save Configuration</span>
          </button>
        </div>
      </div>

      {/* Feature Toggle for Demo */}
      <div className="mt-6 text-center">
        <label className="flex items-center justify-center space-x-2">
          <input
            type="checkbox"
            checked={isFeatureAvailable}
            onChange={() => setIsFeatureAvailable(!isFeatureAvailable)}
            className="form-checkbox text-teal-500"
          />
          <span>Toggle Feature Availability (Demo Purpose)</span>
        </label>
      </div>
    </div>
  );
};

export default AccessManagement;
