import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocieties } from "../store/slices/societySlice";
import { editPlot, deletePlot } from "../store/slices/plotSlice";
import { Edit as EditIcon, Trash2 as DeleteIcon } from "lucide-react";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const PlotList = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);
  const [displayedSociety, setDisplayedSociety] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const plotsPerPage = 15;
  const { enqueueSnackbar } = useSnackbar();

  // Search filters
  const [filters, setFilters] = useState({
    plotNumber: "",
    block: "",
    size: "",
    category: "",
    booking_status: "",
    type: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlot, setCurrentPlot] = useState(null);

  useEffect(() => {
    if (societies.length === 0) {
      dispatch(fetchSocieties());
    }
  }, [dispatch, societies.length]);

  // Filter and paginate plots
  const filteredPlots = (
    displayedSociety
      ? displayedSociety.plots
      : societies.flatMap((s) => s.plots)
  )
    .filter(
      (plot) =>
        (filters.plotNumber === "" ||
          plot.plot_number
            .toString()
            .toLowerCase()
            .includes(filters.plotNumber.toLowerCase())) &&
        (filters.block === "" ||
          plot.block.toLowerCase().includes(filters.block.toLowerCase())) &&
        (filters.size === "" || plot.size.toString().includes(filters.size)) &&
        (filters.booking_status === "" ||
          plot.booking_status
            .toString()
            .toLowerCase()
            .includes(filters.booking_status.toLowerCase())) &&
        (filters.category === "" ||
          plot.category.toLowerCase() === filters.category.toLowerCase()) &&
        (filters.type === "" ||
          plot.plot_type.toLowerCase() === filters.type.toLowerCase())
    )
    .sort((a, b) =>
      a.plot_number.localeCompare(b.plot_number, undefined, { numeric: true })
    );

  const indexOfLastPlot = currentPage * plotsPerPage;
  const indexOfFirstPlot = indexOfLastPlot - plotsPerPage;
  const currentPlots = filteredPlots.slice(indexOfFirstPlot, indexOfLastPlot);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setCurrentPlot(null);
  };

  const openEditModal = (plot) => {
    console.log("Opening modal for plot:", plot);

    if (!plot) {
      console.error("Invalid plot passed into openEditModal");
      return;
    }

    setCurrentPlot(plot);
    setIsModalOpen(true);
  };

  const handleDelete = (plotId) => {
    if (!displayedSociety || !displayedSociety._id) {
      console.error("Society data is missing or invalid");
      alert("Please select a society before deleting a plot.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
      confirmButtonColor: "#EF4444",
      cancelButtonColor: "#64748b",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePlot({ societyId: displayedSociety._id, plotId }))
          .then(() => {
            setDisplayedSociety((prevSociety) => ({
              ...prevSociety,
              plots: prevSociety.plots.filter((plot) => plot._id !== plotId),
            }));
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Plot deleted successfully.",
              background:
                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
              color: "#5EEAD4",
              confirmButtonColor: "#008000",
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: "Failed to delete plot.",
              background:
                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
              color: "#5EEAD4",
              confirmButtonColor: "#f27474",
            });
          });
      }
    });
  };

  const handleEdit = (updatedPlotData) => {
    if (!currentPlot) {
      console.error("currentPlot is null or undefined");
      return;
    }

    const duplicatePlot = displayedSociety.plots.find(
      (plot) =>
        plot.plot_number === updatedPlotData.plot_number &&
        plot._id !== currentPlot._id
    );

    if (duplicatePlot) {
      setErrorMessage(
        `Plot number "${duplicatePlot.plot_number}" already exists in "${displayedSociety.name}"! Please choose a different one.`
      );
      return;
    }
    setErrorMessage("");

    const plotData = {
      societyId: displayedSociety._id,
      plotId: currentPlot._id,
      plotData: updatedPlotData,
    };

    dispatch(editPlot(plotData))
      .then(() => {
        setDisplayedSociety((prevSociety) => ({
          ...prevSociety,
          plots: prevSociety.plots.map((plot) =>
            plot._id === currentPlot._id
              ? { ...plot, ...updatedPlotData }
              : plot
          ),
        }));
        closeEditModal();
        enqueueSnackbar("Plot updated successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .catch((error) => {
        console.error("Error editing plot:", error);
        enqueueSnackbar("An error occurred while editing the plot.", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
  };

  return (
    <div className="bg-slate-800 text-teal-300 p-8 rounded-xl shadow-xl animate-fade-in">
      <div className="max-w-7xl mx-auto space-y-8">
        <h2 className="text-4xl font-bold text-teal-300 text-center mb-12">
          Plot Management System
        </h2>

        {/* Filter Fields */}
        <div className="flex flex-wrap gap-4 mb-6 animate-slide-up">
          <div className="flex-1">
            <input
              type="text"
              name="plotNumber"
              value={filters.plotNumber}
              onChange={handleFilterChange}
              placeholder="Search Plot Number"
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="block"
              value={filters.block}
              onChange={handleFilterChange}
              placeholder="Search Block"
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="size"
              value={filters.size}
              onChange={handleFilterChange}
              placeholder="Search Size"
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            />
          </div>
          <div className="flex-1">
            <select
              name="category"
              value={filters.category}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            >
              <option value="">All Categories</option>
              <option value="General">General</option>
              <option value="Corner">Corner</option>
              <option value="Park Face">Park Face</option>
              <option value="Boulevard">Boulevard</option>
            </select>
          </div>
          <div className="flex-1">
            <input
              type="text"
              name="booking_status"
              value={filters.booking_status}
              onChange={handleFilterChange}
              placeholder="Search Status"
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            />
          </div>
          <div className="flex-1">
            <select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg bg-slate-700 focus:bg-slate-600 focus:outline-none"
            >
              <option value="">All Types</option>
              <option value="Residential">Residential</option>
              <option value="Commercial">Commercial</option>
            </select>
          </div>
        </div>

        <div className="flex flex-wrap gap-3 justify-center">
          <button
            onClick={() => {
              setDisplayedSociety(null);
              setCurrentPage(1);
            }}
            className={`px-6 py-2 rounded-lg font-medium transition-all duration-300 transform hover:scale-105 ${
              displayedSociety === null
                ? "bg-teal-500 text-white shadow-lg shadow-teal-500/30"
                : "bg-slate-700 text-teal-300 hover:bg-slate-600"
            }`}
          >
            All Plots
          </button>

          {societies.map((society) => (
            <button
              key={society._id}
              onClick={() => {
                setDisplayedSociety(society);
                setCurrentPage(1);
                console.log("Displayed society after selecting:", society);
              }}
              className={`px-4 py-2 rounded-lg font-medium transition-all duration-300 transform hover:scale-105  ${
                displayedSociety?._id === society._id
                  ? "bg-teal-500 text-white shadow-lg shadow-teal-500/30"
                  : "bg-slate-700 text-teal-300 hover:bg-slate-600"
              }`}
            >
              {society.name}
            </button>
          ))}
        </div>

        {/* Plot List */}
        <div className="bg-slate-800/50 rounded-xl shadow-xl backdrop-blur-sm overflow-hidden border border-slate-500">
          <div className="p-6 border-b border-slate-700">
            <h3 className="text-2xl font-semibold text-teal-300">
              {displayedSociety
                ? `${displayedSociety.name} Plots`
                : "All Plots"}
            </h3>
          </div>

          <div className="overflow-x-auto bg-slate-700">
            <table className="w-full bg-slate-800 rounded-lg text-teal-300">
              <thead>
                <tr className="bg-slate-900 rounded-lg text-teal-300">
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Plot Number
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Block
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Size
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Type
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Category
                  </th>
                  <th className="px-6 py-4 text-left text-teal-300 font-medium">
                    Status
                  </th>
                  {displayedSociety && (
                    <th className="px-6 py-4 text-left text-teal-300 font-medium">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentPlots.map((plot, index) => (
                  <tr
                    key={index}
                    className={`border-b transition-colors duration-300 ${
                      index % 2 === 0 ? "bg-slate-700" : "bg-slate-800"
                    } hover:bg-slate-600`}
                  >
                    <td className="px-6 py-4 text-teal-300">
                      {plot.plot_number}
                    </td>
                    <td className="px-6 py-4 text-teal-300">{plot.block}</td>
                    <td className="px-6 py-4 text-teal-300">{plot.size}</td>
                    <td className="px-6 py-4 text-teal-300">
                      {plot.plot_type}
                    </td>
                    <td className="px-6 py-4 text-teal-300">{plot.category}</td>
                    <td className="px-6 py-4 text-teal-300">
                      <span
                        className={`
      inline-block px-2 py-1 rounded-full text-sm font-semibold 
      ${plot.booking_status === "Booked" ? "bg-yellow-500 text-yellow-800" : ""}
      ${plot.booking_status === "Transfer" ? "bg-red-500 text-red-100" : ""}
      ${
        plot.booking_status === "Available" ? "bg-green-500 text-green-100" : ""
      }
    `}
                      >
                        {plot.booking_status}
                      </span>
                    </td>

                    {displayedSociety && (
                      <td className="px-6 py-4">
                        <div className="flex gap-3">
                          <button
                            onClick={() => openEditModal(plot)}
                            className="p-2 rounded-lg bg-yellow-500/10 text-yellow-400 hover:bg-yellow-500/20 transition-colors duration-200"
                          >
                            <EditIcon size={18} />
                          </button>
                          <button
                            onClick={() => handleDelete(plot._id)}
                            className="p-2 rounded-lg bg-red-500/10 text-red-400 hover:bg-red-500/20 transition-colors duration-200"
                          >
                            <DeleteIcon size={18} />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex justify-center gap-4 mt-6">
          {Array.from(
            { length: Math.ceil(filteredPlots.length / plotsPerPage) },
            (_, index) => index + 1
          ).map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                pageNumber === currentPage
                  ? "bg-teal-500 text-white"
                  : "bg-slate-600 text-teal-300 hover:bg-slate-500"
              }`}
            >
              {pageNumber}
            </button>
          ))}
        </div>

        {/* Edit Plot Modal */}

        {isModalOpen && (
          <div className="fixed inset-0 top-0 left-0  bg-slate-900/80 backdrop-blur-sm flex items-center justify-center p-6 z-50 overflow-y-auto">
            <div className="bg-slate-800 rounded-xl shadow-xl w-full max-w-md transform transition-all duration-300 scale-100">
              <div className="p-6 border-b border-slate-700">
                <h3 className="text-2xl font-semibold text-teal-300">
                  Edit Plot Details
                </h3>
              </div>

              <div className="p-6 space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Number
                  </label>
                  <input
                    type="text"
                    value={currentPlot.plot_number}
                    onChange={(e) => {
                      setCurrentPlot({
                        ...currentPlot,
                        plot_number: e.target.value,
                      });
                      setErrorMessage("");
                    }}
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                  {errorMessage && (
                    <p className="mt-1 text-sm text-red-500">{errorMessage}</p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Block
                  </label>
                  <input
                    type="text"
                    value={currentPlot.block}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        block: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Size (Marla)
                  </label>
                  <input
                    type="text"
                    value={currentPlot.size}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        size: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Type
                  </label>
                  <select
                    value={currentPlot.plot_type}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        plot_type: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  >
                    <option value="">All Types</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Category
                  </label>
                  <select
                    value={currentPlot.category}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        category: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  >
                    <option value="General">General</option>
                    <option value="Corner">Corner</option>
                    <option value="Park Face">Park Face</option>
                    <option value="Boulevard">Boulevard</option>
                  </select>
                </div>
              </div>

              <div className="p-6 border-t border-slate-700 flex justify-end gap-4">
                <button
                  onClick={closeEditModal}
                  className="px-6 py-2 rounded-lg bg-slate-700 text-teal-300 hover:bg-slate-600 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleEdit(currentPlot)}
                  className="px-6 py-2 rounded-lg bg-teal-500 text-white hover:bg-teal-600 transition-colors duration-200"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlotList;
