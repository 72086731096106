import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendMessage, fetchMessages, markMessagesAsRead } from "../../api";

// Async Thunks for Messaging API
// Fetch messages between two users
export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async (otherUserId, { rejectWithValue }) => {
    try {
      const response = await fetchMessages(otherUserId);
      return response.messages; // Assuming API response has a `messages` key
    } catch (error) {
      return rejectWithValue(error.message || "Error fetching messages");
    }
  }
);

// Send a new message
export const postMessage = createAsyncThunk(
  "messages/postMessage",
  async ({ formData }, { rejectWithValue }) => {
    try {
      // Directly send the formData as it already includes receiverId and content
      const response = await sendMessage(formData); // Send formData to API
      return response.message; // Assuming the API response has a `message` key
    } catch (error) {
      return rejectWithValue(error.message || "Error sending message");
    }
  }
);

// Mark messages as read
export const markAsRead = createAsyncThunk(
  "messages/markAsRead",
  async (otherUserId, { rejectWithValue }) => {
    try {
      const response = await markMessagesAsRead(otherUserId);
      return response.message; // Assuming API response has a `message` key
    } catch (error) {
      return rejectWithValue(error.message || "Error marking messages as read");
    }
  }
);

// Redux Slice
const messageSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [], // Stores the conversation
    status: "idle", // idle | loading | succeeded | failed
    error: null, // Stores error messages
  },
  reducers: {
    resetMessages(state) {
      state.messages = [];
      state.status = "idle";
      state.error = null;
    },
    // Action for adding real-time messages to the Redux state
    addRealTimeMessage(state, action) {
      state.messages.push(action.payload); // Add the new message to the state
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching messages
      .addCase(getMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Handle sending a message
      .addCase(postMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages.push(action.payload); // Append the new message
      })
      .addCase(postMessage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Handle marking messages as read
      .addCase(markAsRead.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetMessages, addRealTimeMessage } = messageSlice.actions;

export default messageSlice.reducer;
