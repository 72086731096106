// import React, { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { addTransaction } from '../store/slices/transactionSlice'
// import { useNavigate } from 'react-router-dom'

// const TransactionCreate = () => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const { loading, error } = useSelector(state => state.transactions)
//   const { societies } = useSelector(state => state.societies)
//   const { activeUser } = useSelector(state => state.auth)
//   const [customTransactionTypes, setCustomTransactionTypes] = useState([])

//   const [formData, setFormData] = useState({
//     societies: [],
//     user_id: '',
//     booking_id: '',
//     employee_id: '',
//     amount: '',
//     transaction_type: '',
//     transaction_direction: '',
//     payment_method: 'Bank Transfer',
//     description: ''
//   })

//   const handleChange = e => {
//     const { name, value } = e.target
//     setFormData({
//       ...formData,
//       [name]: value
//     })
//   }

//   const handleSubmit = async e => {
//     e.preventDefault()

//     if (!formData.amount || !formData.transaction_direction) {
//       alert('Please fill in all required fields.')
//       return
//     }

//     if (
//       formData.transaction_type &&
//       formData.transaction_type !== 'other' &&
//       !customTransactionTypes.includes(formData.transaction_type)
//     ) {
//       if (formData.transaction_type !== 'other') {
//         setCustomTransactionTypes([
//           ...customTransactionTypes,
//           formData.transaction_type
//         ])
//       }
//     }

//     if (
//       formData.transaction_type === 'other' &&
//       !formData.transaction_type_custom
//     ) {
//       alert('Please provide a custom transaction type.')
//       return
//     }

//     const updatedFormData = {
//       ...formData,
//       user_id: activeUser.id,
//       transaction_type:
//         formData.transaction_type === 'other'
//           ? formData.transaction_type_custom
//           : formData.transaction_type
//     }

//     try {
//       const resultAction = await dispatch(addTransaction(updatedFormData))
//       if (addTransaction.fulfilled.match(resultAction)) {
//         alert('Transaction successfully added!')
//         setFormData({
//           societies: [],
//           user_id: '',
//           booking_id: '',
//           employee_id: '',
//           amount: '',
//           transaction_type: '',
//           transaction_direction: '',
//           payment_method: 'Bank Transfer',
//           description: ''
//         })
//         navigate('/create/transaction')
//       }
//     } catch (err) {
//       console.error('Error creating transaction:', err)
//     }
//   }

//   const inputClasses =
//     'mt-1 block w-full px-4 py-3 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl focus:ring-2 focus:ring-teal-500/50 focus:border-teal-400/30 text-gray-100 placeholder-gray-400 transition-all duration-300 hover:border-teal-400/20 shadow-lg shadow-gray-900/20'

//   const labelClasses =
//     'block text-sm font-medium text-teal-400 mb-2 tracking-wide'

//   return (
//     <div className='min-h-screen bg-gray-900 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8'>
//       <div className='max-w-4xl mx-auto'>
//         <div className='bg-gray-800/30 backdrop-blur-xl rounded-3xl shadow-2xl p-8 border border-gray-700/30'>
//           <div className='mb-10 text-center'>
//             <h1 className='text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-teal-200 mb-2'>
//               Create Society Transaction
//             </h1>
//             <p className='text-gray-400'>Enter the transaction details below</p>
//           </div>

//           {error && (
//             <div className='mb-6 p-4 bg-red-900/20 backdrop-blur-sm border border-red-500/30 rounded-xl text-red-400 animate-pulse'>
//               {error}
//             </div>
//           )}

//           <form className='space-y-8' onSubmit={handleSubmit}>
//             <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
//               <div className='group'>
//                 <label htmlFor='societies' className={labelClasses}>
//                   Societies
//                 </label>
//                 <select
//                   id='societies'
//                   name='societies'
//                   value={formData.societies}
//                   onChange={handleChange}
//                   className={`${inputClasses} cursor-pointer`}
//                 >
//                   <option value=''>Select Society</option>
//                   {societies.map(society => (
//                     <option key={society._id} value={society._id}>
//                       {society.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <div className='group'>
//                 <label htmlFor='amount' className={labelClasses}>
//                   Amount
//                 </label>
//                 <input
//                   type='number'
//                   id='amount'
//                   name='amount'
//                   value={formData.amount}
//                   onChange={handleChange}
//                   required
//                   className={inputClasses}
//                   placeholder='Enter amount'
//                 />
//               </div>

//               {/* Transaction Type */}
//               <div>
//                 <label htmlFor='transaction_type' className={labelClasses}>
//                   Transaction Type
//                 </label>
//                 <div className='flex flex-col gap-2'>
//                   <select
//                     id='transaction_type'
//                     name='transaction_type'
//                     value={formData.transaction_type}
//                     onChange={handleChange}
//                     required
//                     className={inputClasses}
//                   >
//                     <option value=''>Select Type</option>
//                     <option value='Full Payment'>Full Payment</option>
//                     <option value='Partial Payment'>Partial Payment</option>
//                     <option value='Installment Payment'>
//                       Installment Payment
//                     </option>
//                     <option value='Salary Payment'>Salary Payment</option>
//                     <option value='Expense Payment'>Expense Payment</option>
//                     <option value='Resell Payment'>Resell Payment</option>
//                     <option value='other'>Other</option>
//                     {customTransactionTypes.map((type, index) => (
//                       <option key={index} value={type}>
//                         {type}
//                       </option>
//                     ))}
//                   </select>

//                   {/* If the user prefers to type their own type */}
//                   {formData.transaction_type === 'other' && (
//                     <input
//                       type='text'
//                       id='transaction_type_custom'
//                       name='transaction_type_custom'
//                       value={formData.transaction_type_custom || ''}
//                       onChange={e => {
//                         handleChange(e)
//                         // Dynamically add to custom types
//                         if (!customTransactionTypes.includes(e.target.value)) {
//                           setCustomTransactionTypes([
//                             ...customTransactionTypes,
//                             e.target.value
//                           ])
//                         }
//                       }}
//                       className={inputClasses}
//                       placeholder='Enter custom transaction type'
//                     />
//                   )}
//                 </div>
//               </div>

//               <div className='group'>
//                 <label htmlFor='transaction_direction' className={labelClasses}>
//                   Transaction Direction
//                 </label>
//                 <select
//                   id='transaction_direction'
//                   name='transaction_direction'
//                   value={formData.transaction_direction}
//                   onChange={handleChange}
//                   required
//                   className={`${inputClasses} cursor-pointer`}
//                 >
//                   <option value=''>Select Direction</option>
//                   <option value='Income'>Income</option>
//                   <option value='Expense'>Expense</option>
//                 </select>
//               </div>

//               <div className='md:col-span-2 group'>
//                 <label htmlFor='payment_method' className={labelClasses}>
//                   Payment Method
//                 </label>
//                 <input
//                   type='text'
//                   id='payment_method'
//                   name='payment_method'
//                   value={formData.payment_method}
//                   onChange={handleChange}
//                   className={inputClasses}
//                   placeholder='Enter payment method'
//                 />
//               </div>

//               <div className='md:col-span-2 group'>
//                 <label htmlFor='description' className={labelClasses}>
//                   Description (Optional)
//                 </label>
//                 <textarea
//                   id='description'
//                   name='description'
//                   value={formData.description}
//                   onChange={handleChange}
//                   rows={4}
//                   className={inputClasses}
//                   placeholder='Enter additional details about the transaction'
//                 />
//               </div>
//             </div>

//             <div className='pt-6'>
//               <button
//                 type='submit'
//                 disabled={loading}
//                 className='w-full bg-gradient-to-r from-teal-500 to-teal-400 text-white py-4 px-6 rounded-xl font-medium
//                          hover:from-teal-400 hover:to-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500
//                          focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-300
//                          disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-teal-500/20
//                          hover:shadow-xl hover:shadow-teal-500/30 transform hover:-translate-y-0.5'
//               >
//                 {loading ? (
//                   <span className='flex items-center justify-center'>
//                     <svg
//                       className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
//                       xmlns='http://www.w3.org/2000/svg'
//                       fill='none'
//                       viewBox='0 0 24 24'
//                     >
//                       <circle
//                         className='opacity-25'
//                         cx='12'
//                         cy='12'
//                         r='10'
//                         stroke='currentColor'
//                         strokeWidth='4'
//                       ></circle>
//                       <path
//                         className='opacity-75'
//                         fill='currentColor'
//                         d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
//                       ></path>
//                     </svg>
//                     Creating...
//                   </span>
//                 ) : (
//                   'Create Transaction'
//                 )}
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default TransactionCreate

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { addTransaction } from "../store/slices/transactionSlice";
import { useNavigate } from "react-router-dom";

const TransactionCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { error } = useSelector((state) => state.transactions);
  const { societies } = useSelector((state) => state.societies);
  const { activeUser } = useSelector((state) => state.auth);
  const [customTransactionTypes, setCustomTransactionTypes] = useState([]);

  // Validation Schema with Pakistani-specific validations
  const TransactionValidationSchema = Yup.object().shape({
    societies: Yup.string().required("سوسائٹی کا انتخاب کریں"),
    amount: Yup.number()
      .required("رقم درج کریں")
      .positive("رقم مثبت ہونی چاہیے")
      .max(1000000, "زیادہ سے زیادہ رقم 1,000,000 ہو سکتی ہے"),
    transaction_type: Yup.string().required("ٹرانزیکشن کی قسم منتخب کریں"),
    transaction_direction: Yup.string().required("ٹرانزیکشن کی سمت منتخب کریں"),
    transaction_type_custom: Yup.string().when("transaction_type", {
      is: "other",
      then: (schema) => schema.required("کسٹم ٹرانزیکشن کی قسم درج کریں"),
      otherwise: (schema) => schema.notRequired(),
    }),
    payment_method: Yup.string().required("ادائیگی کا طریقہ درج کریں"),
    description: Yup.string().max(500, "تفصیل 500 حروف سے زیادہ نہیں ہو سکتی"),
  });
  //   const TransactionValidationSchema = Yup.object().shape({
  //     societies: Yup.string().required("Please select a society"),
  //     amount: Yup.number()
  //       .required("Please enter the amount")
  //       .positive("Amount must be a positive number")
  //       .max(1000000, "Maximum amount cannot exceed 1,000,000"),
  //     transaction_type: Yup.string().required("Please select a transaction type"),
  //     transaction_direction: Yup.string().required("Please select a transaction direction"),
  //     transaction_type_custom: Yup.string().when("transaction_type", {
  //       is: "other",
  //       then: Yup.string().required("Please enter a custom transaction type"),
  //     }),
  //     payment_method: Yup.string().required("Please enter a payment method"),
  //     description: Yup.string().max(500, "Description cannot exceed 500 characters"),
  // });

  // Input and Label Classes
  const inputClasses =
    "mt-1 block w-full px-4 py-3 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl focus:ring-2 focus:ring-teal-500/50 focus:border-teal-400/30 text-gray-100 placeholder-gray-400 transition-all duration-300 hover:border-teal-400/20 shadow-lg shadow-gray-900/20";

  const labelClasses =
    "block text-sm font-medium text-teal-400 mb-2 tracking-wide";

  const errorClasses = "text-red-500 text-xs mt-1";

  return (
    <div className="min-h-screen bg-gray-900 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-gray-800/30 backdrop-blur-xl rounded-3xl shadow-2xl p-8 border border-gray-700/30">
          <div className="mb-10 text-center">
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-teal-200 mb-2">
              Create Society Transaction
            </h1>
            <p className="text-gray-400">Enter the transaction details below</p>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-900/20 backdrop-blur-sm border border-red-500/30 rounded-xl text-red-400 animate-pulse">
              {error}
            </div>
          )}

          <Formik
            initialValues={{
              societies: "",
              amount: "",
              transaction_type: "",
              transaction_type_custom: "",
              transaction_direction: "",
              payment_method: "Bank Transfer",
              description: "",
            }}
            validationSchema={TransactionValidationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                // Handle custom transaction type
                const finalTransactionType =
                  values.transaction_type === "other"
                    ? values.transaction_type_custom
                    : values.transaction_type;

                const updatedFormData = {
                  ...values,
                  user_id: activeUser.id,
                  transaction_type: finalTransactionType,
                  societies: [values.societies], // Ensure it's an array
                };

                const resultAction = await dispatch(
                  addTransaction(updatedFormData)
                );

                if (addTransaction.fulfilled.match(resultAction)) {
                  enqueueSnackbar("Transaction successfully added!", {
                    variant: "success",
                  });
                  resetForm();
                  navigate("/create/transaction");
                } else {
                  enqueueSnackbar("Failed to add transaction", {
                    variant: "error",
                  });
                }
              } catch (err) {
                console.error("Error creating transaction:", err);
                enqueueSnackbar("An error occurred", { variant: "error" });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form className="space-y-8">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  {/* Societies Dropdown */}
                  <div className="group">
                    <label htmlFor="societies" className={labelClasses}>
                      Societies
                    </label>
                    <Field
                      as="select"
                      id="societies"
                      name="societies"
                      className={`${inputClasses} cursor-pointer`}
                    >
                      <option value="">Select Society</option>
                      {societies.map((society) => (
                        <option
                          key={society._id}
                          value={society._id}
                          className="bg-slate-800 text-teal-300"
                        >
                          {society.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="societies"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Amount Input */}
                  <div className="group">
                    <label htmlFor="amount" className={labelClasses}>
                      Amount
                    </label>
                    <Field
                      type="number"
                      id="amount"
                      name="amount"
                      className={inputClasses}
                      placeholder="Enter amount"
                    />
                    <ErrorMessage
                      name="amount"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Transaction Type */}
                  <div>
                    <label htmlFor="transaction_type" className={labelClasses}>
                      Transaction Type
                    </label>
                    <Field
                      as="select"
                      id="transaction_type"
                      name="transaction_type"
                      className={inputClasses}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("transaction_type", value);

                        // Dynamically add to custom types if not already there
                        if (
                          value !== "other" &&
                          value !== "" &&
                          !customTransactionTypes.includes(value)
                        ) {
                          setCustomTransactionTypes([
                            ...customTransactionTypes,
                            value,
                          ]);
                        }
                      }}
                    >
                      <option value="">Select Type</option>
                      <option
                        value="Full Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Full Payment
                      </option>
                      <option
                        value="Partial Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Partial Payment
                      </option>
                      <option
                        value="Installment Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Installment Payment
                      </option>
                      <option
                        value="Salary Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Salary Payment
                      </option>
                      <option
                        value="Expense Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Expense Payment
                      </option>
                      <option
                        value="Resell Payment"
                        className="bg-slate-800 text-teal-300"
                      >
                        Resell Payment
                      </option>
                      <option
                        value="other"
                        className="bg-slate-800 text-teal-300"
                      >
                        Other
                      </option>
                      {customTransactionTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="transaction_type"
                      component="div"
                      className={errorClasses}
                    />

                    {/* Custom Transaction Type Input */}
                    {values.transaction_type === "other" && (
                      <>
                        <Field
                          type="text"
                          id="transaction_type_custom"
                          name="transaction_type_custom"
                          className={`${inputClasses} mt-2`}
                          placeholder="Enter custom transaction type"
                          onChange={(e) => {
                            setFieldValue(
                              "transaction_type_custom",
                              e.target.value
                            );
                            // Dynamically add to custom types
                            if (
                              !customTransactionTypes.includes(e.target.value)
                            ) {
                              setCustomTransactionTypes([
                                ...customTransactionTypes,
                                e.target.value,
                              ]);
                            }
                          }}
                        />
                        <ErrorMessage
                          name="transaction_type_custom"
                          component="div"
                          className={errorClasses}
                        />
                      </>
                    )}
                  </div>

                  {/* Transaction Direction */}
                  <div className="group">
                    <label
                      htmlFor="transaction_direction"
                      className={labelClasses}
                    >
                      Transaction Direction
                    </label>
                    <Field
                      as="select"
                      id="transaction_direction"
                      name="transaction_direction"
                      className={`${inputClasses} cursor-pointer`}
                    >
                      <option value="">Select Direction</option>
                      <option
                        value="Income"
                        className="bg-slate-800 text-teal-300"
                      >
                        Income
                      </option>
                      <option
                        value="Expense"
                        className="bg-slate-800 text-teal-300"
                      >
                        Expense
                      </option>
                    </Field>
                    <ErrorMessage
                      name="transaction_direction"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Payment Method */}
                  <div className="md:col-span-2 group">
                    <label htmlFor="payment_method" className={labelClasses}>
                      Payment Method
                    </label>
                    <Field
                      type="text"
                      id="payment_method"
                      name="payment_method"
                      className={inputClasses}
                      placeholder="Enter payment method"
                    />
                    <ErrorMessage
                      name="payment_method"
                      component="div"
                      className={errorClasses}
                    />
                  </div>

                  {/* Description */}
                  <div className="md:col-span-2 group">
                    <label htmlFor="description" className={labelClasses}>
                      Description (Optional)
                    </label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      rows={4}
                      className={inputClasses}
                      placeholder="Enter additional details about the transaction"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className={errorClasses}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="pt-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-gradient-to-r from-teal-500 to-teal-400 text-white py-4 px-6 rounded-xl font-medium 
                             hover:from-teal-400 hover:to-teal-300 focus:outline-none focus:ring-2 focus:ring-teal-500 
                             focus:ring-offset-2 focus:ring-offset-gray-900 transition-all duration-300 
                             disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-teal-500/20
                             hover:shadow-xl hover:shadow-teal-500/30 transform hover:-translate-y-0.5"
                  >
                    {isSubmitting ? (
                      <span className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Creating...
                      </span>
                    ) : (
                      "Create Transaction"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TransactionCreate;
