// import React, { PureComponent } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";

// export default class MixBarChart extends PureComponent {
//   render() {
//     const { data } = this.props; // Use data passed as props

//     return (
//       <ResponsiveContainer width="100%" height={300}>
//         <BarChart
//           width={500}
//           height={300}
//           data={data}
//           margin={{
//             top: 20,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />

//           <Bar dataKey="totalSoldPlots" stackId="a" fill="#8884d8" />
//           <Bar dataKey="totalBookedPlots" stackId="a" fill="#82ca9d" />
//           <Bar dataKey="totalCustomers" fill="#ffc658" />
//         </BarChart>
//       </ResponsiveContainer>
//     );
//   }
// }

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-3 rounded-lg shadow-xl border border-slate-700">
        <p className="text-teal-300 font-medium mb-1">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className="text-sm text-teal-300/90 py-0.5">
            {entry.name}: {entry.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const MixBarChart = ({ data }) => {
  return (
    <div className="w-full h-[300px] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl p-4 shadow-lg">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#334155" opacity={0.2} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#5eead4" }}
            axisLine={{ stroke: "#334155" }}
          />
          <YAxis tick={{ fill: "#5eead4" }} axisLine={{ stroke: "#334155" }} />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend
            wrapperStyle={{
              paddingTop: "20px",
              color: "#5eead4",
            }}
          /> */}
          <Bar
            dataKey="totalSoldPlots"
            name="Sold Plots"
            fill="#06b6d4"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="totalBookedPlots"
            name="Booked Plots"
            fill="#14b8a6"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="totalCustomers"
            name="Total Customers"
            fill="#0ea5e9"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MixBarChart;
