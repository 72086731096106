import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../store/slices/authSlice";
import { updateEmployeeSalaryDetails } from "../store/slices/salarySlice";
import {
  ChevronDown,
  DollarSign,
  CreditCard,
  Percent,
  Save,
  WalletCards,
  BadgeMinus,
  Ribbon,
} from "lucide-react";

const SalaryManagement = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);

  const [salaryDetails, setSalaryDetails] = useState({
    salary: 0,
    allowances: 0,
    bonuses: 0,
    deductions: 0,
  });

  const [selectedUsername, setSelectedUsername] = useState("");
  const [employeeId, setEmployeeId] = useState(null);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUsername) {
      const selectedUser = users.find(
        (user) => user.username === selectedUsername
      );

      if (selectedUser && selectedUser.employeeData) {
        const { salaryDetails, _id: employeeId } = selectedUser.employeeData;

        if (salaryDetails) {
          const { salary, allowances, bonuses, deductions } = salaryDetails;

          setSalaryDetails({
            salary: salary || 0,
            allowances: allowances || 0,
            bonuses: bonuses || 0,
            deductions: deductions || 0,
          });

          setEmployeeId(employeeId);
        } else {
          console.error("Salary details are missing for the selected user.");
        }
      } else {
        console.error("Selected user does not have employee data.");
      }
    }
  }, [selectedUsername, users]);

  const handleUpdateSalaryDetails = () => {
    if (employeeId) {
      const payload = {
        employeeId,
        salaryDetails: {
          ...salaryDetails,
          cnic:
            users.find((user) => user._id === employeeId)?.employeeData
              ?.personalDetails?.cnic || "default_cnic_value",
        },
      };
      dispatch(updateEmployeeSalaryDetails(payload));
    } else {
      console.error("No employeeId provided for updating salary details.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSalaryDetails({ ...salaryDetails, [name]: parseFloat(value) || 0 });
  };

  const calculateNetSalary = () => {
    const { salary, allowances, bonuses, deductions } = salaryDetails;
    return (salary + allowances + bonuses - deductions).toLocaleString();
  };

  return (
    <div className="flex items-center justify-center p-6">
      <div className="w-full max-w-2xl bg-slate-900/60 backdrop-blur-sm rounded-2xl shadow-2xl border border-teal-300/20 overflow-hidden">
        <div className="p-6 border-b border-teal-300/20">
          <h2 className="text-2xl font-bold text-teal-300 flex items-center">
            <DollarSign className="mr-3 w-6 h-6" />
            Salary Management
          </h2>
        </div>

        <div className="p-6 space-y-6">
          {/* Employee Dropdown */}
          <div>
            <label className="block text-teal-300 mb-2 flex items-center">
              <ChevronDown className="mr-2 w-5 h-5" />
              Select Employee
            </label>
            <div className="relative">
              <select
                value={selectedUsername}
                onChange={(e) => setSelectedUsername(e.target.value)}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 appearance-none focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300"
              >
                <option value="">-- Select Username --</option>
                {users.map((user) =>
                  user.employeeData ? (
                    <option
                      key={user._id}
                      value={user.username}
                      className="bg-slate-800 text-teal-300"
                    >
                      {user.username} - {user.employeeData.designation}
                    </option>
                  ) : null
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-teal-300">
                <ChevronDown className="w-5 h-5" />
              </div>
            </div>
          </div>

          {/* Salary Details Inputs */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-teal-300 mb-2 flex items-center">
                <CreditCard className="mr-2 w-5 h-5" />
                Salary
              </label>
              <input
                type="number"
                name="salary"
                value={salaryDetails.salary}
                onChange={handleChange}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300"
              />
            </div>
            <div>
              <label className="block text-teal-300 mb-2 flex items-center">
                <WalletCards className="mr-2 w-5 h-5" />
                Allowances
              </label>
              <input
                type="number"
                name="allowances"
                value={salaryDetails.allowances}
                onChange={handleChange}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300"
              />
            </div>
            <div>
              <label className="block text-teal-300 mb-2 flex items-center">
                <Ribbon className="mr-2 w-5 h-5" />
                Bonuses
              </label>
              <input
                type="number"
                name="bonuses"
                value={salaryDetails.bonuses}
                onChange={handleChange}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300"
              />
            </div>
            <div>
              <label className="block text-teal-300 mb-2 flex items-center">
                <BadgeMinus className="mr-2 w-5 h-5" />
                Deductions
              </label>
              <input
                type="number"
                name="deductions"
                value={salaryDetails.deductions}
                onChange={handleChange}
                className="w-full bg-slate-800 text-teal-300 border border-teal-300/30 rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-300"
              />
            </div>
          </div>

          {/* Net Salary Display */}
          <div className="bg-slate-800 rounded-lg p-4 border border-teal-300/20">
            <div className="flex justify-between items-center">
              <span className="text-teal-300 font-semibold">Net Salary</span>
              <span className="text-2xl font-bold text-teal-300">
                PKR {calculateNetSalary()}
              </span>
            </div>
          </div>

          {/* Update Button */}
          <button
            onClick={handleUpdateSalaryDetails}
            className="w-full bg-teal-600 hover:bg-teal-700 text-white py-3 rounded-lg flex items-center justify-center space-x-2 transition-all duration-300 transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            <Save className="w-5 h-5 mr-2" />
            Update Salary Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalaryManagement;
