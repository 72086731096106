import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../store/slices/transactionSlice";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

const IncomeDirection = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);

  const [dateRange, setDateRange] = useState("All Dates");

  // Consolidated filter state
  const [filters, setFilters] = useState({
    name: "",
    society: "",
    transactionType: "",
    amount: "",
    date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const plotsPerPage = 10;

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const getDateFilteredTransactions = () => {
    const now = new Date();
    let startDate;

    switch (dateRange) {
      case "Last Year":
        startDate = new Date(
          now.getFullYear() - 1,
          now.getMonth(),
          now.getDate()
        );
        break;
      case "Last 6 Months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 3 Months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last Month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last Day":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        break;
      case "All Dates":
        // No date filter applied, return income transactions
        return transactions.filter((t) => t.transaction_direction === "Income");
      default:
        return transactions.filter((t) => t.transaction_direction === "Income");
    }

    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.transaction_date);
      return (
        transactionDate >= startDate &&
        transaction.transaction_direction === "Income"
      );
    });
  };

  const filteredTransactions = getDateFilteredTransactions().filter(
    (transaction) => {
      const matchesName = filters.name
        ? transaction.customer_id?.name
            ?.toLowerCase()
            .includes(filters.name.toLowerCase()) ||
          transaction.employee_id?.name
            ?.toLowerCase()
            .includes(filters.name.toLowerCase())
        : true;
      const matchesSociety = filters.society
        ? transaction.customer_id?.societies?.some((s) =>
            s.name.toLowerCase().includes(filters.society.toLowerCase())
          )
        : true;
      const matchesTransactionType = filters.transactionType
        ? transaction.transaction_type === filters.transactionType
        : true;
      const matchesAmount = filters.amount
        ? transaction.amount.toString().includes(filters.amount)
        : true;
      const matchesDate = filters.date
        ? new Date(transaction.transaction_date).toDateString() ===
          new Date(filters.date).toDateString()
        : true;

      return (
        matchesName &&
        matchesSociety &&
        matchesTransactionType &&
        matchesAmount &&
        matchesDate
      );
    }
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get transactions for the current page
  const indexOfLastTransaction = currentPage * plotsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - plotsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const calculateTotal = (transactions) =>
    transactions.reduce((sum, t) => sum + Number(t.amount || 0), 0);

  const paymentIncome = transactions.filter((t) =>
    ["Income"].includes(t.transaction_direction)
  );

  const totalIncome = calculateTotal(paymentIncome);

  const exportTransactionsToExcel = (transactions, period) => {
    const dataToExport = transactions.map((transaction) => ({
      Date: new Date(transaction.transaction_date).toLocaleDateString(),
      "Customer Name":
        transaction.customer_id?.name ||
        transaction.employee_id?.name ||
        transaction.user_id?.investorData?.name ||
        "N/A",
      "Society Name":
        transaction.customer_id?.societies
          ?.map((society) => society.name)
          .join(", ") ||
        transaction.employee_id?.society?.name ||
        "N/A",
      Description: transaction.description || "N/A",
      Amount: `${transaction.amount?.toLocaleString()} PKR`,
      Status: transaction.status || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Income Transactions");

    const fileName = `income_transactions_${period}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const handleExportToExcel = () => {
    Swal.fire({
      title: "Export Income Transactions",
      html: `
      <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl">
        <p class="text-teal-300 mb-4">Select the period for export:</p>
       <select 
  id="exportPeriod" 
  class="swal2-select bg-slate-800 text-teal-300 border border-slate-700 rounded-md p-2 outline-none focus:ring-2 focus:ring-teal-500">
  <option value="all" class="bg-slate-800 text-teal-300">All Time</option>
  <option value="day" class="bg-slate-800 text-teal-300">Today</option>
  <option value="week" class="bg-slate-800 text-teal-300">This Week</option>
  <option value="month" class="bg-slate-800 text-teal-300">This Month</option>
  <option value="year" class="bg-slate-800 text-teal-300">This Year</option>
</select>
      </div>
    `,
      confirmButtonText: "Export",
      focusConfirm: false,
      background: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
      customClass: {
        popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
        title: "text-teal-300",
        confirmButton:
          "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
        content: "bg-slate-800 p-6 rounded-lg",
      },
      preConfirm: () => {
        return document.getElementById("exportPeriod").value;
      },
      willOpen: () => {
        const style = document.createElement("style");
        style.innerHTML = `
        #exportPeriod {
          color: #81E6D9; 
        }
        #exportPeriod:focus {
          background-color: #1E293B;
        }
      `;
        document.head.appendChild(style);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const period = result.value;
        exportTransactionsToExcel(getDateFilteredTransactions(), period);
      }
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-4 md:p-8">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent">
            Income Transactions
          </h2>
          {loading && (
            <div className="text-sm text-gray-400 animate-pulse">
              Loading data...
            </div>
          )}
          {/* Date Range Dropdown */}
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="ml-4 bg-slate-800 text-gray-400 border border-slate-700 p-2 rounded"
          >
            <option>All Dates</option>
            <option>Last Year</option>
            <option>Last 6 Months</option>
            <option>Last 3 Months</option>
            <option>Last Month</option>
            <option>Last Day</option>
            <option>Today</option>
          </select>
        </div>

        {/* Total Income Card */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 items-center">
          <div className="bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700">
            <div className="p-6">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-gradient-to-r from-teal-400/10 to-teal-400/10 rounded-full">
                  <div className="w-6 h-6 text-teal-400">📊</div>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-400">
                    Total Income
                  </p>
                  <p className="text-xl font-bold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent">
                    {totalIncome.toLocaleString()} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-3">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={filters.name}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <input
            type="text"
            name="society"
            placeholder="Society"
            value={filters.society}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <div className="flex-1">
            <select
              name="transactionType"
              value={filters.transactionType}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
            >
              <option value="">All Payment</option>
              <option value="Full Payment">Full Payment</option>
              <option value="Partial Payment">Partial Payment</option>
              <option value="Installment Payment">Installment Payment</option>
              <option value="other">Other Payment</option>
            </select>
          </div>
          <input
            type="text"
            name="amount"
            placeholder="Amount"
            value={filters.amount}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <div className="relative">
            <input
              type="date"
              name="date"
              placeholder="Date"
              value={filters.date}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none appearance-none pl-10"
            />
            <span className="absolute left-3 top-2.5 text-teal-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4V2m0 12h8m-8 0H4m8-8V4m4 4h4m-4 4h4"
                />
              </svg>
            </span>
          </div>
        </div>

        {/* Transactions Table */}
        <div className="bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700 overflow-hidden">
          <div className="p-6 border-b border-slate-700 flex justify-between items-center">
            <h3 className="text-3xl font-semibold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent">
              Income Transactions
            </h3>
            <button
              onClick={handleExportToExcel}
              className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 transition"
            >
              Export to Excel
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full bg-slate-800 rounded-lg text-teal-300">
              <thead>
                <tr className="bg-slate-900 rounded-lg text-teal-300">
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Societies
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Transaction Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-700">
                {currentTransactions.map((transaction, index) => (
                  <tr
                    key={transaction._id}
                    className={`transition-colors duration-300 ${
                      index % 2 === 0 ? "bg-slate-700" : "bg-slate-800"
                    } hover:bg-slate-600`}
                  >
                    <td className=" border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type === "Salary Payment" ? (
                        <>
                          Employee
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.employee_id?.name || "No Employee"}
                          </span>
                        </>
                      ) : transaction.transaction_type === "Expense Payment" ? (
                        <>
                          Investor
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.user_id?.investorData?.name ||
                              "No Investor"}
                          </span>
                        </>
                      ) : (
                        <>
                          Customer
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.customer_id?.name || "No Customer"}
                          </span>
                        </>
                      )}
                    </td>
                    <td className=" border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type === "Salary Payment"
                        ? transaction.employee_id?.society?.name || "No Society"
                        : transaction.transaction_type === "Expense Payment" &&
                          transaction.user_id?.investorData?.societies &&
                          transaction.user_id.investorData.societies.length > 0
                        ? transaction.user_id.investorData.societies
                            .map((society) => society.name)
                            .join(", ")
                        : transaction.transaction_type === "Expense Payment"
                        ? "No Society"
                        : transaction.customer_id?.societies &&
                          transaction.customer_id.societies.length > 0
                        ? transaction.customer_id.societies
                            .map((society) => society.name)
                            .join(", ")
                        : "No Society"}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_direction}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.amount.toLocaleString()} PKR
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {new Date(
                        transaction.transaction_date
                      ).toLocaleDateString()}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex space-x-2"></div>
        </div>
        <div className="flex justify-center gap-4 mt-6">
          {Array.from(
            { length: Math.ceil(filteredTransactions.length / plotsPerPage) },
            (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 text-sm rounded-lg border ${
                  currentPage === index + 1
                    ? "bg-teal-400 text-white"
                    : "bg-slate-700 text-teal-300"
                }`}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default IncomeDirection;
